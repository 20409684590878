import {
    RECEIVE_USER_CARDS,
} from '../../actions/allActions';

const userCardsReducer = (state = [], action) => { 
	Object.freeze(state);
	// let nextState = _.cloneDeep(state)
	let deckId;

	switch (action.type) {
		case RECEIVE_USER_CARDS:
			return action.payload.cards;
		default:
			return state;
	}
}

export default userCardsReducer;