import React, { Component } from 'react';
import getConfig from "./UserBrowseConfig";
import { connect } from 'react-redux';
import { Search, X } from 'react-feather';
import { impersonateUser } from '../../actions/allActions';

const $ = require('jquery');
$.DataTable = require('datatables.net');
require("datatables.net-responsive");

class UserBrowse extends Component {
    componentDidMount() {
        $('#loader-wrapper').hide();
        $('#browse-table-wrapper').show();

        const config = getConfig();
        const table = $('#browse-table').DataTable(config);

        table.on('draw', () => {
            $('.button--impersonate-user').on('click', (e) => {
                const userId = parseInt(e.target.getAttribute('data-user-id'));
                this.props.impersonateUser(userId).then(() => this.props.history.push('/'));
            });
            $('#results-count').text(table.page.info().recordsDisplay);
        });
        
        $('#browse-table').on('click', 'td.dtr-control', () => {
            $('.button--impersonate-user').on('click', (e) => {
                const userId = parseInt(e.target.getAttribute('data-user-id'));
                this.props.impersonateUser(userId).then(() => this.props.history.push('/'));
            });
        });

        $('#browse-table-search').on('keyup', function () {
            table.search(this.value).draw();
            $('#results-count').text(table.page.info().recordsDisplay);
        });

        $('th.sorting').on('click', function () {
            const filter = $(this).text();
            setTimeout(() => { table.draw(); }, 250);
            ahoy.track("Click Browse Filter", { filter });
        });
        this.table = table;
        setTimeout(() => { table.draw(); }, 250);
    }

    render() {
        return (
            <div className="layout layout--browse">
                <div className="layout--browse__container">
                    <div className="layout--browse__container__main">
                        <h1>Browse <span>Users</span></h1>
                        <div className='search-wrapper'>
                            <div className="browse-search">
                                <Search />
                                <input id="browse-table-search" className="browse-search__input" type="text" placeholder="Search for any user..." />
                            </div>
                        </div>
                        <div className='count-wrapper'><span id="results-count" className='count'>0</span><span className='label'>Users</span></div>
                        <div id="loader-wrapper" className="loader-wrapper">
                            <div className="lds-ellipsis">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                        <div id="browse-table-wrapper" className="data-table-wrapper">
                            <table id="browse-table" className="responsive" style={{ width: '100%' }} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    impersonateUser: (userId) => dispatch(impersonateUser(userId)),
});

export default connect(null, mapDispatchToProps)(UserBrowse);
