import { 
	RECEIVE_STUDY_PROFILES,
	RECEIVE_STUDY_PROFILE
} from '../../actions/allActions';

const studyProfilesReducer = (state = [], action) => { 
	Object.freeze(state);
	let nextState = _.cloneDeep(state);

	switch (action.type) {
		case RECEIVE_STUDY_PROFILES:
			return action.payload;
		case RECEIVE_STUDY_PROFILE:
			const studyProfileId = action.payload.studyProfile.id;
            const studyProfile = action.payload.studyProfile;
			nextState[studyProfileId] = studyProfile;
			return nextState;
		default:
			return state;
	}
}

export default studyProfilesReducer;
