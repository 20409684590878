import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import isMobile from '../../util/isMobile';
import parse from 'html-react-parser';

function ProTip({ user }) {
    const [showTip, setShowTip] = useState(false);
    const [showStudyTip, setShowStudyTip] = useState(false);
    let location = useLocation();
    let proTips = useRef({});
    let PRO_TIPS_DEFAULTS = {
        index: [
            "You can press <code>Q</code> for a bit of fun.",
            "You can track your progress on the Stats tab. Press <code>S</code> to navigate quickly.",
            "You can search for cards and edit cards in the Browse tab. Press <code>C</code> to navigate quickly.",
            "You can import your Anki decks using the Import Deck feature. Press <code>I</code> to navigate quickly.",
            "You can press <code>N</code> to quickly create a new deck."
        ],
        import: [
            "You can press <code>I</code> to navigate to the Import page."
        ],
        stats: [
            "You can press <code>S</code> to navigate to the Stats page."
        ],
        builder: [
            "You can press <code>CTRL/CMD+ENTER</code> to quickly create a card.",
            "You can press <code>CTRL/CMD+1</code> to create a cloze group.",
            "You can press <code>CTRL/CMD+H</code> to add a hint to a cloze group."
        ],
        study: [
            "You can press <code>Z</code> to undo a rating response.",
            "You can press <code>E</code> to edit a card.",
            "You can press <code>R</code> to report a card.",
            "You can press <code>S</code> to star a card."
        ]
    };
    const THIEME_TIPS = {
        index: [
            "You can press <code>Q</code> for a bit of fun.",
            "You can track your progress on the Stats tab. Press <code>S</code> to navigate quickly.",
        ],
        import: [
            "You can press <code>I</code> to navigate to the Import page."
        ],
        stats: [
            "You can press <code>S</code> to navigate to the Stats page."
        ],
        builder: [
            "You can press <code>CTRL/CMD+ENTER</code> to quickly create a card.",
            "You can press <code>CTRL/CMD+1</code> to create a cloze group.",
            "You can press <code>CTRL/CMD+H</code> to add a hint to a cloze group."
        ],
        study: [
            "You can press <code>Z</code> to undo a rating response.",
            "You can press <code>R</code> to report a card.",
        ]
    };

    const renderProTip = (content) => {
        const proTipToastStructure = (
            <div className='toast--pro-tip'>
                <span className="icon" role="img" aria-label="lightbulb">💡</span>
                <span>Pro Tip: {parse(content)}</span>
            </div>
        );
        toast(() => proTipToastStructure, {
            position: "bottom-left",
            autoClose: 7500,
            delayShow: 1000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light"
        });
    }

    const instantiateProTips = () => {
        let tipsObject = {};
        for (let key in PRO_TIPS_DEFAULTS) {
            tipsObject[key] = 0;
        }
        return tipsObject;
    }

    const findTipToRender = (page) => {
        if (proTips.current[page] >= PRO_TIPS_DEFAULTS[page].length) {
            return;
        }
        renderProTip(PRO_TIPS_DEFAULTS[page][proTips.current[page]]);
        if (page === 'study') {
            setShowStudyTip(false);
            localStorage.setItem('syn-pts-v2-study-seen-first-tip', 'true');
            localStorage.setItem('syn-pts-v2-study-last-seen', new Date());
        } else {
            setShowTip(false);
            localStorage.setItem('syn-pts-v2-last-seen', new Date());
        }
        proTips.current[page] = ++proTips.current[page];
        localStorage.setItem('syn-pts-v2', JSON.stringify(proTips.current));
    }

    const initiateRender = () => {
        if (!isMobile() && showTip) {
            if (location.pathname === '/') {
                findTipToRender('index');
            }
            if (location.pathname.includes('/upload')) {
                findTipToRender('import');
            }
            if (location.pathname.includes('/stats')) {
                findTipToRender('stats');
            }
            if (location.pathname.includes('/builder')) {
                findTipToRender('builder');
            }
        }
        if ((!isMobile() && showStudyTip) || localStorage.getItem('syn-pts-v2-study-seen-first-tip') === 'false') {
            if (location.pathname.includes('/deck')) {
                findTipToRender('study');
            }
        }
    }

    const syncLocalStorage = () => {
        // Get Last Seen Data, Don't Show Tip on First Login
        if (localStorage.getItem('syn-pts-v2-last-seen') !== null) {
            let OneDay = new Date().getTime() - (24 * 60 * 60 * 1000);
            setShowTip(OneDay > Date.parse(localStorage.getItem('syn-pts-v2-last-seen')));
        } else {
            localStorage.setItem('syn-pts-v2-last-seen', new Date());
        }
        // Study
        if (localStorage.getItem('syn-pts-v2-study-last-seen') !== null) {
            let OneDay = new Date().getTime() - (24 * 60 * 60 * 1000);
            setShowStudyTip(OneDay > Date.parse(localStorage.getItem('syn-pts-v2-study-last-seen')));
        } else {
            localStorage.setItem('syn-pts-v2-study-last-seen', new Date());
            localStorage.setItem('syn-pts-v2-study-seen-first-tip', false);
        }

        // Get User Data for Pro Tips from Storage
        // If user does not have a saved ProTips object, instantiate it.
        if (localStorage.getItem('syn-pts-v2') === null) {
            proTips.current = instantiateProTips();
            localStorage.setItem('syn-pts-v2', JSON.stringify(proTips.current));
        } else {
            proTips.current = JSON.parse(localStorage.getItem('syn-pts-v2'));
        }
    }

    const checkOnboardingParameter = () => {
        const urlObj = new URL(window.location.href);
        if (urlObj.hash?.includes('onboarding=true')) {
            return true;
        }
        return false;
    }

    useEffect(() => {
        // Replace with Thieme Tips If Necessary
        if (user.group === 'thieme') {
            PRO_TIPS_DEFAULTS = THIEME_TIPS;
        }
        syncLocalStorage();
        if (!checkOnboardingParameter()) {
            initiateRender();
        }
    }, [])

    useEffect(() => {
        // Replace with Thieme Tips If Necessary
        if (user.group === 'thieme') {
            PRO_TIPS_DEFAULTS = THIEME_TIPS;
        }
        syncLocalStorage();
        if (!checkOnboardingParameter()) {
            initiateRender();
        }
    }, [showTip, showStudyTip, location]);

    return null;
};

const mapStateToProps = (state) => ({
    user: state.user,
});
const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ProTip);
