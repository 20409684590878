import React, { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip';
import { getResponseLabel } from '../../util/cardUpdater';

const FlashCardActions = (props) => {
    const [multichoiceRight, setMultichoiceRight] = useState(true);

    useEffect(() => {
        if (props.card?.model === 'multichoice' && props.isFlipped) {
            const correctAnswer = props.card.multi_choice_answer;
            const correctChoiceEl = document.getElementById(`choice-${correctAnswer}`);
            const checkedChoiceEl = document.querySelector('input[name="multichoice"]:checked')?.parentElement;
            if (correctChoiceEl && checkedChoiceEl) {
                setMultichoiceRight(correctChoiceEl === checkedChoiceEl);
            }
        }
    }, [props.isFlipped]);

    const renderActions = () => {
        const isLoading = _.isEmpty(props.card);
        const intervalHelp = "The time until you will see this card again.";

        let backSideActions;
        if (props.userStudySettings?.num_responses === 2) {
            backSideActions = (
                <>
                    <div className="button-bar_items_item">
                        <button data-tip="1" data-answer="minus1" className="button button--rating button--wrong" onClick={(e) => props.handleButtonResponse(e)}>Wrong</button>
                        <div className='button-bar_items_item_hint' data-tip={intervalHelp}>{getResponseLabel(props.card, 'minus1', props.userStudySettings)}</div>
                    </div>
                    <div className="button-bar_items_item">
                        <button data-tip="2" data-answer="plus1" className="button button--rating button--right" onClick={(e) => props.handleButtonResponse(e)}>Right</button>
                        <div className='button-bar_items_item_hint' data-tip={intervalHelp}>{getResponseLabel(props.card, 'plus1', props.userStudySettings)}</div>
                    </div>
                    <ReactTooltip place="top" type="dark" effect="solid" delayShow={1000} />
                </>
            )
        } else if (props.userStudySettings?.num_responses === 4) {
            backSideActions = (
                <>
                    <div className="button-bar_items_item">
                        <button data-tip="1" data-answer="minus2" className="button button--rating button--again" onClick={(e) => props.handleButtonResponse(e)}>Again</button>
                        <div className='button-bar_items_item_hint' data-tip={intervalHelp}>{getResponseLabel(props.card, 'minus2', props.userStudySettings)}</div>
                    </div>
                    <div className="button-bar_items_item">
                        <button data-tip="2" data-answer="minus1" className="button button--rating button--wrong" onClick={(e) => props.handleButtonResponse(e)}>Wrong</button>
                        <div className='button-bar_items_item_hint' data-tip={intervalHelp}>{getResponseLabel(props.card, 'minus1', props.userStudySettings)}</div>
                    </div>
                    <div className="button-bar_items_item">
                        <button data-tip="3" data-answer="plus1" className="button button--rating button--right" onClick={(e) => props.handleButtonResponse(e)}>Right</button>
                        <div className='button-bar_items_item_hint' data-tip={intervalHelp}>{getResponseLabel(props.card, 'plus1', props.userStudySettings)}</div>
                    </div>
                    <div className="button-bar_items_item">
                        <button data-tip="4" data-answer="plus2" className="button button--rating button--easy" onClick={(e) => props.handleButtonResponse(e)}>Easy</button>
                        <div className='button-bar_items_item_hint' data-tip={intervalHelp}>{getResponseLabel(props.card, 'plus2', props.userStudySettings)}</div>
                    </div>
                    <ReactTooltip place="top" type="dark" effect="solid" delayShow={1000} />
                </>
            )
        } else {
            backSideActions = (
                <>
                    <div className="button-bar_items_item">
                        <button data-tip="1" data-answer="minus1" className="button button--rating button--wrong" onClick={(e) => props.handleButtonResponse(e)}>Wrong</button>
                        <div className='button-bar_items_item_hint' data-tip={intervalHelp}>{getResponseLabel(props.card, 'minus1', props.userStudySettings)}</div>
                    </div>
                    <div className="button-bar_items_item">
                        <button data-tip="2" data-answer="plus1" className="button button--rating button--right" onClick={(e) => props.handleButtonResponse(e)}>Right</button>
                        <div className='button-bar_items_item_hint' data-tip={intervalHelp}>{getResponseLabel(props.card, 'plus1', props.userStudySettings)}</div>
                    </div>
                    <div className="button-bar_items_item">
                        <button data-tip="3" data-answer="plus2" className="button button--rating button--easy" onClick={(e) => props.handleButtonResponse(e)}>Easy</button>
                        <div className='button-bar_items_item_hint' data-tip={intervalHelp}>{getResponseLabel(props.card, 'plus2', props.userStudySettings)}</div>
                    </div>
                    <ReactTooltip place="top" type="dark" effect="solid" delayShow={1000} />
                </>
            )
        }
        if (props.card?.model === 'multichoice') {
            const multichoiceStudySettings = {
                multiple: 4,
                num_responses: 4,
                // 1d, 14d, 56d
                steps: [1440, 20160, 80640]
            }
            backSideActions = (
                <>
                    <div className="button-bar_items_item">
                        <button data-answer={multichoiceRight ? 'plus1' : 'minus2'} className="button button--rating button--right" onClick={(e) => props.handleButtonResponse(e)}>Next</button>
                        <div className='button-bar_items_item_hint' data-tip={intervalHelp}>{getResponseLabel(props.card, multichoiceRight ? 'plus1' : 'minus2', multichoiceStudySettings)}</div>
                    </div>
                    <ReactTooltip place="top" type="dark" effect="solid" delayShow={1000} />
                </>
            )
        }
        const frontSideActions = (
            <>
                <div className="button-bar_items_item">
                    <button
                        className="button button--reveal"
                        onClick={() => !isLoading && props.flipCard()}
                        disabled={props.card?.model === 'multichoice' && !props.multichoiceSelected}
                    >
                        {props.card?.model === 'multichoice' ? 'Submit Answer' : 'Reveal Answer'}
                    </button>
                    <div className='button-bar_items_item_hint spacebar-hint'>SPACEBAR</div>
                </div>
            </>
        );
        return props.isFlipped ? backSideActions : frontSideActions;
    }

    return (
        <React.Fragment>
            <div className={`button-bar ${props.isFlipped ? 'button-bar--assess' : ''} ${'button-bar--' + props.userStudySettings?.num_responses + '-options'} ${props.card?.model === 'multichoice' ? 'button-bar--multichoice' : ''}`}>
                <div className="button-bar__label">{props.card?.model === 'multichoice' ? '' : 'ASSESS YOUR KNOWLEDGE'}</div>
                <div className="button-bar_items">
                    {renderActions()}
                </div>
            </div>
            <ReactTooltip />
        </React.Fragment>
    )
}

export default FlashCardActions;
