import _ from 'lodash';
import React, { Component } from 'react';
// Docs: https://react-select.com/home
import Select from 'react-select';
import customStyles from '../../util/singleSelectStyles';

export default class SingleDeckSelector extends Component {
    handleChange = (selectedOption) => {
        _.isEmpty(selectedOption) ?
            this.props.setSelectedDecks(null) :
            this.props.setSelectedDecks(selectedOption.value);
    }

    render() {
        return (
            <div className='deck-selector'>
                <div className='deck-selector__label'>{this.props.label}</div>
                <div className='deck-selector_wrapper'>
                    <Select
                        defaultValue={this.props.defaultParent}
                        placeholder={'None'}
                        isClearable
                        isSearchable
                        options={this.props.options}
                        onChange={this.handleChange}
                        styles={customStyles}
                        onMenuOpen={this.props.suspendFocusTrap}
                        onMenuClose={this.props.enableFocusTrap}
                        menuPortalTarget={document.body}
                        menuPlacement="auto"
                        menuPosition="fixed"
                    />
                </div>
            </div>
        )
    }
}
