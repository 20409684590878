import {
    RECEIVE_ALL_PROGRESS,
    RECEIVE_PROGRESS,
    RECEIVE_DECK_PROGRESS,
	RECEIVE_PERCENT_MASTERY
} from '../../actions/allActions';

const progressReducer = (state = {}, action) => { 
	Object.freeze(state);
	let nextState = _.cloneDeep(state)

	switch (action.type) {
		case RECEIVE_ALL_PROGRESS:
			return action.payload;
		case RECEIVE_DECK_PROGRESS:
			nextState[action.payload.deckId] = action.payload.progress;
			return nextState;
		case RECEIVE_PERCENT_MASTERY:
			let deckProgress = nextState[action.payload.deckId];
			deckProgress.percentMastery = action.payload.percentMastery;
			return nextState;
		default:
			return state;
	}
}

export default progressReducer;