import React from 'react';
import { Quill } from 'react-quill';
import { fetchPresignedUrl } from '../../actions/allActions';
import ImageResize from 'quill-image-resize-module-react';
const Delta = Quill.import('delta');
const Inline = Quill.import('blots/inline');

/*
 * Event handler to be attached using Quill toolbar module
 * https://quilljs.com/docs/modules/toolbar/
 */
function handleCloze(value) {
  const range = this.quill.getSelection();
  if (!!this.quill.getFormat().cloze && this.quill.getFormat().cloze.group == value) {
    this.quill.formatText(range.index, range.length, 'cloze', false);
  } else {
    this.quill.formatText(range, 'cloze', { group: value });

    // if the cursor is at the end of the doc, add an unformatted space after the cloze
    if ((range.index + range.length + 1) == this.quill.getLength()) {
      this.quill.insertText(range.index + range.length, ' ');
      this.quill.formatText(range.index + range.length, 1, 'cloze', false);
    }
  }
}

function imageHandler() {
  const MIME_TYPE = "image/jpeg";
  const GIF_MIME_TYPE = "image/gif";
  const QUALITY = 0.8;

  const input = document.createElement('input');

  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');
  input.click();

  input.onchange = async () => {
      const tempFile = input.files[0];
      const newFileName = tempFile.name.slice(0, -4) + '-' + genRandId(8) + tempFile.name.slice(-4);
      const file = new File([tempFile], newFileName, {type: tempFile.type});

      if (/^image\//.test(file.type)) {
        const range = this.quill.getSelection();

        // Insert temporary loading placeholder image
        this.quill.insertEmbed(range.index, 'image', `https://d1lze488p2cr08.cloudfront.net/assets/synaptiq-img-loading-sm.gif`);

        // Move cursor to right side of image (easier to continue typing)
        this.quill.setSelection(range.index + 1);

        if (file.type === GIF_MIME_TYPE) {
          uploadImgToS3(file, this.quill, range);
        } else {
          const blobURL = URL.createObjectURL(file);
          const img = new Image();
          img.src = blobURL;
  
          img.onerror = () => {
            URL.revokeObjectURL(img.src);
            // Handle the failure properly
          };
  
          img.onload = () => {
            URL.revokeObjectURL(img.src);
            const [newWidth, newHeight] = calculateSize(img);
            const canvas = document.createElement("canvas");
            canvas.width = newWidth;
            canvas.height = newHeight;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, newWidth, newHeight);
            canvas.toBlob(
              async (blob) => {
                const newFile = new File([blob], file.name);
                uploadImgToS3(newFile, this.quill, range);
              },
              MIME_TYPE,
              QUALITY
            );
          };
        }
      } else {
        alert('You can only upload images.');
      }
  }
}

const uploadImgToS3 = async (file, quill, range) => {
  const bucket = 'synaptiq-prod';
  const referralToken = window.getUserReferralToken()
  const dirName = `images/${referralToken}/`;
  const data = await fetchPresignedUrl(bucket, dirName, file.name);

  if (data.url) {
    $.ajax({
      url : data.url,
      type : "PUT",
      data : file,
      dataType : "text",
      cache : false,
      contentType : file.type,
      processData : false,
      error: (res) => {
          console.log('error: ' + JSON.stringify(res))
      },
      success: (res) => {
        // Remove placeholder image
        quill.deleteText(range.index, 1);
        // Insert uploaded image
        quill.insertEmbed(range.index, 'image', `https://d1lze488p2cr08.cloudfront.net/images/${referralToken}/${file.name}`);
      }
    })  
  }
}

function calculateSize(img) {
  const MAX_WIDTH = 1080;
  const MAX_HEIGHT = 1080;

  let width = img.width;
  let height = img.height;

  // calculate the width and height, constraining the proportions
  if (width > height) {
    if (width > MAX_WIDTH) {
      height = Math.round((height * MAX_WIDTH) / width);
      width = MAX_WIDTH;
    }
  } else {
    if (height > MAX_HEIGHT) {
      width = Math.round((width * MAX_HEIGHT) / height);
      height = MAX_HEIGHT;
    }
  }

  return [width, height];
}

function genRandId(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

const bindings = {
  enter: {
    key: 13,
    shortKey: true,
    handler: function() {
      window.submitShortcut && window.submitShortcut();
    }
  },
  tab: {
    key: 9,
    handler: function() {
      window.setFocusToAnswerField();
      // if (hasSomeParentWithClass(document.activeElement, 'question-container')) {
      //   window.setFocusToAnswerField();
      // } else {
      //   window.setFocusToQuestionField();
      // }
    }
  }
};

const hasSomeParentWithClass = (element, classname) => {
  if (element.className.split(' ').indexOf(classname)>=0) return true;
  return element.parentNode && hasSomeParentWithClass(element.parentNode, classname);
}

// Remove space after cloze if punctuation added
export const addQuillListeners = (quill, handleCreate) => {
  quill.on('text-change', (delta, oldContents, source) => {
    if (source !== 'user') return;
    const range = quill.getSelection();
    // Return if selection is null
    if (!range) { return; }
    // Return if not at end of editor
    if ((range.index + range.length + 1) !== quill.getLength()) return;
    
    const punctuation = ['"', '\'', '.', ',', '!', ')', '}', ']', ':', ';']
    // Sometimes fires every char, sometimes only on space for the previous word (quill bug)
    // This account for both cases
    let char = delta.ops?.[delta.ops?.length - 1]?.insert;
    char = char?.length > 1 ? char[char?.length - 1] : char;
    
    if (punctuation.includes(char)) {
      const twoBack = quill.getText(range.index - 2, 1);
      const isAfterPlainSpace = twoBack === ' ' && !quill.getFormat(range.index - 2, 1).cloze;
      const isAfterCloze = !!quill.getFormat(range.index - 3, 1).cloze;
      if (isAfterPlainSpace && isAfterCloze) {
        quill.deleteText(range.index - 2, 1);
        quill.insertText(range.index - 1, ' ');
        quill.setSelection(range.index, 0);
      }
    }
  });

  // Cloze hint
  quill.keyboard.addBinding({
    key: 'h',
    shortKey: true
  }, function(range, context) {
    if (!!quill.getFormat().cloze) {
      const group = quill.getFormat().cloze.group;
      let start = range.index;
      for (let i = range.index; i >= 0 && !!quill.getFormat(i, 0).cloze && quill.getFormat(i, 0).cloze.group == group; i--) {
        start = i
      }
      // Make sure start is the first index before the target text that isn't cloze
      if (!!quill.getFormat(start, 0).cloze && start > 0) {
        start = start - 1;
      }
      let end = range.index + range.length
      for (let i = range.index; end < quill.getLength() && !!quill.getFormat(i, 0).cloze && quill.getFormat(i, 0).cloze.group == group; i++) {
        end = i
      }
      const index = start;
      const length = end - start + 1;
      quill.formatText(index, length, 'cloze', false);
      quill.insertText(end, ' | ');
      quill.formatText(index, length + 2, 'cloze', { group });
      quill.setSelection(end + 3, 0);
    }
  });
  
  // Cloze group keybinds
  for (let i = 0; i <= 9; i++) {
    const key = i.toString();
    quill.keyboard.addBinding({ 
      key: key, 
      shortKey: true
    }, function(range, context) {
      const groupNum = key === '0' ? '10' : key;
      if (!!quill.getFormat().cloze && quill.getFormat().cloze.group == groupNum) {
        quill.formatText(range.index, range.length, 'cloze', false);
      } else {
        if (range.length == 0) {
          quill.insertText(range.index, ' ');
          quill.formatText(range.index, 1, 'cloze', { group: groupNum });
          if ((range.index + 2) == quill.getLength()) {
            quill.insertText(range.index + 1, ' ');
            quill.formatText(range.index + 1, 1, 'cloze', false);
            quill.setSelection(range.index, 1);
          }
        } else {
          quill.removeFormat(range);
          quill.formatText(range, 'cloze', { group: groupNum });
  
          // if the cursor is at the end of the doc, add an unformatted space after the cloze
          if ((range.index + range.length + 1) == quill.getLength()) {
            quill.insertText(range.index + range.length, ' ');
            quill.formatText(range.index + range.length, 1, 'cloze', false);
            quill.setSelection(range.index, range.length);
          }
        }
      }
    });
  }

  addImgPasteListener(quill);
}

const addImgPasteListener = (quill) => {
  quill.clipboard.addMatcher('img', (node, delta) => {
    if (node.src.includes('d1lze488p2cr08.cloudfront.net')) return delta;
    fetch(node.src)
      .then(res => res.blob())
      .then(blob => {
        const range = quill.getSelection();
        range.index = range.index - 1;

        const blobURL = URL.createObjectURL(blob);

        const img = new Image();
        img.src = blobURL;

        img.onerror = () => {
          URL.revokeObjectURL(img.src);
          quill.deleteText(range.index, 1);
          quill.insertEmbed(range.index, 'image', `https://d1lze488p2cr08.cloudfront.net/assets/upload-failed-sm.png`);
        };

        img.onload = () => {
          const MIME_TYPE = "image/jpeg";
          const QUALITY = 0.8;

          URL.revokeObjectURL(img.src);
          const filename = 'paste-' + genRandId(8);
          const [newWidth, newHeight] = calculateSize(img);
          const canvas = document.createElement("canvas");
          canvas.width = newWidth;
          canvas.height = newHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, newWidth, newHeight);
          canvas.toBlob(
            async (blob) => {
              const newFile = new File([blob], filename);
              uploadImgToS3(newFile, quill, range);
            },
            MIME_TYPE,
            QUALITY
          );
        };
      })
      .catch(err => {
        const range = quill.getSelection();
        range.index = range.index - 1;
        quill.deleteText(range.index, 1);
        quill.insertEmbed(range.index, 'image', `https://d1lze488p2cr08.cloudfront.net/assets/upload-failed-sm.png`);
      })
    return new Delta().insert({image: `https://d1lze488p2cr08.cloudfront.net/assets/synaptiq-img-loading-sm.gif`});
  });
}

/**
 * Register custom inline Cloze blot
 */
class ClozeBlot extends Inline {
    static create(value) {
        if (!value) return super.create(false);
        let node = super.create(value);
        node.setAttribute('data-cloze-group', value.group);
        node.classList.add('cloze-tile');
        node.classList.add(`cloze-group-${value.group}`);
        return node;
    }

    static formats(domNode) {
      return { group: domNode.getAttribute('data-cloze-group') };
    }

    format(name, value) {
      if (name !== this.statics.blotName || !value) return super.format(name, value);
      if (typeof node === 'undefined') return;
      node.setAttribute('data-cloze-group', value.group);
      node.classList.add('cloze-tile');
      node.classList.add(`cloze-group-${value.group}`);
    }
}
ClozeBlot.tagName = 'cloze';
ClozeBlot.blotName = 'cloze';
Quill.register(ClozeBlot);

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["small", "medium", "large", "huge"];
Quill.register(Size, true);

// Add image resizing
Quill.register('modules/imageResize', ImageResize);

// Returns modules object for setting up the Quill editor
// Question & Answer toolbars need to have unique IDs
export const getModules = (version, handleAddAsset) => {
  return {
    toolbar: {
      container: `#toolbar-${version}`,
      handlers: {
        addAsset: handleAddAsset,
        cloze: handleCloze,
        image: imageHandler,
      }
    },
    clipboard: {
      matchVisual: false,
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize']
    },
    keyboard: {
      bindings: bindings
    }
  }
}

function textChangeHandler(params) {
  
}

/* 
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
export const formats = [
    "size",
    'bold', 
    'italic', 
    'underline', 
    'blockquote',
    'script',
    'background', 
    'color', 
    'list', 
    'bullet', 
    'indent',
    'code', 
    'code-block', 
    'formula', 
    'link', 
    'image',
    'cloze',
    'clozeBlot',
    'width'
]

/*
 * Custom toolbar component
 */
const QuillToolbar = ({ version, userAdmin, clozeEditor }) => (
    <div id={`toolbar-${version}`} className='ql-toolbar'>
      <span className="ql-formats">
        <select className="ql-size" defaultValue="medium">
          <option value="small">Small</option>
          <option value="medium">Medium</option>
          <option value="large">Large</option>
          <option value="huge">Huge</option>
        </select>
      </span>
      <span className="ql-formats">
        <button className="ql-bold" data-toggle="tooltip" data-placement="bottom" title="Bold" />
        <button className="ql-italic" data-toggle="tooltip" data-placement="bottom" title="Italic" />
        <button className="ql-underline" data-toggle="tooltip" data-placement="bottom" title="Underline"/>
        <button className="ql-script" value="super" data-toggle="tooltip" data-placement="bottom" title="Superscript" />
        <button className="ql-script" value="sub" data-toggle="tooltip" data-placement="bottom" title="Subscript" />
      </span>
      <span className="ql-formats">
        <select className="ql-background" data-toggle="tooltip" data-placement="bottom" title="Highlight Color" />
        <select className="ql-color" data-toggle="tooltip" data-placement="bottom" title="Text Color" />
      </span>
      <span className="ql-formats">
        <button className="ql-list" value="ordered" data-toggle="tooltip" data-placement="bottom" title="Numbering" />
        <button className="ql-list" value="bullet" data-toggle="tooltip" data-placement="bottom" title="Bullets" />
        {/* <button className="ql-indent" value="-1" data-toggle="tooltip" data-placement="bottom" title="Decrease Indent" /> */}
        {/* <button className="ql-indent" value="+1" data-toggle="tooltip" data-placement="bottom" title="Increase Indent" /> */}
        {/* <button className="ql-blockquote" value="+1" data-toggle="tooltip" data-placement="bottom" title="Blockquote" /> */}
      </span>
      <span className="ql-formats">
        {/* <button className="ql-code" data-toggle="tooltip" data-placement="bottom" title="Inline Code" /> */}
        {/* <button className="ql-code-block" data-toggle="tooltip" data-placement="bottom" title="Code Block" /> */}
        {/* <button className="ql-formula" data-toggle="tooltip" data-placement="bottom" title="Insert Equation" /> */}
        {version === 'question' &&
          <select className="ql-cloze" data-toggle="tooltip" data-placement="bottom" title="Cloze group">
            <option value="1"></option>
            <option value="2"></option>
            <option value="3"></option>
          </select>}
      </span>
      <span className={`ql-formats ${clozeEditor ? 'cloze-editor' : ''}`}>
        <button className="ql-link" data-toggle="tooltip" data-placement="bottom" title="Insert Link" />
        <button className="ql-image" data-toggle="tooltip" data-placement="bottom" title="Insert Image" />
        {version === 'answer' && userAdmin && <button className="ql-addAsset" data-toggle="tooltip" data-placement="bottom" title="Insert Asset" />}
      </span>
    </div>
);

export default QuillToolbar;
