import React from 'react';

import EmptyBadge from '../../../assets/images/graphics/review-badges/rank-empty.svg';
import IronBadge from '../../../assets/images/graphics/review-badges/rank-1.svg';
import BronzeBadge from '../../../assets/images/graphics/review-badges/rank-10.svg';
import SilverBadge from '../../../assets/images/graphics/review-badges/rank-100.svg';
import GoldBadge from '../../../assets/images/graphics/review-badges/rank-500.svg';
import PlatinumBadge from '../../../assets/images/graphics/review-badges/rank-1k.svg';
import DiamondBadge from '../../../assets/images/graphics/review-badges/rank-2k.svg';
import ScholarBadge from '../../../assets/images/graphics/review-badges/rank-5k.svg';
import EliteBadge from '../../../assets/images/graphics/review-badges/rank-10k.svg';
import SageBadge from '../../../assets/images/graphics/review-badges/rank-15k.svg';
import LegendBadge from '../../../assets/images/graphics/review-badges/rank-25k.svg';
import PhenomenonBadge from '../../../assets/images/graphics/review-badges/rank-50k.svg';
import OracleBadge from '../../../assets/images/graphics/review-badges/rank-75k.svg';
import ApexBadge from '../../../assets/images/graphics/review-badges/rank-100k.svg';
import ReactTooltip from 'react-tooltip';
import ProgressBar from '../misc/ProgressBar';

function ReviewBadge({ reviews, loading, type = "badge" }) {

    const reviewLevels = [
        {
            "threshold": 0,
            "label": "Review One Card to Unlock!",
            "graphic": EmptyBadge
        },
        {
            "threshold": 1,
            "label": "Initiate",
            "graphic": IronBadge
        },
        {
            "threshold": 10,
            "label": "Novice",
            "graphic": BronzeBadge
        },
        {
            "threshold": 100,
            "label": "Enthusiast",
            "graphic": SilverBadge
        },
        {
            "threshold": 500,
            "label": "Apprentice",
            "graphic": GoldBadge
        },
        {
            "threshold": 1000,
            "label": "Scholar",
            "graphic": PlatinumBadge
        },
        {
            "threshold": 2000,
            "label": "Expert",
            "graphic": DiamondBadge
        },
        {
            "threshold": 5000,
            "label": "Pro",
            "graphic": ScholarBadge
        },
        {
            "threshold": 10000,
            "label": "Elite",
            "graphic": EliteBadge
        },
        {
            "threshold": 15000,
            "label": "Sage",
            "graphic": SageBadge
        },
        {
            "threshold": 25000,
            "label": "Legend",
            "graphic": LegendBadge
        },
        {
            "threshold": 50000,
            "label": "Phenomenon",
            "graphic": PhenomenonBadge
        },
        {
            "threshold": 75000,
            "label": "Oracle",
            "graphic": OracleBadge
        },
        {
            "threshold": 100000,
            "label": "Apex",
            "graphic": ApexBadge
        },
    ];

    const getCurrentLevel = () => {
        let currentLevel =  reviewLevels.empty;
        for (let l = 0; l < reviewLevels.length; l++) {
            if (reviews >= reviewLevels[l].threshold) {
                currentLevel = reviewLevels[l];
            }
        }
        return currentLevel;
    }
    
    const renderCurrentRank = () => {
        let currentLevel = getCurrentLevel();
        return (
            <>
                {loading ? <img className="current-badge" src={EmptyBadge} /> : <img className="current-badge" src={currentLevel.graphic} alt={currentLevel.label + " Badge"} />}
                {loading ? <h3 className='loader-rect-1'>{currentLevel.label}</h3> : <h3>{currentLevel.label}</h3>}
                <p>Current Rank</p>
            </>
        )
    }

    // const renderTrack = () => {
    //     let currentLevel = getCurrentLevel();
    //     return (
    //         <ProgressBar type="generic" percentMastery={reviews / } />
    //     );
    // }

    return (
        <> 
            {type === 'badge' &&
                <>
                    <div className="stat-badge__icon">
                    {loading ? <img src={EmptyBadge} /> : <img src={badgeImage} alt={badgeImage + " Badge"} />}
                    </div>
                    <div className="stat-badge__stats">
                        {loading ? <div className="stat-badge__stats__value loader-rect-1">0</div> : <div className="stat-badge__stats__value">{badgeName}</div>}
                        <div className="stat-badge__stats__label">Review Level</div>
                    </div>
                </>
            }
            {type === 'container' &&
                <div className='review-badges'>
                    <div className='review-badges_current'>
                        {renderCurrentRank()}
                    </div>
                    <div className='review-badges_track'>
                        <div className='review-badges_track_badges'>
                            {reviews >= 1 ? <img src={IronBadge} alt={"Initiate Badge"} data-tip="Initiate Badge" /> : <img data-tip="Complete 1 Review to Unlock" src={EmptyBadge} alt={"Empty Badge"} />}
                            {reviews >= 10 ? <img src={BronzeBadge} alt={"Novice Badge"} data-tip="Novice Badge" /> : <img data-tip="Complete 10 Reviews to Unlock" src={EmptyBadge} alt={"Empty Badge"} />}
                            {reviews >= 100 ? <img src={SilverBadge} alt={"Enthusiast Badge"} data-tip="Enthusiast Badge" /> : <img data-tip="Complete 100 Reviews to Unlock" src={EmptyBadge} alt={"Empty Badge"} />}
                            {reviews >= 500 ? <img src={GoldBadge} alt={"Apprentice Badge"} data-tip="Apprentice Badge" /> : <img data-tip="Complete 500 Reviews to Unlock" src={EmptyBadge} alt={"Empty Badge"} />}
                            {reviews >= 1000 ? <img src={PlatinumBadge} alt={"Scholar Badge"} data-tip="Scholar Badge" /> : <img data-tip="Complete 1,000 Reviews to Unlock" src={EmptyBadge} alt={"Empty Badge"} />}
                            {reviews >= 2000 ? <img src={DiamondBadge} alt={"Expert Badge"} data-tip="Expert Badge" /> : <img data-tip="Complete 2,000 Reviews to Unlock" src={EmptyBadge} alt={"Empty Badge"} />}
                            {reviews >= 5000 ? <img src={ScholarBadge} alt={"Pro Badge"} data-tip="Pro Badge" /> : <img data-tip="Complete 5,000 Reviews to Unlock" src={EmptyBadge} alt={"Empty Badge"} />}
                            {reviews >= 10000 ? <img src={EliteBadge} alt={"Elite Badge"} data-tip="Elite Badge" /> : <img data-tip="Complete 10,000 Reviews to Unlock" src={EmptyBadge} alt={"Empty Badge"} />}
                            {reviews >= 15000 ? <img src={SageBadge} alt={"Sage Badge"} data-tip="Sage Badge" /> : <img data-tip="Complete 15,000 Reviews to Unlock" src={EmptyBadge} alt={"Empty Badge"} />}
                            {reviews >= 25000 ? <img src={LegendBadge} alt={"Legend Badge"} data-tip="Legend Badge" /> : <img data-tip="Complete 25,000 Reviews to Unlock" src={EmptyBadge} alt={"Empty Badge"} />}
                            {reviews >= 50000 ? <img src={PhenomenonBadge} alt={"Phenomenon Badge"} data-tip="Phenomenon Badge" /> : <img data-tip="Complete 50,000 Reviews to Unlock" src={EmptyBadge} alt={"Empty Badge"} />}
                            {reviews >= 75000 ? <img src={OracleBadge} alt={"Oracle Badge"} data-tip="Oracle Badge" /> : <img data-tip="Complete 75,000 Reviews to Unlock" src={EmptyBadge} alt={"Empty Badge"} />}
                            {reviews >= 100000 ? <img src={ApexBadge} alt={"Apex Badge"} data-tip="Apex Badge" /> : <img data-tip="Complete 100,000 Reviews to Unlock" src={EmptyBadge} alt={"Empty Badge"} />}
                        </div>
                        {/* <div className='review-badges_track-container'>
                            {renderTrack()}
                        </div> */}
                    </div>
                </div>
            }
            <ReactTooltip/>
        </>
    )
}

export default ReviewBadge;
