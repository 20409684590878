import React, { Component } from 'react';
import Modal from '../misc/Modal';
import { connect } from 'react-redux';
import { reportCard } from '../../actions/allActions';
import { initiateModalExit } from '../../util/modalExit';

class CardReporter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            submitted: false
        }
    }

    componentDidMount() {
        document.getElementById("report-card-textarea").focus();
    }

    handleChange = (event) => {
        this.setState({value: event.target.value});
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.props.reportCard(this.props.cardId, this.state.value);
        this.setState({ submitted: true });
    }

    renderForm() {
        return (
            <form onSubmit={this.handleSubmit}>
                <label className='report-label' htmlFor="report-card-textarea">Please describe why you are reporting this card (ex. incorrect, vague, etc.).</label>
                <textarea className='report-input' id="report-card-textarea" type="textarea" value={this.state.value} onChange={this.handleChange}></textarea>
                <input className='button button--error' type="submit" value="Submit" onSubmit={(e) => handleSubmit(e)}/>
            </form>
        )
    }

    renderSuccess() {
        return (
            <div>
                <p>The deck owner will receive this report. On behalf of everyone studying this deck, thanks for helping improve it! 🙏</p>
                <button onClick={() => initiateModalExit('.modal--card-reporter', this.props.hideModal)} className='button button--report-close'>Return to Session</button>
            </div>
        )
    }

    render() {
        return (
            <Modal
                name={'modal--card-reporter'}
                title={this.state.submitted ? "Report Submitted" : "Report Card"}
                hide={this.props.hideModal}
                small={true}
                hideCancel={true}
                content={this.state.submitted ? this.renderSuccess() : this.renderForm()}
            />
        )
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({ reportCard: (cardId, feedback) => reportCard(cardId, feedback) });

export default connect(mapStateToProps, mapDispatchToProps)(CardReporter)