const getConfig = () => (
    {
        "dom": '<<t><"bottom"pl>>',
        columns,
        order: [[0, "desc"]],
        responsive: true,
        processing: true,
        serverSide: true,
        ajax: 'api/v1/datatables/schools',
    }
)

const columns = [
    {
        title: 'Id',
        data: 'id'
    },
    {
        title: 'Name',
        data: 'name',
    },
    {
        title: 'Email Domains',
        data: 'email_domains',
    },
    {
        title: 'Assigned Decks',
        data: 'decks',
    },
    {
        title: 'Students',
        data: 'users',
    },
];

export default getConfig;
