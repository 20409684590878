export const initiateModalExit = (modalTargetSelector, modalCloseFunction) => {
    let target = document.querySelector(modalTargetSelector);
    target?.addEventListener('animationend', () => modalExitAnimation(modalTargetSelector, modalCloseFunction));
    target?.classList.add('modalCloseAnimation');
}

const modalExitAnimation = (modalTargetSelector, modalCloseFunction) => {
    let target = document.querySelector(modalTargetSelector);
    target?.classList.remove('modalCloseAnimation');
    target?.removeEventListener('animationend', this);
    modalCloseFunction();
}
