const customStyles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: 'var(--input-dropdown-background)',
        borderColor: 'var(--input-border-color)',
        borderWidth: '1px',
        borderRadius: '.5rem',
        input: {
          ...provided.input,
          fontSize: '16px !important',
          color: 'var(--type-body-default) !important'
        }
    }),
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: 'var(--input-dropdown-background)',
        zIndex: 1001,
        width: "max-content",
        minWidth: "100%",
        maxWidth: "100%",
    }),
    menuPortal: (provided, state) => ({
        ...provided,
        zIndex: 1001
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: 'transparent',
        color: state.isFocused ? 'var(--button-primary-color)' : 'var(--button-primary-background)',
        backgroundColor: state.isFocused ? 'var(--button-primary-background)' : 'transparent',
        fontWeight: state.isSelected ? 'bold' : 'regular',
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: 'var(--type-body-default)',
        
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: 'var(--button-primary-background)',
      borderRadius: '.25rem'
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: 'var(--button-primary-color)'
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
      "*": {
        fill: 'var(--button-primary-color)',
      },
      "&:hover": {
        backgroundColor: 'var(--button-primary-background)',
        borderRadius: '.25rem'
      }
    }),
}

export default customStyles;
