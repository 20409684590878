import {
    RECEIVE_USER_PROPERTIES,
	CLEAR_USER
} from '../../actions/allActions';

const userPropertiesReducer = (state = {}, action) => { 
	Object.freeze(state);

	switch (action.type) {
		case RECEIVE_USER_PROPERTIES:
			return action.payload.properties;
		case CLEAR_USER:
			return {};
		default:
			return state;
	}
}

export default userPropertiesReducer;
