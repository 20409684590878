import { connect } from 'react-redux';
import Browse from './Browse';
import { 
    fetchUserCards,
    receiveUserCards,
    receiveActiveStudyProfile,
    updateCardContent,
    deleteCard,
    clearCreatedCards,
    fetchBrowseFilters,
    browseCloneDeck,
    suspendCard,
    unSuspendCard
} from '../../actions/allActions';

const mapStateToProps = (state) => {
    return {    
        user: state.user,
        userCards: state.userCards
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchUserCards: () => dispatch(fetchUserCards()),
        setUserCards: (cards) => dispatch(receiveUserCards(cards)),
        clearActiveStudyProfile: () => dispatch(receiveActiveStudyProfile({})),
        updateCardContent: (card) => dispatch(updateCardContent(card)),
        deleteCard: (cardId) => dispatch(deleteCard(cardId)),
        clearCreatedCards: () => dispatch(clearCreatedCards()),
        fetchBrowseFilters: () => dispatch(fetchBrowseFilters()),
        browseCloneDeck: (targetDeckId) => dispatch(browseCloneDeck(targetDeckId)),
        suspendCard: (cardId) => dispatch(suspendCard(cardId)),
        unSuspendCard: (cardId) => dispatch(unSuspendCard(cardId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Browse)
