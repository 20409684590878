import React, { Component } from 'react';
import SignOutButton from '../navbar/SignOutButton';
import { Edit, Info, Unlock, Tool, User, HelpCircle, CreditCard, Sun, ArrowRight, ArrowLeft, ArrowUp, Key, Plus, Check, ExternalLink, RotateCcw, Gift, Settings as SettingsIcon, MessageSquare } from 'react-feather';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import customStyles from '../../util/singleSelectStyles';
import openIntercomUtil from '../../util/openIntercomUtil';
import RedeemCodeModal from "../home/RedeemCodeModal";
import Modal from '../misc/Modal';
import { getProductId } from '../../util/cohortUtil';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import UserStudySettingsEditor from '../home/UserStudySettingsEditor';
import UILight from '../../../assets/images/ui-light.jpg';
import UIDark from '../../../assets/images/ui-dark.jpg';
import UIGlass from '../../../assets/images/ui-glass.jpg';
import ReferModal from '../misc/ReferModal';
import smoothScrollToElement from '../../util/smoothScroll';
import ProfilePictureModal from './ProfilePictureModal';
import isMobile from '../../util/isMobile';
import { userLogout } from '../../actions/allActions';
import AdvancedAccountSettings from './AdvancedAccountSettings';
import { getProductName } from '../../util/cohortUtil';
export default class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editingPassword: false,
            currentPassword: '',
            newPassword: '',
            newPasswordConfirmation: '',
            passwordError: '',
            editingProfile: false,
            firstName: this.props.user.first_name ? this.props.user.first_name : '',
            lastName: this.props.user.last_name ? this.props.user.last_name : '',
            emailReminders: this.props.user.reminder_emails,
            billingPortal: {},
            showGenericUnlocker: false,
            showGlobalStudySettings: false,
            showReferModal: false,
            profilePictureUploaderIsOpen: false,
            showDeleteConfirmation: false,
            showAdvancedAccountSettings: false
        }
    }

    async componentDidMount() {
        this.props.fetchUser();
        // if (isCohortUser(this.props.user) && !isPaywalled(this.props.user)) {
        try {
            const billingPortal = await this.props.fetchBillingPortal();
            this.setState({ billingPortal });
        } catch (error) {
            console.error("An error occurred while fetching the billing portal:", error);
        }
        if (window.location.hash.includes('help')) {
            this.handlePanelClick('help');
        }
    }

    showGlobalStudySettings = () => {
        this.setState({ showGlobalStudySettings: true });
    }

    hideGlobalStudySettings = () => {
        this.setState({ showGlobalStudySettings: false });
    }

    handleSaveProfile = () => {
        const newUserAttributes = { first_name: this.state.firstName, last_name: this.state.lastName };
        this.props.updateUser(this.props.user.id, newUserAttributes);
        this.setState({ editingProfile: false })
    }

    handleSavePassword = () => {
        if (!this.state.newPassword || !this.state.newPasswordConfirmation) {
            this.setState({ passwordError: 'Missing password input.' });
        } else if (this.state.newPassword != this.state.newPasswordConfirmation) {
            this.setState({ passwordError: 'Passwords must match.' });
        } else if (!this.state.newPassword.match(/^[A-Za-z]\w{6,99}$/)) {
            this.setState({ passwordError: 'Password must be at least 6 characters long and cannot contain symbols.' });
        } else if (this.state.currentPassword == this.state.newPassword) {
            this.setState({ passwordError: "New password can't be the same as old password." });
        } else {
            this.props.updatePassword(
                this.state.currentPassword,
                this.state.newPassword,
                this.state.newPasswordConfirmation
            ).then((res) => {
                if (res['status'] == 'success') {
                    this.setState({
                        editingPassword: false,
                        currentPassword: '',
                        newPassword: '',
                        newPasswordConfirmation: '',
                        passwordError: ''
                    });
                    toast.success("Password Reset Successful", {
                        position: "bottom-left",
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        theme: "light"
                    });
                } else {
                    this.setState({ passwordError: res['messages'][0] });
                }
            })
        }
    }

    handleChangeEmailReminders = (newEmailPreferencesObject) => {
        const newValue = newEmailPreferencesObject.value;
        this.setState({ emailReminders: newEmailPreferencesObject.value });
        const newUserAttributes = { reminder_emails: newValue };
        this.props.updateUser(this.props.user.id, newUserAttributes);
        let toastMessage;
        if (newValue === "daily") {
            toastMessage = "Your email preferences have been updated. You will receive email reminders daily.";
        } else if (newValue === "occasional") {
            toastMessage = "Your email preferences have been updated. You will receive email reminders occasionally.";
        } else {
            toastMessage = "Your email preferences have been updated. You won't receive email reminders.";
        }
        toast.success(toastMessage, {
            position: "bottom-left",
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light"
        });
    }

    toggleDarkMode = () => {
        ahoy.track("Toggle Dark Mode");
        if (this.props.theme == 'theme-dark-clean')
            this.props.setTheme("theme-light-clean");
        else {
            this.props.setTheme('theme-dark-clean');
        }
    }

    handleThemeToggle = (e) => {
        ahoy.track("Toggle Theme");
        const themeChoice = e.target.value;
        if (themeChoice === 'light') {
            this.props.setTheme('theme-light-clean');
        } else if (themeChoice === 'dark') {
            this.props.setTheme('theme-dark-clean');
        } else {
            this.props.setTheme('theme-glass');
        }
    }

    showGenericUnlocker = () => {
        this.setState({ showGenericUnlocker: true });
    }

    hideGenericUnlocker = () => {
        this.setState({ showGenericUnlocker: false });
    }

    openReferModal = () => {
        this.setState({ showReferModal: true });
    }

    hideReferModal = () => {
        this.setState({ showReferModal: false });
    }

    openProfilePictureUploader = () => {
        this.setState({ profilePictureUploaderIsOpen: true });
    }

    hideProfilePictureUploader = () => {
        this.setState({ profilePictureUploaderIsOpen: false });
    }

    renderProfilePicture() {
        if (!Capacitor.isNativePlatform()) {
            return (
                <div className='profile-picture-section'>
                    <button className='button button--profile-picture' onClick={this.openProfilePictureUploader}>
                        {this.props.user.profile_picture ? <img className="profile-picture" src={this.props.user.profile_picture} alt="Profile Picture" /> : <div className='empty-avatar'><User /></div>}
                    </button>
                </div>
            );
        } else {
            return;
        }
    }

    renderProfileSection() {
        if (this.state.editingProfile) {
            return (
                <div className='account-settings'>
                    {this.renderProfilePicture()}
                    <div className='personal-info-section'>
                        <div className='grid-row name-fields'>
                            <div className="grid-col">
                                <div className='input'>
                                    <label>First Name</label>
                                    <input type="text" id="first-name" value={this.state.firstName} onChange={(e) => this.setState({ firstName: e.target.value })} />
                                </div>
                            </div>
                            <div className="grid-col">
                                <div className='input'>
                                    <label>Last Name</label>
                                    <input type="text" id="last-name" value={this.state.lastName} onChange={(e) => this.setState({ lastName: e.target.value })} />
                                </div>
                            </div>
                        </div>
                        <div className='actions'>
                            <button className="button button--secondary button--small" onClick={() => this.setState({ editingProfile: false })}>
                                Cancel
                            </button>
                            <button className="button button--primary button--small" onClick={() => this.handleSaveProfile()}>
                                Save
                            </button>
                        </div>
                        <div className='grid-row'>
                            <div className="grid-col">
                                <div className='input'>
                                    <label>Email</label>
                                    <div>{this.props.user.email}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className='account-settings'>
                    {this.renderProfilePicture()}
                    <div className='personal-info-section'>
                        <div className='grid-row'>
                            <div className="grid-col">
                                <div className='input'>
                                    <label>First Name</label>
                                    {!this.state.firstName && <button className='button button--link' onClick={() => this.setState({ editingProfile: true })}><Plus />Add</button>}
                                    <div>{this.props.user.first_name}</div>
                                </div>
                            </div>
                            <div className="grid-col">
                                <div className='input'>
                                    <label>Last Name</label>
                                    {!this.state.lastName && <button className='button button--link' onClick={() => this.setState({ editingProfile: true })}><Plus />Add</button>}
                                    <div>{this.props.user.last_name}</div>
                                </div>
                            </div>
                        </div>
                        <div className='grid-row'>
                            <div className="grid-col">
                                <div className='input'>
                                    <label>Email</label>
                                    <div>{this.props.user.email}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderPasswordSection() {
        if (this.state.editingPassword) {
            return (
                <div className='password-settings'>
                    {this.state.passwordError && <div className='block--status status--error'>{this.state.passwordError}</div>}
                    <div className='input'>
                        <label htmlFor="existing-password">Existing Password</label>
                        <input type="password" id="existing-password" value={this.state.currentPassword} onChange={(e) => this.setState({ currentPassword: e.target.value })} />
                    </div>
                    <div className='input'>
                        <label htmlFor="password">New Password</label>
                        <input type="password" id="password" value={this.state.newPassword} onChange={(e) => this.setState({ newPassword: e.target.value })} />
                    </div>
                    <div className='input'>
                        <label htmlFor="password-confirmation">New Password Confirmation</label>
                        <input type="password" id="password-confirmation" value={this.state.newPasswordConfirmation} onChange={(e) => this.setState({ newPasswordConfirmation: e.target.value })} />
                    </div>
                    <div className='actions'>
                        <button className="button button--secondary button--small" onClick={() => this.setState({ editingPassword: false })}>
                            Cancel
                        </button>
                        <button className="button button--primary button--small" onClick={() => this.handleSavePassword()}>
                            Save
                        </button>
                    </div>
                </div>
            )
        } else {
            return (
                <div className='password-settings'>
                    <div className='input'>
                        <label>Password</label>
                        <div className='password-field'>
                            <div className='password-obfuscated'>••••••••••••••••</div>
                            <button className="button button--secondary button--small" onClick={() => this.setState({ editingPassword: true })}>
                                Change
                            </button>
                        </div>
                    </div>
                </div>
            )
        }
    }

    checkTrialDaysRemaining = () => {
        if (this.props.user?.subscription_status === 'trialing' && this.props.user?.subscription_end_date) {
            const now = moment();
            const subEndDate = moment(this.props.user?.subscription_end_date);
            const days = Math.ceil(subEndDate.diff(now, 'hours') / 24);
            if (days === 1) {
                return days + ' Day';
            }
            return days + ' Days';
        }
    }

    renderBilling() {
        if (!_.isEmpty(this.props.subscriptions)) {
            return (
                <>
                    <div className='sub-section-header'>
                        <h3>Subscriptions</h3>
                        <a className="button button--secondary button--icon-right button--medium" target="_blank" href={this.state.billingPortal?.url}>Manage<ExternalLink /></a>
                    </div>
                    <div>
                        <div className='sub-table-header'>
                            <div className='sub-table-header_label'>Name</div>
                            <div className='sub-table-header_label'>Term</div>
                            <div className='sub-table-header_label'>Status</div>
                        </div>
                        {this.renderSubscriptions()}
                    </div>
                </>
            );
        } else {
            return (
                <div className='no-active'>
                    <p>No active subscriptions.</p>
                </div>
            )
        }
    }

    renderSubscriptions() {
        return Object.keys(this.props.subscriptions).map((key, idx) => {
            return (
                <div key={idx} className='sub-table-row'>
                    {getProductName(key) === 'synaptiq_pro' && <div>Synaptiq Pro</div>}
                    {getProductName(key) === 'gberg' && <div>Greenberg's Handbook of Neurosurgery</div>}
                    {getProductName(key) === 'plastics' && <div>The Essentials of Plastic Surgery</div>}
                    {getProductName(key) === 'top_score' && <div>Top Score for the Radiology Boards</div>}
                    {getProductName(key) === 'ultrasound' && <div>Ultrasound Q&A Review for the Boards</div>}
                    {getProductName(key) === 'gastrointestinal' && <div>Gastrointestinal Imaging Q&A For the Radiology Boards</div>}
                    {getProductName(key) === 'neuroradiology' && <div>Neuroradiology Q&A For the Radiology Boards</div>}
                    <div>
                        {this.props.subscriptions[key].recurring?.interval === 'year' && <div>Yearly</div>}
                        {this.props.subscriptions[key].recurring?.interval === 'month' && <div>Monthly</div>}
                    </div>
                    <div>
                        {this.props.subscriptions[key].active ? <div className='badge badge--success'>Active</div> : <div className='badge badge--error'>Inactive</div>}
                    </div>
                </div>
            )
        })
    }

    hasActiveSubscription = () => {
        return Object.keys(this.props.subscriptions).some(key => this.props.subscriptions[key].active);
    }

    renderCodeAccesses() {
        if (this.props.user.group === 'thieme') {
            const gbergProductId = getProductId('gberg');
            const gbergSubAt = this.props.userProperties[gbergProductId];
            let greenbergSubInfo = gbergSubAt ? (
                <div className='sub-table-row'>
                    <div>Handbook of Neurosurgery Subscription</div>
                    <div>
                        <div>12 Month Access</div>
                        <div className='type-size-small'>{this.calculateTimeRemaining(new Date(gbergSubAt))}</div>
                    </div>
                    <div>
                        {this.isExpired(gbergSubAt) ? <div className='badge badge--error'>Expired</div> : <div className='badge badge--success'>Active</div>}
                    </div>
                </div>
            ) : null;

            const plasticsProductId = getProductId('plastics');
            const plasticsSubAt = this.props.userProperties[plasticsProductId];
            let janisSubInfo = plasticsSubAt ? (
                <div className='sub-table-row'>
                    <div>Essentials of Plastic Surgery Subscription</div>
                    <div>
                        <div>12 Month Access</div>
                        <div className='type-size-small'>{this.calculateTimeRemaining(new Date(plasticsSubAt))}</div>
                    </div>
                    <div>
                        {this.isExpired(plasticsSubAt) ? <div className='badge badge--error'>Expired</div> : <div className='badge badge--success'>Active</div>}
                    </div>
                </div>
            ) : null;

            if (greenbergSubInfo || janisSubInfo) {
                return (
                    <>
                        <div className='sub-section-header'>
                            <h3>Other Products</h3>
                        </div>
                        <div>
                            <div className='sub-table-header'>
                                <div className='sub-table-header_label'>Name</div>
                                <div className='sub-table-header_label'>Term</div>
                                <div className='sub-table-header_label'>Status</div>
                            </div>
                            {greenbergSubInfo}
                            {janisSubInfo}
                        </div>
                    </>
                );
            } else {
                return null;
            }
        }
    }

    calculateTimeRemaining = (date) => {
        let d = date;
        d.setFullYear(d.getFullYear() + 1);

        // Get the current date
        const currentDate = new Date();

        // Calculate the difference in milliseconds between the given date and the current date
        const differenceMs = d.getTime() - currentDate.getTime();

        // If the difference is negative, return "0 days remaining"
        if (differenceMs <= 0) {
            return "0 days remaining";
        }

        const millisecondsPerDay = 1000 * 60 * 60 * 24;
        const millisecondsPerMonth = millisecondsPerDay * 30; // approximate value, assuming 30 days per month

        const months = Math.floor(differenceMs / millisecondsPerMonth);
        const remainingMilliseconds = differenceMs % millisecondsPerMonth;
        const days = Math.floor(remainingMilliseconds / millisecondsPerDay);

        if (months >= 1) {
            return `${months} month${months > 1 ? 's' : ''} remaining`;
        } else {
            return `${days} day${days > 1 ? 's' : ''} remaining`;
        }
    }

    isExpired = (purchaseDate) => {
        const date = moment(purchaseDate);
        const oneYearAgo = moment().subtract(1, 'years');
        return date.isBefore(oneYearAgo);
    }

    // renderUpgradeSection = () => {
    //     if (this.props.user.subscription_status === 'inactive') {
    //         return (
    //             <div className='button-wrapper'>
    //                 <Link to="/checkout" className="button"><Unlock />Unlock Pro Now</Link>
    //             </div>
    //         );
    //     } else if (this.props.user.subscription_status === 'trialing' || this.props.user.subscription_status === 'cancelled') {
    //         return (
    //             <Link to="/checkout" className="button"><Unlock />Unlock Pro</Link>
    //         );
    //     } else if (this.props.user.subscription_status === 'active') {
    //         return null;
    //     }
    // }

    handlePanelClick = (id) => {
        if (window.innerWidth < 900) {
            if (id === 'refer') {
                this.openReferModal();
            } else if (id === 'redeem') {
                this.showGenericUnlocker();
            } else {
                document.getElementById(id)?.classList.add('active');
            }
        } else {
            smoothScrollToElement(id, '.layout_container');
        }
    }

    closePanel = (id) => {
        document.getElementById(id)?.classList.remove('active');
    }

    toggleDemoView = () => {
        if (this.props.userProperties?.demoView) {
            const currentlyDemoView = this.props.userProperties?.demoView;
            this.props.updateUserProperties({ demoView: !currentlyDemoView });
        } else {
            this.props.updateUserProperties({ demoView: true });
        }
    }

    showAdvancedAccountSettings = () => {
        this.setState({ showAdvancedAccountSettings: true });
    }

    hideAdvancedAccountSettings = () => {
        this.setState({ showAdvancedAccountSettings: false });
    }

    showDeleteConfirmation = () => {
        this.setState({ showDeleteConfirmation: true, showAdvancedAccountSettings: false });
    }

    hideDeleteConfirmation = () => {
        this.setState({ showDeleteConfirmation: false });
    }

    deleteUser = () => {
        this.props.deleteUser(this.props.user.id).then(res => {
            if (res.status === 'success') {
                ahoy.track("Account Deletion");
                userLogout();
                setTimeout(() => window.location.pathname = '/users/sign_in', 1500);
            }
        })
        this.hideDeleteConfirmation();
    }

    renderDeleteConfirmation() {
        return (<Modal
            name={'modal--delete-confirmation'}
            title={'Delete Account'}
            small={true}
            hide={this.hideDeleteConfirmation}
            cancelButtonText={'Cancel'}
            content={
                <>
                    <p>You are about to delete your account. This action is permanent.</p>
                    <p className='m-b-0'>Are you sure you want to proceed?</p>
                </>
            }
            actions={
                <button className='button button--error' onClick={this.deleteUser}>Delete Account</button>
            }
        />)
    }

    render() {
        const emailFrequencyOptions = [
            { label: 'Never', value: 'never' },
            { label: 'Occasional', value: 'occasional' },
            { label: 'Daily', value: 'daily' }
        ];
        const emailStudyRemindersTooltip = `
            <div><p>Email Study Reminders are sent each morning and show the number of cards due that day.</p></div>
        `;
        return (
            <div className='layout layout--settings'>
                <div className='layout_container'>
                    <div className="layout--settings_drawer">
                        <h1>Settings</h1>
                        <ul className='settings-menu'>
                            <li><button className='button button--link' onClick={() => this.handlePanelClick('account')}><span><User />Account</span><ArrowRight /></button></li>
                            <li><button className='button button--link' onClick={() => this.handlePanelClick('preferences')}><span><Sun />Preferences</span><ArrowRight /></button></li>
                            {!Capacitor.isNativePlatform() && <li><button className='button button--link' onClick={() => this.handlePanelClick('billing')}><span><CreditCard />Subscriptions &amp; Billing</span><ArrowRight /></button></li>}
                            {/* <li className='refer'><button className='button button--link' onClick={() => this.handlePanelClick('refer')}><span><Gift />Refer a Friend</span><ArrowRight /></button></li> */}
                            {!Capacitor.isNativePlatform() && <li><button className='button button--link' onClick={() => this.handlePanelClick('redeem')}><span><Key />Redeem Code</span><ArrowRight /></button></li>}
                            <li><button className='button button--link' onClick={() => this.handlePanelClick('help')}><span><HelpCircle />Help</span><ArrowRight /></button></li>
                            <SignOutButton />
                        </ul>
                    </div>
                    <div className="layout--settings_settings">
                        <h1>Settings</h1>
                        <div id="account" className='section'>
                            <div className='section_header section_header--account'>
                                <button className='button button--secondary button--close-drawer' onClick={() => this.closePanel('account')}><ArrowLeft /></button>
                                <h2 className='underline'>Account</h2>
                                <button className='button button--edit-profile' onClick={() => this.setState({ editingProfile: true })}><Edit /></button>
                            </div>
                            {this.renderProfileSection()}
                            {this.renderPasswordSection()}
                            <button className="button button--link button--advanced-account-settings" onClick={this.showAdvancedAccountSettings}><SettingsIcon /> Advanced Account Settings</button>
                        </div>
                        <div id="preferences" className='section'>
                            <div className='section_header'>
                                <button className='button button--secondary button--close-drawer' onClick={() => this.closePanel('preferences')}><ArrowLeft /></button>
                                <h2 className='underline m-b-1'>Preferences</h2>
                            </div>
                            <h3 className="m-t-0">Global Study Settings</h3>
                            <button className="button button--secondary" onClick={() => this.showGlobalStudySettings()}>
                                <Tool />Study Settings
                            </button>
                            <h3>Notifications</h3>
                            <div className='input' style={{ maxWidth: '16rem' }}>
                                <label className='label reminders-label'>Email Study Reminders<Info data-tip={emailStudyRemindersTooltip} data-html={true} /></label>
                                <Select
                                    defaultValue={emailFrequencyOptions.find(el => el.value.toLowerCase() === this.state.emailReminders)}
                                    options={emailFrequencyOptions}
                                    onChange={(newEmailPreferencesObject) => this.handleChangeEmailReminders(newEmailPreferencesObject)}
                                    styles={customStyles}
                                    isSearchable={false}
                                    isClearable={false}
                                />
                            </div>
                            <h3 className='m-t-2'>Theme</h3>
                            {this.props.user.group === 'thieme' && <div className='input--switch'>
                                <span className='label'>Dark Mode</span>
                                <label className='switch'>
                                    <input id="dark-mode-toggle" type="checkbox" onChange={() => this.toggleDarkMode()} checked={this.props.theme == 'theme-dark-clean'}></input>
                                    <span className="slider round"></span>
                                </label>
                            </div>}
                            {this.props.user.group !== 'thieme' &&
                                <div className='toggle toggle-theme'>
                                    <div className={`toggle_option ${this.props.theme === 'theme-glass' ? 'active' : ''}`}>
                                        <div>
                                            <input
                                                type="radio"
                                                name="theme"
                                                value="glass"
                                                checked={this.props.theme === 'theme-glass'}
                                                onChange={this.handleThemeToggle}
                                            />
                                            <img src={UIGlass} alt="Glass Theme" />
                                        </div>
                                        <span>Glass</span>
                                    </div>
                                    <div className={`toggle_option ${this.props.theme === 'theme-light-clean' || this.props.theme === '' ? 'active' : ''}`}>
                                        <div>
                                            <input
                                                type="radio"
                                                name="theme"
                                                value="light"
                                                checked={this.props.theme === 'theme-light-clean' || this.props.theme === ''}
                                                onChange={this.handleThemeToggle}
                                            />
                                            <img src={UILight} alt="Light Theme" />
                                        </div>
                                        <span>Light Mode</span>
                                    </div>
                                    <div className={`toggle_option ${this.props.theme === 'theme-dark-clean' ? 'active' : ''}`}>
                                        <div>
                                            <input
                                                type="radio"
                                                name="theme"
                                                value="dark"
                                                checked={this.props.theme === 'theme-dark-clean'}
                                                onChange={this.handleThemeToggle}
                                            />
                                            <img src={UIDark} alt="Dark Theme" />
                                        </div>
                                        <span>Dark Mode</span>
                                    </div>
                                </div>
                            }
                            {this.props.user.group !== 'thieme' && this.props.user.admin && <div className='input--switch m-t-2'>
                                <span className='label'>Demo View</span>
                                <label className='switch'>
                                    <input id="demo-view-toggle" type="checkbox" onChange={() => this.toggleDemoView()} checked={this.props.userProperties?.demoView}></input>
                                    <span className="slider round"></span>
                                </label>
                            </div>}
                        </div>
                        {!Capacitor.isNativePlatform() && <div id="billing" className='section'>
                            <div className='section_header'>
                                <button className='button button--secondary button--close-drawer' onClick={() => this.closePanel('billing')}><ArrowLeft /></button>
                                <h2 className='underline m-b-1'>Subscriptions &amp; Billing</h2>
                            </div>
                            {this.renderBilling()}
                            {this.renderCodeAccesses()}
                        </div>}
                        {!Capacitor.isNativePlatform() &&<div id="redeem" className='section'>
                            <div className='section_header'>
                                <button className='button button--secondary button--close-drawer' onClick={() => this.closePanel('redeem')}><ArrowLeft /></button>
                                <h2 className='underline m-b-1'>Redeem Thieme Code</h2>
                            </div>
                            <p>Have a Thieme access code? Activate or extend your subscription.</p>
                            <p>Redeeming a new code will add 12 months on top of any time remaining on your current subscription.</p>
                            <button className='button button--secondary' onClick={this.showGenericUnlocker}><Unlock />Redeem Code</button>
                        </div>}
                        <div id="help" className='section'>
                            <div className='section_header'>
                                <button className='button button--secondary button--close-drawer' onClick={() => this.closePanel('help')}><ArrowLeft /></button>
                                <h2 className='underline m-b-1'>Help</h2>
                            </div>
                            <p>Need assistance?</p>
                            <a className="button button--secondary m-r-1 m-b-1" href='https://intercom.help/synaptiq-learning-inc/en/' target='_blank'><HelpCircle />View Help Center</a>
                            {(this.props.user.group === 'thieme' || this.hasActiveSubscription()) && <button className="button button--secondary m-r-1 m-b-1" onClick={openIntercomUtil}><MessageSquare/>Chat with Us</button>}
                            {this.props.user.group === 'thieme' && <button className="button button--secondary m-r-1 m-b-1" onClick={() => this.props.openSpacedRepOnboarding('greenberg')}><RotateCcw />Watch Flashcard Tutorial</button>}
                            {this.props.user.group === 'thieme' && <button className="button button--secondary m-r-1 m-b-1" onClick={() => this.props.openSpacedRepOnboarding('plastics')}><RotateCcw />Watch Multiple Choice Tutorial</button>}
                        </div>
                        <div className='section section-actions'>
                            <SignOutButton />
                        </div>
                        <div className='section section--advanced'>
                            <button className="button button--link" onClick={this.showAdvancedAccountSettings}><SettingsIcon /> Advanced Account Settings</button>
                        </div>
                        {this.state.showGenericUnlocker && (
                            <RedeemCodeModal
                                hide={this.hideGenericUnlocker}
                            />
                        )}
                        {this.state.showGlobalStudySettings &&
                            <UserStudySettingsEditor
                                hide={this.hideGlobalStudySettings}
                            />
                        }
                        {this.state.showReferModal &&
                            <ReferModal
                                hide={this.hideReferModal}
                            />
                        }
                        {this.state.profilePictureUploaderIsOpen &&
                            <ProfilePictureModal
                                hide={this.hideProfilePictureUploader}
                            />
                        }
                        {this.state.showAdvancedAccountSettings &&
                            <AdvancedAccountSettings
                                hide={this.hideAdvancedAccountSettings}
                                showDeleteConfirmation={this.showDeleteConfirmation}
                            />
                        }
                        {this.state.showDeleteConfirmation &&
                            this.renderDeleteConfirmation()
                        }
                        <ReactTooltip />
                    </div>
                </div>
            </div>
        )
    }
}

