import { connect } from 'react-redux'
import Navbar from './Header'
import { withRouter } from 'react-router-dom';
import {
    stopImpersonatingUser,
    clearActiveStudySession
} from '../../actions/allActions';

const mapStateToProps = (state, ownProps) => {
    const deckId = state.activeStudyProfile.deckId;
    const cards = _.get(state, `cards[${deckId}]`, {});
    const dueCards = _.defaultTo(cards.due, []);
    const newCards = _.defaultTo(cards.new, []);
    const activeStudyProfileFallback = { deckId, due: dueCards.length, new: newCards.length };
    const activeStudyProfile = _.get(state, `activeStudyProfile`, activeStudyProfileFallback);
    return {
        activeStudyProfile,
        user: state.user,
        deck: _.defaultTo(state.decks[deckId], {}),
        percentMastery: _.get(state.progress, `[${deckId}].percentMastery`, 0),
        theme: state.theme,
        userProperties: state.userProperties,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        stopImpersonatingUser: (userId) => dispatch(stopImpersonatingUser(userId)),
        clearActiveStudySession: () => dispatch(clearActiveStudySession()),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar))
