import React, { useCallback, useState, useRef } from 'react';
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import { useDropzone } from 'react-dropzone';
import { fetchPresignedUrl, updateUser } from '../../actions/allActions';
import Modal from '../misc/Modal';
import { User } from 'react-feather';
import heic2any from "heic2any";
import Cropper from 'react-cropper';

function ProfilePictureModal({ user, hide, updateUser }) {
    const maxSize = 5_000_000;
    const [imageSrc, setImageSrc] = useState(null);
    const [croppedImageData, setCroppedImageData] = useState(null);
    const cropperRef = useRef(null);

    const uploadProfilePictureToS3 = async (file) => {
        const bucket = 'synaptiq-prod';
        const dirName = `profile-pictures/`;
        const data = await fetchPresignedUrl(bucket, dirName, file.name);
        if (data.url) {
            $.ajax({
                xhr: function () {
                    var xhr = new window.XMLHttpRequest();
                    xhr.upload.addEventListener("progress", function (evt) {
                        if (evt.lengthComputable) {
                            let percentComplete = evt.loaded / evt.total;
                            percentComplete = parseInt(percentComplete * 99);
                        }
                    }, false);
                    return xhr;
                },
                url: data.url,
                type: "PUT",
                data: file,
                dataType: "text",
                cache: false,
                contentType: file.type,
                processData: false,
                error: (res) => {
                    console.log('error: ' + JSON.stringify(res))
                },
                success: (res) => {
                    updateUser(user.id, { profile_picture: 'https://d1lze488p2cr08.cloudfront.net/profile-pictures/' + file.name });
                }
            })
        }
    }

    const toastConfig = { position: "bottom-left", autoClose: 3000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined }

    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles.length !== 1) return;
        const file = acceptedFiles[0];
        if (file.type === 'image/heic') {
            heic2any({
                blob: file,
                toType: "image/jpeg",
                quality: 0.8
            }).then((convertedBlob) => {
                setImageSrc(URL.createObjectURL(convertedBlob));
            }).catch((error) => {
                console.error("Failed to convert HEIC", error);
            });
        } else {
            setImageSrc(URL.createObjectURL(file));
        }
    }, []);

    const onDropRejected = useCallback(rejectedFiles => {
        if (rejectedFiles.length < 1) return;
        if (rejectedFiles[0].file.size > maxSize) {
            const sizeMessage = parse(
                `<div className="error--upload-size">Profile pictures must be under 5 MB.</div>`
            );
            window.toast.error(sizeMessage, toastConfig);
        } else if (rejectedFiles[0].errors[0].code === 'file-invalid-type') {
            window.toast.error("File must be a .png", toastConfig);
        }
    }, [])

    const {
        isDragActive,
        getRootProps,
        getInputProps,
        isDragReject,
        acceptedFiles,
        fileRejections,
        open } = useDropzone({
            onDropRejected,
            onDrop,
            accept: { 'image/png': ['.png'], 'image/jpg': ['.jpg'], 'image/jpeg': ['.jpeg'], 'image/heic': ['.heic'], 'image/heif': ['.heif'] },
            minSize: 0,
            maxSize,
            multiple: false
        });

    const generateRandomHash = (length = 32) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    }

    const getCroppedImage = () => {
        const imageElement = cropperRef?.current;
        const cropper = imageElement?.cropper;

        cropper.getCroppedCanvas().toBlob((blob) => {
            const hash = generateRandomHash();
            const filename = `${hash}_croppedImage.jpeg`;
            const file = new File([blob], filename, { type: "image/jpeg", lastModified: Date.now() });
            setCroppedImageData(file);
        }, "image/jpeg", 0.95);
    };

    const saveProfilePicture = () => {
        uploadProfilePictureToS3(croppedImageData);
        hide();
    }

    const clearProfilePicture = () => {
        updateUser(user.id, { profile_picture: '' });
    }

    const backToStart = () => {
        setImageSrc(null);
        setCroppedImageData(null);
        if (cropperRef.current) {
            cropperRef.current.value = '';
        }
    }

    return (
        <Modal
            name={'modal--profile-picture'}
            hide={hide}
            small={true}
            title={'Update Profile Picture'}
            hideCancel={true}
            content={
                <>
                    {!imageSrc && <div className='upload-dropzone'>
                        <div className='click-area' {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className='content-wrapper'>
                                {user.profile_picture ? <img className="profile-picture" src={user.profile_picture} /> : <User />}
                            </div>
                        </div>
                        <div id="preview"></div>
                    </div>}
                    {imageSrc && (
                        <div>
                            <Cropper
                                ref={cropperRef}
                                src={imageSrc}
                                style={{ height: 258, width: '100%' }}
                                initialAspectRatio={1}
                                aspectRatio={1}
                                guides={false}
                                crop={getCroppedImage}
                            />
                        </div>
                    )}
                </>
            }
            actions={
                <>
                    <div>
                        {!imageSrc && <button className='button button--secondary' onClick={hide}>Close</button>}
                        {imageSrc && <button className='button button--secondary' onClick={backToStart}>Back</button>}
                    </div>
                    <div>
                        {user.profile_picture && !imageSrc && <button className='button button--secondary' onClick={clearProfilePicture}>Remove Picture</button>}
                        {imageSrc && <button className='button button--success' onClick={saveProfilePicture}>Save</button>}
                    </div>
                </>
            }
        />
    )
}

const mapStateToProps = (state) => ({
    user: state.user
});
const mapDispatchToProps = (dispatch) => ({
    updateUser: (user_id, attributes) => dispatch(updateUser(user_id, attributes)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ProfilePictureModal);
