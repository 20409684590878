import React, { useState, useEffect } from 'react';
import { fetchAssetList } from '../../util/apiUtil';
import Select from 'react-select';
import customStyles from '../../util/singleSelectStyles';
import AssetDisplay from './AssetDisplay';
import Modal from '../misc/Modal';

function AssetSelector({ onAssetSubmission, hide }) {
    const [assetOptions, setAssetOptions] = useState(null);
    const [selectedAssetId, setSelectedAssetId] = useState(null);

    useEffect(() => {
        fetchAssetList()
            .then(responseData => {
                formatAssetData(responseData);
            });
    }, []);

    const formatAssetData = (data) => {
        const options = data.results.map(o => ({
            label: o.properties.Title.title[0].plain_text,
            value: o.id
        }));
        setAssetOptions(options)
    }

    const handleAssetSelection = (e) => {
        setSelectedAssetId(e.value);
    }

    const submitAssetSelection = (assetId) => {
        hide();
        if (onAssetSubmission) {
            onAssetSubmission(assetId);
        }
    }

    return (
        <Modal
            name={"modal--select-asset"}
            hide={hide}
            title={"Insert Asset"}
            isFocusTrapPaused={true}
            content={
                <>
                    {assetOptions && <Select
                        options={assetOptions}
                        onChange={(value) => handleAssetSelection(value)}
                        styles={customStyles}
                        isSearchable
                        isClearable
                        menuPortalTarget={document.body}
                        menuPlacement="auto"
                        menuPosition="fixed"
                    />}
                    {selectedAssetId && <AssetDisplay pageId={selectedAssetId} />}
                </>
            }
            actions={
                <button className="button button--success" onClick={() => submitAssetSelection(selectedAssetId)}>Add</button>
            }
        />
    );
}

export default AssetSelector;
