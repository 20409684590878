import React, { useState } from 'react';

const YieldToggle = (props) => {
    const [selected, setSelected] = useState(props.selected.value);

    const handleChange = (tag) => {
        setSelected(tag.value);
        const tagArr = [tag];
        tag.value === 'all' ? props.handleYieldToggleChange(null) : props.handleYieldToggleChange(tagArr);
    };

    return (
        <div className='toggle yield-toggle'>
            {props.values.map((item, i) => {
                return (
                    <div key={i} className="toggle_option">
                        <input
                            type="radio"
                            name="yield-toggle"
                            value={item.value}
                            checked={selected === item.value}
                            onChange={() => handleChange(item)}
                        />
                        <label>{item.label}</label>
                    </div>
                );
            })}
        </div>
    );
}

export default YieldToggle;
