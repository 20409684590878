import _ from 'lodash';
import React, { Component } from 'react';
import Select from 'react-select';

export default class MultiDeckSelector extends Component {
    constructor(props) {
        super(props);
        this.state = { selectedOptions: null };
    }

    handleChange = (selectedOptions) => {
        this.setState({ selectedOptions });
        this.props.setSelectedDecks(selectedOptions);
    }

    render() {
        const label = this.props.type === 'deck' ? 'Deck: ' : 'Subdeck: ';
        const customStyles = {
            control: (provided, state) => ({
                ...provided,
                backgroundColor: 'var(--input-dropdown-background)',
                borderColor: 'var(--input-border-color)'
            }),
            menu: (provided, state) => ({
                ...provided,
                backgroundColor: 'var(--input-dropdown-background)',
            }),
            option: (provided, state) => ({
                ...provided,
                backgroundColor: 'transparent',
                color: 'var(--button-primary-background)',
                "&:hover": {
                  backgroundColor: 'var(--button-primary-background)',
                  color: 'var( --button-primary-color)',
                }
            }),
            singleValue: (provided, state) => ({
                ...provided,
                color: 'var(--type-body-default)',
            }),
            multiValue: (provided, state) => ({
              ...provided,
              backgroundColor: 'var(--button-primary-background)',
              borderRadius: '.25rem'
            }),
            multiValueLabel: (provided, state) => ({
              ...provided,
              color: 'var(--button-primary-color)'
            }),
            multiValueRemove: (provided, state) => ({
              ...provided,
              cursor: 'pointer',
              "*": {
                fill: 'var(--button-primary-color)',
              },
              "&:hover": {
                backgroundColor: 'var(--button-primary-background)',
                borderRadius: '.25rem'
              }
            }),
        }  

        return (
            <div className='deck-selector'>
                <div className='deck-selector__label'>{label}</div>
                <div style={{width: '300px'}}>
                    <Select 
                        // isMulti
                        defaultValue={
                            this.props.options.filter(el => el.value === this.props.initialSelectedDeck)
                        }
                        styles={customStyles}
                        options={this.props.options}
                        onChange={this.handleChange}
                    />
                </div>
            </div>
        )
    }
}
