import { SET_THEME } from '../../actions/allActions';

const themeReducer = (state = '', action) => { 
	Object.freeze(state);
	switch (action.type) {
		case SET_THEME:
			return action.payload;
		default:
			return state;
	}
}

export default themeReducer;
