import React, { useEffect, useState } from 'react'
import { Switch, Route } from 'react-router-dom';
import { useSelector, connect } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { injectStyle } from "react-toastify/dist/inject-style";
import HeaderContainer from './global/HeaderContainer'
import AppBarContainer from './global/AppBarContainer';
import HomeContainer from './home/HomeContainer'
import UserMenuMobileContainer from './user/UserMenuMobileContainer'
import SettingsContainer from './user/SettingsContainer'
import StatsContainer from './stats/StatsContainer';
import BrowseContainer from './browse/BrowseContainer';
import UserBrowse from './impersonate/UserBrowse';
import SchoolsIndex from './schools/SchoolsIndex';
import CuratorHome from './curator/CuratorHome';
import CardBuilderContainer from './builder/CardBuilderContainer';
import DeckUploader from './upload/DeckUploader';
import FlashCardStudyContainer from './study/FlashCardStudyContainer';
import Checkout from './checkout/Checkout';
import CheckoutSuccess from './checkout/CheckoutSuccess';
// import Onboarding from './home/Onboarding';
import ThiemeOnboarding from './home/ThiemeOnboarding';
import ProTip from './misc/ProTip';
import NavigationKeybinds from './global/NavigationKeybinds';
import DynamicBackground from './misc/DynamicBackground';
import { isString } from 'lodash';
import isMobile from '../util/isMobile';
import GlobalNavigation from './global/GlobalNavigation';
import { useHistory } from 'react-router-dom';
import {
    setTheme,
} from '../actions/allActions'

const App = ({ user, setTheme, decks }) => {
    const theme = useSelector((state) => state.theme);
    const menuState = useSelector((state) => state.menuState);
    // const [showOnboarding, setShowOnboarding] = useState(false);
    const [showThiemeOnboarding, setShowThiemeOnboarding] = useState(false);
    const [thiemeOnboardingType, setThiemeOnboardingType] = useState(null);

    const history = useHistory();

    // THEME
    // Set Default
    useEffect(() => {
        if (theme === '' && user.group !== 'thieme') {
            document.body.classList.add('theme-light-clean');
            setTheme('theme-light-clean');
        }
        if (Capacitor.getPlatform() === 'ios') {
            let wWidth = window.innerWidth;
            if (theme === 'theme-light-clean' || theme === '') {
                setStatusBarStyleLight();
            } else {
                if (theme === 'theme-glass' && wWidth < 900) {
                    setStatusBarStyleLight();
                } else {
                    setStatusBarStyleDark();
                }
            }
        }
    }, [theme]);

    document.body.className = document.body.className.replace(/theme-.*?($| )/g, '');
    if (user.group === 'thieme' && theme !== "theme-dark-clean") {
        document.body.classList.add('theme-thieme');
    } else if (!!theme && isString(theme)) {
        document.body.classList.add(theme);
    }

    if (Capacitor.getPlatform() === 'ios') {
        document.body.classList.add('CAPACITOR-IOS');
    }
    if (Capacitor.isNativePlatform()) {
        document.body.classList.add('IS-CAPACITOR');
    }

    // Menu
    if (menuState) {
        document.body.classList.remove('gnav-collapsed');
    } else {
        document.body.classList.add('gnav-collapsed');
    }

    // add react-toastify to window
    window.toast = toast;
    injectStyle();

    const openThiemeOnboarding = (type) => {
        setThiemeOnboardingType(type);
        setShowThiemeOnboarding(true);
    }

    const closeThiemeOnboarding = () => {
        localStorage.setItem('showThiemeOnboarding', false);
        setShowThiemeOnboarding(false);
    }

    useEffect(() => {
        // const showOB = (localStorage["showOnboarding"] === 'true');
        const showThiemeOB = (localStorage["showThiemeOnboarding"] === 'true');
        if (showThiemeOB && Capacitor.getPlatform() !== 'ios') {
            setShowThiemeOnboarding(true);
            setThiemeOnboardingType(localStorage["thiemeOnboardingType"]);
        }
        const streamlinedCheckout = localStorage.getItem('streamlinedCheckout');
        if (streamlinedCheckout) {
            const productId = localStorage.getItem('streamlinedCheckoutProductId');
            localStorage.removeItem('streamlinedCheckout');
            localStorage.removeItem('streamlinedCheckoutProductId');
            // Redirect to checkout for productId
            history.push(`/checkout/${productId}`);
        }
    }, []);

    const setStatusBarStyleDark = async () => {
        await StatusBar.setStyle({ style: Style.Dark });
    };

    const setStatusBarStyleLight = async () => {
        await StatusBar.setStyle({ style: Style.Light });
    };

    return (
        <>
            {theme === 'theme-glass' && user.group !== 'thieme' && <DynamicBackground />}
            <div className="layout layout--site gnav-open">
                <GlobalNavigation />
                <div className="layout--site__main">
                    <HeaderContainer />
                    <Switch>
                        {user.admin && <Route path="/impersonate" component={UserBrowse} />}
                        {user.admin && <Route path="/schools" component={SchoolsIndex} />}
                        {(user.admin || user.curator || user.group === 'user-curator') && <Route path="/curation" component={CuratorHome} />}
                        <Route path="/deck/:id" component={FlashCardStudyContainer} />
                        <Route path='/stats' component={StatsContainer} />
                        <Route path='/checkout/:prod_id' component={Checkout} />
                        <Route path='/success' component={CheckoutSuccess} />
                        <Route path='/browse' component={BrowseContainer} />
                        <Route path='/user' component={UserMenuMobileContainer} />
                        <Route path='/settings'>
                            <SettingsContainer openSpacedRepOnboarding={openThiemeOnboarding} />
                        </Route>
                        <Route path='/builder' component={CardBuilderContainer} />
                        <Route path='/upload' component={DeckUploader} />
                        <Route path='/' component={HomeContainer} />
                    </Switch>
                </div>
                <ToastContainer />
                {!isMobile() && <ProTip />}
                {!isMobile() && <NavigationKeybinds />}
                {showThiemeOnboarding && <ThiemeOnboarding name={user.first_name} group={user.group} hide={closeThiemeOnboarding} type={thiemeOnboardingType} />}
                <AppBarContainer />
            </div>
        </>
    );
};

const mapStateToProps = state => ({ user: state.user, decks: state.decks });
const mapDispatchToProps = dispatch => ({ setTheme: (theme) => dispatch(setTheme(theme)) });
export default connect(mapStateToProps, mapDispatchToProps)(App);
