import {
    RECEIVE_TAGS,
    RECEIVE_SELECTED_TAGS,
    RECEIVE_TAGGING_STRATEGY,
	CLEAR_TAGS
} from '../../actions/allActions';

const tagsReducer = (state = _default, action) => { 
	Object.freeze(state);
	let nextState = _.cloneDeep(state);

	switch (action.type) {
		case RECEIVE_TAGS:
			nextState.list = reorderTags(action.payload);
			return nextState;
		case RECEIVE_SELECTED_TAGS:
			nextState.selected = action.payload;
			return nextState;
		case RECEIVE_TAGGING_STRATEGY:
			nextState.strategy = action.payload;
			return nextState;
		case CLEAR_TAGS:
			nextState.list = [];
			nextState.selected = null;
			return nextState;
		default:
			return state;
	}
}

const _default = {
	list: [],
	selected: null,
	strategy: 'and'
}

const reorderTags = (tags) => {
	if (!tags) return;

	tags = tags.sort();

	// Move low yield
	let idx = tags.findIndex(tag => tag == 'Low Yield')
	if (idx !== -1) {
		tags.splice(idx, 1)
		tags.unshift('Low Yield')
	}

	// Move medium yield
	idx = tags.findIndex(tag => tag == 'Medium Yield')
	if (idx !== -1) {
		tags.splice(idx, 1)
		tags.unshift('Medium Yield')
	}

	// Move high yield
	idx = tags.findIndex(tag => tag == 'High Yield')
	if (idx !== -1) {
		tags.splice(idx, 1)
		tags.unshift('High Yield')
	}

	idx = tags.findIndex(tag => tag == "Relevant for USMLE Step 2")
	if (idx !== -1) {
		tags.splice(idx, 1)
		tags.unshift("Relevant for USMLE Step 2")
	}

	idx = tags.findIndex(tag => tag == "Relevant for USMLE Step 1")
	if (idx !== -1) {
		tags.splice(idx, 1)
		tags.unshift("Relevant for USMLE Step 1")
	}

	idx = tags.findIndex(tag => tag == "High Yield for Step 2")
	if (idx !== -1) {
		tags.splice(idx, 1)
		tags.unshift("High Yield for Step 2")
	}

	idx = tags.findIndex(tag => tag == "High Yield for Step 1")
	if (idx !== -1) {
		tags.splice(idx, 1)
		tags.unshift("High Yield for Step 1")
	}

	return tags;
}

export default tagsReducer;
