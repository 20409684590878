import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from '../reducers/rootReducer';
import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import localforage from 'localforage';

const persistConfig = {
  key: 'root',
  storage: localforage
  // stateReconciler: autoMergeLevel1,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default (preloadedState = {}) => {
  let store;
  if (process.env.NODE_ENV !== 'production') {
    store = createStore(
      persistedReducer, 
      preloadedState, 
      applyMiddleware(thunk, logger)
    )
  } else {
    store = createStore(
      persistedReducer, 
      preloadedState, 
      applyMiddleware(thunk)
    )
  }
  let persistor = persistStore(store)
  return { store, persistor }
}
