import _ from 'lodash';
import { RECEIVE_ACTIVE_STUDY_PROFILE } from '../../actions/allActions';

const activeStudyProfileReducer = (state = {}, action) => { 
	Object.freeze(state);
	// let nextState = _.cloneDeep(state);

	switch (action.type) {
		case RECEIVE_ACTIVE_STUDY_PROFILE:
			if (!_.isEmpty(action.payload)) {
				action.payload.due = Math.max(action.payload.due, 0);
				action.payload.new = Math.max(action.payload.new, 0);
			}
			return action.payload;
		default:
			return state;
	}
}

export default activeStudyProfileReducer;
