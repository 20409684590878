import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import DeckEditor from './DeckEditor';
import { receiveSelectedDeck } from '../../actions/allActions'; 

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.user,
        decks: state.decks,
        deck: _.defaultTo(state.decks[ownProps.deckId], {})
    }
}

const mapDispatchToProps = dispatch => {
    return {
        receiveSelectedDeck: (deckId) => dispatch(receiveSelectedDeck(deckId)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeckEditor));
