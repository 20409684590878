import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import QuillEditor from './QuillEditor';
import MultiDeckSelector from './MultiDeckSelector';
import CreatedCardTile from './CreatedCardTile';
import { Eye } from 'react-feather';
import formatKenhubLinks from '../../util/formatKenhubLinks';

import KeyEnter from '../../../assets/images/icons/key-cmd-enter.svg';
import Key19 from '../../../assets/images/icons/key-cmd-1-9.svg';
import KeyCmdH from '../../../assets/images/icons/key-cmd-h.svg';
import AssetSelector from '../assets/AssetSelector';

export default class CardBuilder extends Component {
    constructor(props) {
        super(props);
        const deckOptions = this.formatDeckOptions();

        const initialDeckSelection = deckOptions.find(deck => deck.value === this.props.selectedDeckId);
        
        this.state = { 
            deckOptions,
            selectedDeck: _.isEmpty(initialDeckSelection) ? null : initialDeckSelection,
            questionHtml: null,
            answerHtml: null,
            cardBeingEdited: null,
            model: 'basic',
            selectedFile: null,
            ord: 0,
        }

        this.questionRef = React.createRef();
        this.answerRef = React.createRef();
    }

    formatDeckOptions() {
        return Object.values(this.props.decks)
            .filter(deck => deck.access !== 'viewer')
            .map(deck => ({ value: deck.id, label: deck.name }));
    }

    componentDidMount() {
        document.body.classList.add('builder');
        window.getUserReferralToken = this.getUserReferralToken;
        window.submitShortcut = this.submitShortcut;
    }

    componentWillUnmount() {
        document.body.classList.remove('builder');
        window.submitShortcut = null;
        this.props.clearSelectedDeck();
        this.props.clearCreatedCards();
    }

    getUserReferralToken = () => {
        return this.props.user.referral_token;
    }

    submitShortcut = () => {
        if (!!this.state.cardBeingEdited) {
            this.handleEditSubmit();
        } else {
            this.handleCreate();
            const editor = document.querySelector('.question .ql-editor');
            if (editor) {
                editor.focus();
            }
        }
    }

    setQuestionHtml = (html) => {
        this.setState({ questionHtml: html }, this.handleQuestionChangeModelUpdate);
    }

    handleQuestionChangeModelUpdate = () => {
        if (this.state.model === 'multichoice') return;
        this.updateModel();
    }

    updateModel = () => {
        if (!this.state.questionHtml) {
            this.setState({ model: 'basic' });
        } else if (this.state.questionHtml.includes('cloze') && this.state.model !== 'cloze') {
            this.setState({ model: 'cloze' });
        } else if (!this.state.questionHtml.includes('cloze')) {
            this.setState({ model: 'basic' });
        }
    }

    toggleQAModel = () => {
        if (this.state.model === 'multichoice') {
            this.updateModel();
        } else {
            this.setState({ model: 'multichoice' });
        }
    }

    setAnswerHtml = (html) => {
        this.setState({ answerHtml: html });
    }

    setSelectedDecks = (selectedDeck) => {
        this.setState({ selectedDeck });
    }

    setModel = (model) => {
        this.setState({ model });
    }

    resetEditor = () => {
        const model = this.state.model === 'multichoice' ? 'multichoice' : 'basic';
        this.setState({
            questionHtml: null,
            answerHtml: null,
            cardBeingEdited: null,
            model: model,
            ord: 0,
        });
    }

    handleCreate = () => {
        const toastConfig = {
            position: "bottom-left",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        }
        if (_.isEmpty(this.state.selectedDeck)) {
            window.toast.error('You must select a deck.', toastConfig);
            return;
        }
        if (!this.cardCreationAllowed()) {
            window.toast.error('You must fill out both sides of the card.', toastConfig);
            return;
        } 
        if (Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) < 900) {
            this.isMultiCard(this.state.questionHtml) ? 
                window.toast.success('Cards Successfully Created', toastConfig) 
                : window.toast.success('Card Successfully Created', toastConfig);
        }

        const questionHtml = formatKenhubLinks(this.state.questionHtml);
        const answerHtml = formatKenhubLinks(this.state.answerHtml);
        
        let card = {};
        if (this.state.model === 'basic' || this.state.model === 'cloze') {
            card = {
                question: questionHtml,
                answer: this.state.model == 'cloze' ? '' : answerHtml,
                deck_id: this.state.selectedDeck?.value,
                extra: this.state.model == 'cloze' ? answerHtml : '',
                model: this.state.model,
                ord: this.state.ord,
            }
        } else if (this.state.model === 'multichoice') {
            const correctAnswer = this.extractMultichoiceCorrectAnswer(questionHtml);
            const mainContent = this.formatMultichoiceContent(questionHtml);
            const extraContent = this.formatMultichoiceExtraContent(answerHtml);
            card = {
                question: mainContent,
                answer: mainContent,
                deck_id: this.state.selectedDeck?.value,
                extra: extraContent,
                model: this.state.model,
                ord: this.state.ord,
                multi_choice_answer: correctAnswer,
            }
        }
        this.props.createCards(card);
        this.resetEditor();
    }

    cardCreationAllowed = () => {
        if (!this.state.questionHtml || this.state.questionHtml == '<p><br></p>') return false;
        if (!this.state.answerHtml && this.state.model == 'basic') return false;
        if (this.state.answerHtml == '<p><br></p>' && this.state.model == 'basic') return false;
        return true;
    }

    extractMultichoiceCorrectAnswer = (inputString) => {
        const regex = /\]\]\((.)\)/;
        const match = inputString.match(regex);

        if (match && match[1]) {
            const letter = match[1];
            // Convert letter A-E to corresponding number 0-4
            return letter.charCodeAt(0) - 'A'.charCodeAt(0);
        } else {
            return null;
        }
    }

    formatMultichoiceContent = (questionHtml) => {
        // Remove non-breaking spaces
        let formattedContent = questionHtml.replace('&nbsp;', '');
        // Remove the answer substring
        formattedContent = formattedContent.replace(/\]\]\((.)\)/, ']]');
        // Remove possible <p> tags surrounding multichoice option section
        formattedContent = formattedContent.replace('<p>[[', '[[').replace(']]</p>', ']]');
        // Format image substring
        formattedContent = formattedContent.replace(/<p>\{(.*?)\}<\/p>/g, '<img src="https://d1lze488p2cr08.cloudfront.net/radiology/top-score/images/$1.jpg">');
        // Format multichoice section
        formattedContent = this.formatMultichoiceOptions(formattedContent);
        
        return formattedContent;
    }

    formatMultichoiceExtraContent = (extraContent) => {
        if (!extraContent) return extraContent;
        
        // Remove non-breaking spaces
        let formattedContent = extraContent.replace('&nbsp;', '');
        
        // Start with "<b>Explanation</b>"
        formattedContent = "<p><strong>Explanation</strong></p>" + formattedContent;

        // Replace "Other choices and discussion" with bold tag
        formattedContent = formattedContent.replace("Other choices and discussion", "<strong>Other choices and discussion</strong>");
        
        return formattedContent;
    }

    formatMultichoiceOptions = (inputString) => {
        const regex = /\[\[((.|\n|\r)*?)\]\]/;
        const matches = inputString.match(regex);
        if (!matches || matches.length < 2) {
            return inputString;
        }

        const choices = matches[1].replace(/<\/?p>/g, ' ').split(/\s(?=[A-E]\.)/).map(choice => choice.trim());
        let htmlOutput = '<div id="multichoice-options">';

        choices.forEach((choice, index) => {
            htmlOutput += `<label id="choice-${index}" for="choice-${index}-input"><input id="choice-${index}-input" type="radio" name="multichoice" /><span>${choice}</span></label>`;
        });

        htmlOutput += '</div>';
        return inputString.replace(regex, htmlOutput);
    }

    isMultiCard = (str) => {
        let matches = str.match(/data-cloze-group="(\d)"/g) || [];
        matches = matches.filter((v, i, a) => a.indexOf(v) === i);
        return matches.length > 1;
    }

    deleteCard = (cardId) => {
        this.props.deleteCard(cardId)
    }

    // TODO: handle QA card model
    initiateEditCard = (card) => {
        // Smooth Scroll to Top on Edit
        const animationDuration = 250;
        const targetPosition = 0;
        const startPosition = window.pageYOffset || document.documentElement.scrollTop;
        const distance = targetPosition - startPosition;
        const startTime = performance.now();
        const scrollAnimation = function (currentTime) {
            const elapsedTime = currentTime - startTime;
            const progress = elapsedTime / animationDuration;
            const newPosition = startPosition + distance * progress;
            window.scrollTo(0, newPosition);
            if (elapsedTime < animationDuration) {
                requestAnimationFrame(scrollAnimation);
            }
        };
        requestAnimationFrame(scrollAnimation);

        if (card.model == 'basic') {
            this.setState({
                questionHtml: card.question,
                answerHtml: card.answer,
                cardBeingEdited: card.id,
                model: 'basic',
                ord: card.ord
            })
        } else if (card.model == 'cloze') {
            const questionHtml = this.formatClozeForEditing(card.question, card.answer);
            this.setState({
                questionHtml: questionHtml,
                answerHtml: card.extra,
                cardBeingEdited: card.id,
                model: 'cloze',
                ord: card.ord
            })
        } else if (card.model == 'multichoice') {
            const questionHtml = this.formatMultichoiceQuestionForEditing(card.question, card.multi_choice_answer);
            const extraContent = this.formatMultichoiceExtraForEditing(card.extra);
            this.setState({
                questionHtml: questionHtml,
                answerHtml: extraContent,
                cardBeingEdited: card.id,
                model: 'multichoice',
                ord: card.ord
            })
        }
    }

    formatClozeForEditing(questionHtml, answerHtml) {
        let isMatch = true;
        for (let i = 0; isMatch; i++) {
            const regexAnswerGlobal = /<span class="card__cloze".*?>(.*?)<\/span>/g;
            const regexAnswer = /<span class="card__cloze".*?>(.*?)<\/span>/;

            const currentAnswerSpan = answerHtml.match(regexAnswerGlobal)?.[i];
            const answer = currentAnswerSpan?.match(regexAnswer)?.[1];

            const regexQuestion = /<span class="card__cloze".*?>\[(.*?)\]<\/span>/;
            const questionMatch = questionHtml.match(regexQuestion);
            const hint = questionMatch?.[1];

            // break if there are no more cloze questions to format
            if (!questionMatch) break;

            questionHtml = questionHtml.replace(
                /<span class="card__cloze".*?\[.*?\]<\/span>/, 
                `<cloze data-cloze-group="1" class="cloze-tile cloze-group-1">${answer}${hint == '...' ? '' : ' | ' + hint}</cloze>`
            )
        }
        return questionHtml;
    }

    formatMultichoiceQuestionForEditing(question, answerNum) {
        let formattedQuestion = question.replace("<span>A.", "<span>[[A.");
        const answerLetter = this.answerNumberToLetter(answerNum);
        formattedQuestion = formattedQuestion.replace("</span></label></div>", `]](${answerLetter})</span></label></div>`);
        formattedQuestion = formattedQuestion.replace(/<br>/g, '');
        return formattedQuestion;
    }

    formatMultichoiceExtraForEditing(extraContent) {
        let formattedContent = extraContent.replace('<p><strong>Explanation</strong></p>', '');
        formattedContent = formattedContent.replace(/<br>/g, '');
        return formattedContent;
    }

    answerNumberToLetter(num) {
        const mapping = ['A', 'B', 'C', 'D', 'E'];
        if (num >= 0 && num <= 4) {
            return mapping[num];
        } else {
            return '';
        }
    }
    
    handleEditSubmit() {
        if (_.isEmpty(this.state.selectedDeck)) return;
        let card = {};
        if (this.state.model == 'multichoice') {
            const correctAnswer = this.extractMultichoiceCorrectAnswer(this.state.questionHtml);
            const mainContent = this.formatMultichoiceContent(this.state.questionHtml);
            const extraContent = this.formatMultichoiceExtraContent(this.state.answerHtml);
            card = {
                id: this.state.cardBeingEdited,
                question: mainContent,
                answer: mainContent,
                deck_id: this.state.selectedDeck?.value,
                extra: extraContent,
                model: this.state.model,
                ord: this.state.ord,
                multi_choice_answer: correctAnswer,
            }
        } else {
            card = {
                id: this.state.cardBeingEdited,
                question: this.state.questionHtml,
                answer: this.state.model == 'cloze' ? '' : this.state.answerHtml,
                deck_id: this.state.selectedDeck?.value,
                extra: this.state.model == 'cloze' ? this.state.answerHtml : '',
                model: this.state.model,
                ord: this.state.ord,
            }
        }
        this.props.updateCardContent(card);
        this.resetEditor();
    }

    renderActions() {
        let keybindsTooltip = `<div class="tooltip tooltip--keybind">
            <div class="tooltip--keybind__item"><img src=` + KeyEnter + ` alt="Command Plus Enter"/>&nbsp;= Create/Update Card</div>
            <div class="tooltip--keybind__item"><img src=` + Key19 + ` alt="Command Plus One through 9"/>&nbsp;= Create Cloze Group</div>
            <div class="tooltip--keybind__item"><img src=` + KeyCmdH + ` alt="Command Plus H"/>&nbsp;= Add Hint to Cloze Field</div>
        </div>`;

        if (!!this.state.cardBeingEdited) {
            return (<>
                <button className="button button--secondary" onClick={() => this.resetEditor()}>Cancel</button>
                <button className="button" onClick={() => this.handleEditSubmit()}>Update</button>
            </>)
        } else {
            return (
                <>
                    <button className='view-keybinds' data-tip={keybindsTooltip} data-html={true} data-class="keybind-tooltip"><Eye/>View Keyboard Shortcuts</button>
                    <button className="button" onClick={() => this.handleCreate()}>Create</button>
                    <ReactTooltip />
                </>
            );
        }
    }

    render() {
        return (
            <div className='layout layout--builder'>
                <div className='layout--builder__main layout_container'>
                    <div className='layout--builder__main__builder'>
                        {!!this.state.cardBeingEdited ? <h1>Update <span>Card</span></h1> : <h1>Create <span>New Cards</span></h1>}
                        <MultiDeckSelector type={'deck'} options={this.state.deckOptions} initialSelectedDeck={this.props.selectedDeckId} setSelectedDecks={this.setSelectedDecks} />
                        {(this.props.user.admin || this.props.user.curator) && !this.props.userProperties?.demoView && (
                            <div style={{display: 'flex', alignItems: 'center', gap: '.5rem', marginBottom: '1rem'}}>
                                <h3 className='m-x-0 m-y-0'>QA Card:</h3>
                                <label class="qa-switch">
                                    <input 
                                        type="checkbox" 
                                        checked={this.state.model === 'multichoice'}
                                        onChange={this.toggleQAModel}
                                    />
                                    <span class="qa-slider round"></span>
                                </label>
                            </div>
                        )}
                        <div className='builder'>
                            <div className='question-container'>
                                <h2>Front</h2>
                                <QuillEditor 
                                    ref={this.questionRef}
                                    version={'question'} 
                                    value={this.state.questionHtml} 
                                    setValue={this.setQuestionHtml} 
                                    setModel={this.setModel} 
                                    handleCreate={this.handleCreate}
                                />
                            </div>
                            <div className='answer-container'>
                                {(this.state.model == 'cloze' || this.state.model == 'multichoice') ? (<h2>Extra</h2>) : (<h2>Back</h2>)}
                                <QuillEditor 
                                    ref={this.answerRef}
                                    version={'answer'} 
                                    value={this.state.answerHtml} 
                                    setValue={this.setAnswerHtml}
                                    handleCreate={this.handleCreate}
                                    userAdmin={this.props.user.admin || this.props.user.curator}
                                    clozeEditor={this.state.model === 'cloze'}
                                />
                            </div>
                        </div>
                        {this.props.user?.admin && (
                            <div className='ord-setter'>
                                <label htmlFor="quantity">Order:</label>
                                <input type="number" name="card-ord" value={this.state.ord} onChange={(e) => this.setState({ ord: e.target.value })} min="-99999999" max="99999999"></input>
                            </div>
                        )}
                        <div className='actions'>
                            {this.renderActions()}
                        </div>
                    </div>
                    <div className='layout--builder__main__panel'>
                        <h2>Created Cards</h2>
                        <div className='card-layout'>
                            {Object.keys(this.props.createdCards).length == 0 ? (<p className='no-cards-message'>Created cards will appear here...</p>) : ''}
                            {this.props.createdCards.map((card, idx) => (
                                <CreatedCardTile
                                    key={idx}
                                    card={card}
                                    initiateEditCard={this.initiateEditCard}
                                    deleteCard={this.deleteCard}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
