import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import update from 'immutability-helper'
import Modal from '../misc/Modal';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DragIcon } from '../../util/dragIcon';
import { 
    bulkUpdateDeckOwnershipOrder
} from '../../actions/allActions';

function DeckOrderer({ decksTree, hide, bulkUpdateDeckOwnershipOrder }) {
    const [decks, setDecks] = useState(decksTree.map(deck => {
        return { id: deck.id, name: deck.name };
    }));

    const moveDeck = useCallback((dragIndex, hoverIndex) => {
        setDecks((prevDecks) =>
            update(prevDecks, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevDecks[dragIndex]],
                ],
            }),
        )
    }, [])

    const renderDeck = useCallback((deck, index) => {
        return (
            <DeckListItem
                key={deck.id}
                deck={deck}
                index={index}
                moveDeck={moveDeck}
            />
        )
    }, [])

    const handleSaveChanges = () => {
        bulkUpdateDeckOwnershipOrder(decks);
        hide();
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <Modal
                name={'modal--deck-reorderer'}
                title={'Reorder Decks'}
                hide={hide}
                small={true}
                content={
                    <>
                        <p>Drag your decks into the desired order.</p>
                        <div className='deck-list'>
                            {decks.map((deck, index) => renderDeck(deck, index))}
                        </div>
                    </>
                }
                actions={
                    <button className="button" onClick={handleSaveChanges}>Save</button>
                }
            />
        </DndProvider>
    )
}

const DeckListItem = ({ deck, index, moveDeck }) => {
    const ref = React.useRef(null);

    const [{ handlerId }, drop] = useDrop({
        accept: 'deck',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Determine mouse position
            const clientOffset = monitor.getClientOffset()
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveDeck(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    })

    const [{ isDragging }, drag] = useDrag({
        type: 'deck',
        item: () => {
            return { id: deck.id, index }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    return (
        <div className="deck-list_item" ref={ref} style={{ opacity }} data-handler-id={handlerId}>
            <DragIcon/>
            <span>{deck.name}</span>
        </div>
    );
};

const mapStateToProps = (state) => ({
    decksTree: state.decksTree
});
const mapDispatchToProps = (dispatch) => ({
    bulkUpdateDeckOwnershipOrder: (decks) => dispatch(bulkUpdateDeckOwnershipOrder(decks)),
});
export default connect(mapStateToProps, mapDispatchToProps)(DeckOrderer);
