import { connect } from 'react-redux'
import FlashCardStudy from './FlashCardStudy'
import { 
    fetchCards,
    receiveCardAndMeta,
    receivePastCard,
    clearPastCards,
    shiftCard,
    undoCardResponse,
    fetchPercentMastery,
    receiveActiveStudyProfile,
    updateCardResponse,
    clearCards,
    tagCard,
    untagCard,
    updateCardContent,
    fetchUserStudySettings,
    updateUserProperties,
    suspendCard,
    receiveActiveStudySession,
    clearActiveStudySession
} from '../../actions/allActions'

const mapStateToProps = (state, ownProps) => {
    const deckId = parseInt(ownProps.match.params.id);
    const deck = _.get(state, `decks[${deckId}]`, {});
    const cards = state.cards;
    const dueCards = _.defaultTo(cards.due, []);
    const newCards = _.defaultTo(cards.new, []);
    const activeStudyProfileFallback = { deckId, due: dueCards.length, new: newCards.length };
    const activeStudyProfile = _.get(state, `activeStudyProfile`, activeStudyProfileFallback);
    const userStudySettings = state.userStudySettings;
    const prevCardInfo = _.get(state, `pastCards[0]`, {});
    const [currentCard, currentType] = calcCurrentCard(dueCards, newCards, activeStudyProfile);
    const progress = _.get(state, `progress[${deckId}]`, {});
    const selectedTags = state.tags.selected;
    const taggingStrategy = state.tags.strategy;
    const loading = !cards.due || !cards.new;
    const user = state.user;
    const userProperties = state.userProperties;
    const activeStudySession = state.activeStudySession;
    const theme = state.theme;

    return {
        deckId,
        deck,
        cards,
        dueCards,
        newCards,
        activeStudyProfile,
        userStudySettings,
        prevCardInfo,
        currentCard,
        currentType,
        progress,
        selectedTags,
        taggingStrategy,
        loading,
        user,
        userProperties,
        activeStudySession,
        theme
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchCards: (deckId, options) => dispatch(fetchCards(deckId, options)),
        clearCards: () => dispatch(clearCards()),
        flipCard: () => dispatch({ type: 'FLIP_CARD' }),
        unflipCard: () => dispatch({ type: 'UNFLIP_CARD' }),
        receivePastCard: (payload) => dispatch(receivePastCard(payload)),
        clearPastCards: () => dispatch(clearPastCards()),
        setActiveStudyProfile: (activeStudyProfile) => dispatch(receiveActiveStudyProfile(activeStudyProfile)),
        shiftCard: (deckId, type) => dispatch(shiftCard({ deckId, type })),
        undoCardResponse: (cardInfo) => dispatch(undoCardResponse(cardInfo)),
        fetchPercentMastery: (deckId) => dispatch(fetchPercentMastery(deckId)),
        addCardToQueue: (deckId, type, card) => dispatch(receiveCardAndMeta({ deckId, type, card })),
        updateCardResponse: (card, response) => updateCardResponse(card, response),
        tagCard: (cardId, tags) => dispatch(tagCard(cardId, tags)),
        untagCard: (cardId, tags) => dispatch(untagCard(cardId, tags)),
        updateCardContent: (card) => dispatch(updateCardContent(card)),
        fetchUserStudySettings: () => dispatch(fetchUserStudySettings()),
        updateUserProperties: properties => dispatch(updateUserProperties(properties)),
        suspendCard: (cardId) => dispatch(suspendCard(cardId)),
        receiveActiveStudySession: (payload) => dispatch(receiveActiveStudySession(payload)),
        clearActiveStudySession: () => dispatch(clearActiveStudySession())
    }
}

const calcCurrentCard = (dueCards, newCards, activeStudyProfile) => {
    if ((!moreDueToStudy(activeStudyProfile) && !moreNewToStudy(activeStudyProfile)) || (!dueCardsAvailable(dueCards) && !newCardsAvailable(newCards))) {
        return [null, null];
    } else if (moreDueToStudy(activeStudyProfile) && dueCardsAvailable(dueCards) && isStrictDue(dueCards[0]) && isDueNow(dueCards[0])) {
        return [dueCards[0], 'due'];
    } else if (moreDueToStudy(activeStudyProfile) && dueCardsAvailable(dueCards) && !isStrictDue(dueCards[0])) {
        return [dueCards[0], 'due'];
    } else if (moreNewToStudy(activeStudyProfile) && newCardsAvailable(newCards)) {
        return [newCards[0], 'new'];
    } else if (moreDueToStudy(activeStudyProfile) && dueCardsAvailable(dueCards)) {
        return [dueCards[0], 'due'];
    } else {
        return [null, null];
    }
}

const moreDueToStudy = (activeStudyProfile) => {
    return activeStudyProfile.due > 0;
}

const moreNewToStudy = (activeStudyProfile) => {
    return activeStudyProfile.new > 0;
}

const dueCardsAvailable = (dueCards) => {
    return dueCards.length > 0;
}

const newCardsAvailable = (newCards) => {
    return newCards.length > 0;
}

const isStrictDue = (card) => {
    return card.interval < 1440;
}

const isDueNow = (card) => {
    const nextPracticeDueAt = card.next_practice_due_at;
    return moment().isSameOrAfter(moment(nextPracticeDueAt));
}

export default connect(mapStateToProps, mapDispatchToProps)(FlashCardStudy)
