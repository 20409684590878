import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Copy, Plus, Edit, GitMerge, RefreshCcw, Layers, Lock, Book, Trash2, Send, EyeOff, Eye, ChevronRight } from 'react-feather';
import { isCohortUser, isPaywalled } from '../../util/cohortUtil';
import { isThiemeDeck } from '../../util/cohortUtil';

import {
    receiveSelectedDeck,
    saveCollapseDefaults
} from '../../actions/allActions';

function DeckMenu({ user, deck, deckMenuTarget, hide, menuEditDeck, menuMergeDeck, clearSelectedId, menuCloneDeck, menuShareDeck, menuOpenConfirmingMenu, menuShowSchoolAssigner, receiveSelectedDeck, saveCollapseDefaults, lastInputType }) {
    const [menuOpen, setMenuOpen] = useState(false);
    const [focusedIndex, setFocusedIndex] = useState(0);
    const [menuPosition, setMenuPosition] = useState(
        {
            left: 0,
            top: 0
        }
    );

    useEffect(() => {
        document.addEventListener('click', determineClickForHide);
        return () => {
            document.removeEventListener('click', determineClickForHide);
        }
    }, []);

    useEffect(() => {
        const menu = document.querySelector('#deck-menu');
        const items = menu.querySelectorAll('a, button');
        function handleKeyDown(e) {
            if (e.key === "ArrowDown" || e.which === 40 || e.code === "ArrowDown") {
                e.preventDefault();
                let nextIndex = (focusedIndex + 1) % items.length;
                items[nextIndex].focus();
                setFocusedIndex(nextIndex);
            } else if (e.key === "ArrowUp" || e.which === 38 || e.code === "ArrowUp") {
                e.preventDefault();
                let prevIndex = (focusedIndex - 1 + items.length) % items.length;
                items[prevIndex].focus();
                setFocusedIndex(prevIndex);
            } else if (e.shiftKey && (e.key === "Tab" || e.which === 9 || e.code === "Tab")) {
                e.preventDefault();
                hide();
                deckMenuTarget.focus();
            } else if (e.key === "Tab" || e.which === 9 || e.code === "Tab") {
                e.preventDefault();
                hide();
                function findNextElement(el) {
                    if (el.nextElementSibling) return el.nextElementSibling;
                    el = el.parentNode;
                    while (el && !el.nextElementSibling) {
                        el = el.parentNode;
                    }
                    return el && el.nextElementSibling;
                }
                let nextEl = findNextElement(deckMenuTarget);
                if (nextEl) {
                    nextEl.focus();
                }
            }
        }
        menu.addEventListener('keydown', handleKeyDown);
        return () => menu.removeEventListener('keydown', handleKeyDown);
    }, [focusedIndex]);

    const determineClickForHide = (e) => {
        const deckMenu = document.getElementById('deck-menu');
        if (deckMenu?.contains(e.target)) {
            hide();
        } else {
            clearSelectedId();
            hide();
        }
        document.removeEventListener('click', determineClickForHide);
    }

    useEffect(() => {
        calculateMenuPosition();
    }, [deckMenuTarget]);

    const calculateMenuPosition = () => {
        let dropdown = document.getElementById('deck-menu');

        let parent = document.querySelector('.layout--home__container__wrapper__decks');
        let rectMenuTarget = deckMenuTarget.getBoundingClientRect();
        let rectParent = parent.getBoundingClientRect();
        let dropdownHeight = dropdown.offsetHeight;

        let relativeTop = rectMenuTarget.top - rectParent.top;
        let relativeLeft = rectMenuTarget.left - rectParent.left;

        if (relativeTop + rectMenuTarget.height + dropdownHeight > rectParent.height) {
            relativeTop = relativeTop - dropdownHeight;
        } else {
            relativeTop = relativeTop + rectMenuTarget.height;
        }

        // Don't let the first item to be above
        if (relativeTop < -220) {
            relativeTop = rectMenuTarget.top - rectParent.top + rectMenuTarget.height
        }

        setMenuOpen(true);
        setMenuPosition({ left: relativeLeft, top: relativeTop });

        if (lastInputType === 'click') {
            const container = document.querySelector('.layout--home__container');
            const scrollTop = container.scrollTop;
            focusFirstElement();
            container.scrollTop = scrollTop;
        } else {
            setTimeout(() => {
                focusFirstElement();
            }, 100);
        }
    }

    const focusFirstElement = () => {
        const parentElement = document.querySelector('#deck-menu');
        const firstFocusable = findFirstFocusableElement(parentElement);
        if (firstFocusable) {
            firstFocusable.focus();
        }
    }

    const findFirstFocusableElement = (element) => {
        const focusableSelectors = [
            'a[href]',
            'button:not([disabled])',
            'textarea:not([disabled])',
            'input[type="text"]:not([disabled])',
            'input[type="radio"]:not([disabled])',
            'input[type="checkbox"]:not([disabled])',
            'select:not([disabled])',
            '[tabindex]:not([tabindex="-1"]):not([disabled])'
        ];
        const focusableElements = Array.from(
            element.querySelectorAll(focusableSelectors.join(','))
        );
        const isVisiblyFocusable = (el) => {
            const style = window.getComputedStyle(el);
            return (
                style.display !== 'none' &&
                style.visibility !== 'hidden' &&
                style.visibility !== 'collapse' &&
                style.opacity !== '0'
            );
        };
        return focusableElements.find(isVisiblyFocusable);
    }

    return (
        <>
            <div id="deck-menu" className={`menu menu--deck-menu ${menuOpen ? 'active' : ''}`} style={{ top: menuPosition.top + 'px', left: menuPosition.left + 'px' }}>
                <div className="menu__dropdown">
                    {deck.access === 'owner' &&
                        <div className="menu__dropdown__item">
                            {<button style={{ textDecoration: 'none' }} onClick={menuEditDeck}><Edit /> Edit Deck</button>}
                        </div>
                    }
                    {!deck.parent_id &&
                        <div className="menu__dropdown__item">
                            {<button style={{ textDecoration: 'none' }} onClick={() => menuShareDeck(deck.id, true)}><Send /> Share Deck</button>}
                        </div>
                    }
                    {deck.access !== 'viewer' && !Capacitor.isNativePlatform() &&
                        <div className="menu__dropdown__item">
                            <Link to={`/builder`} style={{ textDecoration: 'none' }} onClick={() => receiveSelectedDeck(deck.id)}><Plus /> Add Cards</Link>
                        </div>
                    }
                    {!isCohortUser(user) && deck.access === 'owner' &&
                        <div className="menu__dropdown__item">
                            {<button style={{ textDecoration: 'none' }} onClick={() => menuMergeDeck(true)}><GitMerge /> Merge Deck</button>}
                        </div>
                    }
                    {!isPaywalled(user) &&
                        <div className="menu__dropdown__item">
                            {<button style={{ textDecoration: 'none' }} onClick={() => menuOpenConfirmingMenu('reset', deck.id)}><RefreshCcw /> Reset Deck</button>}
                        </div>
                    }
                    {/* <div className="menu__dropdown__item">
                        <button style={{ textDecoration: 'none', paddingRight: '.5rem' }}><EyeOff /> Suspend Cards <ChevronRight style={{margin: '0 0 0 1rem'}}/></button>
                        <div className='flyout'>
                            <div className="menu__dropdown__item">
                                {<button style={{ textDecoration: 'none' }} onClick={() => menuOpenConfirmingMenu('suspend', deck.id)}><EyeOff /> Suspend All Cards</button>}
                            </div>
                            <div className="menu__dropdown__item">
                                {<button style={{ textDecoration: 'none' }} onClick={() => menuOpenConfirmingMenu('unsuspend', deck.id)}><Eye /> Reactivate All Cards</button>}
                            </div>
                        </div>
                    </div> */}
                    {deck.access === 'owner' &&
                        <div className="menu__dropdown__item">
                            {<button style={{ textDecoration: 'none' }} onClick={() => menuOpenConfirmingMenu('delete', deck.id)}><Trash2 /> Delete Deck</button>}
                        </div>
                    }
                    {deck.access !== 'owner' && !isThiemeDeck(deck) &&
                        <div className="menu__dropdown__item">
                            {<button style={{ textDecoration: 'none' }} onClick={() => menuOpenConfirmingMenu('remove', deck.id)}><Trash2 /> Remove Deck</button>}
                        </div>
                    }
                    {/* {(user.admin || user.curator) &&
                        <div className="menu__dropdown__item">
                            {<button style={{ textDecoration: 'none' }} onClick={() => menuCloneDeck(deck.id)}><Copy /> Clone Deck</button>}
                        </div>
                    } */}
                    {/* {(user.admin || user.curator) &&
                        <div className="menu__dropdown__item">
                            {<button style={{ textDecoration: 'none' }} onClick={() => saveCollapseDefaults(deck.id)}><Layers /> Save Collapse Defaults</button>}
                        </div>
                    } */}
                    {/* {(user.admin || user.curator) &&
                        <div className="menu__dropdown__item">
                            {deck.paid ?
                                (<button style={{ textDecoration: 'none' }} onClick={() => setAsPaid(deck.id)}><Lock /> Set as Not Pro</button>)
                                : (<button style={{ textDecoration: 'none' }} onClick={() => setAsPaid(deck.id)}><Lock /> Set as Pro</button>)
                            }
                        </div>
                    } */}
                    {/* {(user.admin || user.curator) &&
                        <div className="menu__dropdown__item">
                            {<button style={{ textDecoration: 'none' }} onClick={menuShowSchoolAssigner}><Book /> Assign to School</button>}
                        </div>
                    } */}
                </div>
            </div>
            <div className='menu-overlay'></div>
        </>
    )
}

const mapStateToProps = (state, ownProps) => ({
    deck: _.defaultTo(state.decks[ownProps.deckId], {}),
});
const mapDispatchToProps = (dispatch) => ({
    receiveSelectedDeck: (deckId) => dispatch(receiveSelectedDeck(deckId)),
    saveCollapseDefaults: (deckId) => dispatch(saveCollapseDefaults(deckId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(DeckMenu);
