import {
    RECEIVE_USER,
	CLEAR_USER,
} from '../../actions/allActions';

const userReducer = (state = {}, action) => { 
	Object.freeze(state);

	switch (action.type) {
		case RECEIVE_USER:
			return action.payload.user;
		case CLEAR_USER:
			return {};
		default:
			return state;
	}
}

export default userReducer;