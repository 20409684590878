import SynaptiqLogo from '../../../assets/images/icons/header-icon.svg';

const getConfig = () => (
    {
        "dom": '<<t><"bottom"pl>>',
        columns,
        order: [[0, "desc"]],
        responsive: true,
        processing: true,
        serverSide: true,
        ajax: 'api/v1/datatables/user',
    }
)

const columns = [
    {
        title: 'Id',
        data: 'id'
    },
    {
        title: 'First Name',
        data: 'first_name',
    },
    {
        title: 'Last Name',
        data: 'last_name',
    },
    {
        title: 'Email',
        data: 'email'
    },
    {
        data: 'id',
        sortable: false,
        className: 'actions',
        width: '240px',
        render: (data, type, full) => (`<button class="button button--secondary button--small button--impersonate-user" aria-label="Impersonate User" data-user-id="${data}">Impersonate</button>`)
    },
];

export default getConfig;