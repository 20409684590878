import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import customStyles from '../../util/singleSelectStyles';
import { 
    fetchSchools,
    assignSchool,
 } from '../../actions/allActions';
import Modal from '../misc/Modal';
import { assign } from 'lodash';

function SchoolAssigner({ deck, hide, fetchSchools, assignSchool }) {
    const [schools, setSchools] = useState([]);
    const [selectedSchool, setSelectedSchool] = useState(null);

    useEffect(async () => {
        const fetchedSchools = await fetchSchools();
        const formattedSchools = fetchedSchools?.schools?.map(school => { return { 'value': school.id, 'label': school.name } })
        setSchools(formattedSchools);
        if (formattedSchools && formattedSchools.length > 0 && deck) {
            const defaultSchool = formattedSchools.find(el => el.value === deck.school_id);
            setSelectedSchool(defaultSchool);
        }
    }, []);

    const schoolChangeHandler = (selection) => {
        setSelectedSchool(selection);
    }

    const handleSubmit = () => {
        assignSchool(deck.id, selectedSchool?.value);
        hide();
    }

    return (
        <Modal
            name={'modal--clone-deck'}
            title='Assign to School'
            hide={hide}
            small={true}
            content={
                <>
                    <div className='row'>
                        <Select
                            placeholder={'Select a School'}
                            isSearchable
                            isClearable
                            styles={customStyles}
                            value={selectedSchool}
                            options={schools}
                            onChange={schoolChangeHandler}
                        />
                    </div>
                </>
            }
            actions={
                <>
                    <button className='button m-l-auto' onClick={handleSubmit}>Assign</button>
                </>
            }
        >
        </Modal>
    )
}

const mapStateToProps = (state) => ({
});
const mapDispatchToProps = (dispatch) => ({
    fetchSchools: () => dispatch(fetchSchools()),
    assignSchool: (deckId, schoolId) => dispatch(assignSchool(deckId, schoolId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SchoolAssigner);
