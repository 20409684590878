import React, { Component } from 'react';
import { MoreVertical } from 'react-feather';
import parse from 'html-react-parser';

export default class CreatedCardTile extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            menuIsOpen: false,
        }
        this.dropdownRef = React.createRef();
    }

    toggleCardMenu = () => {
        this.setState({ menuIsOpen: !this.state.menuIsOpen }, () => {
            document.addEventListener("click", this.closeMenu);
        });
    }

    closeMenu = () => {
        if(this.dropdownRef.current && this.dropdownRef.current.contains(event.target)) return;
        this.setState({ menuIsOpen: false }, () => {
            document.removeEventListener('click', this.closeMenu);
        });
    }

    answerNumToLetter = (number) => {
        if (number === null) return null;
        // A has a char code of 65, so adding the number to 65 gives us the char code for the corresponding letter.
        return String.fromCharCode(65 + number);
    }

    renderCardContent = (card) => {
        if (card.model === 'multichoice') {
            return (
                <>
                    <div className='created-card-content'>
                        {parse(card.question)}
                        <p className='correct-answer-label'>Correct Answer: {this.answerNumToLetter(card.multi_choice_answer)}</p>
                        {parse(card.extra)}
                    </div>
                </>
            )
        } else {
            return (
                <div className='created-card-content'>
                    {parse(card.question)}
                    <hr className="solid"></hr>
                    {parse(card.answer)}
                </div>
            )
        }
    }

    render() {
        const { card, initiateEditCard, deleteCard } = this.props;
        return (
            <div className='created-card'>
                {this.renderCardContent(card)}
                <div ref={this.dropdownRef} id="card-action-menu" className={`menu menu--card-actions ${this.state.menuIsOpen ? 'is-open' : ''}`}>
                    <button className="button button--icon-only" onClick={() => this.toggleCardMenu()}>
                        <MoreVertical/>
                    </button>
                    <div className="menu__dropdown">
                        <div className="menu__dropdown__item">
                            <button onClick={() => {
                                this.setState({ menuIsOpen: false });
                                initiateEditCard(card);
                            }}>Edit</button>
                        </div>
                        <div className="menu__dropdown__item">
                            <button onClick={() => {
                                this.setState({ menuIsOpen: false });
                                deleteCard(card.id);
                            }}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
