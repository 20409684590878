import {
    RECEIVE_PENDING_IMPORT,
	REMOVE_PENDING_IMPORT,
	CLEAR_PENDING_IMPORTS
} from '../../actions/allActions';

const pendingImportsReducer = (state = [], action) => { 
	Object.freeze(state);
	let nextState = _.cloneDeep(state);

	switch (action.type) {
		case RECEIVE_PENDING_IMPORT:
			if (!!action.payload.name && !!action.payload.jid) {
				const filename = action.payload.name.replace('.apkg', '');
				action.payload.name = filename;
				nextState.push(action.payload);
			}
			return nextState;
		case REMOVE_PENDING_IMPORT:
			const jid = action.payload;
			nextState = nextState.filter((deckImport) => deckImport.jid !== jid);
			return nextState; 
		case CLEAR_PENDING_IMPORTS:
			return []; 
		default:
			return state;
	}
}

export default pendingImportsReducer;
