import { connect } from 'react-redux'
import SessionSettings from './SessionSettings'
import { 
    fetchCards,
    clearTags,
    clearCards,
    receiveActiveStudyProfile,
    receiveSelectedDeck,
    receiveSelectedTags,
    receiveTaggingStrategy,
    resetDeck,
    deleteDeck,
    deleteDeckOwnership,
    suspendDeck,
    unSuspendDeck,
    fetchTags,
    receiveTags,
    fetchDeckProgress,
    fetchUserProperties,
    updateUserProperties,
    saveCollapseDefaults,
    setAsPaid,
    invalidateProgressCaches,
} from '../../actions/allActions'
import _ from 'lodash'

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.user,
        userProperties: state.userProperties,
        deckId: ownProps.deckId,
        deck: _.defaultTo(state.decks[ownProps.deckId], {}),
        cards: state.cards,
        tags: _.defaultTo(state.tags.list, []),
        selectedTags: state.tags.selected,
        taggingStrategy: state.tags.strategy,
        activeStudyProfile: _.defaultTo(state.activeStudyProfile, {}),
        progress: _.defaultTo(state.progress[ownProps.deckId], {}),
        dueCount: _.get(state.progress[ownProps.deckId], 'due', 0),
        newCount: _.get(state.progress[ownProps.deckId], 'new', 0),
        cardsLoading: _.defaultTo(state.loading.cardsLoading, false),
        progressLoading: _.defaultTo(state.loading.progressLoading, false),
        loading: _.defaultTo(state.loading.cardsLoading, false) || _.defaultTo(state.loading.progressLoading, false),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        fetchCards: (deckId, options) => dispatch(fetchCards(deckId, options)),
        receiveActiveStudyProfile: (activeStudyProfile) => dispatch(receiveActiveStudyProfile(activeStudyProfile)),
        receiveSelectedDeck: (deckId) => dispatch(receiveSelectedDeck(deckId)),
        receiveSelectedTags: (tag) => dispatch(receiveSelectedTags(tag)),
        receiveTaggingStrategy: (strategy) => dispatch(receiveTaggingStrategy(strategy)),
        clearSelectedDeck: () => dispatch(receiveSelectedDeck(null)),
        resetDeck: deckId => dispatch(resetDeck(deckId)),
        deleteDeck: deckId => dispatch(deleteDeck(deckId)),
        deleteDeckOwnership: deckId => dispatch(deleteDeckOwnership(deckId)),
        suspendDeck: deckId => dispatch(suspendDeck(deckId)),
        unSuspendDeck: deckId => dispatch(unSuspendDeck(deckId)),
        fetchTags: () => dispatch(fetchTags(ownProps.deckId)),
        receiveTags: (tags) => dispatch(receiveTags(tags)),
        fetchDeckProgress: (tags = null, taggingStrategy = null) => dispatch(fetchDeckProgress(ownProps.deckId, tags, taggingStrategy)),
        clearCards: () => dispatch(clearCards()),
        clearTags: () => dispatch(clearTags()),
        fetchUserProperties: () => dispatch(fetchUserProperties()),
        updateUserProperties: properties => dispatch(updateUserProperties(properties)),
        saveCollapseDefaults: deckId => dispatch(saveCollapseDefaults(deckId)),
        setAsPaid: deckId => dispatch(setAsPaid(deckId)),
        invalidateProgressCaches: (deckId) => invalidateProgressCaches(deckId),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionSettings)
