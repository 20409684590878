import { cacheCardImages } from '../../util/imgCaching';
import {
	RECEIVE_CARDS,
	SHIFT_CARD,
	RECEIVE_CARD_AND_META,
	UNDO_CARD_RESPONSE,
	CLEAR_CARDS,
	RECEIVE_TAG_CARD,
	RECEIVE_UNTAG_CARD,
	RECEIVE_UPDATED_CARD,
	RECEIVE_ACTIVE_STUDY_PROFILE
} from '../../actions/allActions';

const cardsReducer = (state = {}, action) => { 
	Object.freeze(state);
	let nextState = _.cloneDeep(state);
	let card, cards, type, cardId, tags;

	switch (action.type) {
		case RECEIVE_CARDS:
			for (const type in action.payload) {
				const existingCards = _.defaultTo(state[type], []);
				const newCards = action.payload[type];
				let combinedCards = existingCards.concat(newCards);
				combinedCards = uniqueCards(combinedCards);
				nextState[type] = combinedCards;
				if (type === 'due' && !state['due']) {
					orderAndPromoteNextDue(nextState['due']);
				}
				cacheCardImages(newCards);
			}
			return nextState;
		case SHIFT_CARD:
			type = action.payload.type;
			nextState[type].shift();
			if (type === 'due') {
				orderAndPromoteNextDue(nextState['due']);
			}
			return nextState;
		case RECEIVE_CARD_AND_META:
			card = action.payload.card;
			type = action.payload.type;
			cards = nextState[type];
			cards.push(card);
			return nextState;
		case UNDO_CARD_RESPONSE:
			type = action.payload.type;
			card = action.payload.card;
			cards = nextState[action.payload.type];

			cards.unshift(card);
			nextState[type] = uniqueCards(cards);

			return nextState;
		case CLEAR_CARDS:
			return {};
		// Adds tag to card obj in state so we can show Star / Unstar properly
		case RECEIVE_TAG_CARD:
			cardId = action.payload.cardId;
			tags = action.payload.tags;
			if (!Array.isArray(tags)) tags = [tags];

			card = nextState['new']?.find(card => card.id == cardId);
			if (!!card) card.tag_list = card.tag_list.concat(tags);

			card = nextState['due']?.find(card => card.id == cardId);
			if (!!card) card.tag_list = card.tag_list.concat(tags);

			return nextState;
		// Removes tag from card obj in state so we can show Star / Unstar properly
		case RECEIVE_UNTAG_CARD:
			cardId = action.payload.cardId;
			tags = action.payload.tags;
			if (!Array.isArray(tags)) tags = [tags];

			card = nextState['new']?.find(card => card.id == cardId);
			if (!!card) card.tag_list = card.tag_list.filter(tag => !tags.includes(tag));
			
			card = nextState['due']?.find(card => card.id == cardId);
			if (!!card) card.tag_list = card.tag_list.filter(tag => !tags.includes(tag));

			return nextState;
		case RECEIVE_UPDATED_CARD:
			const newCard = action.payload.card;
			if (!newCard) return state;

			let oldCard;
			oldCard = nextState['new']?.find(card => card.id == newCard.id);
			oldCard = !!oldCard ? oldCard : nextState['due']?.find(card => card.id == newCard.id);
			if (!!oldCard) {
				oldCard.question = newCard.question;
				oldCard.question_overlay = newCard.question_overlay;
				oldCard.answer = newCard.answer;
				oldCard.answer_overlay = newCard.answer_overlay;
				oldCard.extra = newCard.extra;
				oldCard.header = newCard.header;
				oldCard.css = newCard.css;
				oldCard.ord = newCard.ord;
				oldCard.axon_learn_more = newCard.axon_learn_more;
				oldCard.axon_learn_more_verified = newCard.axon_learn_more_verified;
				oldCard.axon_quiz_me = newCard.axon_quiz_me;
				oldCard.axon_quiz_me_verified = newCard.axon_quiz_me_verified;
				oldCard.axon_quiz_me_answer = newCard.axon_quiz_me_answer;
				oldCard.axon_quiz_me_answer_verified = newCard.axon_quiz_me_answer_verified;
			}

			return nextState;
		// Make sure there aren't more cards queued than are in the active session
		case RECEIVE_ACTIVE_STUDY_PROFILE:
			if (!_.isEmpty(action.payload)) {
				const newLen = action.payload['new'];
				const dueLen = action.payload['due'];
				nextState['new']?.splice(newLen);
				nextState['due']?.splice(dueLen);
			}
			return nextState;
		default:
			return state;
	}
}

const uniqueCards = (arr) => {
  let a = arr.concat();
  for(let i=0; i<a.length; ++i) {
      for(let j=i+1; j<a.length; ++j) {
          if(a[i].id === a[j].id)
              a.splice(j--, 1);
      }
  }
  return a;
}

// Orders the cards and moves the next due card to the front of the array
const orderAndPromoteNextDue = (dueCards) => {
	if (dueCards.length === 0) return;
	orderByNextDueAsc(dueCards);
	promoteNextDue(dueCards);
}

const promoteNextDue = (dueCards) => {
	if (dueCards.length === 0) return;
	
	const strictDueCard = dueCards.find(card => card.interval < 1440);
	const flexibleDueCard = dueCards.find(card => card.interval >= 1440);

	if (!!strictDueCard && isDueNow(strictDueCard)) {
		moveToFront(strictDueCard, dueCards);
	} else if (!!flexibleDueCard) {
		moveToFront(flexibleDueCard, dueCards);
	} else if (!!strictDueCard) {
		moveToFront(strictDueCard, dueCards);
	}
}

const orderByNextDueAsc = (cards) => {
	cards.sort((a, b) => {
		return (a.next_practice_due_at < b.next_practice_due_at) ? -1 : 1;
	});
}

const isDueNow = (card) => {
	const nextPracticeDueAt = card.next_practice_due_at;
	return moment().isSameOrAfter(moment(nextPracticeDueAt));
}

const moveToFront = (card, arr) => {
	const idx = arr.indexOf(card);
	arr.splice(idx, 1);
	arr.unshift(card);
}

const trimArray = (arr, len) => {
	arr.splice(len);
}

export default cardsReducer;
