import {
    RECEIVE_CREATED_CARDS,
    RECEIVE_UPDATED_CARD,
	CLEAN_UP_DELETED_CARD,
	CLEAR_CREATED_CARDS
} from '../../actions/allActions';

const createdCardsReducer = (state = [], action) => { 
	Object.freeze(state);
	let nextState = _.cloneDeep(state);

	switch (action.type) {
		case RECEIVE_CREATED_CARDS:
			nextState = [...action.payload.cards, ...nextState]
			return nextState;
		case RECEIVE_UPDATED_CARD:
			const card = action.payload.card;
			nextState = nextState.filter(createdCard => (createdCard.id !== card.id));
			nextState.unshift(card);
			return nextState;
		case CLEAN_UP_DELETED_CARD:
			const cardId = action.payload.card.id;
			nextState = nextState.filter(createdCard => (createdCard.id !== cardId));
			return nextState;
		case CLEAR_CREATED_CARDS:
			return [];
		default:
			return state;
	}
}

export default createdCardsReducer;
