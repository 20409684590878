import {
	RECEIVE_USER_STATS
} from '../../actions/allActions';

const userStatsReducer = (state = {}, action) => { 
	Object.freeze(state);

	switch (action.type) {
		case RECEIVE_USER_STATS:
			return action.payload.userStats;
		default:
			return state;
	}
}

export default userStatsReducer;