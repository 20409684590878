import React, { Component } from 'react';
import Modal from '../misc/Modal';

export default class CardDetailsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ratingCounts: {
                'minus2': 0,
                'minus1': 0,
                'plus1': 0,
                'plus2': 0
            },
            barHeights: {
                minus2: '.5rem',
                minus1: '.5rem',
                plus1: '.5rem',
                plus2: '.5rem'
            }
        }
    }

    componentDidMount() {
        this.calculateBarHeights();
    }

    countOccurrences = (history) => {
        // Define a map to store the counts
        let counts = {
            'minus2': 0,
            'minus1': 0,
            'plus1': 0,
            'plus2': 0
        };
    
        // Iterate over each array in the list
        history.forEach(arr => {
            if (counts[arr[0]] !== undefined) {
                counts[arr[0]] += 1;
            }
        });
    
        this.setState({ ratingCounts: counts});
        return counts;
    }

    renderLastResponse = () => {
        const mapLabels = {
            'minus2': "Again",
            'minus1': "Wrong",
            'plus1': "Right",
            'plus2': "Easy"
        }

        if (this.props.card.history.length > 0) {
            return (
                <div className='last'>
                    <div><span>Last Rating:</span> {mapLabels[(this.props.card.history[this.props.card.history.length - 1][0])]}</div>
                    <div className='type-style-italic'>{this.formatTimestamp(this.props.card.history[this.props.card.history.length - 1][1])}</div>
                </div>
            )
        }
    }

    formatTimestamp = (timestamp) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(timestamp).toLocaleDateString(undefined, options);
    }

    calculateBarHeights = () => {
        const responseCounts = this.countOccurrences(this.props.card.history);
        const { minus2, minus1, plus1, plus2 } = responseCounts;
        const maxCount = Math.max(minus2, minus1, plus1, plus2);
        this.setState({ barHeights: {
            minus2: (0.25 + (11.75 * minus2 / maxCount)) + "rem",
            minus1: (0.25 + (11.75 * minus1 / maxCount)) + "rem",
            plus1: (0.25 + (11.75 * plus1 / maxCount)) + "rem",
            plus2: (0.25 + (11.75 * plus2 / maxCount)) + "rem"
        }});
    }

    render() {
        return (
            <Modal
                name={'modal--card-details'}
                title={'Card Details'}
                hide={this.props.hide}
                cancelButtonText={"Close"}
                content={
                    <div className='charts-wrapper'>
                        <div className='rating-chart'>
                            <div className='top-wrapper'>
                                <h3>Responses<span className='badge badge--default'>{this.props.card.history.length}</span></h3>
                                {this.renderLastResponse()}
                            </div>
                            <div className='chart'>
                                <div className='bars'>
                                    <div className='bar bar--again' style={{ height: this.state.barHeights.minus2 }}>
                                        <div className='count'>{this.state.ratingCounts.minus2}</div>
                                        <div className='label'>Again</div>
                                    </div>
                                    <div className='bar bar--wrong' style={{ height: this.state.barHeights.minus1 }}>
                                        <div className='count'>{this.state.ratingCounts.minus1}</div>
                                        <div className='label'>Wrong</div>
                                    </div>
                                    <div className='bar bar--right' style={{ height: this.state.barHeights.plus1 }}>
                                        <div className='count'>{this.state.ratingCounts.plus1}</div>
                                        <div className='label'>Right</div>
                                    </div>
                                    <div className='bar bar--easy' style={{ height: this.state.barHeights.plus2 }}>
                                        <div className='count'>{this.state.ratingCounts.plus2}</div>
                                        <div className='label'>Easy</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='level-chart'>
                            <div className='level'>
                                <h3>Card Level</h3>
                                <div className='number'>{this.props.card.level}</div>
                            </div>
                            <div className='chart'>
                                <div className='bars'>
                                    <div className={`bar bar--1 ${this.props.card.level === 1 ? 'active' : '' }`}>
                                        <div className='indicator'></div>
                                        <div className='label'>1</div>
                                    </div>
                                    <div className={`bar bar--2 ${this.props.card.level === 2 ? 'active' : '' }`}>
                                        <div className='indicator'></div>
                                        <div className='label'>2</div>
                                    </div>
                                    <div className={`bar bar--3 ${this.props.card.level === 3 ? 'active' : '' }`}>
                                        <div className='indicator'></div>
                                        <div className='label'>3</div>
                                    </div>
                                    <div className={`bar bar--4 ${this.props.card.level === 4 ? 'active' : '' }`}>
                                        <div className='indicator'></div>
                                        <div className='label'>4</div>
                                    </div>
                                    <div className={`bar bar--5 ${this.props.card.level === 5 ? 'active' : '' }`}>
                                        <div className='indicator'></div>
                                        <div className='label'>5</div>
                                    </div>
                                    <div className={`bar bar--6 ${this.props.card.level === 6 ? 'active' : '' }`}>
                                        <div className='indicator'></div>
                                        <div className='label'>6</div>
                                    </div>
                                    <div className={`bar bar--7 ${this.props.card.level === 7 ? 'active' : '' }`}>
                                        <div className='indicator'></div>
                                        <div className='label'>7</div>
                                    </div>
                                    <div className={`bar bar--8 ${this.props.card.level >= 8 ? 'active' : '' }`}>
                                        <div className='indicator'></div>
                                        <div className='label'>8+</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            />
        )
    }
}
