import { combineReducers } from 'redux';
import cards from './entities/cardsReducer';
import pastCards from './entities/pastCardsReducer';
import userCards from './entities/userCardsReducer';
import createdCards from './entities/createdCardsReducer';
import decks from './entities/decksReducer';
import decksTree from './entities/decksTreeReducer';
import progress from './entities/progressReducer';
import activeStudyProfile from './entities/activeStudyProfileReducer';
import activeStudySession from './entities/activeStudySessionReducer';
import studyProfiles from './entities/studyProfilesReducer';
import pastStudySessions from './entities/pastStudySessionsReducer';
import userStudySettings from './entities/userStudySettingsReducer';
import user from './entities/userReducer';
import userProperties from './entities/userPropertiesReducer';
import subscriptions from './entities/subscriptionsReducer';
import checkoutSessions from './entities/checkoutSessionsReducer';
import sharedUsers from './entities/sharedUsersReducer';
import userStats from './entities/userStatsReducer';
import heatmap from './entities/heatmapReducer';
import selectedDeck from './entities/selectedDeckReducer';
import pendingImports from './entities/pendingImportsReducer';
import lastCloneTarget from './entities/lastCloneTargetReducer';
import tags from './entities/tagsReducer';
import theme from './entities/themeReducer';
import menuState from './entities/menuStateReducer';
import loading from './entities/loadingReducer';

const appReducer = combineReducers({
    cards,
    pastCards,
    userCards,
    createdCards,
    decks,
    decksTree,
    progress,
    activeStudyProfile,
    activeStudySession,
    studyProfiles,
    userStudySettings,
    user,
    subscriptions,
    userProperties,
    checkoutSessions,
    sharedUsers,
    userStats,
    heatmap,
    selectedDeck,
    pendingImports,
    lastCloneTarget,
    pastStudySessions,
    tags,
    theme,
    menuState,
    loading,
});

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        return appReducer(undefined, action)
    }
    return appReducer(state, action)
}

export default rootReducer;
