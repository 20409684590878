import {
	RECEIVE_HEATMAP
} from '../../actions/allActions';

const heatmapReducer = (state = [], action) => { 
	Object.freeze(state);

	switch (action.type) {
		case RECEIVE_HEATMAP:
			return action.payload.heatmap;
		default:
			return state;
	}
}

export default heatmapReducer;