import { SET_MENU_STATE } from '../../actions/allActions';

const menuStateReducer = (state = true, action) => { 
	Object.freeze(state);
	switch (action.type) {
		case SET_MENU_STATE:
			return action.payload;
		default:
			return state;
	}
}

export default menuStateReducer;
