import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MasteryBar from './MasteryBar';
import PlayIcon from '../../../assets/images/icons/play.svg'; 
import ReactTooltip from 'react-tooltip';
import { ChevronRight, Lock, Plus, XCircle, Unlock, MoreVertical, Zap, CornerRightDown } from 'react-feather';
import NoDeckGraphic from '../../../assets/images/no-decks-graphic.svg';
import RedeemCodeGraphic from '../../../assets/images/redeem-code.svg';
import ClipLoader from "react-spinners/ClipLoader";
import { isThiemeDeck } from '../../util/cohortUtil';
import _ from 'lodash';
import { DragIcon } from '../../util/dragIcon';
import isAppleDevice from '../../util/isAppleDevice';

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deckId: null,
            showModal: false,
            billingPortal: {},
            isApple: isAppleDevice()
        }
    }

    async componentDidMount() {
        this.setState({ deckId: null, showModal: false });

        const billingPortal = await this.props.fetchBillingPortal();
        this.setState({ billingPortal });
    }

    setSelectedDeck = (id) => {
        this.props.receiveSelectedDeck(id);
    }

    toggleSubsection(e, deck) {
        // Don't toggle again if we're still loading the previous toggle
        if (this.props.loading.toggleLoading) return;

        let targetSub, isExpanding;
        if (e.target.nodeName === "svg") {
            targetSub = e.target.parentElement.getAttribute('data-target');
            isExpanding = e.target.classList.toggle('subsection-open');
        } else if (e.target.nodeName === "polyline") {
            let parentSvg = e.target.parentElement;
            targetSub = parentSvg.parentElement.getAttribute('data-target');
            isExpanding = parentSvg.classList.toggle('subsection-open');
        } else {
            targetSub = e.target.getAttribute('data-target');
            isExpanding = e.target.children[0].classList.toggle('subsection-open');
        }
        // We should only load if we're expanding and we haven't already loaded the children
        const shouldLoad = isExpanding && deck.children.length === 0;
        this.props.toggleDeckCollapsed(deck.id, shouldLoad, isExpanding);
        // document.getElementById(targetSub)?.classList.toggle('subsection-show');
        document.getElementById(`subsection-${deck.id}`)?.classList.toggle('subsection-show');
    }

    mobileToggleSubsection(e, deck) {
        const isExpanding = e.target?.classList.toggle('subsection-open');
        const shouldLoad = isExpanding && deck.children.length === 0;
        this.props.toggleDeckCollapsed(deck.id, shouldLoad, isExpanding);
    }

    handleLockedDeckClick = (deck) => {
        if (!Capacitor.isNativePlatform()) {
            if (deck.stripe_product_id) {
                this.props.history.push(`/checkout/${deck.stripe_product_id}`);
            } else {
                this.props.history.push('/checkout');
            }
        }
    }

    handleNewLockedDeckClick = (deck) => {
        if (!Capacitor.isNativePlatform()) {
            this.props.history.push(`/checkout/${deck.stripe_product_id}`);
        }
    }

    renderQuickStartTooltip = (deckID) => {
        if (this.props.decksTree[0]?.id === deckID) {
            return this.state.isApple ? 'Quick Start Last Session - &#8984; + 1' : 'Quick Start Last Session - CMD + 1';
        } else if (this.props.decksTree[1]?.id === deckID) {
            return this.state.isApple ? 'Quick Start Last Session - &#8984; + 2' : 'Quick Start Last Session - CMD + 2';
        } else if (this.props.decksTree[2]?.id === deckID) {
            return this.state.isApple ? 'Quick Start Last Session - &#8984; + 3' : 'Quick Start Last Session - CMD + 3';
        } else {
            return 'Quick Start Last Session';
        }
    }

    handleLockedThiemeDeckClick = (deckID) => {
        if (!Capacitor.isNativePlatform()) {
            this.props.showThiemeUnlocker(deckID);
        }
    }

    renderDeck(deck) {
        const children = deck.children.map(child => this.renderDeck(child));
        let toggleButton, mobileToggleButton;
        const clipLoader = (
            <ClipLoader color={'var(--theme-primary)'} loading={true} speedMultiplier={.75} cssOverride={{
                "width": "100%",
                "height": "100%"
            }} />
        );
        const clipLoaderMobile = (
            <ClipLoader color={'var(--theme-primary)'} loading={true} speedMultiplier={.75} cssOverride={{
                "width": ".75rem",
                "height": ".75rem",
                "marginLeft": ".25rem" 
            }} />
        );

        if (deck.has_children) {
            toggleButton = (
                <button 
                    className={`component--deck-index__top__left__toggle ${this.props.loading.toggleLoading === deck.id && children.length == 0 ? 'is-loading' : ''}`} 
                    onClick={(e) => this.toggleSubsection(e, deck)}
                >
                    {/* If this is a deck that's loading (i.e. expanding) and we've not already loaded the children */}
                    {this.props.loading.toggleLoading === deck.id && children.length == 0  ?
                        clipLoader : <ChevronRight className={deck.collapsed ? '' : 'subsection-open'}/>
                    }
                </button>
            )
            mobileToggleButton = (
                <button 
                    className={`component--deck-index__top_mobile-toggle ${deck.collapsed ? '' : 'subsection-open'}`} 
                    onClick={(e) => this.mobileToggleSubsection(e, deck)}
                >
                    {deck.collapsed || ( this.props.loading.toggleLoading === deck.id && children.length == 0) ? 
                        'View Sub Decks' : 'Collapse Sub Decks'}
                    {this.props.loading.toggleLoading === deck.id && children.length == 0 ? 
                        clipLoaderMobile : <ChevronRight/>}
                </button>
            )
        }

        const unlockedDeck = (
            <>
                <Link to="/#" onClick={() => this.props.handleClickDeck(deck.id)}>
                    <div className="button button--play">
                        <img src={PlayIcon} alt="Play Icon" />
                    </div>
                    <div className="component--deck-index__name">
                        {deck.name}
                    </div>
                </Link>
            </>
        );
        
        const lockedDeck = (deck.supports_code_unlock) ? (
            <div className='locked-deck' onClick={() => this.handleLockedThiemeDeckClick(deck.id)}>
                <div className="button button--play locked">
                    <Lock stroke='#fff' />
                </div>
                <div className="component--deck-index__name">
                    {deck.name}
                </div>
                {deck.root && <div className='button button--small' style={{height: 'fit-content', marginLeft: '.5rem'}}>Unlock</div>}
            </div>
        ) : (
            <div className="locked-deck" onClick={() => this.handleLockedDeckClick(deck)}>
                <div className="button button--play locked">
                    <img src={PlayIcon} alt="Play Icon" />
                </div>
                <div className="component--deck-index__name">
                    {deck.name}
                </div>
                {deck.root && <div className='button button--small' style={{height: 'fit-content', marginLeft: '.5rem'}}>Unlock</div>}
            </div>
        );

        let showLocked = (
            deck.stripe_product_id &&
            !this.props.subscriptions[deck.stripe_product_id] &&
            !this.props.userProperties[deck.stripe_product_id] &&
            !this.props.user.admin &&
            !this.props.user.curator
        );

        let thiemePurchaseExpired = false;
        if (deck.stripe_product_id &&
            !this.props.subscriptions[deck.stripe_product_id] &&
            this.props.userProperties[deck.stripe_product_id] &&
            !this.props.user.admin &&
            !this.props.user.curator) {
            const purchaseDate = this.props.userProperties[deck.stripe_product_id];
            const date = moment(purchaseDate);
            const oneYearAgo = moment().subtract(1, 'years');
            thiemePurchaseExpired = date.isBefore(oneYearAgo);
        }

        const deckMastery = _.get(this.props.progress, `${deck.id}.percentMastery`, 0);
        const masteredCards = _.get(this.props.progress, `${deck.id}.mastered`, 0);
        const learningCards = _.get(this.props.progress, `${deck.id}.learning`, 0);
        const newCards = _.get(this.props.progress, `${deck.id}.new`, 0);
        const totalCards = _.get(this.props.progress, `${deck.id}.total`, 0);
        return (
            <div key={deck.id} className="component component--deck-index">
                <div className="component--deck-index__top">
                    <div className="component--deck-index__top__left">
                        {(showLocked || thiemePurchaseExpired) ? lockedDeck : unlockedDeck}
                        {/* {this.props.user.subscription_status !== 'active' && deck.root && deck.paid && <Link to={'/checkout'} className='badge badge--pro m-x-0-50'>PRO</Link>} */}
                        {toggleButton}
                        {(!showLocked && !thiemePurchaseExpired) && (
                            <div className="component--deck-index__top__left__actions">
                                <button className="button button--icon-only button--shortcut" onClick={(e) => this.props.openDeckMenu(deck.id, e)} onKeyDown={(e) => this.props.openDeckMenuKeypress(deck.id, e)} aria-label="Deck Menu" data-tip={`Deck Menu`} data-html={true} data-class="keybind-tooltip" data-delay-show="150"><MoreVertical/></button>
                                <button className="button button--icon-only button--shortcut" aria-label="Quick Start" onClick={() => this.props.quickStart(deck.id)} data-tip={this.renderQuickStartTooltip(deck.id)} data-html={true} data-class="keybind-tooltip" data-delay-show="150"><Zap/></button>
                                {((this.props.user.curator || this.props.user.admin) && deck.access === 'owner') && <button className="button button--icon-only button--shortcut" onClick={() => this.props.createSubDeck(deck.id)} aria-label="Create Subdeck" data-tip={`Create Subdeck`} data-html={true} data-class="keybind-tooltip" data-delay-show="150"><CornerRightDown/></button>}
                            </div>
                        )}
                    </div>
                    <div className="component--deck-index__top__right">
                        <div className='badge-counters'>
                            {!deck.locked && (
                                <div className='badge-count'>
                                    {this.props.loadingProgress ? <span className='loader-rect-2'>0</span> : <span className='badge-count_value'>{this.props.progress[deck.id]?.due}</span>}
                                    <span className='badge-count_label'>Due</span>
                                </div>
                            )}
                            <div className='badge-count badge-count--new'>
                                {this.props.loadingProgress ? <span className='loader-rect-2'>0</span> : <span className='badge-count_value'>{this.props.progress[deck.id]?.new}</span>}
                                <span className='badge-count_label'>New</span>
                            </div>
                        </div>
                        <MasteryBar
                            percentMastery={deckMastery}
                            masteredCardCount={masteredCards}
                            learningCardCount={learningCards}
                            newCardCount={newCards}
                            totalCards={totalCards}
                        />
                    </div>
                    {mobileToggleButton}
                </div>
                { 
                    children.length != 0 &&
                    <div id={`subsection-${deck.id}`} className={`component--deck-index__sub ${deck.collapsed ? '' : 'subsection-show'}`}>
                        {children}
                    </div>
                }
                <div className='drag-handle-container'>
                    <button onClick={(e) => this.props.openDeckOrderer(e)} onMouseDown={this.props.openDeckOrderer}><DragIcon/></button>
                </div>
            </div>
        )
    }

    renderPendingDeck(deckImport) {
        return (
            <div key={Math.floor(Math.random() * 1000)} className="component component--deck-index component--deck-index--disabled">
                <div className="component--deck-index__top">
                    <div className="component--deck-index__top__left">
                        <div className='spinner-wrapper'>
                            <ClipLoader color={'var(--theme-primary)'} loading={true} speedMultiplier={.75} />
                        </div>
                        <div className="component--deck-index__name">
                            {deckImport.name}
                        </div>
                        <div className="component--deck-index__top__left__actions">
                            <button className="button button--icon-only" onClick={() => this.props.removePendingImport(deckImport.jid)} data-tip="Remove Pending Deck"><XCircle/></button>
                        </div>
                    </div>
                    <div className="component--deck-index__top__right"></div>
                </div>
            </div>
        )
    }

    renderDecks() {
        // Renders No Decks graphic
        if (this.props.decksTree.length === 0) return (
            <div className='no-deck'>
                {!Capacitor.isNativePlatform() && <div className='home-block redeem-code' onClick={() => this.props.showGenericUnlocker()}>
                    <img src={RedeemCodeGraphic} alt=""/>
                    <div>
                        <p>Have an access code?</p>
                        <button className="button button--small--wide">
                            <Unlock/>Unlock Deck
                        </button>
                    </div>
                </div>}
                <div className='home-block no-deck-display' onClick={() => this.props.handleClickCreateDeck()}>
                    <img src={NoDeckGraphic} alt=""/>
                    <div>
                        <p>Create a new deck to get started.</p>
                        <button className="button button--small--wide">
                            <Plus/>Create Deck
                        </button>
                    </div>
                </div>
            </div>
        );
        const deckList = this.props.decksTree.map(deck => this.renderDeck(deck));
        return deckList;
    }

    renderPendingDecks() {
        if (_.isEmpty(this.props.pendingImports)) return;
        const importList = this.props.pendingImports.map(pendingImport => this.renderPendingDeck(pendingImport));
        return importList;
    }

    render() {
        const decks = this.props.decksTree.map(deck => this.renderDeck(deck));
        return (
            <React.Fragment>
                {this.renderPendingDecks()}
                <div className={`${this.props.decksTree.length === 1 ? 'single-deck' : ''}`}>
                    {this.renderDecks()}
                </div>
                <ReactTooltip />
            </React.Fragment>
        )
    }
}
