import { connect } from 'react-redux'
import AppBar from './AppBar'
import { withRouter } from 'react-router-dom';

const mapStateToProps = state => {
    return {
        user: state.user,
        subscriptions: state.subscriptions,
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default withRouter(connect(mapStateToProps, null)(AppBar))
