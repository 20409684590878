import React from 'react'
import ReactDOM from 'react-dom'
import Root from './components/root';
import configureStore from './store/store';
import _ from 'lodash';

import * as ApiUtil from './util/apiUtil';
import * as AllActions from './actions/allActions';

document.addEventListener('DOMContentLoaded', () => {
  const config = configureStore()
  if (process.env.NODE_ENV === 'development') {
    window.store = config.store
    window.api = ApiUtil
    window.actions = AllActions
  }

  try {
    const root = document.getElementById("root")
    if (root) {
      ReactDOM.render(<Root store={config.store} persistor={config.persistor} />, root);
    }
  } catch(err) {
    console.log("Don't worry, be happy.")
  }
})
