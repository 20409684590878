import React, { Component } from 'react';
import SingleDeckSelector from '../builder/SingleDeckSelector';
import Modal from '../misc/Modal';

export default class DeckEditor extends Component {
    constructor(props) {
        super(props);

        const parentOptions = Object.values(this.props.decks)
            .filter((deck) => {
                return (deck.id !== this.props.deckId) && (deck.access === 'owner')
            }).map(deck => ({ value: deck.id, label: deck.name }));
        const deckName = !!this.props.deckId ? this.props.deck.name : '';
        const deckDescription = !!this.props.deckId ? this.props.deck.description : '';
        let defaultParent;
        if (this.props.parentDeckId) {
            defaultParent = parentOptions.find(option => option.value == this.props.parentDeckId);
        } else {
            defaultParent = parentOptions.find(option => option.value == this.props.deck.parent_id);
        }
        
        this.state = {
            deckName,
            deckDescription,
            defaultParent,
            parentOptions,
            parentDeckId: null,
            initializedDebounce: false, 
            debounced: null,
            isFocusTrapPaused: false
        };
    }

    componentDidMount() {
        this.initializedDebounce();
        document.getElementById('create-deck-input').focus();
        if (this.state.defaultParent) this.setSelectedDecks(this.state.defaultParent.value);
    }

    componentWillUnmount() {
        this.removeKeyBinds();
    }

    // Set Home Page Keybinds
    initializedDebounce = () => {
        if (!this.state.initializedDebounce) {
            const debounced = _.debounce(this.keyBinds, 50, { 'leading': true, 'trailing': false })
            window.addEventListener('keydown', debounced)
            this.setState({ initializedDebounce: true, debounced })
        }
    }
    removeKeyBinds = () => {
        if (this.state.initializedDebounce) {
            window.removeEventListener('keydown', this.state.debounced);
        }
    }
    keyBinds = (e) => {
        // Escape Keybinds
        if (e.key === "Enter" || e.which === 13 || e.code === "Enter") {
            if (!!this.props.deckId && document.activeElement === document.getElementById('create-deck-input')) {
                this.handleSubmit(e);
            } else {
                if (this.state.deckName && document.activeElement === document.getElementById('create-deck-input')) {
                    this.createDeckAddCards(e);
                }
            }
        }
    }

    handleChange(e) {
        this.setState({ deckName: e.target.value });
    }

    handleDescriptionChange(e) {
        this.setState({ deckDescription: e.target.value });
    }

    setSelectedDecks = (selectedDeckId) => {
        this.setState({ parentDeckId: selectedDeckId });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        !!this.props.deckId ? 
            this.props.updateDeck({ id: this.props.deckId, name: this.state.deckName, description: this.state.deckDescription, parent_id: this.state.parentDeckId }) : 
            this.props.createDeck({ name: this.state.deckName, description: this.state.deckDescription, parent_id: this.state.parentDeckId });
        this.props.hide();
    }

    createDeckAddCards = (e) => {
        e.preventDefault();
        this.props.hide();
        this.props.createDeck({ name: this.state.deckName, description: this.state.deckDescription, parent_id: this.state.parentDeckId }).then((payload) => {
            this.props.receiveSelectedDeck(payload.deck.id);
            this.props.history.push('/builder');
        });
    }

    renderActions = () => {
        if (!!this.props.deckId) {
            return <button className='button m-l-auto' onClick={(e) => this.handleSubmit(e)}>Update Deck</button>;
        } else {
            return (
                <div>
                    <button className='button button--secondary' onClick={(e) => this.handleSubmit(e)} disabled={!this.state.deckName}>Create Deck</button>
                    <button className='button' onClick={(e) => this.createDeckAddCards(e)} disabled={!this.state.deckName}>Create Deck &amp; Add Cards</button>
                </div>
            )
        }
    }

    suspendFocusTrap = () => {
        this.setState({isFocusTrapPaused: true});
    }
    enableFocusTrap = () => {
        this.setState({isFocusTrapPaused: false});
    }

    render() {
        const titleText = !!this.props.deckId ? 'Edit Deck' : 'New Deck';
        return (
            <Modal
                name={'modal--create-deck'}
                title={titleText}
                hide={this.props.hide}
                isFocusTrapPaused={this.state.isFocusTrapPaused}
                content={
                    <form>
                        <div className='input'>
                            <label className='create-deck-label m-t-0'>Deck Name</label>
                            <input id="create-deck-input" className='create-deck-input' type="text" value={this.state.deckName} onChange={(e) => this.handleChange(e)} />
                        </div>
                        {this.props.user.admin && <div className='input m-b-1'>
                            <label>Description</label>
                            <textarea onChange={(e) => this.handleDescriptionChange(e)} placeholder='Enter description...' value={this.state.deckDescription} />
                        </div>}
                        <SingleDeckSelector
                            type={'deck'}
                            options={this.state.parentOptions}
                            defaultParent={this.state.defaultParent}
                            setSelectedDecks={this.setSelectedDecks}
                            label={'Parent Deck'}
                            enableFocusTrap={this.enableFocusTrap}
                            suspendFocusTrap={this.suspendFocusTrap}
                        />
                    </form>
                }
                actions={this.renderActions()}
            />
        )
    }
}
