import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { Send, Info, Trash, Clipboard } from 'react-feather';
import Select from 'react-select';
import customStyles from '../../util/singleSelectStyles';
import { isThiemeDeck } from '../../util/cohortUtil';
import { 
    fetchSharedUsers,
    addSharedUser,
    updateSharedUsers
} from '../../actions/allActions';
import isValidEmail from '../../util/isValidEmail';
import FocusTrap from 'focus-trap-react';

function DeckSharer({ user, deck, hide, fetchSharedUsers, addSharedUser, updateSharedUsers, sharedUsers }) {
    const [isFocusTrapPaused, setIsFocusTrapPaused] = useState(false);
    const [emailInput, setEmailInput] = useState('');
    const [permissionChanges, setPermissionChanges] = useState([]);

    useEffect(() => {
        fetchSharedUsers(deck.id);
        document.body.classList.add("overflow-hidden");
    }, []);

    const overlayClick = (e) => {
        if ((e.target === e.currentTarget) && !permissionChanges.length) hide();
    }

    const handleSubmitShare = () => {
        // TODO: handle already added email
        const userObj = { email: emailInput, access: 'viewer' }
        addSharedUser(deck.id, userObj);
        ahoy.track("Click Submit Share", { recipient: emailInput });
        setEmailInput('');

        toast.success("Your deck invite has been sent. Viewers will appear in this list when they accept the invite.", {
            position: "bottom-left",
            autoClose: 6000,
            delayShow: 1000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light"
        });
    }

    const handlePermissionChange = (userObj, newPermissionObj) => {
        userObj.access = newPermissionObj.value;
        let filteredPermissions = permissionChanges.filter(permissionObj => permissionObj.email !== userObj.email);
        setPermissionChanges([...filteredPermissions, userObj]);
    }
    
    const handleSaveChanges = () => {
        updateSharedUsers(deck.id, permissionChanges);
        setPermissionChanges([]);
        ahoy.track("Click Update Permissions");

        toast.success("Your changes have been saved.", {
            position: "bottom-left",
            autoClose: 4000,
            delayShow: 1000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light"
        });
    }

    const suspendFocusTrap = () => {
        setIsFocusTrapPaused(true);
    }
    
    const enableFocusTrap = () => {
        setIsFocusTrapPaused(false);
    }
        
    const renderPermissions = (user) => {
        if (user.access === 'owner') return <div>Owner</div>;
        const selectValues = [
            { label: 'Viewer', value: 'viewer' },
            { label: 'Editor', value: 'editor' }
        ];
        const removeUser = { value: 'remove' };
        let toBeRemoved = permissionChanges.find(userObj => userObj.name == user.name)?.access;
        return (
            <>
                {toBeRemoved === "remove" && <div className='remove-pending'>Removed Pending Save</div>}
                <button className='button button--icon-only' aria-label="Remove user from shared deck." onClick={() => handlePermissionChange(user, removeUser)} data-tip={`Remove user from shared deck. Note: This will also remove their study progress.`} data-html={true} data-class="keybind-tooltip" data-delay-show="150"><Trash/></button>
                <Select
                    defaultValue={selectValues.find(el => el.value.toLowerCase() === user.access.toLowerCase())}
                    options={selectValues}
                    onChange={(newPermissionObj) => handlePermissionChange(user, newPermissionObj)}
                    onMenuOpen={suspendFocusTrap}
                    onMenuClose={enableFocusTrap}
                    styles={customStyles}
                    menuPortalTarget={document.body}
                    menuPlacement="auto"
                    menuPosition="fixed"
                />
            </>
        );
    }

    const copyLinktoClipboard = () => {
        if (deck.uuid?.includes('gberg')) {
            navigator.clipboard.writeText(`https://www.synaptiq.app/thieme/trial`);
        } else if (deck.uuid?.includes('plastics')) {
            navigator.clipboard.writeText(`https://www.synaptiq.app/thieme/plastics/trial`);
        } else if (deck.uuid === '0868b90e4a') {
            navigator.clipboard.writeText(`https://www.synaptiq.app/thieme/top_score`);
        } else if (deck.uuid === '000463d31f') {
            navigator.clipboard.writeText(`https://www.synaptiq.app/thieme/ultrasound`);
        } else if (deck.uuid === 'b4ef1abf6d') {
            navigator.clipboard.writeText(`https://www.synaptiq.app/thieme/neuroradiology`);
        } else if (deck.uuid === 'e90b8840b5') {
            navigator.clipboard.writeText(`https://www.synaptiq.app/thieme/gastrointestinal`);
        } else {
            navigator.clipboard.writeText(`https://www.synaptiq.app/users/sign_up?uuids[]=${deck.uuid}`);
        }
        toast.success("Link copied to clipboard.", {
            position: "bottom-left",
            autoClose: 2000,
            delayShow: 0,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light"
        });
    }

    const ownerPermissionsTip = `
        <div class="permission-roles">
            <p><strong>Owner</strong> - The Owner can manage user roles, decks, and cards. Owners can see names and emails of deck Editors and Viewers.</p>
            <p><strong>Editor</strong> - Editors can add, edit, and delete cards, but not decks. Only make people you trust Editors of your decks.</p>
            <p><strong>Viewer</strong> - Viewers can only study the deck.</p>
        </div>
    `;
    const nonOwnerPermissionsTip = `
        <div class="permission-roles">
            <p><strong>Owner</strong> - Owners can manage user roles, decks, and cards. Owners can see names and emails of deck Editors and Viewers.</p>
            <p><strong>Editor</strong> - Editors can add, edit, and delete cards, but not decks.</p>
            <p><strong>Viewer</strong> - Viewers can only study the deck.</p>
        </div>
    `;

    const countRole = (role) => {
        return sharedUsers.filter(user => user['access'] === role).length;
    }

    const isActiveRole = (role) => {
        return deck.access === role;
    }

    return (
        <FocusTrap
            paused={isFocusTrapPaused}
        >
            <div className="modal modal--share-deck is-open" onMouseDown={overlayClick}>
                <div className="modal_content">
                    <div className="modal_content_main">
                        <div className="modal_content_main_top">
                            <h2 className="modal_content_main_top_title">Share <span className='highlight'>{deck?.name}</span></h2>
                        </div>
                        <div className="modal_content_main_body">
                            <form>
                                <div className='input'>
                                    <label className='sr-only' htmlFor="share-email">Email</label>
                                    <div className='wrapper'>
                                        <input 
                                            type="email"
                                            id="share-email"
                                            placeholder='Add people by email'
                                            className={`input--text ${emailInput === '' || isValidEmail(emailInput) ? '' : 'val-error'}`}
                                            value={emailInput}
                                            onChange={(e) => setEmailInput(e.target.value)}
                                        />
                                        <button 
                                            className='button' 
                                            type="submit" 
                                            onClick={handleSubmitShare} 
                                            disabled={!isValidEmail(emailInput)}
                                        >
                                            <Send/><span>Share</span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                            {deck.access === 'owner' &&
                                <div className='user-access-list'>
                                    <div className='user-access-list_header'>
                                        <h3>Users with access</h3>
                                        <p>Role<Info data-tip={ownerPermissionsTip} data-html={true}/></p>
                                    </div>
                                    <div className='user-access-list_rows'>
                                        {sharedUsers.map((userObj, key) => {
                                            const name = (userObj.email === user.email) ? `${userObj.name} (You)` : userObj.name;
                                            return (
                                                <div className='user-access-list_rows_row' key={key}>
                                                    <div className='user-access-list_rows_row_left'>
                                                        <div className='user-access-list_rows_row_left_name'>{name}</div>
                                                        <div>{userObj.email}</div>
                                                    </div>
                                                    <div className='user-access-list_rows_row_right'>
                                                        {renderPermissions(userObj)}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className='overflow-fade'></div>
                                </div>
                            }
                            {deck.access !== 'owner' && user.group !== 'thieme' &&
                                <div className='user-access-list_counts'>
                                    <h3>Users with access <Info data-tip={nonOwnerPermissionsTip} data-html={true} /></h3>
                                    <div className={`badge badge--default ${isActiveRole('owner') ? 'active-role' : ''}`} data-tip={isActiveRole('owner') ? 'Your Role' : ''}><span>{countRole('owner')}</span> {countRole('owner') === 1 ? 'Owner' : 'Owners'}</div>
                                    <div className={`badge badge--default ${isActiveRole('editor') ? 'active-role' : ''}`} data-tip={isActiveRole('editor') ? 'Your Role' : ''}><span>{countRole('editor')}</span> {countRole('editor') === 1 ? 'Editor' : 'Editors'}</div>
                                    <div className={`badge badge--default ${isActiveRole('viewer') ? 'active-role' : ''}`} data-tip={isActiveRole('viewer') ? 'Your Role' : ''}><span>{countRole('viewer')}</span> {countRole('viewer') === 1 ? 'Viewer' : 'Viewers'}</div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className='modal_content_actions'>
                        {permissionChanges.length ?
                            <>
                                <button className="button button--secondary" onClick={hide}>Cancel</button>
                                <button className={`button ${permissionChanges.length ? 'button--success' : ''}`} onClick={handleSaveChanges}>Save</button>
                            </>
                            :
                            <>
                                <button className="button button--copy" onClick={copyLinktoClipboard}><Clipboard/>Copy<span>&nbsp;Share Link</span></button>
                                <button className="button button--secondary" onClick={hide}>Done</button>
                            </>
                        }
                    </div>
                </div>
                <ReactTooltip />
            </div>
        </FocusTrap>
    )
}

const mapStateToProps = (state) => ({
    user: state.user,
    sharedUsers: state.sharedUsers
});
const mapDispatchToProps = (dispatch) => ({
    fetchSharedUsers: (deckId) => dispatch(fetchSharedUsers(deckId)),
    addSharedUser: (deckId, userObj) => dispatch(addSharedUser(deckId, userObj)),
    updateSharedUsers: (deckId, updates) => dispatch(updateSharedUsers(deckId, updates)),
});
export default connect(mapStateToProps, mapDispatchToProps)(DeckSharer);
