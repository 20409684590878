import React, { useState, useEffect } from 'react';
import getConfig from "./SchoolBrowseConfig";
import { connect } from 'react-redux';
import {
    createSchool,
} from '../../actions/allActions';

const $ = require('jquery');
$.DataTable = require('datatables.net');
require("datatables.net-responsive");

function SchoolsIndex({ createSchool }) {
    const [schoolName, setSchoolName] = useState('');
    const [schoolDomains, setSchoolDomains] = useState('');
    const [table, setTable] = useState(null);

    useEffect(async () => {
        $('#loader-wrapper').hide();
        $('#browse-table-wrapper').show();

        const config = getConfig();
        const table = $('#browse-table').DataTable(config);

        table.on('draw', () => {
            $('#results-count').text(table.page.info().recordsDisplay);
        });

        $('#browse-table-search').on('keyup', function () {
            // console.log('searching....');
            table.search(this.value).draw();
            $('#results-count').text(table.page.info().recordsDisplay);
        });

        $('th.sorting').on('click', function () {
            const filter = $(this).text();
            setTimeout(() => { table.draw(); }, 250);
        });
        setTimeout(() => { table.draw(); }, 250);
        setTable(table);
    }, []);

    const handleAddSchool = async () => {
        const domainsArr = schoolDomains.replace(/\s/g, '').split(',');
        const school = { name: schoolName, email_domains: domainsArr };
        await createSchool(school);
        setSchoolName('');
        setSchoolDomains('');
        table.draw();
    }

    return (
        <div className="layout layout--browse">
            <div className="layout--browse__container">
                <div className="layout--browse__container__main">
                    <h1>Browse <span>Schools</span></h1>
                    <div className="add-school-row">
                        <input 
                            type="text" 
                            id="name" 
                            name="name" 
                            placeholder='School name...'
                            value={schoolName} 
                            onChange={(e) => setSchoolName(e.target.value)}
                        />
                        <input 
                            type="text"
                            id="email_domains"
                            name="email_domains"
                            placeholder='School domains...'
                            value={schoolDomains}
                            onChange={(e) => setSchoolDomains(e.target.value)}
                        />
                        <button 
                            className="button button--secondary button--small"
                            onClick={handleAddSchool}
                        >
                            Add School
                        </button>
                    </div>
                    <div className='search-wrapper'>
                        <div className="browse-search">
                            <input id="browse-table-search" className="browse-search__input" type="text" placeholder="Search for a school..." />
                        </div>
                    </div>
                    <div className='count-wrapper'><span id="results-count" className='count'>0</span><span className='label'>Schools</span></div>
                    <div id="loader-wrapper" className="loader-wrapper">
                        <div className="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                    <div id="browse-table-wrapper" className="data-table-wrapper">
                        <table id="browse-table" className="responsive" style={{ width: '100%' }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

const mSTP = (state) => ({
});
const mDTP = (dispatch) => ({
    createSchool: (school) => dispatch(createSchool(school)),
});
export default connect(mSTP, mDTP)(SchoolsIndex);
