// ---------------------------------------------
// Stats API
// ---------------------------------------------

// Fetches the current user's stats
export const fetchUserStats = () => {
    return $.ajax({
        method: 'GET',
        url: `api/v1/stats/users`
    })
}

// Fetches the current user's heatmap
export const fetchHeatmap = () => {
    return $.ajax({
        method: 'GET',
        url: `api/v1/stats/heatmap`
    })
}

// ---------------------------------------------
// Users API
// ---------------------------------------------

// Fetches the current user
export const fetchUser = () => {
    return $.ajax({
        method: 'GET',
        url: 'api/v1/users'
    })
}

// Update user
export const updateUser = (user_id, attributes) => {
    const requestData = { user: attributes };
    return $.ajax({
        method: 'PATCH',
        url: `api/v1/users/${user_id}`,
        data: requestData,
    })
}

// Delete user
export const deleteUser = (userId) => {
    return $.ajax({
        method: 'DELETE',
        url: `api/v1/users/${userId}`
    })
}

// Fetches the current user's subscriptions
export const fetchUserSubscriptions = () => {
    return $.ajax({
        method: 'GET',
        url: 'api/v1/users/subscriptions'
    })
}

// Fetches the current user's properties object
export const fetchUserProperties = () => {
    return $.ajax({
        method: 'GET',
        url: 'api/v1/users/properties'
    })
}

// Update current user's properties object
export const updateUserProperties = (properties) => {
    return $.ajax({
        method: 'PATCH',
        url: `api/v1/users/properties`,
        data: { properties },
    })
}

// Deletes the specified property from userProperties
export const deleteUserProperty = key => {
    return $.ajax({
        method: 'DELETE',
        url: `api/v1/users/properties`,
        data: { key }
    })
}

// Updates the current user's password
export const updatePassword = (current_password, new_password, new_password_confirmation) => {
    return $.ajax({
        method: 'PATCH',
        url: '/api/v1/users/password',
        data: { 
            current_password,
            new_password,
            new_password_confirmation 
        }
    })
}

// Updates the current user's name
export const updateUserName = (first_name, last_name) => {
    return $.ajax({
        method: 'PATCH',
        url: '/api/v1/users/name',
        data: { 
            first_name,
            last_name
        }
    })
}

// Imperonsate user
export const impersonateUser = userId => {
    return $.ajax({
        method: 'POST',
        url: `api/v1/users/impersonate/${userId}`
    })
}

// Stop imperonsating user
export const stopImpersonatingUser = userId => {
    return $.ajax({
        method: 'POST',
        url: `api/v1/users/stop_impersonating/${userId}`
    })
}

// Refer by email
export const referByEmail = (email) => {
    return $.ajax({
        method: 'POST',
        url: `api/v1/users/refer`,
        data: { email }
    })
}

// Fetch Random Quiz Me Topic
export const fetchRandomTopic = () => {
    return $.ajax({
        method: 'GET',
        url: 'api/v1/users/random_topic'
    })
}

// Schedule Cards
export const scheduleCards = (cardIds) => {
    return $.ajax({
        method: 'POST',
        url: 'api/v1/users/schedule_cards',
        data: { cardIds }
    })
}

// Schedule Related Cards
export const scheduleRelatedCards = (vignette) => {
    return $.ajax({
        method: 'POST',
        url: 'api/v1/users/schedule_related_cards',
        data: { vignette }
    })
}

// ---------------------------------------------
// Schools API
// ---------------------------------------------

// Fetch all schools
export const fetchSchools = () => {
    return $.ajax({
        method: 'GET',
        url: `api/v1/schools`
    })
}

// Creates a new card
export const createSchool = school => {
    return $.ajax({
        method: 'POST',
        url: 'api/v1/schools',
        data: { school }
    })
}

// ---------------------------------------------
// Cards API
// ---------------------------------------------

// Update card
export const updateCard = (card) => {
    return $.ajax({
        method: 'PATCH',
        url: `api/v1/cards/${card.id}`,
        data: { card }
    })
}

// Update card content
export const updateCardContent = (card) => {
    return $.ajax({
        method: 'PATCH',
        url: `api/v1/cards/${card.id}/update_card_content`,
        data: { card },
        success: function() {
            toast.success("Card Changes Saved", {
                position: "bottom-left",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
        }
    })
}

// Update card response
export const updateCardResponse = (card, response, retries) => {
    return $.ajax({
        method: 'PATCH',
        url: `api/v1/cards/${card.id}/response`,
        data: { card, response },
        success: function (response) {
        },
        error: function (xhr, textStatus, errorThrown) {
            // console.log("Error updating card.");
            const delay = 100;

            if (retries > 0) {
                // console.log("Retrying in " + delay + " ms...");
                setTimeout(() => {
                    updateCardResponse(card, response, retries - 1);
                }, delay);
            } else {
                const toastConfig = { position: "bottom-left", hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined, autoClose: false }
                toast.error('Card failed to update. Check network connection.', toastConfig);
            }
        }
    })
}

// Report card
export const reportCard = (cardId, feedback) => {
    return $.ajax({
        method: 'PATCH',
        url: `api/v1/cards/${cardId}/report`,
        data: { feedback },
        success: function() {
            toast.success("Your report has been submitted.", {
                position: "bottom-left",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
        }
    })
}

// Report Axon Response
export const reportAxonResponse = (cardId, response, feedback, messages) => {
    return $.ajax({
        method: 'POST',
        url: `api/v1/cards/${cardId}/report_axon_response`,
        data: {
            response: response,
            feedback: feedback,
            messages: messages
        },
        success: function() {
            toast.success("Your report has been submitted.", {
                position: "bottom-left",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
        }
    })
}

// Report Axon Home Response
export const reportAxonHomeResponse = (response, feedback, messages) => {
    return $.ajax({
        method: 'POST',
        url: `api/v1/axon/report_axon_response`,
        data: {
            response: response,
            feedback: feedback,
            messages: messages
        },
        success: function() {
            toast.success("Your report has been submitted.", {
                position: "bottom-left",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
        }
    })
}

// Tag card
export const tagCard = (cardId, tags) => {
    return $.ajax({
        method: 'PATCH',
        url: `api/v1/cards/${cardId}/tag`,
        data: { tags }
    })
}

// Un-tag card
export const untagCard = (cardId, tags) => {
    return $.ajax({
        method: 'PATCH',
        url: `api/v1/cards/${cardId}/untag`,
        data: { tags }
    })
}

// Clone card to deck
export const cloneCard = (cardId, targetDeckId) => {
    return $.ajax({
        method: 'POST',
        url: `api/v1/cards/${cardId}/clone`,
        data: { targetDeckId }
    })
}

// Fetch all user's cards
export const fetchUserCards = () => {
    return $.ajax({
        method: 'GET',
        url: `api/v1/cards`
    })
}

// Creates a new card
export const createCards = card => {
    return $.ajax({
        method: 'POST',
        url: 'api/v1/cards',
        data: { card }
    })
}

// Deletes the specified card
export const deleteCard = cardId => {
    return $.ajax({
        method: 'DELETE',
        url: `api/v1/cards/${cardId}`,
        success: function() {
            toast.success("Card Deleted", {
                position: "bottom-left",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
        }
    })
}

// ---------------------------------------------
// Decks API
// ---------------------------------------------

// Creates a new deck for the current user
export const createDeck = deck => {
    return $.ajax({
        method: 'POST',
        url: 'api/v1/decks',
        data: { deck }
    })
}

// Creates a new deck for the current user
export const updateDeck = deck => {
    return $.ajax({
        method: 'PATCH',
        url: `api/v1/decks/${deck.id}`,
        data: { deck }
    })
}

// Resets scheduling on the specified deck
export const resetDeck = deckId => {
    return $.ajax({
        method: 'PATCH',
        url: `api/v1/decks/${deckId}/reset`,
        success: function() {
            toast.success("Deck Reset Successfully", {
                position: "bottom-left",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
        }
    })
}

// Clone deck
export const cloneDeck = (deckId, options) => {
    return $.ajax({
        method: 'POST',
        url: `api/v1/decks/${deckId}/clone`,
        data: { options }
    })
}

// Clone deck from browse
export const browseCloneDeck = (targetdeckId, cloneCount) => {
    return $.ajax({
        method: 'POST',
        url: `api/v1/decks/${targetdeckId}/clone_browse`,
        data: { cloneCount }
    })
}

// Save collapse defaults for shared deck
export const saveCollapseDefaults = deckId => {
    return $.ajax({
        method: 'POST',
        url: `api/v1/decks/${deckId}/save_collapse_defaults`,
    })
}

// Set deck as paid
export const setAsPaid = deckId => {
    return $.ajax({
        method: 'POST',
        url: `api/v1/decks/${deckId}/set_as_paid`,
    })
}

// Invalidate cached deck progress for lineage
export const invalidateProgressCaches = (deckId) => {
    // console.log('invalidating progress caches ajax request...');
    return $.ajax({
        method: 'POST',
        url: `api/v1/decks/${deckId}/invalidate`,
    })
}

// Deletes the specified deck
export const deleteDeck = deckId => {
    return $.ajax({
        method: 'DELETE',
        url: `api/v1/decks/${deckId}`,
        success: function() {
            toast.success("Deck Deleted Successfully", {
                position: "bottom-left",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
        },
        error: function (jqXHR) {
            const toastConfig = { position: "bottom-left", hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined, autoClose: false }
            toast.error(jqXHR.responseJSON.error, toastConfig);
        }
    })
}

// Toggles deck collapse
export const toggleDeckCollapsed = (deckId, isExpanding) => {
    return $.ajax({
        method: 'GET',
        url: `api/v1/decks/${deckId}/toggle_collapsed`,
        data: { isExpanding }
    })
}

// Fetches list of all current user's decks
export const fetchDecks = () => {
    return $.ajax({
        method: 'GET',
        url: 'api/v1/decks'
    })
}

// Fetches decksTree for all current user's decks
export const fetchDecksTree = () => {
    return $.ajax({
        method: 'GET',
        url: 'api/v1/decks/tree'
    })
}

// Merges cards from one deck into another
export const mergeDecks = (selected_deck_id, target_deck_id, retain_original) => {
    return $.ajax({
        method: 'POST',
        url: 'api/v1/decks/merge',
        data: {
            selected_deck_id,
            target_deck_id,
            retain_original
        }
    })
}

// Fetches count cards of filter type for a specific deck
export const fetchCards = (deckId, options) => {
    return $.ajax({
        method: 'GET',
        url: `api/v1/decks/${deckId}/cards`,
        data: { options }
    })
}

// Fetches count cards of filter type for a specific deck
export const fetchTags = (deckId) => {
    return $.ajax({
        method: 'GET',
        url: `api/v1/decks/${deckId}/tags`
    })
}
// Fetches num due & new for all decks
export const fetchAllProgress = () => {
    return $.ajax({
        method: 'GET',
        url: 'api/v1/decks/progress'
    })
}

export const fetchDeckProgress = (deckId, tags = null, taggingStrategy = null) => {
    return $.ajax({
        method: 'GET',
        url: `api/v1/decks/${deckId}/progress`,
        data: { tags, taggingStrategy }
    })
}

// Fetches percent mastery for a deck
export const fetchPercentMastery = (deckId) => {
    return $.ajax({
        method: 'GET',
        url: `api/v1/decks/${deckId}/mastery`
    })
}

// Creates a new deck for the current user
export const importDeck = deckName => {
    return $.ajax({
        method: 'GET',
        url: 'api/v1/decks/import',
        data: { deckName }
    })
}

// Creates a new deck for the current user
export const fetchImportStatus = job_id => {
    return $.ajax({
        method: 'GET',
        url: 'api/v1/decks/import_status',
        data: { job_id }
    })
}

// Fetches all the users sharing a deck
export const fetchSharedUsers = deckId => {
    return $.ajax({
        method: 'GET',
        url: `api/v1/decks/${deckId}/deck_ownerships`
    })
}

// Fetches all the users sharing a deck
export const addSharedUser = (deckId, user) => {
    return $.ajax({
        method: 'POST',
        url: `api/v1/decks/${deckId}/deck_ownerships`,
        data: { user }
    })
}

// Fetches all the users sharing a deck
export const updateSharedUsers = (deckId, updates) => {
    return $.ajax({
        method: 'PATCH',
        url: `api/v1/decks/${deckId}/deck_ownerships`,
        data: { updates }
    })
}

export const bulkUpdateDeckOwnershipOrder = (decks) => {
    return $.ajax({
        method: 'PATCH',
        url: `api/v1/deck_ownerships/update_order`,
        data: { decks }
    })
}

// Deletes the specified deck
export const deleteDeckOwnership = deckId => {
    return $.ajax({
        method: 'DELETE',
        url: `api/v1/decks/${deckId}/deck_ownerships`,
        success: function () {
            toast.success("Deck Removed Successfully", {
                position: "bottom-left",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
        }
    })
}

export const assignSchool = (deckId, schoolId) => {
    return $.ajax({
        method: 'POST',
        url: `api/v1/decks/${deckId}/assign_school`,
        data: { schoolId }
    })
}

// ---------------------------------------------
// STUDY PROFILE SETTINGS API
// ---------------------------------------------

// Fetches study profiles
export const fetchStudyProfiles = () => {
    return $.ajax({
        method: 'GET',
        url: '/api/v1/study_profiles'
    })
}

// Updates Study Session
export const updateStudyProfile = (study_profile) => {
    return $.ajax({
        method: 'PUT',
        url: `api/v1/study_profiles/${study_profile.id}`,
        data: { study_profile }
    })
}

// ---------------------------------------------
// PAST STUDY SESSIONS API
// ---------------------------------------------

// Get Past Study Sessions
export const fetchPastStudySessions = () => {
    return $.ajax({
        method: 'GET',
        url: '/api/v1/past_study_sessions'
    })
}

// Create Past Study Session
export const createPastStudySession = (past_study_session) => {
    return $.ajax({
        method: 'POST',
        url: `api/v1/past_study_sessions`,
        data: { past_study_session }
    })
}

// ---------------------------------------------
// USER STUDY SETTINGS API
// ---------------------------------------------

// Fetches presigned AWS url
export const fetchUserStudySettings = () => {
    return $.ajax({
        method: 'GET',
        url: '/api/v1/user_study_settings'
    })
}

// Updates Study Session
export const updateUserStudySettings = (user_study_settings) => {
    return $.ajax({
        method: 'PUT',
        url: `api/v1/user_study_settings/${user_study_settings.id}`,
        data: { user_study_settings }
    })
}

// ---------------------------------------------
// AWS API
// ---------------------------------------------

// Fetches presigned AWS url
export const fetchPresignedUrl = (bucket, dir_name, filename) => {
    return $.ajax({
        method: 'GET',
        url: 'api/v1/aws/presigned',
        data: {
            bucket,
            dir_name,
            filename
        }
    })
}

// ---------------------------------------------
// NOTION API
// ---------------------------------------------
export const fetchAssetList = () => {
    return $.ajax({
        method: 'GET',
        url: `/api/v1/fetch_asset_list`
    })
}

export const fetchAssetContent = pageId => {
    return $.ajax({
        method: 'GET',
        url: `/api/v1/fetch_asset_content/${pageId}`
    })
}

// ---------------------------------------------
// Curation / Metal API
// ---------------------------------------------

// Fetches semantic search results from Metal
export const fetchSemanticSearchResults = (searchValue) => {
    return $.ajax({
        method: 'GET',
        url: 'api/v1/metal',
        data: {
            searchValue,
        }
    })
}

// Clone card to deck
export const curatorClone = (targetDeckId, cardIds) => {
    return $.ajax({
        method: 'POST',
        url: `api/v1/metal/clone`,
        data: { targetDeckId, cardIds }
    })
}

// ---------------------------------------------
// DATATABLES API
// ---------------------------------------------

// Fetches the current user's checkout
export const fetchBrowseFilters = () => {
    return $.ajax({
        method: 'GET',
        url: 'api/v1/datatables/filters'
    })
}

// ---------------------------------------------
// CardSuspends API
// ---------------------------------------------

// Suspends the given card for the current user
export const suspendCard = cardId => {
    return $.ajax({
        method: 'POST',
        url: 'api/v1/card_suspends',
        data: { cardId }
    })
}

// Suspends all the cards in a given deck for the current user
export const suspendDeck = deckId => {
    return $.ajax({
        method: 'POST',
        url: 'api/v1/card_suspends/deck',
        data: { deckId }
    })
}

// Suspends the given card for the current user
export const unSuspendCard = cardId => {
    return $.ajax({
        method: 'DELETE',
        url: 'api/v1/card_suspends',
        data: { cardId }
    })
}

// Un-suspends all the cards in a given deck for the current user
export const unSuspendDeck = deckId => {
    return $.ajax({
        method: 'DELETE',
        url: 'api/v1/card_suspends/deck',
        data: { deckId }
    })
}

// ---------------------------------------------
// CHECKOUTS API
// ---------------------------------------------

// Fetches checkout for given deck
// export const fetchCheckoutSession = deckId => {
//     return $.ajax({
//         method: 'GET',
//         url: `/api/v1/checkouts/${deckId}`
//     })
// }

// Fetches the current user's checkout
export const fetchCheckoutSessions = (product_id) => {
    return $.ajax({
        method: 'GET',
        url: 'api/v1/subscriptions',
        data: { product_id }
    })
}

// Creates a new subscription free trial for the current_user
export const startFreeTrial = () => {
    return $.ajax({
        method: 'POST',
        url: 'api/v1/users/free_trial'
    })
}

// Redeem an access code to unlock deck(s)
export const redeemAccessCode = (accessCode) => {
    return $.ajax({
        method: 'POST',
        url: `api/v1/users/redeem_access_code`,
        data: { accessCode }
    })
}

// Fetches billing portal
export const fetchBillingPortal = () => {
    return $.ajax({
        method: 'GET',
        url: 'api/v1/subscriptions/billing'
    })
}

// Checkout success hook
export const postCheckoutSuccess = () => {
    return $.ajax({
        method: 'POST',
        url: 'api/v1/subscriptions/success'
    })
}
