import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import ReactTooltip from 'react-tooltip';
import { Info } from 'react-feather';
import { updateStudyProfile } from '../../actions/allActions';
import Modal from '../misc/Modal';

function StudyProfileEditor({ studyProfile, hide, updateStudyProfile }) {
    const [values, setValues] = useState({
        id: studyProfile.id,
        name: studyProfile.name,
        mode: studyProfile.mode,
        due: studyProfile.due,
        new: studyProfile.new,
        total: studyProfile.total
    });

    useEffect(() => {
        document.getElementById('name').focus();
    }, []);

    const changeHandler = e => {
        setValues({...values, [e.target.name]: e.target.value});
    }

    const setValuesCb = (key, value) => {
        setValues({...values, [key]: value});
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        updateStudyProfile(values).then(res => document.getElementById("btn_option_0")?.click());
        hide();
    }

    const tooltipDescription = 'Customize your Study Profile and save it across all decks.';

    return (
        <Modal
            name={'modal--edit-study-session'}
            title={
                <>
                    <span>Study Profile Editor</span><Info data-tip={tooltipDescription}/>
                    <ReactTooltip />
                </>
            }
            alternateHideFunction={hide}
            content={
                <form>
                    <div className='input'>
                        <label htmlFor="name">Profile Name</label>
                        <input type="text" id="name" name="name" value={values.name} onChange={changeHandler} maxLength="40" />
                    </div>
                    <p className='label'>Profile Settings</p>
                    <div className='input--radio'>
                        <input type="radio" id="fixed-num" name="mode" value="fixed_number" checked={values.mode === 'fixed_number'} onChange={changeHandler} />
                        <label htmlFor="fixed-num">Study a fixed number of cards</label>
                    </div>
                    <div className='input--radio'>
                        <input type="radio" id="new-and-due" name="mode" value="new_and_due" checked={values.mode === 'new_and_due'} onChange={changeHandler} />
                        <label htmlFor="new-and-due">Study a number of New and Due cards</label>
                    </div>
                    <hr/>
                    <div className='settings-tiles'>
                        {
                            values.mode == 'new_and_due' ?
                                <>
                                    <SettingsTile version={'new'} setValuesCb={setValuesCb} studyProfile={studyProfile} />
                                    <SettingsTile version={'due'} setValuesCb={setValuesCb} studyProfile={studyProfile} />
                                </> :
                                <SettingsTile version={'total'} setValuesCb={setValuesCb} studyProfile={studyProfile} />
                        }
                    </div>
                </form>
            }
            actions={
                <button className='button button--success' onClick={e => handleSubmit(e)} value="Save">Save</button>
            }
        />
    )
}

function SettingsTile({ version, setValuesCb, studyProfile }) {
    const ALL_CARDS_NUM = 99999999;

    const shouldMakeZero = (studyProfile.mode === 'fixed_number' && version !== 'total') || (studyProfile.mode === 'new_and_due' && version == 'total');
    let defaultCounterValue = shouldMakeZero ? 0 : studyProfile[version];
    const defaultRadioValue = defaultCounterValue >= ALL_CARDS_NUM ? 'all' : 'custom';
    defaultCounterValue = defaultCounterValue >= ALL_CARDS_NUM ? 0 : defaultCounterValue;

    const [radioValue, setRadioValue] = useState(defaultRadioValue);
    const [counterValue, setCounterValue] = useState(defaultCounterValue);

    const handleChange = (e) => {
        const targetValue = e.target.value;
        if (e.target.type == 'radio') {
            setRadioValue(targetValue);
            (targetValue == 'custom') ? setValuesCb(version, counterValue) : setValuesCb(version, ALL_CARDS_NUM);
        } else if (e.target.type == 'number') {
            setCounterValue(targetValue);
            setValuesCb(version, targetValue);
        }
    }

    const label = (version === 'new' ? 'New Cards' : 
                    version === 'due' ? 'Due Cards' : 'Total Cards');

    return (
        <div className='settings-tiles__tile'>
            <div className='label'>{label}</div>
            <div className='radio-text-input'>
                <div className='input--radio'>
                    <input type="radio" id={version + "-card-count"} name={version} value="custom" checked={radioValue === 'custom'} onChange={handleChange} />
                    <label htmlFor={version + "-card-count"}>{label}</label>
                </div>
                <input type="number" id={version + "-quantity"} name={version + "-card-count"} disabled={radioValue !== 'custom'} value={counterValue} onChange={handleChange} min="0" max="999"></input>
            </div>
            <div className='input--radio'>
                <input type="radio" id={version + "-all"} name={version} value="all" checked={radioValue === 'all'} onChange={handleChange} />
                <label htmlFor={version + "-all"}>All{version === 'total' ? ' (New & Due)' : ''}</label><br/>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
    updateStudyProfile: (studyProfile) => dispatch(updateStudyProfile(studyProfile))
});
export default connect(mapStateToProps, mapDispatchToProps)(StudyProfileEditor);
