import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ChevronRight } from 'react-feather';
import ClipLoader from "react-spinners/ClipLoader";
import Modal from '../misc/Modal';
import _ from 'lodash';
import { 
    receiveLastCloneTarget,
    toggleDeckCollapsed,
} from '../../actions/allActions';

function TargetDeckSelector({ decksTree, loading, hide, toggleDeckCollapsed, receiveLastCloneTarget, lastCloneTarget, cloneCount }) {
    const handleDeckClick = (deckId) => {
        receiveLastCloneTarget(deckId);
        hide();
    }

    const toggleSubsection = (e, deck) => {
        // Don't toggle again if we're still loading the previous toggle
        if (loading.toggleLoading) return;

        let targetSub, isExpanding;
        if (e.target.nodeName === "svg") {
            targetSub = e.target.parentElement.getAttribute('data-target');
            isExpanding = e.target.classList.toggle('subsection-open');
        } else if (e.target.nodeName === "polyline") {
            let parentSvg = e.target.parentElement;
            targetSub = parentSvg.parentElement.getAttribute('data-target');
            isExpanding = parentSvg.classList.toggle('subsection-open');
        } else {
            targetSub = e.target.getAttribute('data-target');
            isExpanding = e.target.children[0].classList.toggle('subsection-open');
        }
        // We should only load if we're expanding and we haven't already loaded the children
        const shouldLoad = isExpanding && deck.children.length === 0;
        toggleDeckCollapsed(deck.id, shouldLoad, isExpanding);
        // document.getElementById(targetSub)?.classList.toggle('subsection-show');
        document.getElementById(`subsection-${deck.id}`)?.classList.toggle('subsection-show');
    }

    const mobileToggleSubsection = (e, deck) => {
        const isExpanding = e.target?.classList.toggle('subsection-show');
        const shouldLoad = isExpanding && deck.children.length === 0;
        toggleDeckCollapsed(deck.id, shouldLoad, isExpanding);
    }

    const renderDeck = (deck) => {
        const children = deck.children.map(child => renderDeck(child));
        let toggleButton, mobileToggleButton;

        const clipLoader = (
            <ClipLoader color={'var(--theme-primary)'} loading={true} speedMultiplier={.75} cssOverride={{
                "width": "100%",
                "height": "100%"
            }} />
        );
        const clipLoaderMobile = (
            <ClipLoader color={'var(--theme-primary)'} loading={true} speedMultiplier={.75} cssOverride={{
                "width": ".75rem",
                "height": ".75rem",
                "marginLeft": ".25rem"
            }} />
        );

        if (deck.has_children) {
            toggleButton = (
                <button
                    className={`component--deck-index__top__left__toggle ${loading.toggleLoading === deck.id && children.length == 0 ? 'is-loading' : ''}`}
                    onClick={(e) => toggleSubsection(e, deck)}
                >
                    {/* If this is a deck that's loading (i.e. expanding) and we've not already loaded the children */}
                    {loading.toggleLoading === deck.id && children.length == 0 ?
                        clipLoader : <ChevronRight className={deck.collapsed ? '' : 'subsection-open'} />
                    }
                </button>
            )
            mobileToggleButton = (
                <button
                    className={`component--deck-index__top_mobile-toggle ${deck.collapsed ? '' : 'subsection-open'}`}
                    onClick={(e) => mobileToggleSubsection(e, deck)}
                >
                    {deck.collapsed || (loading.toggleLoading === deck.id && children.length == 0) ?
                        'View Sub Decks' : 'Collapse Sub Decks'}
                    {loading.toggleLoading === deck.id && children.length == 0 ?
                        clipLoaderMobile : <ChevronRight />}
                </button>
            )
        }

        return (
            <div key={deck.id} className="component component--deck-index component--deck-index--cloner">
                <div className="component--deck-index__top">
                    <div className="component--deck-index__top__left">
                        <div>
                            <div className="component--deck-index__name">
                                {deck.name}
                            </div>
                        </div>
                        {toggleButton}
                    </div>
                    <div className="component--deck-index__top__right">
                        <button className="button button--secondary" onClick={() => handleDeckClick(deck.id)}>Choose</button>
                    </div>
                    {mobileToggleButton}
                </div>
                {
                    children.length != 0 &&
                    <div id={`subsection-${deck.id}`} className={`component--deck-index__sub ${deck.collapsed ? '' : 'subsection-show'}`}>
                        {children}
                    </div>
                }
            </div>
        )
    }

    return (
        <Modal
            name={"modal--clone-card"}
            hide={hide}
            title="Select Target Deck"
            cancelButtonText={"Close"}
            content={
                <>
                    <p className='m-t-0'>
                        Select a deck as the target for cloning cards into.
                    </p>
                    <div className='tree-wrapper'>
                        {decksTree.map(deck => renderDeck(deck))}
                    </div>
                </>
            }
        />
    )
}

const mapStateToProps = (state) => ({
    decksTree: state.decksTree,
    decks: state.decks,
    loading: state.loading,
    lastCloneTarget: state.lastCloneTarget,
});
const mapDispatchToProps = (dispatch) => ({
    receiveLastCloneTarget: (payload) => dispatch(receiveLastCloneTarget(payload)),
    toggleDeckCollapsed: (deckId, shouldLoad, isExpanding) => dispatch(toggleDeckCollapsed(deckId, shouldLoad, isExpanding)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TargetDeckSelector);
