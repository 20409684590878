import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { BarChart2, Menu, Home, HelpCircle, Grid, ExternalLink, Layers, Edit3, Gift, Minimize } from 'react-feather';
import { setMenuState } from '../../actions/allActions';
import { useLocation } from 'react-router-dom';
import openIntercomUtil from '../../util/openIntercomUtil.js';

import SynaptiqLogo from '../../../assets/images/synaptiq-logo.svg';
import SynaptiqLogoDark from '../../../assets/images/synaptiq-logo-dark.svg';
import ThiemeLogo from '../../../assets/images/thieme-synaptiq-logo.svg';
import ThiemeLogoDark from '../../../assets/images/thieme-synaptiq-logo-dark.svg';
import ReferModal from '../misc/ReferModal';

function GlobalNavigation({ user, theme, menuState, setMenuState, userProperties, decks, subscriptions }) {
    let location = useLocation();
    const [currentPath, setCurrentPath] = useState(location?.pathname);
    const [intercomOpen, setIntercomOpen] = useState(false);
    const [showReferModal, setShowReferModal] = useState(false);

    useEffect(() => {
        setCurrentPath(location.pathname);
    }, [location]);

    const handleClickHome = () => {
        ahoy.track("Click Home");
    }

    const handleLibraryClick = () => {
        ahoy.track("Click Library");
    }

    const handleClickStats = () => {
        ahoy.track("Click Stats");
    }

    const handleClickBrowse = () => {
        ahoy.track("Click Browse");
    }

    const openHelpMenu = () => {
        document.getElementById('user-menu')?.classList.remove("is-open");
        if (intercomOpen) {
            Intercom('hide');
        } else {
            openIntercomUtil();
        }
        setIntercomOpen(!intercomOpen);
    }

    const renderHeader = () => {
        let logoPath = theme == "theme-dark-clean" ? SynaptiqLogoDark : SynaptiqLogo;
        if (theme === 'theme-glass') logoPath = SynaptiqLogoDark;
        let logo = <Link className="logo" to={`/`}><img src={logoPath} alt="Synaptiq Logo" /></Link>;
        if (user.group === 'thieme') {
            logo = <><Link className="logo thieme-logo thieme-logo--light" to={`/`}><img src={ThiemeLogo} alt="Thieme Logo" /></Link><Link className="logo thieme-logo thieme-logo--dark" to={`/`}><img src={ThiemeLogoDark} alt="Thieme Logo" /></Link></>;
        }
        if (!menuState) {
            return;
        }
        return logo;
    }

    const toggleMenuState = () => {
        setMenuState(!menuState);
    }

    const openReferModal = () => {
        setShowReferModal(true);
    }

    const hideReferModal = () => {
        setShowReferModal(false);
    }

    return (
        <div className='gnav'>
            <div className='gnav_top'>
                <div className='gnav_top_header'>
                    {renderHeader()}
                    <button className='button button--gnav-toggle' onClick={toggleMenuState}>{menuState ? <Minimize /> : <Menu />}</button>
                </div>
                <div className='nav'>
                    <ul>
                        <li>
                            <Link className={`button button--secondary ${currentPath === '/' ? 'active' : ''}`} to={`/`} onClick={handleClickHome}>
                                <span><Home /><span>Home</span></span>
                                <div className='dot'></div>
                                <div className='badge badge--keybind'>H</div>
                            </Link>
                        </li>
                        <li>
                            <Link className={`button button--secondary ${currentPath.includes('/browse') ? 'active' : ''}`} to={`/browse`} onClick={handleClickBrowse}>
                                <span><Layers /><span>Cards</span></span>
                                <div className='dot'></div>
                                <div className='badge badge--keybind'>C</div>
                            </Link>
                        </li>
                        <li>
                            <Link className={`button button--secondary ${currentPath.includes('/stats') ? 'active' : ''}`} to={`/stats`} onClick={handleClickStats}>
                                <span><BarChart2 /><span>Stats</span></span>
                                <div className='dot'></div>
                                <div className='badge badge--keybind'>S</div>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='gnav_bottom'>
                <div className='nav'>
                    <ul>
                        {(user.admin || user.curator || user.group === 'user-curator') && !userProperties?.demoView && <li>
                            <Link className={`button button--secondary ${currentPath.includes('/curation') ? 'active' : ''}`} to={`/curation`}>
                                <span><Edit3 /><span>Curation</span></span>
                                <div className='dot'></div>
                            </Link>
                        </li>}
                        {user.group !== 'thieme' && !Capacitor.isNativePlatform() && <li>
                            <a className='button button--secondary' href="https://synaptiq.co/library/" target='_blank' onClick={handleLibraryClick}>
                                <span><Grid /><span>Library<ExternalLink className='m-l-0-50' /></span></span>
                                <div className='dot'></div>
                            </a>
                        </li>}
                        <li>
                            <Link className={`button button--secondary`} to={`/settings#help`}>
                                <span><HelpCircle /><span>Help</span></span>
                                <div className='dot'></div>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            {showReferModal && <ReferModal hide={hideReferModal} />}
        </div>
    );
}

const mapStateToProps = (state) => ({
    user: state.user,
    theme: state.theme,
    menuState: state.menuState,
    userProperties: state.userProperties,
    decks: state.decks,
    subscriptions: state.subscriptions
});
const mapDispatchToProps = (dispatch) => ({
    setMenuState: (menuState) => dispatch(setMenuState(menuState)),
    referByEmail: (email, uuids = []) => dispatch(referByEmail(email, uuids)),
});
export default connect(mapStateToProps, mapDispatchToProps)(GlobalNavigation);
