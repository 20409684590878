import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Confetti from '../misc/Confetti';
import { Check } from 'react-feather';
import { postCheckoutSuccess } from '../../actions/allActions';

function CheckoutSuccess({ postCheckoutSuccess }) {
    useEffect(() => {
        setTimeout(postCheckoutSuccess, 500);
    }, []);

    return (
        <div className='layout layout--checkout layout--checkout--success'>
            <div className="layout--checkout_container layout_container">
                <Confetti/>
                <div>
                    <div className='circle-check'>
                        <Check/>
                    </div>
                    <h1>Your Content is Unlocked!</h1>
                    <p>Happy studying!</p>
                    <div className='button-wrapper'>
                        <Link className="button button--wide button--checkout" to={`/`}>Return Home</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
});
const mapDispatchToProps = (dispatch) => ({
    postCheckoutSuccess: () => dispatch(postCheckoutSuccess())
});
export default connect(mapStateToProps, mapDispatchToProps)(CheckoutSuccess);
