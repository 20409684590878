let reqCount = 0;
const getCuratorConfig = () => (
    {
        "dom": '<<t><"bottom"pl>>',
        columns: [
            {
                title: 'Card',
                data: 'answer',
                className: 'col-answer',
                render: (data, type, row) => {
                    if (row.model === "multichoice") {
                        const labelId = `id="choice-${row.multi_choice_answer}"`;
                        const correctLabelStartIndex = data.indexOf(labelId);
                        if (correctLabelStartIndex === -1) return data;
                        const closeAngleBracketIndex = data.indexOf(">", correctLabelStartIndex);
                        const modifiedData = [
                            data.slice(0, closeAngleBracketIndex),
                            ' class="correct-answer"',
                            data.slice(closeAngleBracketIndex)
                        ].join('');
                        return modifiedData;
                    } else {
                        const frontData = !!row.css ? row.question + row.css : row.question;
                        const backData = !!row.css ? data + row.css : data;
                        return row.model == 'occlusion' ? `<div class="front">${row.question + row.question_overlay}</div><div class="back">${row.question + row.answer_overlay}</div>` : `<div class="front">${frontData}</div><div class="back">${backData}</div>`;
                    }
                }
            },
            {
                title: 'Extra',
                data: 'extra',
                className: 'col-extra',
                render: (data, type, row) => {
                    if (data === null || data === "") {
                        return null;
                    } else {
                        return `<div class="spacer"></div><div class="truncate-col">${data}</div>`
                    }
                }
            },
            {
                title: 'Deck',
                data: 'deck'
            },
            {
                title: 'GUID',
                data: 'guid'
            },
            {
                data: 'id',
                sortable: false,
                className: 'actions',
                width: '240px',
                render: (data, type, full) => {
                    let options;
                    const editBtn = `<button class='button button--secondary button--small button--edit-card' aria-label="Edit Card" data-card-id='${data}'>Edit</button>`
                    const cloneBtn = `<button class='button button--secondary button--small button--clone-card' aria-label="Clone Card" data-card-id='${data}'>Clone</button>`
                    const deleteBtn = `<button class='button button--secondary button--small button--delete-card' aria-label="Delete Card" data-card-id='${data}'>Delete</button>`
                    // const suspendBtn = `<button class='button button--secondary button--small button--suspend-card' aria-label="Suspend Card" data-card-id='${data}'>Suspend</button>`
                    const suspendBtn = '';

                    if (full.editable) {
                        options = editBtn + cloneBtn + deleteBtn + suspendBtn;
                    } else if (!full.editable) {
                        options = cloneBtn + suspendBtn;
                    } else {
                        options = suspendBtn;
                    }

                    options += `<br><p>Card ID: ${data}</p>`;
                    return options;
                }
            },
        ],
        order: [[ 3, "desc" ]],
        responsive: true,
        processing: true,
        serverSide: true,
        ajax: {
            url: 'api/v1/datatables',
            beforeSend: function () {
                reqCount += 1;
                $("#search-loading-icon").show();
                $("#browse-table").addClass('table-loading');
            },
            complete: function () {
                reqCount -= 1;
                if (reqCount <= 0) {
                    $("#search-loading-icon").hide();
                    $("#browse-table").removeClass('table-loading');
                }
            },
        }
    }
)

export default getCuratorConfig;
