import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Modal from '../misc/Modal';
import StudyProfileEditor from './StudyProfileEditor';
import {
    updateUserStudySettings,
    updateUserProperties
} from '../../actions/allActions';
import { studyProfilesArray } from '../../reducers/selectors';
import Controller from '../../../assets/images/Controller.jpg';
import ControllerDark from '../../../assets/images/ControllerDark.jpg';
import ControllerVertical from '../../../assets/images/ControllerVertical.jpg';
import ControllerVerticalDark from '../../../assets/images/ControllerDarkVertical.jpg';
import { Info } from 'react-feather';
import ReactTooltip from 'react-tooltip';

function UserStudySettingsEditor({ user, hide, studyProfilesArr, studyProfiles, userStudySettings, updateUserStudySettings, userProperties, updateUserProperties }) {
    const [confirmation, setConfirmation] = useState(false);
    const [steps, setSteps] = useState(userStudySettings.steps);
    const [step0Unit, setStep0Unit] = useState(userStudySettings.steps[0] < 1440 ? 'm' : 'd');
    const [step1Unit, setStep1Unit] = useState(userStudySettings.steps[1] < 1440 ? 'm' : 'd');
    const [dynamicSteps, setDynamicSteps] = useState([0, 0, 0, 0, 0, 0]);
    const [multiple, setMultiple] = useState(userStudySettings.multiple);
    const [numResponses, setNumResponses] = useState(userStudySettings.num_responses);
    const [fontSizePreference, setFontSizePreference] = useState(userProperties.studyTextSize);
    const [controllerOrientation, setControllerOrientation] = useState(userProperties.studyControllerOrientation);

    const [showProfileEditor, setShowProfileEditor] = useState(false);
    const [targetProfileId, setTargetProfileId] = useState(null);

    useEffect(() => {
        recalculateDynamicSteps();
    }, [steps]);

    useEffect(() => {
        recalculateDynamicSteps();
    }, [multiple]);

    useEffect(() => {
        if (targetProfileId) {
            setShowProfileEditor(true);
        }
    }, [targetProfileId]);

    const recalculateDynamicSteps = () => {
        const mins = steps[steps.length - 1];
        let newCalculatedSteps = [...dynamicSteps];
        for (let i = 0; i < 6; i++) {
            const newMins = mins * (multiple ** (i + 1));
            newCalculatedSteps[i] = Math.round(newMins);
        }
        setDynamicSteps(newCalculatedSteps);
    }

    const stepsChangeHandler = (e) => {
        let stepVal = parseInt(e.target.value);
        const stepIdx = parseInt(e.target.name);

        const unitSelector = document.getElementById(`step${stepIdx}-unit`);
        // If the selected unit is days, or this is the third step which is hardcoded as days
        if (unitSelector?.value === 'd' || stepIdx === 2) stepVal = stepVal * 1440;

        let newSteps = [...steps];
        newSteps[stepIdx] = stepVal;
        setSteps(newSteps);
    }

    const unitChangeHandler = (e) => {
        const name = e.target.name;
        const unit = e.target.value;

        let newSteps = [...steps];

        if (name === 'step0Unit') {
            setStep0Unit(unit);
            if (unit === 'd') {
                newSteps[0] = Math.round(newSteps[0] * 1440);
            } else {
                newSteps[0] = Math.round(newSteps[0] / 1440);
            }
        } else {
            setStep1Unit(unit);
            if (unit === 'd') {
                newSteps[1] = Math.round(newSteps[1] * 1440);
            } else {
                newSteps[1] = Math.round(newSteps[1] / 1440);
            }
        }

        setSteps(newSteps);
    }

    const multipleChangeHandler = (e) => {
        const newMultiple = parseFloat(e.target.value);
        setMultiple(newMultiple);
    };

    const numResponsesChangeHandler = (e) => {
        const newNumResponses = e.target.value;
        setNumResponses(parseInt(newNumResponses));
    };

    const fontSizeChangeHandler = (e) => {
        setFontSizePreference(e.target.value);
    }

    const resetDefaults = () => {
        setSteps([10, 1440, 4320]);
        setMultiple(2.0);
        setNumResponses(4);
        setConfirmation(false);
        setStep0Unit('m')
        setStep1Unit('d')
        const toastConfig = { position: "bottom-left", autoClose: 3000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined }
        toast.success('Global Study Settings have been reset to defaults.', toastConfig);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const userStudySettings = { steps, multiple, num_responses: numResponses };
        updateUserStudySettings(userStudySettings);
        updateUserProperties({ studyTextSize: fontSizePreference });
        const toastConfig = { position: "bottom-left", autoClose: 3000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined }
        toast.success('Global Study Settings saved successfully.', toastConfig);
        hide();
    }

    const toggleConfirmation = () => {
        setConfirmation(!confirmation);
    }

    const hideConfirmation = () => {
        setConfirmation(false);
    }

    const toggleHelpModal = () => {
        window.Intercom('showArticle', 7852447);
    }

    const studyOptions = studyProfilesArr.map((session, key) => {
        return (
            <button key={key} className='button button--secondary profile-option' onClick={() => editStudyProfile(session.id)}>
                {session.name}
            </button>
        );
    });

    const editStudyProfile = (id) => {
        setTargetProfileId(id);
    }

    const hideStudyProfileEditor = () => {
        setShowProfileEditor(false);
        setTargetProfileId(null);
    }

    const controllerOrientationHandler = (e) => {
        const value = e.target.value;
        updateUserProperties({ studyControllerOrientation: value });
        setControllerOrientation(value);
    }

    const renderControllerDiagram = () => {
        if (controllerOrientation === 'h' || !controllerOrientation) {
            return (
                <div className='m-t-1'>
                    <img className="controller-light" style={{ maxWidth: '100%' }} src={Controller} alt="Controller Support"/>
                    <img className="controller-dark" style={{ maxWidth: '100%' }} src={ControllerDark} alt="Controller Support"/>
                </div>
            );
        } else {
            return (
                <div className='m-t-1'>
                    <img className="controller-light" style={{ maxWidth: '100%', width: '24rem' }} src={ControllerVertical} alt="Controller Support"/>
                    <img className="controller-dark" style={{ maxWidth: '100%', width: '24rem' }} src={ControllerVerticalDark} alt="Controller Support"/>
                </div>
            );
        }
    }

    return (
        <>
            <Modal
                name={'modal--user-study-settings-editor'}
                title={
                    <>
                        Global <span>Study Settings</span>
                    </>
                }
                hide={hide}
                small={false}
                content={
                    <>
                        <h3 className='m-b-0-50 m-t-0'>Study Profiles</h3>
                        <p className='m-t-0-50'>Click on one of the study profiles below to edit.</p>
                        <div className='settings-section profiles-section'>
                            {studyOptions}
                        </div>
                        {user.group === 'thieme' && <div className='block--status status--message m-t-0'>The settings below only apply to the Greenberg's Handbook of Neurosurgery flashcard deck.</div>}
                        <h3 className='m-b-0-50'>Scheduling</h3>
                        <p className='m-t-0-50'>Change the default Level Intervals and Multiplier for your studies.<Info style={{stroke: 'var(--button-primary-background)', height: '1rem'}} data-tip="Cards have Levels, with each Level corresponding to an Interval of time until you will see that card again. When you rate cards, their Levels will go up and down. Using the inputs below, you may change the intervals for Levels 1-3. Beyond Level 3, you may adjust the Multiplier, which will set a Level's Interval by multiplying the previous Level's Interval by the Multiplier."/></p>
                        <p className='m-t-0'>Learn more about how the Synaptiq Scheduling Algorithm works.<button className="button button--link m-l-0-50" onClick={toggleHelpModal}>Learn More</button></p>
                        <div className='settings-section levels-section'>
                            <p style={{fontSize: '.75rem', color: 'var(--type-heading-color)', fontWeight: 'bold', marginBottom: '.5rem'}}>Levels</p>
                            <div className="levels">
                                <div className="levels_level">
                                    <span>1</span>
                                    <div className='levels_level_box'>
                                        <input
                                            type="number"
                                            name="0"
                                            value={steps[0] >= 1440 ? Math.round(steps[0] / 1440) : steps[0]}
                                            onChange={stepsChangeHandler}
                                            min="1"
                                            max="9999"
                                        />
                                        <select name="step0Unit" id="step0-unit" value={step0Unit} onChange={unitChangeHandler}>
                                            <option value="m">Mins</option>
                                            <option value="d">Days</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="levels_level">
                                    <span>2</span>
                                    <div className='levels_level_box'>
                                        <input
                                            type="number"
                                            name="1"
                                            value={steps[1] >= 1440 ? Math.round(steps[1] / 1440) : steps[1]}
                                            onChange={stepsChangeHandler}
                                            min="1"
                                            max="9999"
                                        />
                                        <select name="step1Unit" id="step1-unit" value={step1Unit} onChange={unitChangeHandler}>
                                            <option value="m">Mins</option>
                                            <option value="d">Days</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="levels_level">
                                    <span>3</span>
                                    <div className='levels_level_box'>
                                        <input
                                            type="number"
                                            name="2"
                                            value={steps[2] >= 1440 ? Math.round(steps[2] / 1440) : steps[2]}
                                            onChange={stepsChangeHandler}
                                            min="1"
                                            max="10"
                                        />
                                        <div className="levels_level_box_label">Days</div>
                                    </div>
                                </div>
                                <div className="levels_level">
                                    <span>4</span>
                                    <div className='levels_level_box'>
                                        <div className='levels_level_box_value'>{dynamicSteps[0] >= 1440 ? Math.round(dynamicSteps[0] / 1440) : dynamicSteps[0]}</div>
                                        <div className="levels_level_box_label">Days</div>
                                    </div>
                                </div>
                                <div className="levels_level">
                                    <span>5</span>
                                    <div className='levels_level_box'>
                                        <div className='levels_level_box_value'>{dynamicSteps[1] >= 1440 ? Math.round(dynamicSteps[1] / 1440) : dynamicSteps[1]}</div>
                                        <div className="levels_level_box_label">Days</div>
                                    </div>
                                </div>
                                <div className="levels_level">
                                    <span>6</span>
                                    <div className='levels_level_box'>
                                        <div className='levels_level_box_value'>{dynamicSteps[2] >= 1440 ? Math.round(dynamicSteps[2] / 1440) : dynamicSteps[2]}</div>
                                        <div className="levels_level_box_label">Days</div>
                                    </div>
                                </div>
                                <div className="levels_level">
                                    <span>7</span>
                                    <div className='levels_level_box'>
                                        <div className='levels_level_box_value'>{dynamicSteps[3] >= 1440 ? Math.round(dynamicSteps[3] / 1440) : dynamicSteps[3]}</div>
                                        <div className="levels_level_box_label">Days</div>
                                    </div>
                                </div>
                                <div className="levels_level">
                                    <span>8</span>
                                    <div className='levels_level_box'>
                                        <div className='levels_level_box_value'>{dynamicSteps[4] >= 1440 ? Math.round(dynamicSteps[4] / 1440) : dynamicSteps[4]}</div>
                                        <div className="levels_level_box_label">Days</div>
                                    </div>
                                </div>
                                <div className="levels_level">
                                    <span>9+</span>
                                    <div className='levels_level_box'>
                                        <div className='levels_level_box_value'>...</div>
                                    </div>
                                </div>
                            </div>
                            <div className='multiple'>
                                <div className='multiple_wrapper'>
                                    <div className='multiple_wrapper_spanner'></div>
                                    <div className='multiple_wrapper_input'>
                                        <label htmlFor="multiple-input">Multiplier</label>
                                        <input
                                            id="multiple-input"
                                            type='number'
                                            step='0.1'
                                            value={multiple}
                                            onChange={multipleChangeHandler}
                                            min="1.1"
                                            max="4.0"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h3 className='m-b-0-50'>Preferences</h3>
                        <div className='section preferences-section'>
                            <h4 className='m-b-0'>Response Options</h4>
                            <p>Select the number of rating responses.</p>
                            <div className='toggle'>
                                <div className="toggle_option">
                                    <input
                                        type="radio"
                                        name="num-btns"
                                        value="2"
                                        checked={"2" === numResponses.toString()}
                                        onChange={numResponsesChangeHandler}
                                    />
                                    <label>Two</label>
                                </div>
                                <div className="toggle_option">
                                    <input
                                        type="radio"
                                        name="num-btns"
                                        value="3"
                                        checked={"3" === numResponses.toString()}
                                        onChange={numResponsesChangeHandler}
                                    />
                                    <label>Three</label>
                                </div>
                                <div className="toggle_option">
                                    <input
                                        type="radio"
                                        name="num-btns"
                                        value="4"
                                        checked={"4" === numResponses.toString()}
                                        onChange={numResponsesChangeHandler}
                                    />
                                    <label>Four</label>
                                </div>
                            </div>
                            <h4 className='m-t-2 m-b-0'>Card Text Size</h4>
                            <p>Change the text sizing on flashcards.</p>
                            <div className='toggle'>
                                <div className="toggle_option">
                                    <input
                                        type="radio"
                                        name="study-text-size"
                                        value="small"
                                        checked={"small" === fontSizePreference}
                                        onChange={fontSizeChangeHandler}
                                    />
                                    <label>Smaller</label>
                                </div>
                                <div className="toggle_option">
                                    <input
                                        type="radio"
                                        name="study-text-size"
                                        value="default"
                                        checked={"default" === fontSizePreference || !fontSizePreference}
                                        onChange={fontSizeChangeHandler}
                                    />
                                    <label>Regular</label>
                                </div>
                                <div className="toggle_option">
                                    <input
                                        type="radio"
                                        name="study-text-size"
                                        value="large"
                                        checked={"large" === fontSizePreference}
                                        onChange={fontSizeChangeHandler}
                                    />
                                    <label>Larger</label>
                                </div>
                            </div>
                        </div>
                        <h3 className='m-b-0-50'>Controller Support</h3>
                        <div className='section controller-section'>
                            <p>Synaptiq currently supports the 8BitDo controller, but will expand to more controllers and customizability soon!</p>
                            <div className='toggle'>
                                <div className="toggle_option">
                                    <input
                                        type="radio"
                                        name="controller-orientation"
                                        value="h"
                                        checked={"h" === controllerOrientation || !controllerOrientation}
                                        onChange={controllerOrientationHandler}
                                    />
                                    <label>Horizontal Layout</label>
                                </div>
                                <div className="toggle_option">
                                    <input
                                        type="radio"
                                        name="controller-orientation"
                                        value="v"
                                        checked={"v" === controllerOrientation}
                                        onChange={controllerOrientationHandler}
                                    />
                                    <label>Vertical Layout</label>
                                </div>
                            </div>
                            {renderControllerDiagram()}
                        </div>
                        <ReactTooltip/>
                    </>
                }
                actions={
                    <div>
                        <button className="button button--link m-r-2" onClick={toggleConfirmation}>Reset to Defaults</button>
                        <button className="button" onClick={handleSubmit}>Save</button>
                    </div>
                }
            />
            {showProfileEditor && <StudyProfileEditor
                studyProfile={studyProfiles[targetProfileId]}
                hide={hideStudyProfileEditor}
            />}
            {confirmation && <Modal
                name={'modal--confirm-reset-defaults'}
                title="Reset Global Study Settings?"
                small={true}
                hide={hideConfirmation}
                content={
                    <p>Are you sure you want to reset the Global Study Settings back to defaults?</p>
                }
                actions={
                    <button className="button button--error" onClick={resetDefaults}>Reset</button>
                }
            />}
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user,
    userProperties: state.userProperties,
    userStudySettings: state.userStudySettings,
    studyProfilesArr: studyProfilesArray(state),
    studyProfiles: state.studyProfiles,
});
const mapDispatchToProps = (dispatch) => ({
    updateUserStudySettings: (userStudySettings) => dispatch(updateUserStudySettings(userStudySettings)),
    updateUserProperties: properties => dispatch(updateUserProperties(properties)),
});
export default connect(mapStateToProps, mapDispatchToProps)(UserStudySettingsEditor);
