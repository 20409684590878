import {
	RECEIVE_ACTIVE_STUDY_SESSION,
	CLEAR_ACTIVE_STUDY_SESSION,
} from '../../actions/allActions';

const activeStudySessionReducer = (state = {}, action) => {
	Object.freeze(state);
	switch (action.type) {
		case RECEIVE_ACTIVE_STUDY_SESSION:
			if (!_.isEmpty(action.payload)) {
				return action.payload;
			}
		case CLEAR_ACTIVE_STUDY_SESSION:
			return {};
		default:
			return state;
	}
}

export default activeStudySessionReducer;
