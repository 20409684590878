import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import BG_1 from '../../../assets/images/backgrounds/ian-simmonds-kRLXoi3Dtqs-unsplash.jpg';
import BG_2 from '../../../assets/images/backgrounds/k-soma-Q-2EigkW_z0-unsplash.jpg';
import BG_3 from '../../../assets/images/backgrounds/k-soma-plXL7vYXfyA-unsplash.jpg';
import BG_4 from '../../../assets/images/backgrounds/bob-brewer-3yN9g6xAXkg-unsplash.jpg';
import BG_5 from '../../../assets/images/backgrounds/thilak-mohan-NRX9Zy-k3nU-unsplash.jpg';
import BG_6 from '../../../assets/images/backgrounds/bernd-dittrich-9NQmjbE6Y7k-unsplash.jpg';
import BG_7 from '../../../assets/images/backgrounds/dennis-mita-6pOB4LkAFs0-unsplash.jpg';
import BG_8 from '../../../assets/images/backgrounds/marek-piwnicki-sIaIHalCnsM-unsplash.jpg';
import BG_9 from '../../../assets/images/backgrounds/vitalik-vynarchyk-TUzsO59UFpo-unsplash.jpg';
import BG_10 from '../../../assets/images/backgrounds/hugo-rouquette-u8oLHKcGLdQ-unsplash.jpg';
import BG_11 from '../../../assets/images/backgrounds/thilak-mohan-USK5WvXLrX4-unsplash.jpg';
import BG_12 from '../../../assets/images/backgrounds/francesco-ungaro-wIaARgeF2rE-unsplash.jpg';
import BG_13 from '../../../assets/images/backgrounds/marek-piwnicki-G3msBI-g1sI-unsplash.jpg';
import BG_14 from '../../../assets/images/backgrounds/thilak-mohan-3FprkLEJ2gQ-unsplash.jpg';
import BG_15 from '../../../assets/images/backgrounds/hector-john-periquin-aGrPtiRXX9s-unsplash.jpg';
import BG_16 from '../../../assets/images/backgrounds/mr-wang-Y-zq8A1G_dQ-unsplash.jpg';
import BG_17 from '../../../assets/images/backgrounds/alex-kulikov-vBpefozS0As-unsplash.jpg';
import BG_18 from '../../../assets/images/backgrounds/willian-justen-de-vasconcellos-ODZiEQDbjbs-unsplash.jpg';
import BG_19 from '../../../assets/images/backgrounds/kym-mackinnon-rG-1gfiQN0k-unsplash.jpg';
import BG_20 from '../../../assets/images/backgrounds/thilak-mohan-_LfLA5Nqfko-unsplash.jpg';
import BG_21 from '../../../assets/images/backgrounds/alberto-biondi-pgZIObXHpmE-unsplash.jpg';
import BG_22 from '../../../assets/images/backgrounds/nunzio-guerrera-8XKIbXCuqq8-unsplash.jpg';
import BG_23 from '../../../assets/images/backgrounds/bob-brewer-mov3YIkS4Lg-unsplash.jpg';
import BG_24 from '../../../assets/images/backgrounds/kabita-darlami-f_dFplZ6IaQ-unsplash.jpg';
import BG_25 from '../../../assets/images/backgrounds/martin-bennie-WNUzH97lDc0-unsplash.jpg';
import BG_26 from '../../../assets/images/backgrounds/john-towner-JgOeRuGD_Y4-unsplash.jpg';
import BG_27 from '../../../assets/images/backgrounds/mohamed-nohassi-jivFqUh9W1g-unsplash.jpg';
import BG_28 from '../../../assets/images/backgrounds/enq-1998-AKs-_E7bpUg-unsplash.jpg';
import BG_29 from '../../../assets/images/backgrounds/arthur-hamilton-9Y1iOQ4rvtw-unsplash.jpg';
import BG_30 from '../../../assets/images/backgrounds/nunzio-guerrera-9HnW4qYFE0o-unsplash.jpg';
import BG_31 from '../../../assets/images/backgrounds/marek-piwnicki-YqelP6p-BSM-unsplash.jpg';

function DynamicBackground({ userProperties }) {
    const [loaded, setLoaded] = useState(false);

    const BG_Images = [
        BG_1,
        BG_2,
        BG_3,
        BG_4,
        BG_5,
        BG_6,
        BG_7,
        BG_8,
        BG_9,
        BG_10,
        BG_11,
        BG_12,
        BG_13,
        BG_14,
        BG_15,
        BG_16,
        BG_17,
        BG_18,
        BG_19,
        BG_20,
        BG_21,
        BG_22,
        BG_23,
        BG_24,
        BG_25,
        BG_26,
        BG_27,
        BG_28,
        BG_29,
        BG_30,
        BG_31
    ];

    const getCurrentDay = () => {
        const currentDate = new Date();
        return currentDate.getDate();
    }

    useEffect(() => {
        const img = new Image();
        if (userProperties?.demoView) {
            img.src = BG_1;
        } else {
            img.src = BG_Images[getCurrentDay() - 1] ? BG_Images[getCurrentDay() - 1] : BG_1;
        }
        img.onload = () => setLoaded(true);
    }, []);

    return (
        <div className='dynamic-background'>
            <img style={{
                opacity: loaded ? 1 : 0,
                transition: 'opacity 1s'
            }} src={userProperties?.demoView ? BG_1 : (BG_Images[getCurrentDay() - 1] ? BG_Images[getCurrentDay() - 1] : BG_1)} alt=""/>
            <div className='fade-bubble'></div>
            <div className='fade-overlay'></div>
            <div className='glass-overlay'></div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    userProperties: state.userProperties
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(DynamicBackground);
