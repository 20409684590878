import React, { Component } from 'react';

function ProgressBar({ type, percentMastery, referralCount, classes }) {
    const barWidth = {
        width: `${percentMastery}%`
    };

    let display;
    if (type !== 'generic') {
        if (!referralCount) {
            display = (
                <>
                    <span className="component--progress-bar__percentage__value">{percentMastery}%</span>
                    <span className="component--progress-bar__percentage__label"> Mastery</span>
                </>
            );
        } else {
            display = <span className="component--progress-bar__percentage__value">{referralCount} of 2</span>
        }
    }

    return (
        <div className={`component component--progress-bar ${classes ? classes : ''}`}>
            <div className="component--progress-bar__container">
                <div style={barWidth} className={percentMastery > 0 ? 'component--progress-bar__container__bar non-zero' : 'component--progress-bar__container__bar'}></div>
            </div>
            {display && <div className="component--progress-bar__percentage">
                {display}
            </div>}
        </div>
    )
};

export default ProgressBar;
