import React, { useState } from 'react';
import { connect } from 'react-redux';
import Modal from './Modal';
import { Check, Send, Clipboard } from 'react-feather';
import isValidEmail from '../../util/isValidEmail';
import {
    referByEmail
} from '../../actions/allActions';

function ReferModal({ hide, user, referByEmail }) {
    const [referralLinkCopied, setReferralLinkCopied] = useState(false);
    const [referralEmailSent, setReferralEmailSent] = useState(false);
    const [referralEmail, setReferralEmail] = useState('');

    const handleCopyButtonClick = () => {
        setReferralLinkCopied(true);
        setReferralEmailSent(false);
        navigator.clipboard.writeText(`https://www.synaptiq.app/users/sign_up?r=${user.referral_token}`);
    }

    const handleSendEmail = () => {
        ahoy.track('Referral Sent', { referrer_email: user.email, referred_email: referralEmail });
        referByEmail(referralEmail);
        setReferralEmail("");
        setReferralEmailSent(true);
        setReferralLinkCopied(false);
    }

    const handleReferralEmailChange = (e) => {
        setReferralEmail(e.target.value);
    }

    return (
        <Modal
            name={'modal--refer'}
            title={'Refer a Friend'}
            small={true}
            hide={hide}
            hideCancel={true}
            content={
                <>
                    <div className={`before-copy ${referralLinkCopied || referralEmailSent ? 'hide' : ''}`}>
                        <p className='m-t-0'>Receive a <span className='sb'>$10 Starbucks gift card</span> for every referral that subscribes!</p>
                        <p>Your referral will also receive a promotion code for <strong>$10 off</strong> their subscription.</p>
                    </div>
                    <div className={`after-copy ${referralLinkCopied ? 'show' : ''}`}>
                        <div className='check'><Check/></div>
                        <h3>Link Copied!</h3>
                    </div>
                    <div className={`after-copy ${referralEmailSent ? 'show' : ''}`}>
                        <div className='check'><Check/></div>
                        <h3>Referral Submitted!</h3>
                    </div>
                    <form>
                        <div className='email-input'>
                            <input type="text" value={referralEmail} onChange={(e) => handleReferralEmailChange(e)}/>
                        </div>
                        <button
                            className='button button--send'
                            onClick={handleSendEmail}
                            disabled={referralEmail === "" || !isValidEmail(referralEmail)}
                        >
                            <Send/>Send Referral
                        </button>
                    </form>
                </>
            }
            actions={
                <>
                    <button className='button' onClick={handleCopyButtonClick}><Clipboard/>Copy Referral Link</button>
                    <button className='button button--secondary' onClick={hide}>Done</button>
                </>
            }
    />
    );
}

const mapStateToProps = (state) => ({
    user: state.user
});
const mapDispatchToProps = (dispatch) => ({
    referByEmail: (email) => dispatch(referByEmail(email)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ReferModal);
