const COHORT_GROUPS = ['stanford_w23']

export const isCohortUser = user => {
    return !!user && COHORT_GROUPS.includes(user?.group);
}

export const isPaywalled = user => {
    return isCohortUser(user) && !user?.on_trial_or_subscribed;
}

export const isSubbed = user => {
    return isCohortUser(user) && user?.on_trial_or_subscribed;
}

export const isThiemeDeck = (deck) => {
    return deck.uuid?.includes('gberg') || deck.uuid?.includes('plastics');
}

export const getProductId = (productName) => {
    const productMap = (process.env.NODE_ENV === 'development') ?
        {
            synaptiq_pro: 'prod_O40elkNkPNyasW',
            gberg: 'prod_OxSlNlPXHXUeu2',
            plastics: 'prod_OhlFcm0BlLYtr7',
            top_score: 'prod_P4f1TISBBVRqvY',
            ultrasound: 'prod_PBmzZHN4frXjRC',
            neuroradiology: 'prod_PBnMlHpm9J4W6v',
            gastrointestinal: 'prod_PBnNtn6EEXkSDS',
        } 
        : {
            synaptiq_pro: 'prod_O5rmqCz801iiKe',
            gberg: 'prod_OxSHGoGXCHntFM',
            plastics: 'prod_OhlIT73CRLXxSb',
            top_score: 'prod_P4ev12B5qYEs2s',
            ultrasound: 'prod_PBmyPEvyZzEpUv',
            neuroradiology: 'prod_PBnNPXnhK7dRlG',
            gastrointestinal: 'prod_PBnOwC68xK61eC',
        }
    return productMap[productName];
}

export const getProductName = (productId) => {
    const productMap = {
        // dev
        prod_O40elkNkPNyasW: 'synaptiq_pro',
        prod_OxSlNlPXHXUeu2: 'gberg',
        prod_OhlFcm0BlLYtr7: 'plastics',
        prod_P4f1TISBBVRqvY: 'top_score',
        prod_PBmzZHN4frXjRC: 'ultrasound',
        prod_PBnMlHpm9J4W6v: 'neuroradiology',
        prod_PBnNtn6EEXkSDS: 'gastrointestinal',
        // prod
        prod_O5rmqCz801iiKe: 'synaptiq_pro',
        prod_OxSHGoGXCHntFM: 'gberg',
        prod_OhlIT73CRLXxSb: 'plastics',
        prod_P4ev12B5qYEs2s: 'top_score',
        prod_PBmyPEvyZzEpUv: 'ultrasound',
        prod_PBnNPXnhK7dRlG: 'neuroradiology',
        prod_PBnOwC68xK61eC: 'gastrointestinal',
    }
    return productMap[productId];
}

export const userHasThiemeDeck = (decks) => {
    for (const deck of Object.values(decks)) {
        // greenberg
        if (deck.uuid === 'gberg-2d1b50af9352ffd0') {
            return true;
            // plastics
        } else if (deck.uuid === 'plastics-66714a7a70b4') {
            return true;
            // neuroradiology
        } else if (deck.uuid === 'b4ef1abf6d') {
            return true;
            // gastrointestinal
        } else if (deck.uuid === 'e90b8840b5') {
            return true;
            // top score
        } else if (deck.uuid === '0868b90e4a') {
            return true;
            // ultrasound
        } else if (deck.uuid === '000463d31f') {
            return true;
        }
    }
    return false;
}
