import React, { Component } from 'react';
import SingleDeckSelector from '../builder/SingleDeckSelector';
import { connect } from 'react-redux';
import { mergeDecks } from '../../actions/allActions';
import Modal from '../misc/Modal';

class DeckMerger extends Component {
    constructor(props) {
        super(props);

        const parentOptions = Object.values(this.props.decks)
            .filter((deck) => {
                return (deck.id !== this.props.deckId) && (deck.access === 'owner')
            }).map(deck => ({ value: deck.id, label: deck.name }));
        const deckName = !!this.props.deckId ? this.props.deck.name : '';
        
        this.state = {
            deckName,
            parentOptions,
            targetDeckId: null,
            retainOriginal: false,
            isConfirmationModalOpen: false,
            isFocusTrapPaused: false
        };
    }

    componentDidMount() {
        document.body.classList.add("overflow-hidden");
    }

    componentWillUnmount() {
        document.body.classList.remove("overflow-hidden");
    }

    handleChange(e) {
        this.setState({ deckName: e.target.value });
    }

    setSelectedDeck = (selectedDeckId) => {
        this.setState({ targetDeckId: selectedDeckId });
    }

    handleSubmit() {
        if (!!this.props.deckId && !!this.state.targetDeckId) {
            this.props.mergeDecks(this.props.deckId, this.state.targetDeckId, this.state.retainOriginal);
            this.props.hideAndMergeDeck();
        }
    }

    showConfirmationModal = () => {
        this.setState({ isConfirmationModalOpen: true });
    }

    handleCheckboxChange = (e) => {
        const retainOriginal = e.target.checked;
        this.setState({ retainOriginal })
    }

    suspendFocusTrap = () => {
        this.setState({isFocusTrapPaused: true});
    }
    enableFocusTrap = () => {
        this.setState({isFocusTrapPaused: false});
    }

    renderConfirmationTitle = () => {
        const targetName = this.props.decks[this.state.targetDeckId]?.name;
        return (
            <>
                Are you sure you want to merge <span className='confirmation-deck-name'>{this.props.deck?.name}</span> into <span className='confirmation-deck-name'>{targetName}</span>?
            </>
        );
    }

    renderConfirmationContent = () => {
        return (
            <p className="m-y-0">This action is permanent and cannot be undone.</p>
        );
    }

    renderConfirmationActions = () => {
        return (
            <>
                <button className="button button--secondary" onClick={this.props.hideAndCancelMerge}>Cancel</button>
                <button className="button button--error" onClick={() => this.handleSubmit()}>Merge</button>
            </>
        );
    }

    renderMergeContent = () => {
        return (
            <>
                <p className='m-t-0'>
                    <span>Merge </span>
                    <span className='highlight'>{this.props.deck?.name} </span>
                    <span className='m-r-0-50'>deck into:</span>
                </p>
                <SingleDeckSelector
                    type={'deck'}
                    options={this.state.parentOptions}
                    defaultParent={undefined}
                    setSelectedDecks={this.setSelectedDeck}
                    enableFocusTrap={this.enableFocusTrap}
                    suspendFocusTrap={this.suspendFocusTrap}
                />
                {/* <input type="checkbox" id="retain-original" name="retain-original" value={this.state.retainOriginal} onChange={this.handleCheckboxChange} />
                <label for="retain-original">Retain Original Deck</label> */}
            </>
        )
    }

    renderMergeActions = () => {
        return (
            <>
                <button className='button button--secondary' onClick={this.props.hideAndCancelMerge}>Cancel</button>
                <button className={`button ${this.state.targetDeckId ? '' : 'is-disabled'}`} onClick={this.showConfirmationModal}>Merge</button>
            </>
        )
    }

    render() {
        return (
            <Modal
                name={"modal--merge-deck"}
                hide={this.props.hideAndCancelMerge}
                title={this.state.isConfirmationModalOpen ? this.renderConfirmationTitle() : "Merge Deck"}
                isFocusTrapPaused={this.state.isFocusTrapPaused}
                content={this.state.isConfirmationModalOpen ? this.renderConfirmationContent() : this.renderMergeContent()}
                actions={this.state.isConfirmationModalOpen ? this.renderConfirmationActions() : this.renderMergeActions()}
                hideCancel={true}
            />
        )
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({ mergeDecks: (selected_deck_id, target_deck_id, retain_original) => dispatch(mergeDecks(selected_deck_id, target_deck_id, retain_original)) });

export default connect(mapStateToProps, mapDispatchToProps)(DeckMerger);
