import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import RedeemCodeModal from "../home/RedeemCodeModal";
import { connect } from 'react-redux';
import { User, HelpCircle, Settings, Eye, Unlock, Key } from 'react-feather';
import openIntercomUtil from '../../util/openIntercomUtil';

class UserMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            initializedDebounce: false, 
            debounced: null,
            showGenericUnlocker: false,
        }
    }

    componentDidMount() {
        this.initializedDebounce();
        document.addEventListener('mouseup', this.handleMouseUp);
    }

    componentWillUnmount() {
        this.removeKeyBinds();
        document.removeEventListener('mouseup', this.handleMouseUp);
    }

    handleMouseUp = (e) => {
        const container = document.getElementById('user-menu');
        if (container && !container.contains(e.target)) {
            this.setState({ isOpen: false });
        }
    }

    // Set Home Page Keybinds
    initializedDebounce = () => {
        if (!this.state.initializedDebounce) {
            const debounced = _.debounce(this.keyBinds, 50, { 'leading': true, 'trailing': false })
            window.addEventListener('keydown', debounced)
            this.setState({ initializedDebounce: true, debounced })
        }
    }
    removeKeyBinds = () => {
        if (this.state.initializedDebounce) {
            window.removeEventListener('keydown', this.state.debounced);
        }
    }
    keyBinds = (e) => {
        // If in an input field, return.
        if (this !== e.target && (/textarea|select|input/i.test( e.target.nodeName ) || e.target.type === "text" || e.target.isContentEditable)) { return; }  
        if (e.key === "KeyT" || e.which === 84 || e.code === "KeyT") {
            this.toggleTheme();
        }
    }
    
    openHelpMenu = () => {
        document.getElementById('user-menu')?.classList.remove("is-open");
        openIntercomUtil();
    }

    toggleMenu = () => {
        this.setState({isOpen: !this.state.isOpen});
    }

    toggleTheme = () => {
        ahoy.track("Toggle Theme");
        if (this.props.theme == 'theme-dark-clean') {
            if (this.props.user.group !== 'thieme') {
                this.props.setTheme("theme-glass"); 
            } else {
                this.props.setTheme('theme-light-clean');
            }
        } else if (this.props.theme == 'theme-glass') {
            this.props.setTheme('theme-light-clean');
        } else {
            this.props.setTheme("theme-dark-clean"); 
        }
    }

    handleSettingsClick = () => {
        this.props.history.push('/settings');
        this.setState({isOpen: false});
    }

    handleImpersonate = () => {
        this.setState({ isOpen: !this.state.isOpen });
        this.props.history.push('/impersonate');
    }

    showGenericUnlocker = () => {
        this.setState({ showGenericUnlocker: true });
    }

    hideGenericUnlocker = () => {
        this.setState({ showGenericUnlocker: false });
    }

    handleClickUnlockPro = () => {
        this.setState({isOpen: false});
        this.props.history.push('/checkout')
    }

    render() {
        const iconUser = <User/>;
        const iconSettings = <Settings/>
        const iconHelp = <HelpCircle/>;
        const iconEye = <Eye/>;
        return (
            <>
                <div id="user-menu" className={`menu menu--user ${this.state.isOpen ? 'is-open' : ''}`}>
                    <Link className={`menu__button button button--user ${this.props.user.profile_picture ? 'pic' : '' }`} to={'/settings'}>{this.props.user.profile_picture ? <img src={this.props.user.profile_picture} alt="Profile Picture"/> : iconUser}</Link>
                </div>
                {this.state.showGenericUnlocker && (
                    <RedeemCodeModal
                        hide={this.hideGenericUnlocker}
                    />
                )}
            </>

        )
    }
}

const mapStateToProps = state => ({ user: state.user });
export default connect(mapStateToProps, null)(UserMenu);
