import React, { Component } from 'react';
import UserMenuMobileContainer from '../user/UserMenuMobileContainer';
import { Link } from 'react-router-dom';
import ProgressBar from '../misc/ProgressBar';
import HeaderIcon from '../../../assets/images/icons/header-icon.svg';
import { Eye, Home, Unlock } from 'react-feather';
import GlobalSearch from './GlobalSearch';

export default class Navbar extends Component {
    constructor(props) {
        super(props);
    }

    logout() {
        $.ajax({
            method: 'DELETE',
            url: 'users/sign_out'
        }).then(() => {
            this.props.clearActiveStudySession();
            window.location.pathname = '/users/sign_in'
        });
    }

    handleStopImpersonating() {
        this.props.stopImpersonatingUser(this.props.user.id).then(() => {
            window.location.reload();
        });
    }

    checkTrialDaysRemaining = () => {
        if (this.props.user?.subscription_status === 'trialing' && this.props.user?.subscription_end_date) {
            const now = moment();
            const subEndDate = moment(this.props.user?.subscription_end_date);
            const days = Math.ceil(subEndDate.diff(now, 'hours') / 24);
            return days;
        }
    }

    handleImpersonate = () => {
        this.props.history.push('/impersonate');
    }

    render() {
        let middleContent, leftContent, rightContent;
        if (this.props.location.pathname.includes('/deck')) {
            leftContent = (<Link className="button button--secondary" to={`/`}><Home/><span>Home</span></Link>);
        }
        rightContent = (
            <>
                {this.props.user.impersonating &&
                    <button className="button button--error" onClick={() => this.handleStopImpersonating()}>Exit</button>
                }
                {this.props.user?.admin && !this.props.userProperties?.demoView && !this.props.user?.impersonating &&
                    <button className="button button--icon-only button--impersonate" onClick={() => this.handleImpersonate()}><Eye/></button>
                }
                {((this.props.user.subscription_status === 'trialing') && this.props.user.group !== 'thieme') &&
                    <Link className={`button button--pro ${this.props.location.pathname.includes('/checkout') ? 'visibility-hidden' : ''}`} to={'/checkout'} data-tip="You're on a free trial. Unlock Pro to save your progress and retain access to Pro features.">
                        <div className='title'><span>Free</span>Trial</div>
                        <div className='bar'>     
                            <div className='bar-wrapper'>
                                <div className='bar-wrapper_bar' style={{width: ((this.checkTrialDaysRemaining() / 5) * 100) + '%'}}></div>
                            </div>
                            <div className='days-left'>{this.checkTrialDaysRemaining() || '0'} {this.checkTrialDaysRemaining() === 1 ? 'Day' : 'Days'} Left</div>
                        </div>
                        <Unlock/>
                    </Link>
                }
                <UserMenuMobileContainer />
            </>
        );

        // Study Session Display
        if (!_.isEmpty(this.props.deck)) {
            middleContent = (
                <div className='header__middle'>
                    <h1 className="header__middle__deck-title">{this.props.deck.name}</h1>
                    <ProgressBar percentMastery={this.props.percentMastery} />
                </div>
            );
            rightContent = (
                <div className='counters'>
                    <div className={`counter new ${this.props.activeStudyProfile.new > 99 ? 'small' : ''}`}>{this.props.activeStudyProfile.new}</div>
                    <div className={`counter due ${this.props.activeStudyProfile.due > 99 ? 'small' : ''}`}>{this.props.activeStudyProfile.due}</div>
                </div>
            )
        } else {
            middleContent = (
                <GlobalSearch/>
            );
        }

        // Mobile Header
        let mobileHeaderContentRight, mobileHeaderContentLeft;
        if (this.props.location.pathname.includes('/deck')) {
            mobileHeaderContentLeft = (
                <Link to={`/`} className='button button--secondary button--study-home'><Home/><span>Home</span></Link>
            );
            mobileHeaderContentRight = (
                <div className="header--mobile__right">
                    <div className='counters'>
                        <div className={`counter new ${this.props.activeStudyProfile.new > 99 ? 'small' : ''}`}>{this.props.activeStudyProfile.new}</div>
                        <div className={`counter due ${this.props.activeStudyProfile.due > 99 ? 'small' : ''}`}>{this.props.activeStudyProfile.due}</div>
                    </div>
                </div>
            );
        } else {
            mobileHeaderContentLeft = (
                <Link to={`/`}><img src={HeaderIcon} alt="Synaptiq Icon" /></Link>
            );
            mobileHeaderContentRight = (
                <div className="header--mobile__right">
                    <GlobalSearch/>
                </div>
            );
        }

        return (
            <React.Fragment>
                <nav className="header">
                    <div className="header__left">
                        {leftContent}
                    </div>
                    {middleContent}
                    <div className="header__right">
                        {rightContent}
                    </div>
                </nav>
                <nav className="header--mobile">
                    {mobileHeaderContentLeft}
                    {mobileHeaderContentRight}
                </nav>
            </React.Fragment>
        )
    }
}
