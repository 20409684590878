import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    receiveSelectedDeck,
    removePendingImport,
    toggleDeckCollapsed,
    fetchBillingPortal,
} from '../../actions/allActions';
import DeckIndex from './DeckIndex';

const mapStateToProps = state => {
    return {
        user: state.user,
        userProperties: state.userProperties,
        decksTree: state.decksTree,
        progress: state.progress,
        progressLoading: _.defaultTo(state.loading.progressLoading, false),
        pendingImports: state.pendingImports,
        loading: state.loading,
        subscriptions: state.subscriptions,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        receiveSelectedDeck: (deckId) => dispatch(receiveSelectedDeck(deckId)),
        removePendingImport: (job_id) => dispatch(removePendingImport(job_id)),
        toggleDeckCollapsed: (deckId, shouldLoad, isExpanding) => dispatch(toggleDeckCollapsed(deckId, shouldLoad, isExpanding)),
        fetchBillingPortal: () => dispatch(fetchBillingPortal()),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeckIndex));
