import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    fetchCheckoutSessions,
} from '../../actions/allActions';
import { getProductName } from '../../util/cohortUtil';
import { ArrowRight, ArrowUp, Check, ChevronDown, ChevronUp, ExternalLink } from 'react-feather';
import ReactTooltip from 'react-tooltip';
import smoothScrollToElement from '../../util/smoothScroll';
import Target from '../../../assets/images/graphics/Target.svg';
import Sync from '../../../assets/images/graphics/Sync.svg';
import JanisPlasticsProductImage from '../../../assets/images/thieme/Janis_Plastic-Surgery-Flashcards_1024x1536px.jpg';
import TopScoreProductImage from '../../../assets/images/thieme/Radiology_Flashcards_1024x1536px_k2.jpg';
import GastrointestimalProductImage from '../../../assets/images/thieme/Radiology-Flashcards_1024x1536px_1.jpg';
import UltrasoundProductImage from '../../../assets/images/thieme/Radiology-Flashcards_1024x1536px_2.jpg';
import NeuroradiologyProductImage from '../../../assets/images/thieme/Radiology-Flashcards_1024x1536px_3.jpg';

function Checkout({ user, checkoutSessions, fetchCheckoutSessions, product_id }) {
    const [mobileStarterIsOpen, setMobileStarterIsOpen] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState('monthly');

    useEffect(async () => {
        fetchCheckoutSessions(product_id);
        window.scrollTo(0, 0);
    }, []);

    const showMobileStarter = () => {
        setMobileStarterIsOpen(true);
    }

    const hideMobileStarter = () => {
        setMobileStarterIsOpen(false);
    }

    const handlePlanClick = (plan) => {
        setSelectedPlan(plan);
    }

    const scrollToTop = () => {
        let wWidth = window.innerWidth;
        if (wWidth >= 900) {
            smoothScrollToElement("section-checkout", '.layout_container');
        } else {
            window.scrollTo(0, 0);
        }
    }

    const scrollToCompare = () => {
        let wWidth = window.innerWidth;
        if (wWidth >= 900) {
            smoothScrollToElement("section-compare", '.layout_container');
        } else {
            let comp = document.getElementById('section-compare');
            if (comp) {
                var offset = comp.getBoundingClientRect().top + window.scrollY;
                window.scrollTo({ top: offset, behavior: 'smooth' });
            }
        }
    }

    const renderCheckoutContent = () => {
        const productName = getProductName(product_id);
        if (productName === 'synaptiq_pro') {
            return (
                <>
                    <div className="layout--checkout_container_header">
                        <h1>Level up with Synaptiq Pro</h1>
                        <div>
                            <button onClick={scrollToCompare} className='button button--secondary button--icon-right m-b-1-50'>Compare Synaptiq vs Anki<ArrowRight /></button>
                        </div>
                    </div>
                    <div className="layout--checkout_container_body">
                        <div className='section-checkout' id="section-checkout">
                            <div className='fade'></div>
                            <div className={`tile tile-preview ${mobileStarterIsOpen ? 'is-open' : ''}`}>
                                <div className='top'>
                                    <h2>Starter</h2>
                                    <p className='sub-head'>All the basics</p>
                                    <div className='pricing pricing-free'>
                                        <h3>
                                            <span className='pricing_price'>Free</span>
                                        </h3>
                                        <p className='sub-price'>Forever</p>
                                    </div>
                                </div>
                                <div className='button-wrapper'>
                                    <button className="button button--checkout" disabled>Default Plan</button>
                                </div>
                                <button className='button button--icon-circle' onClick={showMobileStarter}><ChevronDown /></button>
                            </div>
                            <div className={`tile tile-starter ${mobileStarterIsOpen ? 'is-open' : ''}`}>
                                <div className='top'>
                                    <h2>Starter</h2>
                                    <p className='sub-head'>All the basics</p>
                                    <div className='pricing pricing-free'>
                                        <h3>
                                            <span className='pricing_price'>Free</span>
                                        </h3>
                                        <p className='sub-price'>Forever</p>
                                    </div>
                                    <ul>
                                        <li>Starter features:</li>
                                        <li><span><Check /></span>USMLE Step 1 deck</li>
                                        <li><span><Check /></span>Customizable spaced repetition</li>
                                        <li><span><Check /></span>Card builder & 100MB Anki import</li>
                                        <li><span><Check /></span>Analytics & due card projections</li>
                                        <li><span><Check /></span>Deck collaboration & syncing</li>
                                    </ul>
                                </div>
                                <div className='button-wrapper'>
                                    <button className="button button--checkout" disabled>Default Plan</button>
                                    <p>Free Forever</p>
                                </div>
                                <button className='button button--icon-circle' onClick={hideMobileStarter}><ChevronUp /></button>
                            </div>
                            <div className='tile tile--middle'>
                                {/* <div className='savings-badge'><span>45% savings</span></div> */}
                                <div className='top'>
                                    {user.subscription_status === 'trialing' && <div className='trialing'>Trialing</div>}
                                    <h2>Pro</h2>
                                    <p className='sub-head'>An elite tool for elite learners</p>
                                    <div className='sub-options'>
                                        <button className={`form-control ${selectedPlan === 'monthly' ? 'checked' : ''}`} onClick={() => handlePlanClick('monthly')}>
                                            <label htmlFor='annual'>
                                                <p>Monthly</p>
                                                <h3 className='pricing'>
                                                    <span className='pricing_price'>$38</span>
                                                    <span className='pricing_label'>/&nbsp;&nbsp;Month</span>
                                                </h3>
                                            </label>
                                        </button>
                                        <button className={`form-control ${selectedPlan === 'annual' ? 'checked' : ''}`} onClick={() => handlePlanClick('annual')}>
                                            <label htmlFor='annual'>
                                                <p>Annual</p>
                                                <h3 className='pricing'>
                                                    <span className='pricing_price'>$28</span>
                                                    <span className='pricing_label'>/&nbsp;&nbsp;Month</span>
                                                </h3>
                                                <p className='sub-price'>$336 billed annually</p>
                                            </label>
                                        </button>
                                    </div>
                                    <ul>
                                        <li>Everything in Starter, plus:</li>
                                        <li><span><Check /></span>School-specific decks</li>
                                        <li><span><Check /></span>Unlimited Anki import</li>
                                        <li><span><Check /></span>Priority support from physicians</li>
                                    </ul>
                                </div>
                                <div className='button-wrapper'>
                                    <a className="button button--checkout" href={selectedPlan === 'annual' ? checkoutSessions?.[1]?.url : checkoutSessions?.[0]?.url}>Get Started</a>
                                    <p>Cancel Anytime</p>
                                </div>
                            </div>
                            <div className='fade fade-2'></div>
                        </div>
                        <div className='section-compare' id="section-compare">
                            <h2><span className='syn'>Synaptiq</span>vs<span className='anki'>Anki</span></h2>
                            <p><strong>Question:</strong> "Anki is free, why should I use Synaptiq?"</p>
                            <p><strong>Answer:</strong> Synaptiq is a modern platform <strong>designed for studying medicine.</strong> Here are a few of our key differentiators to other tools like Anki:</p>
                            <div className='diffs'>
                                <div className=''>
                                    <img src={Target} alt="Target" />
                                    <h3>Curriculum-Specific Content</h3>
                                    <p>We work with your classmates to produce relevant, accurate flashcard decks <strong>based on your lectures</strong>. Our decks are high-yield and are updated as we receive learner feedback.</p>
                                </div>
                                <div className=''>
                                    <img src={Sync} alt="Sync" />
                                    <h3>Card Syncing</h3>
                                    <p>As you study your curriculum-specific decks, we automatically sync your progress on cards contained in Step 1, Step 2, and Shelf Exam decks, allowing you to <strong>start preparing for Boards years in advance.</strong></p>
                                </div>
                            </div>
                            <div className='button-wrapper'>
                                <button onClick={scrollToTop} className='button button--icon-right'>Back to Checkout <ArrowUp /></button>
                            </div>
                        </div>
                    </div>
                </>
            );
        } else if (productName === 'plastics') {
            return (
                <div className='thieme-container'>
                    <div className=''>
                        <h1>Essentials of Plastic Surgery | Q&A Companion, Second Edition</h1>
                        <div className='price-wrap'>
                            <p className='price'><span>$75</span>/ Year</p>
                            <a className="button button--checkout" href={selectedPlan === 'annual' ? checkoutSessions?.[1]?.url : checkoutSessions?.[0]?.url}>Unlock Now</a>
                        </div>
                        <p><strong>Studying plastic surgery just got a whole lot easier!</strong></p>
                        <p>Powered by Synaptiq, learn from a 1600+ physician-curated flashcard deck, with questions, answers and explanations based on Janis and Jones' Essentials of Plastic Surgery Q&A Companion. Questions cover all topics, from aesthetic to reconstructive, that residents need to know. The flashcards are delivered in an intuitive study platform that leverages spaced-repetition technology to help you learn faster and retain more than ever.</p>
                        <p><strong>Key Features</strong></p>
                        <ul>
                            <li>1600+ physician-curated plastic surgery flashcards based on the acclaimed Essentials of Plastic Surgery</li>
                            <li>Robust statistics let you visualize and track progress to build strong study habits</li>
                            <li>Optimize your learning schedule with smart recommendations and customizations</li>
                            <li>Curate a one-stop flashcard library by easily importing Anki decks to supplement our pre-made plastic surgery study set…</li>
                            <li>… or create your own with the flashcard editor and collaborative deck builder</li>
                            <li>Fit studying into your busy schedule with bite-sized study sessions accessible across desktop, tablet, or mobile</li>
                        </ul>
                        <p>Plastic Surgery Spaced-Repetition Flashcards will help you through your entire plastic surgery residency. They are also ideal for fellows, medical students, those studying for the ABPS or other plastic surgery boards, and anyone who needs to effectively and efficiently learn plastic surgery.</p>
                        <a className="button button--checkout" href={selectedPlan === 'annual' ? checkoutSessions?.[1]?.url : checkoutSessions?.[0]?.url}>Unlock Now</a>
                        <div className='or'>OR</div>
                        <a
                            href="https://shop.thieme.com/Janis-and-Jones-Plastic-Surgery-Spaced-Repetition-Flashcards/000000000326290101"
                            className="button button--link button--icon-right"
                            target="_blank">
                            Purchase on Thieme<ExternalLink />
                        </a>
                    </div>
                    <div className='product-image'>
                        <img src={JanisPlasticsProductImage} alt="Essentials of Plastic Surgery | Q&A Companion, Second Edition"/>
                    </div>
                </div>
            );
        } else if (productName === 'top_score') {
            return (
                <div className='thieme-container'>
                    <div>
                        <h1>Top Score for the Radiology Boards</h1>
                        <p className='m-b-0 text-transform-uppercase' style={{fontSize: '.75rem', marginTop: '1rem'}}>Choose a Plan:</p>
                        <div className='price-wrap m-t-0'>
                            <div className='sub-options'>
                                <button className={`form-control ${selectedPlan === 'monthly' ? 'checked' : ''}`} onClick={() => handlePlanClick('monthly')}>
                                    <label htmlFor='annual'>
                                        <p>Monthly</p>
                                        <h3 className='pricing'>
                                            <span className='pricing_price'>$12</span>
                                            <span className='pricing_label'>/&nbsp;Month</span>
                                        </h3>
                                    </label>
                                </button>
                                <button className={`form-control ${selectedPlan === 'annual' ? 'checked' : ''}`} onClick={() => handlePlanClick('annual')}>
                                    <label htmlFor='annual'>
                                        <p>Annual</p>
                                        <h3 className='pricing'>
                                            <span className='pricing_price'>~$10.50</span>
                                            <span className='pricing_label'>/&nbsp;Month</span>
                                        </h3>
                                        <p className='sub-price'>$125 billed annually</p>
                                    </label>
                                </button>
                            </div>
                            <a className="button button--checkout" style={{flexShrink: 0}} href={selectedPlan === 'annual' ? checkoutSessions?.[1]?.url : checkoutSessions?.[0]?.url}>Unlock Now</a>
                        </div>
                        <p>Adaptive Question Bank for the Core & Certifying Exams in spaced-repetition flashcard format, enable learners to leverage this powerful educational technology in the most user-friendly and accessible way ever.</p>
                        <p><strong>Key Features</strong></p>
                        <ul>
                            <li>Complete review for the Core Exam.</li>
                            <li>Q&A style adheres to the ABR style for multiple choice questions for graduate medical tests and CME.</li>
                            <li>As the understanding of each radiology fact strengthens, the algorithm further and further increases the time until you review that fact again.</li>
                            <li>This ensures that your time is only spent on areas that need improvement - not concepts you already know.</li>
                            <li>You review each fact just as you are about to forget it, producing the greatest memory boost through the desirable difficulty phenomenon.</li>
                            <li>The flashcards are delivered in a powerful and intuitive study App leveraging cutting-edge spaced repetition technology to help the user learn faster and retain more than ever.</li>
                        </ul>
                        {/* <p>$948 for 12-month access, it's a great value for residents who are preparing fo the Core examination.</p> */}
                        <a className="button button--checkout" href={selectedPlan === 'annual' ? checkoutSessions?.[1]?.url : checkoutSessions?.[0]?.url}>Unlock Now</a>
                    </div>
                    <div className='product-image'>
                        <img src={TopScoreProductImage} alt="Top Score for the Radiology Boards"/>
                    </div>
                </div>
            );
        } else if (productName === 'ultrasound') {
            return (
                <div className='thieme-container'>
                    <div>
                        <h1>Ultrasound Q&A Review for the Radiology Boards</h1>
                        <p className='m-b-0 text-transform-uppercase' style={{fontSize: '.75rem', marginTop: '1rem'}}>Choose a Plan:</p>
                        <div className='price-wrap m-t-0'>
                            <div className='sub-options'>
                                <button className={`form-control ${selectedPlan === 'monthly' ? 'checked' : ''}`} onClick={() => handlePlanClick('monthly')}>
                                    <label htmlFor='annual'>
                                        <p>Monthly</p>
                                        <h3 className='pricing'>
                                            <span className='pricing_price'>$5.00</span>
                                            <span className='pricing_label'>/&nbsp;Month</span>
                                        </h3>
                                    </label>
                                </button>
                                <button className={`form-control ${selectedPlan === 'annual' ? 'checked' : ''}`} onClick={() => handlePlanClick('annual')}>
                                    <label htmlFor='annual'>
                                        <p>Annual</p>
                                        <h3 className='pricing'>
                                            <span className='pricing_price'>~$4.10</span>
                                            <span className='pricing_label'>/&nbsp;Month</span>
                                        </h3>
                                        <p className='sub-price'>$49 billed annually</p>
                                    </label>
                                </button>
                            </div>
                            <a className="button button--checkout" style={{flexShrink: 0}} href={selectedPlan === 'annual' ? checkoutSessions?.[1]?.url : checkoutSessions?.[0]?.url}>Unlock Now</a>
                        </div>
                        <p>Ultrasound Q&A For the Boards in a spaced-repetition flashcard format, enable learners to leverage this powerful educational technology in the most user-friendly and accessible way ever.</p>
                        <ul>
                            <li>Q&A content created by an expert radiologist and educator, Adrian Dawkins, MD, and an impressive array of authors.</li>
                            <li>Includes content that covers first and second trimesters of pregnancy, pediatric, renal, hepatobiliary, musculoskeletal, breast, neck and scrotal ultrasound, and physics for a more comprehensive preparation for the boards.</li>
                            <li>Essential review for radiology trainees prepping for initial board certification and sonographers preparing for the boards.</li>
                            <li>As the understanding of each ultrasound fact strengthens, the algorithm further and further increases the time until you review that fact again.</li>
                            <li>The flashcards are presented using an algorithm that ensures that your time is only spent on areas that need improvement - not concepts you already know.</li>
                            <li>The flashcards are delivered in a powerful and intuitive study App leveraging cutting-edge spaced repetition technology to help the user learn faster and retain more than ever.</li>
                        </ul>
                        <p>$49.00 for 12-month access or $5.00 for a monthly subscription.</p>
                        <a className="button button--checkout" href={selectedPlan === 'annual' ? checkoutSessions?.[1]?.url : checkoutSessions?.[0]?.url}>Unlock Now</a>
                    </div>
                    <div className='product-image'>
                        <img src={UltrasoundProductImage} alt="Ultrasound Q&A Review for the Boards"/>
                    </div>
                </div>
            );
        } else if (productName === 'gastrointestinal') {
            return (
                <div className='thieme-container'>
                    <div>
                        <h1>Gastrointestinal Imaging Q&A for the Radiology Boards</h1>
                        <p className='m-b-0 text-transform-uppercase' style={{fontSize: '.75rem', marginTop: '1rem'}}>Choose a Plan:</p>
                        <div className='price-wrap m-t-0'>
                            <div className='sub-options'>
                                <button className={`form-control ${selectedPlan === 'monthly' ? 'checked' : ''}`} onClick={() => handlePlanClick('monthly')}>
                                    <label htmlFor='annual'>
                                        <p>Monthly</p>
                                        <h3 className='pricing'>
                                            <span className='pricing_price'>$5.00</span>
                                            <span className='pricing_label'>/&nbsp;Month</span>
                                        </h3>
                                    </label>
                                </button>
                                <button className={`form-control ${selectedPlan === 'annual' ? 'checked' : ''}`} onClick={() => handlePlanClick('annual')}>
                                    <label htmlFor='annual'>
                                        <p>Annual</p>
                                        <h3 className='pricing'>
                                            <span className='pricing_price'>~$4.10</span>
                                            <span className='pricing_label'>/&nbsp;Month</span>
                                        </h3>
                                        <p className='sub-price'>$49 billed annually</p>
                                    </label>
                                </button>
                            </div>
                            <a className="button button--checkout" style={{flexShrink: 0}} href={selectedPlan === 'annual' ? checkoutSessions?.[1]?.url : checkoutSessions?.[0]?.url}>Unlock Now</a>
                        </div>
                        <p>Gastrointestinal Imaging Q&A For the Radiology Boards in a spaced-repetition flashcard format, enable learners to leverage this powerful educational technology in the most user-friendly and accessible way ever.</p>
                        <ul>
                            <li>Q&A expertly created by Humaira Chaudhry, MD and a team of expert radiologists.</li>
                            <li>Covers all body imaging modalities, including CT, MRI, radiographs, fluoroscopy, and ultrasound.</li>
                            <li>Test your knowledge of gastrointestinal imaging with less trepidation.</li>
                            <li>The spaced-repetition flashcards include high-yield questions to provide residents with a better understanding of gastrointestinal imaging.</li>
                            <li>As the understanding of each GI radiology fact strengthens, the algorithm further and further increases the time until you review that fact again.</li>
                            <li>The flashcards are presented using an algorithm that ensures that your time is only spent on areas that need improvement - not concepts you already know.</li>
                            <li>The flashcards are delivered in a powerful and intuitive study App leveraging cutting-edge spaced repetition technology to help the user learn faster and retain more than ever.</li>
                        </ul>
                        <p>$49.00 for 12-month access or $5.00 for a monthly subscription.</p>
                        <a className="button button--checkout" href={selectedPlan === 'annual' ? checkoutSessions?.[1]?.url : checkoutSessions?.[0]?.url}>Unlock Now</a>
                    </div>
                    <div className='product-image'>
                        <img src={GastrointestimalProductImage} alt="Ultrasound Q&A Review for the Boards"/>
                    </div>
                </div>
            );
        } else if (productName === 'neuroradiology') {
            return (
                <div className='thieme-container'>
                    <div>
                        <h1>Neuroradiology Q&A for the Radiology Boards</h1>
                        <p className='m-b-0 text-transform-uppercase' style={{fontSize: '.75rem', marginTop: '1rem'}}>Choose a Plan:</p>
                        <div className='price-wrap m-t-0'>
                            <div className='sub-options'>
                                <button className={`form-control ${selectedPlan === 'monthly' ? 'checked' : ''}`} onClick={() => handlePlanClick('monthly')}>
                                    <label htmlFor='annual'>
                                        <p>Monthly</p>
                                        <h3 className='pricing'>
                                            <span className='pricing_price'>$5.00</span>
                                            <span className='pricing_label'>/&nbsp;Month</span>
                                        </h3>
                                    </label>
                                </button>
                                <button className={`form-control ${selectedPlan === 'annual' ? 'checked' : ''}`} onClick={() => handlePlanClick('annual')}>
                                    <label htmlFor='annual'>
                                        <p>Annual</p>
                                        <h3 className='pricing'>
                                            <span className='pricing_price'>~$4.10</span>
                                            <span className='pricing_label'>/&nbsp;Month</span>
                                        </h3>
                                        <p className='sub-price'>$49 billed annually</p>
                                    </label>
                                </button>
                            </div>
                            <a className="button button--checkout" style={{flexShrink: 0}} href={selectedPlan === 'annual' ? checkoutSessions?.[1]?.url : checkoutSessions?.[0]?.url}>Unlock Now</a>
                        </div>
                        <p>Neuroradiology Q&A For the Radiology Boards in a spaced-repetition flashcard format, enable learners to leverage this powerful educational technology in the most user-friendly and accessible way ever.</p>
                        <ul>
                            <li>Q&A content created by Michael Iv, MD and with contributions from other expert academic radiologists.</li>
                            <li>This is a must-have study tool that will help radiologists prepare for and ace the Core, Certifying, and/or Neuroradiology Subspecialty ABR exams, and to improve clinical knowledge.</li>
                            <li>The spaced-repetition flashcards include high-yield questions to provide residents with a better understanding of neuroradiology.</li>
                            <li>As the understanding of each neuroradiology fact strengthens, the algorithm further and further increases the time until you review that fact again.</li>
                            <li>The flashcards are presented using an algorithm that ensures that your time is only spent on areas that need improvement - not concepts you already know.</li>
                            <li>The flashcards are delivered in a powerful and intuitive study App leveraging cutting-edge spaced repetition technology to help the user learn faster and retain more than ever.</li>
                        </ul>
                        <p> $49.00 for 12-month access or $5.00 for a monthly subscription.</p>
                        <a className="button button--checkout" href={selectedPlan === 'annual' ? checkoutSessions?.[1]?.url : checkoutSessions?.[0]?.url}>Unlock Now</a>
                    </div>
                    <div className='product-image'>
                        <img src={NeuroradiologyProductImage} alt="Ultrasound Q&A Review for the Boards"/>
                    </div>
                </div>
            );
        } else {
            return (
                <>
                    <h2>The product you're looking for does not or no longer exists.</h2>
                    <p>Please contact <a href="mailto:support@synaptiq.co">Synaptiq Support</a> if you need help.</p>
                </>
            );
        }
    }

    return (
        <div className='layout layout--checkout'>
            <div className="layout--checkout_container layout_container">
                {renderCheckoutContent()}
            </div>
            <ReactTooltip />
        </div>
    )
}

const mSTP = (state, ownProps) => ({
    user: state.user,
    checkoutSessions: state.checkoutSessions,
    product_id: ownProps.match.params.prod_id,
});
const mDTP = (dispatch) => ({
    fetchCheckoutSessions: (product_id) => dispatch(fetchCheckoutSessions(product_id)),
});
export default connect(mSTP, mDTP)(Checkout);
