const AGAIN_INTERVAL = 2;

export const getResponseLabel = (card, response, userStudySettings) => {
    if (_.isEmpty(card)) return '...';

    const newLevel = calcNewLevel(card, response);
    const newInterval = calcNewInterval(response, newLevel, userStudySettings, card.model);

    return convertMinutesToString(newInterval);
}

export const updateCardFrontend = (card, response, userStudySettings) => {
    const newLevel = calcNewLevel(card, response);
    const newInterval = calcNewInterval(response, newLevel, userStudySettings, card.model);

    const fuzzMargin = newInterval < 1440 ? 2 : 10;
    const fuzz = Math.floor(Math.random() * fuzzMargin);
    const newNextDueAt = moment().add(newInterval + fuzz, 'm').toISOString();

    // console.log('_________________________');
    // console.log('#### BEFORE ####');
    // console.log('currLevel: ' + card.level);
    // console.log('currInterval: ' + card.interval);
    // console.log('currDueAt: ' + card.next_practice_due_at);
    // console.log('currTimesSeen: ' + card.times_seen);
    // console.log('_________________________');
    // console.log('response: ' + response);
    // console.log('_________________________');
    // console.log('#### AFTER ####');
    // console.log('currLevel: ' + newLevel);
    // console.log('currInterval: ' + newInterval);
    // console.log('currDueAt: ' + newNextDueAt);
    // console.log('currTimesSeen: ');
    // console.log(card.times_seen + 1); 
    // console.log('lastPracticeAt: ' + moment().toISOString());
    // console.log('_________________________');

    card.level = newLevel;
    card.interval = newInterval;
    card.next_practice_due_at = newNextDueAt;
    card.isFlipped = false;
    card.times_seen = card.times_seen + 1;
    card.last_practiced_at = moment().toISOString();

    return card;
}

const calcNewLevel = (card, response) => {
    const level = card?.level || 1;
    let newLevel;

    if (response === 'minus2') {
        newLevel = Math.max(1, level - 2);
    } else if (response === 'minus1') {
        newLevel = Math.max(1, level - 1);
    } else if (response === 'plus1') {
        newLevel = level + 1;
    } else if (response === 'plus2') {
        newLevel = level + 2;
    }

    return newLevel;
}

const calcNewInterval = (response, newLevel, userStudySettings, cardModel) => {
    if (response === 'minus2' && cardModel !== 'multichoice') return AGAIN_INTERVAL;

    const steps = userStudySettings.steps;
    const multiple = userStudySettings.multiple;
    let newInterval;
    if (newLevel > steps.length) {
        const lastStep = steps[steps.length - 1];
        const stepsLen = steps.length;
        newInterval = lastStep * (multiple ** (newLevel - stepsLen));
    } else {
        newInterval = steps[newLevel - 1];
    }

    return newInterval;
}

function convertMinutesToString(minutes) {
    const minutesInHour = 60;
    const minutesInDay = 24 * minutesInHour;
    let value;
    let unit;

    if (minutes >= minutesInDay) {
        value = Math.round(minutes / minutesInDay);
        unit = "d";
    } else {
        value = minutes;
        unit = "m";
    }

    return `${value}${unit}`;
}
