import {
  START_TOGGLE_LOADING,
  RECEIVE_DECKS_TREE,
  START_CARDS_LOADING,
  RECEIVE_CARDS,
  START_PROGRESS_LOADING,
  RECEIVE_DECK_PROGRESS,
  RECEIVE_ALL_PROGRESS,
  START_USER_STATS_LOADING,
  RECEIVE_USER_STATS
} from '../../actions/allActions';

const initialState = {
  toggleLoading: null,
  cardsLoading: false,
  progressLoading: false,
};

const loadingReducer = (state = initialState, action) => {
  Object.freeze(state);
  switch (action.type) {
    case START_TOGGLE_LOADING:
      return Object.assign({}, state, { toggleLoading: action.payload.deckId });
    case RECEIVE_DECKS_TREE:
      return Object.assign({}, state, { toggleLoading: null });
    case START_CARDS_LOADING:
      return Object.assign({}, state, { cardsLoading: true });
    case RECEIVE_CARDS:
      return Object.assign({}, state, { cardsLoading: false });
    case START_PROGRESS_LOADING:
      return Object.assign({}, state, { progressLoading: true });
    case RECEIVE_DECK_PROGRESS:
      return Object.assign({}, state, { progressLoading: false });
    case RECEIVE_ALL_PROGRESS:
      return Object.assign({}, state, { progressLoading: false });
    case START_USER_STATS_LOADING:
      return Object.assign({}, state, { userStatsLoading: true });
    case RECEIVE_USER_STATS:
      return Object.assign({}, state, { userStatsLoading: false });
    default:
      return state;
  }
};

export default loadingReducer;
