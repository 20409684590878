import React from 'react';
import { connect } from 'react-redux';
import Modal from '../misc/Modal';
import { XOctagon } from 'react-feather';

function AdvancedAccountSettings({ hide, showDeleteConfirmation }) {
    return (
        <Modal
            name={'modal--advanced-account-settings'}
            hide={hide}
            small={true}
            title={'Advanced Account Settings'}
            content={
                <>
                    <div className='content-wrapper'>
                        <button className="button button--error" onClick={showDeleteConfirmation}><XOctagon />Delete Account</button>
                    </div>
                </>
            }
        />
    )
}

const mapStateToProps = (state) => ({
});
const mapDispatchToProps = (dispatch) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(AdvancedAccountSettings);
