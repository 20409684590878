let reqCount = 0;
const getConfig = () => (
    {
        "dom": '<<t><"bottom"pl>>',
        columns,
        order: [[ 3, "desc" ]],
        responsive: true,
        processing: true,
        serverSide: true,
        ajax: {
            url: 'api/v1/datatables',
            beforeSend: function () {
                reqCount += 1;
                $("#search-loading-icon").show();
                $("#browse-table").addClass('table-loading');
            },
            complete: function () {
                reqCount -= 1;
                if (reqCount <= 0) {
                    $("#search-loading-icon").hide();
                    $("#browse-table").removeClass('table-loading');
                }
            },
        }
    }
)

const columns = [
    {
        title: 'Card',
        data: 'answer',
        className: 'col-answer',
        render: (data, type, row) => {
            if (row.model === "multichoice") {
                const frontData = data;
                const labelId = `id="choice-${row.multi_choice_answer}"`;
                const correctLabelStartIndex = data.indexOf(labelId);
                if (correctLabelStartIndex === -1) return data;
                const closeAngleBracketIndex = data.indexOf(">", correctLabelStartIndex);
                const backData = [
                    data.slice(0, closeAngleBracketIndex),
                    ' class="correct-answer"',
                    data.slice(closeAngleBracketIndex)
                ].join('');
                return `<div class="front">${frontData}</div><div class="back">${backData}</div>`;
            } else {
                const frontData = !!row.css ? row.question + row.css : row.question;
                const backData = !!row.css ? data + row.css : data;
                return row.model == 'occlusion' ? `<div class="front">${row.question + row.question_overlay}</div><div class="back">${row.question + row.answer_overlay}</div>` : `<div class="front">${frontData}</div><div class="back">${backData}</div>`;
            }
        }
    },
    {
        title: 'Extra',
        data: 'extra',
        className: 'col-extra',
        render: (data, type, row) => {
            if (data === null || data === "") {
                return null;
            } else {
                return `<div class="spacer"></div><div class="truncate-col">${data}</div>`
            }
        }
    },
    {
        title: 'Deck',
        data: 'deck'
    },
    {
        data: 'id',
        sortable: false,
        className: 'actions',
        width: '240px',
        render: (data, type, full) => {
            let options;
            const editBtn = `<button class='button button--secondary button--small button--edit-card' aria-label="Edit Card" data-card-id='${data}'>Edit</button>`
            const cloneBtn = `<button class='button button--secondary button--small button--clone-card' aria-label="Clone Card" data-card-id='${data}'>Clone</button>`
            const deleteBtn = `<button class='button button--secondary button--small button--delete-card' aria-label="Delete Card" data-card-id='${data}'>Delete</button>`
            // const suspendBtn = `<button class='button button--secondary button--small button--suspend-card' aria-label="Suspend Card" data-card-id='${data}'>Suspend</button>`
            const suspendBtn = '';

            if ((full.curator || full.admin) && full.editable) {
                options = editBtn + cloneBtn + deleteBtn + suspendBtn;
            } else if ((full.curator || full.admin) && !full.editable) {
                options = cloneBtn + suspendBtn;
            } else if (!(full.curator || full.admin) && full.editable) {
                options = editBtn + deleteBtn + suspendBtn;
            } else {
                options = suspendBtn;
            }
            return options;
        }
    },
];

export default getConfig;
