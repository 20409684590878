import {
    RECEIVE_DECKS_TREE,
	CLEAR_DECKS_TREE,
	RECEIVE_DECK_TOGGLE
} from '../../actions/allActions';

const decksTreeReducer = (state = [], action) => { 
	Object.freeze(state);
	let nextState = _.cloneDeep(state);

	switch (action.type) {
		case RECEIVE_DECKS_TREE:
			const decksArr = action.payload.decksTree

			// Sort first by ord ascending, then by created_at descending
			decksArr.sort((a, b) => {
				// Sort by 'ord' attribute in ascending order
				if (a.ord < b.ord) {
					return -1;
				} else if (a.ord > b.ord) {
					return 1;
				}

				// If 'ord' is equal, sort by 'created_at' attribute in descending order
				const dateA = new Date(a.created_at);
				const dateB = new Date(b.created_at);
				return dateB - dateA;
			});
			
			return decksArr;
		case CLEAR_DECKS_TREE:
			return [];
		case RECEIVE_DECK_TOGGLE:
			if (!!action.payload.id) {
				for (const rootDeck of nextState) {
					updateCollapsed(rootDeck, action.payload.id, action.payload.collapsed);
				}
			}
			return nextState;
		default:
			return state;
	}
}

function updateCollapsed(deck, targetId, collapsed) {
	if (deck.id === targetId) {
		deck.collapsed = collapsed;
		return;
	} else {
		for (const childDeck of deck.children) {
			updateCollapsed(childDeck, targetId, collapsed);
		}
	}
}

export default decksTreeReducer;