import { connect } from 'react-redux'
import Stats from './Stats'
import { 
    fetchUserStats,
    fetchHeatmap,
    receiveActiveStudyProfile,
    fetchPastStudySessions,
} from '../../actions/allActions'
import _ from 'lodash'

const mapStateToProps = state => {
    return {
        user: state.user,
        userStats: state.userStats,
        currentStreak: _.get(state, 'userStats.currentStreak', 0),
        longestStreak: _.get(state, 'userStats.longestStreak', 0),
        totalStudyCount: _.get(state, 'userStats.totalStudyCount', 0),
        cardBreakdown: _.get(state, 'userStats.cardBreakdown', []),
        progress: state.progress,
        heatmap: state.heatmap,
        userStatsLoading: _.defaultTo(state.loading.userStatsLoading, false),
        pastStudySessions: state.pastStudySessions
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchPastStudySessions: () => dispatch(fetchPastStudySessions()),
        fetchUserStats: () => dispatch(fetchUserStats()),
        fetchHeatmap: () => dispatch(fetchHeatmap()),
        clearActiveStudyProfile: () => dispatch(receiveActiveStudyProfile({})),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Stats)