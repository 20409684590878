import React, { useState, useEffect } from 'react';
import { fetchAssetContent } from '../../util/apiUtil';

function AssetDisplay({ pageId }) {
    const [assetBlocks, setAssetBlocks] = useState([]);
    const [lastEditted, setLastEditted] = useState();
    const [blockNotFound, setBlockNotFound] = useState(false);

    useEffect(async () => {
        await fetchAssetContent(pageId)
            .then(responseData => {
                if (!responseData) {
                    setBlockNotFound(true);
                } else {
                    setAssetBlocks(responseData.results);
                    findLastEditted(responseData.results);
                }
            })
            .catch(error => {
                console.error('Error fetching Notion page content:', error);
            });
    }, [pageId]);

    const findLastEditted = (data) => {
        if (!data || data.length === 0) return null;
        const latestDate = new Date(Math.max(...data.map(e => new Date(e.last_edited_time))));
        setLastEditted(latestDate.toLocaleString());
    }

    return (
        <div className='asset-block'>
            {!assetBlocks.length && !blockNotFound && <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>}
            {blockNotFound && <div>This asset is no longer available and/or cannot be found. Please contact Synaptiq Support for assistance.</div>}
            {assetBlocks.length > 0 && <div>
                {assetBlocks.map(block => {
                    switch (block.type) {
                        case 'paragraph':
                            return <p key={block.id}>{block.paragraph.rich_text[0]?.text?.content}</p>;

                        case 'heading_1':
                            return <h1 key={block.id}>{block.heading_1.rich_text[0]?.text?.content}</h1>;

                        case 'heading_2':
                            return <h2 key={block.id}>{block.heading_2.rich_text[0]?.text?.content}</h2>;

                        case 'heading_3':
                            return <h3 key={block.id}>{block.heading_3.rich_text[0]?.text?.content}</h3>;

                        case 'image':
                            if (block.image.type === 'file') {
                                const imageSrc = block.image.file.url;
                                const imageAlt = block.image.caption.length ? block.image.caption[0].text?.content : 'Image Asset';
                                return (
                                    <div key={block.id}>
                                        <img src={imageSrc} alt={imageAlt} />
                                    </div>
                                );
                            } else if (block.image.type === 'external') {
                                const imageSrc = block.image.external.url;
                                const imageAlt = block.image.caption.length ? block.image.caption[0].text?.content : 'Image Asset';
                                return (
                                    <div key={block.id}>
                                        <img src={imageSrc} alt={imageAlt} />
                                    </div>
                                );
                            } else {
                                return null;
                            }
                        
                        default:
                            return null; // Return null for unhandled block types to avoid rendering issues
                    }
                })}
                {lastEditted && <div className='updated'>Last Updated: {lastEditted}</div>}
            </div>}
        </div>
    );
}

export default AssetDisplay;
