import React, { Component } from 'react';
import { Quill } from 'react-quill';
import ReactQuill from "react-quill"
import QuillToolbar, { getModules, formats, addQuillListeners } from "./QuillToolbar";
import AssetSelector from '../assets/AssetSelector';

/* 
 * Quill Editor component with custom toolbar
 */
export default class QuillEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
          value: null,
          toggleAssetModal: false
        };
        this.quillRef = null;      // Quill instance
        this.reactQuillRef = null; // ReactQuill component
    }

    componentDidMount() {
      this.attachQuillRefs();
      addQuillListeners(this.quillRef, this.props.handleCreate);

      if (this.props.version == 'answer') {
        window.setFocusToAnswerField = this.setFocusToAnswerField;
      }
    }
    
    setFocusToAnswerField = () => {
      this.quillRef.focus();
    }

    componentDidUpdate() {
      this.attachQuillRefs()
    }
    
    componentWillUnmount() {
      window.setFocusToAnswerField = null;
    }

    attachQuillRefs = () => {
      if (typeof this.reactQuillRef.getEditor !== 'function') return;
      if (this.quillRef != null) return;
      this.quillRef = this.reactQuillRef.getEditor();
      Quill.register({
        'formats/DivBlot': DivBlot
      });
    }

    showAssetModal = () => {
      this.setState({ toggleAssetModal: true });
    }

    hideAssetModal = () => {
      this.setState({ toggleAssetModal: false });
    }

    handleInsertSelected = (selectedAsset) => {
      const range = this.quillRef.getSelection(true);
      if (range) {
          this.quillRef.insertEmbed(range.index, 'DivBlot', selectedAsset);
      };
    }

    render() {
      return (
          <div className='card-wrapper'>
              <QuillToolbar
                version={this.props.version}
                userAdmin={this.props.userAdmin}
                clozeEditor={this.props.clozeEditor}
                action={this.props.action}
              />
              <ReactQuill
                  ref={(el) => { this.reactQuillRef = el }}
                  className={this.props.version}
                  theme={'snow'}
                  value={this.props.value}
                  placeholder={'Write here...'}
                  onChange={this.props.setValue}
                  style={{minHeight: '300px'}}
                  modules={getModules(this.props.version, this.showAssetModal)}
                  formats={ [...formats, 'DivBlot'] }
              />
              {this.state.toggleAssetModal && <AssetSelector
                onAssetSubmission={this.handleInsertSelected}
                hide={this.hideAssetModal}
              />}
          </div>
      )
    }
}

const BlockEmbed = Quill.import('blots/block/embed');
class DivBlot extends BlockEmbed {
  static create(value) {
    let node = super.create();
    node.setAttribute('data-asset-id', value);
    node.innerHTML = "[ ASSET: " + value + " ]";
    return node;
  }

  static value(node) {
    return node.getAttribute('data-asset-id');
  }
}

DivBlot.blotName = 'DivBlot';
DivBlot.tagName = 'DIV';
