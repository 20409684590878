import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';

export default class MasteryBar extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let masteryWidth = Math.round((this.props.masteredCardCount / this.props.totalCards) * 100);
        let learningWidth = Math.round((this.props.learningCardCount / this.props.totalCards) * 100);
        let newWidth = 100 - masteryWidth - learningWidth;
        const masteryBarWidth = {
            width: `${masteryWidth}%`,
            raw: masteryWidth
        };
        const learningBarWidth = {
            width: `${learningWidth}%`,
            left: `${masteryWidth}%`,
            raw: learningWidth
        };
        const newBarWidth = {
            width: `${newWidth}%`,
            left: `${learningWidth + masteryWidth}%`,
        };

        let display = (
            <>
                <span className="component--mastery-bar__percentage__value">{this.props.percentMastery}%</span>
                <span className="component--mastery-bar__percentage__label"> Mastery</span>
            </>
        );

        return (
            <React.Fragment>
                <div className="component component--mastery-bar">
                    <div className="component--mastery-bar__container">
                        {/* <div style={masteryBarWidth} className={`component--mastery-bar__container__bar component--mastery-bar__container__bar--mastered`} data-tip={`${masteryWidth}% Mastered`} data-delay-show="150"></div>
                        <div style={learningBarWidth} className={`component--mastery-bar__container__bar component--mastery-bar__container__bar--learning`} data-tip={`${learningWidth}% Learning`} data-delay-show="150"></div>
                        <div style={newBarWidth} className={`component--mastery-bar__container__bar component--mastery-bar__container__bar--new`} data-tip={`${newWidth}% New`} data-delay-show="150"></div> */}
                        <div style={masteryBarWidth} className={`component--mastery-bar__container__bar component--mastery-bar__container__bar--mastered ${masteryBarWidth.raw > 0 && learningBarWidth.raw == 0 ? 'round-right non-zero' : ''}`}></div>
                        <div style={learningBarWidth} className={`component--mastery-bar__container__bar component--mastery-bar__container__bar--learning round-right ${masteryBarWidth.raw == 0 && learningBarWidth.raw > 0 ? 'non-zero' : ''}`}></div>
                        <div style={newBarWidth} className={`component--mastery-bar__container__bar component--mastery-bar__container__bar--new`}></div>
                    </div>
                    <div className="component--mastery-bar__percentage">
                        {display}
                    </div>
                    {/* <ReactTooltip/> */}
                </div>
            </React.Fragment>
        )
    }
}
