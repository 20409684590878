import _ from 'lodash';
import React, { Component } from 'react';
import { X } from 'react-feather';
import LogoCircle from '../../../assets/images/logo-circle.svg';
import DotGrid from '../../../assets/images/dot-grid.svg';
import parse from 'html-react-parser';
import isMobile from '../../util/isMobile';
import FocusTrap from 'focus-trap-react';

export default class ThiemeOnboarding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentFrame: 0,
            exiting: false,
            exitted: false,
            viewingOnboarding: false,
            rating: null,
            ratingImplication: null,
            correct: false,
            selectedMultichoice: null
        }
        this.timeoutIds = [];
    }

    componentDidMount() {
        document.body.classList.add('overflow-hidden');
        setTimeout(() => {
            let children = document.getElementById('ob-sr-frame-0').children;
            for (var i = 0; i < children.length; i++) {
                this.delayRender(children[i], i);
            }
        });
    }

    componentWillUnmount() {
        document.body.classList.remove('overflow-hidden');
        this.timeoutIds.forEach(id => clearTimeout(id));
        if (!isMobile) {
            toast(() => parse("You can change to Dark Mode by pressing <code>t</code>."), {
                position: "bottom-left",
                autoClose: 7500,
                delayShow: 1000,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "light"
            });
        }
    }

    delayRender = (child, delay) => {
        const id = setTimeout(() => {
            child.classList.add('enter');
        }, 1250 * delay);
        this.timeoutIds.push(id);
    }

    exitOnboarding = () => {
        this.setState({ currentFrame: -1 });
        this.props.hide();
        document.body.classList.remove('overflow-hidden');
    }

    frameNext = () => {
        this.setState({ currentFrame: this.state.currentFrame + 1 });
        if (!this.state.viewingOnboarding) this.state.viewingOnboarding = true;
        setTimeout(() => {
            let children = document.getElementById('ob-sr-frame-' + this.state.currentFrame.toString())?.children;
            for (var i = 0; i < children.length; i++) {
                this.delayRender(children[i], i);
            }
        });
    }

    rateCard = (rating) => {
        let description;
        if (rating === 'easy') {
            description = "Because you selected Easy, you won't see this card again for several days.\n\nIf you had selected Right, you would see this card tomorrow, and if you had selected Wrong or Again, you'd see it again today.";
        } else if (rating === 'wrong') {
            description = "Because you selected Wrong, you'll frequently see this card until you know it.\n\nIf you had selected Easy, we wouldn't show you this card again for several days, and Right would be in between.";
        } else if (rating === 'again') {
            description = "Because you selected Again, you'll see this card again in this same study session.\n\nIf you had selected Easy, we wouldn't show you this card again for several days, and Right would be in between.";
        } else {
            description = "Because you selected Right, you'll see this card again tomorrow.\n\nIf you had selected Easy, there would be an even bigger delay, and if you had selected Wrong or Again, you'd see this card again today.";
        }
        this.setState({ rating: rating, ratingImplication: description });
        this.frameNext();
    }

    renderProgress = () => {
        return (
            <>
                <div className={`step ${this.state.currentFrame >= 0 ? 'active' : ''}`}></div>
                <div className={`step ${this.state.currentFrame >= 1 ? 'active' : ''}`}></div>
                <div className={`step ${this.state.currentFrame >= 2 ? 'active' : ''}`}></div>
                <div className={`step ${this.state.currentFrame >= 3 ? 'active' : ''}`}></div>
                {(this.props.type !== 'plastics' && this.props.type !== 'top_score' && this.props.type !== 'ultrasound' && this.props.type !== 'gastrointestinal' && this.props.type !== 'neuroradiology') && <div className={`step ${this.state.currentFrame >= 4 ? 'active' : ''}`}></div>}
            </>
        );
    }

    submitAnswer = (correct, choice) => {
        if (this.state.currentFrame !== 1) return;
        this.setState({ selectedMultichoice: choice });
        const multichoiceOptions = document.getElementById('multichoice-options');
        multichoiceOptions?.classList.add('answer-revealed');

        document.getElementById(`choice-4`)?.classList.add('multichoice-correct-answer');

        const checkedChoiceEl = document.querySelector('input[name="multichoice"]:checked')?.parentElement;
        if (!correct && checkedChoiceEl) {
            checkedChoiceEl?.classList.add('multichoice-incorrect-answer');
        }
        if (correct) {
            this.setState({ correct: true });
        }
        this.frameNext();
    }

    renderOnboardingContent = () => {
        let genericQuestion = `Diabetic Ketoacidosis often causes high serum levels of <span className="answer">[which electrolyte]</span> despite a total-body deficiency`;
        let genericAnswer = `Diabetic Ketoacidosis often causes high serum levels of <span className="answer">potassium</span> despite a total-body deficiency`;
        let thiemeQuestion = `A nasal polypoid mass in a newborn should be considered <span className="answer">[diagnosis]</span> until proven otherwise`;
        let thiemeAnswer = `A nasal polypoid mass in a newborn should be considered <span className="answer">encephalocele</span> until proven otherwise`;

        if (this.props.type === 'plastics' || this.props.type === 'top_score' || this.props.type === 'ultrasound' || this.props.type === 'gastrointestinal' || this.props.type === 'neuroradiology') {
            return (
                <>
                    <div id={"ob-sr-frame-" + 0} className={`sr-ob-thieme_content_frame ${this.state.currentFrame == 0 ? 'show' : ''}`}>
                        <h1><span>Hello</span> {this.props.name}</h1>
                        <p>Welcome to Synaptiq<span className="m-l-0-50" role="img" aria-label="waving hand">👋</span></p>
                        <div>
                            <p>Would you like a one minute introduction to <span className="type-weight-bold">Spaced Repetition</span> multiple-choice flashcards?</p>
                            <div className='m-t-1-50'>
                                <button className='button button--secondary m-r-1' onClick={() => this.exitOnboarding()}>No</button>
                                <button className='button button--secondary' onClick={() => this.frameNext()}>Yes</button>
                            </div>
                        </div>
                    </div>
                    <div id={"ob-sr-frame-" + 1} className={`sr-ob-thieme_content_frame ${this.state.currentFrame == 1 ? 'show' : ''}`}>
                        <p>Spaced repetition is a highly efficient learning technique that involves repeated testing over time, with longer intervals as your knowledge improves.</p>
                        <p>Let's practice - try your best to answer this question:</p>
                        <div>
                            <div id="multichoice-question">A 21-year-old man presents with a transverse soft-tissue tip amputation to his dominant thumb at the level of the lunula. There is exposed distal phalanx and the soft-tissue defect measures 2 x 1 cm. He is generally healthy and a nonsmoker. Which one of the following would be the best reconstructive option in this case?</div>
                            <div id="multichoice-options">
                                <label id="choice-0" htmlFor="choice-0-input" onClick={() => this.submitAnswer(false, 0)}>
                                    <input id="choice-0-input" type="radio" name="multichoice" />
                                    <span>A. Healing by secondary intention</span>
                                </label>
                                <label id="choice-1" htmlFor="choice-1-input" onClick={() => this.submitAnswer(false, 1)}>
                                    <input id="choice-1-input" type="radio" name="multichoice" />
                                    <span>B. Revision amputation</span>
                                </label>
                                <label id="choice-2" htmlFor="choice-2-input" onClick={() => this.submitAnswer(false, 2)}>
                                    <input id="choice-2-input" type="radio" name="multichoice" />
                                    <span>C. Distally based dorsal hand flap (Quaba)</span>
                                </label>
                                <label id="choice-3" htmlFor="choice-3-input" onClick={() => this.submitAnswer(false, 3)}>
                                    <input id="choice-3-input" type="radio" name="multichoice" />
                                    <span>D. Thenar flap</span>
                                </label>
                                <label id="choice-4" htmlFor="choice-4-input" onClick={() => this.submitAnswer(true, 4)}>
                                    <input id="choice-4-input" type="radio" name="multichoice" />
                                    <span>E. Neurovascular island flap (Littler)</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div id={"ob-sr-frame-" + 2} className={`sr-ob-thieme_content_frame ${this.state.currentFrame == 2 ? 'show' : ''}`}>
                        <div>
                            <div id="multichoice-question" className='m-t-0'>A 21-year-old man presents with a transverse soft-tissue tip amputation to his dominant thumb at the level of the lunula. There is exposed distal phalanx and the soft-tissue defect measures 2 x 1 cm. He is generally healthy and a nonsmoker. Which one of the following would be the best reconstructive option in this case?</div>
                            <div id="multichoice-options" className='answer-revealed'>
                                <label id="choice-0" htmlFor="choice-0-input" className={this.state.selectedMultichoice === 0 ? 'multichoice-incorrect-answer' : ''}>
                                    <input id="choice-0-input" type="radio" name="multichoice" />
                                    <span>A. Healing by secondary intention</span>
                                </label>
                                <label id="choice-1" htmlFor="choice-1-input" className={this.state.selectedMultichoice === 1 ? 'multichoice-incorrect-answer' : ''}>
                                    <input id="choice-1-input" type="radio" name="multichoice" />
                                    <span>B. Revision amputation</span>
                                </label>
                                <label id="choice-2" htmlFor="choice-2-input" className={this.state.selectedMultichoice === 2 ? 'multichoice-incorrect-answer' : ''}>
                                    <input id="choice-2-input" type="radio" name="multichoice" />
                                    <span>C. Distally based dorsal hand flap (Quaba)</span>
                                </label>
                                <label id="choice-3" htmlFor="choice-3-input" className={this.state.selectedMultichoice === 3 ? 'multichoice-incorrect-answer' : ''}>
                                    <input id="choice-3-input" type="radio" name="multichoice" />
                                    <span>D. Thenar flap</span>
                                </label>
                                <label id="choice-4" htmlFor="choice-4-input" className='multichoice-correct-answer'>
                                    <input id="choice-4-input" type="radio" name="multichoice" />
                                    <span>E. Neurovascular island flap (Littler)</span>
                                </label>
                            </div>
                        </div>
                        {this.state.correct && <p>Perfect! Because your answer was correct, you won't see this question again for a while. If your answer was wrong, we would show you this question frequently until you know it well.</p>}
                        {!this.state.correct && <p>Because your answer wasn't quite right, we'll show you this question frequently until you know it well. If your answer was correct, you wouldn't see this question again for a while.</p>}
                        <button className='button d-block m-t-1-50 button--secondary' onClick={() => this.frameNext()}>Next</button>
                    </div>
                    <div id={"ob-sr-frame-" + 3} className={`sr-ob-thieme_content_frame ${this.state.currentFrame == 3 ? 'show' : ''}`}>
                        <p>Based on your correct and incorrect answers, Synaptiq automatically builds a personalized review schedule to target your knowledge gaps.</p>
                        <p>Study your Due questions daily to reinforce each concept at the optimal time.</p>
                        <p>Thanks for choosing Synaptiq!</p>
                        <button className='button d-block m-t-1-50' onClick={() => this.exitOnboarding()}>Finish</button>
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <div id={"ob-sr-frame-" + 0} className={`sr-ob-thieme_content_frame ${this.state.currentFrame == 0 ? 'show' : ''}`}>
                        <h1><span>Hello</span> {this.props.name}</h1>
                        <p>Welcome to Synaptiq<span className="m-l-0-50" role="img" aria-label="waving hand">👋</span></p>
                        <div>
                            <p>Would you like a one minute introduction to <span className="type-weight-bold">Spaced Repetition</span> flashcards?</p>
                            <div className='m-t-1-50'>
                                <button className='button button--secondary m-r-1' onClick={() => this.exitOnboarding()}>No</button>
                                <button className='button button--secondary' onClick={() => this.frameNext()}>Yes</button>
                            </div>
                        </div>
                    </div>
                    <div id={"ob-sr-frame-" + 1} className={`sr-ob-thieme_content_frame ${this.state.currentFrame == 1 ? 'show' : ''}`}>
                        <p>Spaced repetition is a highly efficient learning technique that involves repeated testing over time, with longer intervals as your knowledge improves.</p>
                        <p>Let's practice - try to think of the answer to this question, and then click <span className="type-weight-bold">Reveal Answer</span>.</p>
                        <div className='rate-card-container'>
                            <div className="rate-card">{this.props.group === 'thieme' ? parse(thiemeQuestion) : parse(genericQuestion)}</div>
                        </div>
                        <button className='button button--reveal' onClick={() => this.frameNext()}>Reveal Answer</button>
                    </div>
                    <div id={"ob-sr-frame-" + 2} className={`sr-ob-thieme_content_frame ${this.state.currentFrame == 2 ? 'show' : ''}`}>
                        <div className='rate-card-container'>
                            <div className="rate-card">{this.props.group === 'thieme' ? parse(thiemeAnswer) : parse(genericAnswer)}</div>
                        </div>
                        <p>Perfect, now rate the difficulty of this card.</p>
                        <div className='rating-buttons'>
                            <button className='button button--rating button--again' onClick={() => this.rateCard('again')}>Again</button>
                            <button className='button button--rating button--wrong' onClick={() => this.rateCard('wrong')}>Wrong</button>
                            <button className='button button--rating button--right' onClick={() => this.rateCard('right')}>Right</button>
                            <button className='button button--rating button--easy' onClick={() => this.rateCard('easy')}>Easy</button>
                        </div>
                    </div>
                    <div id={"ob-sr-frame-" + 3} className={`sr-ob-thieme_content_frame ${this.state.currentFrame == 3 ? 'show' : ''}`}>
                        <p>{this.state.ratingImplication}</p>
                        <p>The intervals will get longer and longer as you learn.</p>
                        <button className='button d-block m-t-1-50 button--secondary' onClick={() => this.frameNext()}>Next</button>
                    </div>
                    <div id={"ob-sr-frame-" + 4} className={`sr-ob-thieme_content_frame ${this.state.currentFrame == 4 ? 'show' : ''}`}>
                        <p>Synaptiq will build a personalized review schedule based on your strengths and weaknesses, refining it over time as you learn.</p>
                        <p>Study your <span className="type-weight-bold">Due</span> cards daily to reinforce each concept at the optimal time.</p>
                        <p>Thanks for choosing Synaptiq!</p>
                        <button className='button d-block m-t-1-50' onClick={() => this.exitOnboarding()}>Finish</button>
                    </div>
                </>
            )
        }
    }

    render() {
        return (
            <FocusTrap>
                <div className={`sr-ob-thieme ${this.state.exiting ? 'fade-out' : ''} ${this.state.exitted ? 'hide' : ''} `}>
                    <img className='dot-grid' src={DotGrid} alt="" />
                    <button className='button--exit' onClick={() => this.exitOnboarding()}><X /></button>
                    <div className='progress-indicator'>{this.renderProgress()}</div>
                    <div className={`sr-ob-thieme_content ${(this.props.type === 'plastics' || this.props.type === 'top_score' || this.props.type === 'ultrasound' || this.props.type === 'gastrointestinal' && this.props.type === 'neuroradiology') ? 'type--plastics' : ''}`}>
                        <div className='logo'>
                            <img src={LogoCircle} alt="Synaptiq Logo" />
                        </div>
                        <div id="ob-sr-frame-exit" className={`sr-ob-thieme_content_frame ${this.state.currentFrame == -1 ? 'show' : ''}`}></div>
                        {this.renderOnboardingContent()}
                    </div>
                    <img className='dot-grid dot-grid-2' src={DotGrid} alt="" />
                </div>
            </FocusTrap>
        );
    }
}
