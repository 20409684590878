import { 
	RECEIVE_PAST_STUDY_SESSIONS,
} from '../../actions/allActions';

const pastStudySessionsReducer = (state = [], action) => {
	switch (action.type) {
		case RECEIVE_PAST_STUDY_SESSIONS:
			return action.payload.pastStudySessions;
		default:
			return state;
	}
}

export default pastStudySessionsReducer;
