import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ExternalLink } from 'react-feather';
import { debounce } from 'lodash';
import { redeemAccessCode } from '../../actions/allActions';
import { initiateModalExit } from '../../util/modalExit';
import { getProductName } from '../../util/cohortUtil';
import Modal from '../misc/Modal';
import GreenbergProductImage from '../../../assets/images/thieme/Greenberg-Flashcards_1024x1536px_ok.jpg';
import JanisPlasticsProductImage from '../../../assets/images/thieme/Janis_Plastic-Surgery-Flashcards_1024x1536px.jpg';
import TopScoreProductImage from '../../../assets/images/thieme/Radiology_Flashcards_1024x1536px_k2.jpg';
import GastrointestimalProductImage from '../../../assets/images/thieme/Radiology-Flashcards_1024x1536px_1.jpg';
import UltrasoundProductImage from '../../../assets/images/thieme/Radiology-Flashcards_1024x1536px_2.jpg';
import NeuroradiologyProductImage from '../../../assets/images/thieme/Radiology-Flashcards_1024x1536px_3';

function ThiemeUnlocker({ deck, hide, redeemAccessCode, user, userProperties, subscriptions }) {
    const [variant, setVariant] = useState('');
    const [accessCode, setAccessCode] = useState('');
    const [thiemePurchaseExpired, setThiemePurchaseExpired] = useState(false);

    useEffect(() => {
        const productName = getProductName(deck.stripe_product_id);
        setVariant(productName);

        if (deck.stripe_product_id &&
            !subscriptions[deck.stripe_product_id] &&
            userProperties[deck.stripe_product_id] &&
            !user.admin &&
            !user.curator) {
            const purchaseDate = userProperties[deck.stripe_product_id];
            const date = moment(purchaseDate);
            const oneYearAgo = moment().subtract(1, 'years');
            const isExpired = date.isBefore(oneYearAgo);
            console.log('isExpired: ', isExpired);
            setThiemePurchaseExpired(isExpired);
        }
    }, []);

    useEffect(() => {
        document.body.classList.add("overflow-hidden");
        window.addEventListener('keydown', debouncedChangeHandler);
        return () => {
            window.removeEventListener('keydown', debouncedChangeHandler);
        }
    }, [accessCode]);

    const keyBinds = (e) => {
        if (e.key === "Enter" || e.which === 13 || e.code === "Enter") {
            if (!!accessCode) {
                handleSaveChanges();
            }
        }
    }

    const debouncedChangeHandler = useMemo(
        () => debounce(keyBinds, 50, { 'leading': true, 'trailing': false })
    , [accessCode]);

    const handleSaveChanges = () => {
        redeemAccessCode(accessCode).then(res => {
            if (res.status === 'success') {
                const toastConfig = { position: "bottom-left", autoClose: 3000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined }
                toast.success('Deck successfully unlocked!', toastConfig);
                modalExit();
            } else {
                const toastConfig = { position: "bottom-left", autoClose: false, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined }
                toast.error('Access code invalid.', toastConfig);
            }
        });
        setAccessCode('');
    }

    const modalExit = () => {
        document.body.classList.remove("overflow-hidden");
        initiateModalExit('.modal--thieme-unlocker', hide);
    }

    const handleInputChange = (event) => {
        setAccessCode(event.target.value);
    };

    const getBuyLink = () => {
        if (variant === 'gberg') {
            return (
                <a
                    href="https://shop.thieme.com/Greenberg-s-Neurosurgery-Spaced-Repetition-Flashcards/000000000325470101"
                    className="button button--primary button--icon-right"
                    target="_blank">
                    Thieme Webshop<ExternalLink />
                </a>
            )
        } else if (variant === 'plastics') {
            return (
                <a
                    href="https://shop.thieme.com/Janis-and-Jones-Plastic-Surgery-Spaced-Repetition-Flashcards/000000000326290101"
                    className="button button--primary button--icon-right"
                    target="_blank">
                    Thieme Webshop<ExternalLink />
                </a>
            )
        }
    }

    const renderProductImage = () => {
        if (variant === 'gberg') {
            return (
                <img src={GreenbergProductImage} alt="Greenberg's Handbook of Neurosurgery Spaced-Repeition Flashcards"/>
            )
        } else if (variant === 'plastics') {
            return (
                <img src={JanisPlasticsProductImage} alt="Essentials of Plastic Surgery | Q&A Companion, Second Edition"/>
            )
        } else if (variant === 'top_score') {
            return (
                <img src={TopScoreProductImage} alt="Top Score for the Radiology Boards"/>
            )
        } else if (variant === 'ultrasound') {
            return (
                <img src={UltrasoundProductImage} alt="Ultrasound Q&A Review for the Boards"/>
            )
        } else if (variant === 'neuroradiology') {
            return (
                <img src={NeuroradiologyProductImage} alt="Neuroradiology Q&A For the Radiology Boards"/>
            )
        } else if (variant === 'gastrointestinal') {
            return (
                <img src={GastrointestimalProductImage} alt="Gastrointestinal Imaging Q&A For the Radiology Boards"/>
            )
        }
    }

    const renderUnlockContent = () => {
        if (variant === 'gberg') {
            return (
                <>
                    <div className='modal_content_main_body_left'>
                        <h2>Unlock <span className='highlight'>{deck.name}</span></h2>
                        {thiemePurchaseExpired && (
                            <p className='expired-message'>Your access to this deck has expired, but your progress has been saved. Please purchase the deck again to continue studying.</p>
                        )}    
                        <div className='input'>
                            <label>1. Purchase Deck</label>
                            <a
                                href="https://shop.thieme.com/Greenberg-s-Neurosurgery-Spaced-Repetition-Flashcards/000000000325470101"
                                className="button button--primary button--icon-right"
                                target="_blank">
                                Thieme Webshop<ExternalLink />
                            </a>
                        </div>
                        <p>You will receive a code via email after purchase.</p>
                        <form>
                            <div className='input'>
                                <label className='label'>2. Enter Access Code</label>
                                <input type="text" value={accessCode} onChange={handleInputChange} />
                            </div>
                        </form>
                    </div>
                    <div className='modal_content_main_body_right'>
                        <img src={GreenbergProductImage} alt="Greenberg's Handbook of Neurosurgery Spaced-Repeition Flashcards"/>
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <div className='modal_content_main_body_subleft'>
                        {variant === 'plastics'
                            && <img src={JanisPlasticsProductImage} alt="Essentials of Plastic Surgery | Q&A Companion, Second Edition"/>}
                        {variant === 'top_score'
                            && <img src={TopScoreProductImage} alt="Top Score for the Radiology Boards"/>}
                        {variant === 'ultrasound'
                            && <img src={UltrasoundProductImage} alt="Ultrasound Q&A Review for the Boards"/>}
                        {variant === 'neuroradiology'
                            && <img src={NeuroradiologyProductImage} alt="Neuroradiology Q&A For the Radiology Boards"/>}
                        {variant === 'gastrointestinal'
                            && <img src={GastrointestimalProductImage} alt="Gastrointestinal Imaging Q&A For the Radiology Boards"/>}
                    </div>
                    <div className='modal_content_main_body_subright'>
                        <h2>Unlock <span className='highlight'>{deck.name}</span></h2>
                        <div className='options'>
                            {thiemePurchaseExpired && (
                                <p className='expired-message'>Your access to this deck has expired, but your progress has been saved. Please purchase the deck again to continue studying.</p>
                            )}  
                            <div className='input'>
                                <label>Purchase Deck</label>
                            </div>
                            <Link to={`/checkout/${deck.stripe_product_id}`} className='button button--sub'>Unlock Now</Link>
                            <div className='or'>OR</div>
                            <form>
                                <div className='input'>
                                    <label className='label'>Redeem a Code</label>
                                    <input type="text" value={accessCode} onChange={handleInputChange} placeholder='Enter code...'/>
                                    {accessCode && <button className='button button--success' onClick={handleSaveChanges}>Unlock</button>}
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            )
        }
    }

    const renderUnlockActions = () => {
        if (variant === 'gberg') {
            return (
                <button className='button button--success' onClick={handleSaveChanges} disabled={!accessCode}>Unlock</button>
            );
        }
    }

    return (
        <Modal
            name={'modal--thieme-unlocker'}
            hide={hide}
            content={renderUnlockContent()}
            actions={renderUnlockActions()}
            cancelButtonText={'Close'}
        >
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    user: state.user,
    userProperties: state.userProperties,
    subscriptions: state.subscriptions,
});
const mapDispatchToProps = (dispatch) => ({
    redeemAccessCode: (accessCode) => dispatch(redeemAccessCode(accessCode))
});
export default connect(mapStateToProps, mapDispatchToProps)(ThiemeUnlocker);
