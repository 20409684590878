import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

function GamepadController({ handleButtonPress }) {
    const [gamepadIndex, setGamepadIndex] = useState(null);
    const animationFrameId = useRef(null);
    const buttonStates = useRef([]);

    const checkGamepad = () => {
        if (gamepadIndex !== null) {
            const controller = navigator.getGamepads()[gamepadIndex];
            controller?.buttons.forEach((button, buttonIndex) => {
                if (button.pressed && !buttonStates.current[buttonIndex]) {
                    // console.log(buttonIndex);
                    handleButtonPress(buttonIndex);
                }
                buttonStates.current[buttonIndex] = button.pressed;
            });

            // Check for axes movement (e.g. D-pad and joysticks)
            controller?.axes.forEach((axis, axisIndex) => {
                if(axisIndex === 0) { // D-pad left and right
                    if(axis === -1) {
                        handleButtonPress(100); // 100 is artificial button index for "D-pad left"
                    } else if(axis === 1) {
                        handleButtonPress(101); // 101 is artificial button index for "D-pad right"
                    }
                } else if(axisIndex === 1) { // D-pad up and down
                    if(axis === -1) {
                        handleButtonPress(102); // 102 is artificial button index for "D-pad up"
                    } else if(axis === 1) {
                        handleButtonPress(103); // 103 is artificial button index for "D-pad down"
                    }
                }
            });
        }
        animationFrameId.current = requestAnimationFrame(checkGamepad);
    }

    useEffect(() => {
        window.addEventListener('gamepadconnected', (e) => {
            setGamepadIndex(e.gamepad.index);
            if (e.gamepad.index !== null) {
                toast.success("Controller Connected", {
                    position: "bottom-left",
                    autoClose: 3000,
                    delayShow: 1000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "light"
                });
            }
        });
        return () => {
            if (animationFrameId.current) {
                cancelAnimationFrame(animationFrameId.current);
            }
        }
    }, []);

    useEffect(() => {
        if (animationFrameId.current) {
            cancelAnimationFrame(animationFrameId.current);
        }
        checkGamepad();
    }, [gamepadIndex]);

    return null;
}

const mapStateToProps = (state) => ({
    // Add necessary state props here
});
const mapDispatchToProps = (dispatch) => ({
    // Add necessary dispatch functions here
});
export default connect(mapStateToProps, mapDispatchToProps)(GamepadController);
