import React, { Component } from 'react';
import getConfig from "./BrowseConfig";
import getAdminConfig from "./BrowseAdminConfig";
import getCuratorConfig from "./BrowseCuratorConfig";
import CardEditor from '../builder/CardEditor';
import CardMover from '../mover/CardMover';
import { Search, Filter, X, ArrowLeft } from 'react-feather';
import isMobile from '../../util/isMobile';
import Select from 'react-select';
import customStyles from '../../util/singleSelectStyles';
import { getSearchValue } from '../../util/getSearchValueUtil';
import $ from "jquery";

export default class Browse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showFronts: false,
            showCardEditor: false,
            showBrowseClone: false,
            cardBeingEdited: null,
            cardBeingCloned: null,
            cardBeingDeleted: null,
            searchValue: '',
            deckFilterOptions: [],
            tagFilterOptions: [],
            resultsCount: 0,
            showSuspendedCards: false,
        }
        ahoy.track("View Browse");
    }

    componentDidMount() {
        window.getUserReferralToken = this.getUserReferralToken;

        this.props.clearActiveStudyProfile();
        this.props.fetchBrowseFilters().then(filterOptions => {
            let tagOptions = filterOptions.tags;
            let deckOptions = filterOptions.deck;
            deckOptions = deckOptions.map(opt => {
                return {
                    label: opt,
                    value: opt
                }
            })
            tagOptions = tagOptions
                .filter((tag) => !tag.includes('[hide]'))
                .map(opt => {
                    return {
                        label: opt,
                        value: opt
                    }
                })
            deckOptions.sort((a, b) => (a.label > b.label) ? 1 : -1);
            this.setState({ deckFilterOptions: deckOptions, tagFilterOptions: tagOptions });
        })

        if (!isMobile()) {
            $('#browse-table-search').trigger('focus');
        }

        let config;
        // if (this.props.user?.admin && this.props.user?.group !== 'thieme') {
        if (this.props.user?.admin) {
            config = getAdminConfig();
        } else if (this.props.user?.curator && this.props.user?.group !== 'thieme') {
            config = getCuratorConfig();
        } else {
            config = getConfig();
        }

        // Initialize the datatable
        const table = $('#browse-table').DataTable(config);

        // Every time the table draws/redraws, re-add event listeners
        table.on('draw', () => {
            $('.button--edit-card').on('click', (e) => {
                const cardId = parseInt(e.target.getAttribute('data-card-id'));
                const cardBeingEdited = table.data().toArray().find(row => row.id == cardId);
                this.setState({ cardBeingEdited });
            });
            $('.button--clone-card').on('click', (e) => {
                const cardId = parseInt(e.target.getAttribute('data-card-id'));
                const cardBeingCloned = table.data().toArray().find(row => row.id == cardId);
                this.setState({ cardBeingCloned });
            });
            $('.button--delete-card').on('click', (e) => {
                const cardId = parseInt(e.target.getAttribute('data-card-id'));
                const cardBeingDeleted = table.data().toArray().find(row => row.id == cardId);
                this.setState({ cardBeingDeleted });
                if (this.props.user?.curator) {
                    this.handleCardDelete();
                }
            });
            // $('.button--suspend-card').on('click', (e) => {
            //     const cardId = parseInt(e.target.getAttribute('data-card-id'));

            //     if (this.state.showSuspendedCards) {
            //         this.props.unSuspendCard(cardId);
            //     } else {
            //         this.props.suspendCard(cardId);
            //     }

            //     // Invalidate row in browse table
            //     const rowNum = this.table.rows().data().toArray().findIndex(row => row.id === cardId);
            //     this.table.row(rowNum).remove().draw(false);
            // });
            // Change suspend card button text depending on view
            // if (this.state.showSuspendedCards) {
            //     $('.button--suspend-card').text('Reactivate')
            // } else {
            //     $('.button--suspend-card').text('Suspend')
            // }

            // Calculate the number of results on redraw and store to state
            const resultsCount = table.page.info().recordsDisplay;
            $('#results-count').text(resultsCount);
            this.setState({ resultsCount });
        });

        // Every time one of the rows is expanded, re-add the event listeners
        // Necessary because the buttons are initially hidden in the collpased row
        $('#browse-table').on('click', 'td.dtr-control', () => {
            $('.button--edit-card').on('click', (e) => {
                const cardId = parseInt(e.target.getAttribute('data-card-id'));
                const cardBeingEdited = table.data().toArray().find(row => row.id == cardId);
                this.setState({ cardBeingEdited });
            });
            $('.button--clone-card').on('click', (e) => {
                const cardId = parseInt(e.target.getAttribute('data-card-id'));
                const cardBeingCloned = table.data().toArray().find(row => row.id == cardId);
                this.setState({ cardBeingCloned });
            });
            $('.button--delete-card').on('click', (e) => {
                const cardId = parseInt(e.target.getAttribute('data-card-id'));
                const cardBeingDeleted = table.data().toArray().find(row => row.id == cardId);
                this.setState({ cardBeingDeleted });
                if (this.props.user?.curator) {
                    this.handleCardDelete();
                }
            });
            // $('.button--suspend-card').on('click', (e) => {
            //     const cardId = parseInt(e.target.getAttribute('data-card-id'));

            //     if (this.state.showSuspendedCards) {
            //         this.props.unSuspendCard(cardId);
            //     } else {
            //         this.props.suspendCard(cardId);
            //     }

            //     // Invalidate row in browse table
            //     const rowNum = this.table.rows().data().toArray().findIndex(row => row.id === cardId);
            //     this.table.row(rowNum).remove().draw(false);
            // });
            // // Change suspend card button text depending on view
            // if (this.state.showSuspendedCards) {
            //     $('.button--suspend-card').text('Reactivate')
            // } else {
            //     $('.button--suspend-card').text('Suspend')
            // }
        });

        // // Every time a column is sorted this triggers
        // // Commented out because it seems to work without it
        // $('th.sorting').on('click', function () {
        //     const filter = $(this).text();
        //     setTimeout(() => { table.draw(); }, 250);
        //     ahoy.track("Click Browse Filter", { filter });
        // });

        this.table = table;
        // Removed because seems unnecessary
        // setTimeout(() => { table.draw(); }, 250);

        // Event listener shortcut for escaping card deletion modal
        $(window).on('keydown', (e) => {
            if (this.state.cardBeingDeleted && e.key === "Escape" || e.which === 27 || e.code === "Escape") {
                this.hideConfirmationModal();
            }
        });

        // Event listener for handling window resizing
        $(window).on('resize', (e) => {
            document.body.classList.remove("overflow-hidden");
            this.setState({ filterDrawerIsOpen: false });
        });

        // Handles auto-search from navbar
        let query = getSearchValue();
        if (query) {
            this.setState({ searchValue: query });
            $('#browse-table-search').val(query);
            // Make sure the browse table is initialized before searching/redrawing
            if ($.fn.dataTable.isDataTable('#browse-table')) {
                this.table.search(query).draw();
            } else {
                setTimeout(() => { this.table.search(query).draw() }, 300);
            }
            $('#results-count').text(this.table.page.info().recordsDisplay);
        }
        this.unlisten = this.props.history.listen(this.handleRouteChange);

        const button = $('<button>');
        const spanFront = $('<span>').addClass('front').text('View Backs');
        const spanBack = $('<span>').addClass('back').text('View Fronts');
        button.addClass('button--toggle-view');
        button.append(spanFront, spanBack);
        button.on('click', (e) => this.toggleView(e));
        $(table.column(0).header()).append(button);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.showFronts !== this.state.showFronts) {
            if (this.state.showFronts) {
                $('#browse-table').addClass('show-fronts');
            } else {
                $('#browse-table').removeClass('show-fronts');
            }
        }
    }

    componentWillUnmount() {
        this.props.setUserCards({ cards: [] });
        this.props.clearCreatedCards();
        document.body.classList.remove("overflow-hidden");
        this.unlisten();
    }

    handleRouteChange = () => {
        let query = getSearchValue();
        if (query) {
            this.setState({ searchValue: query });
            $('#browse-table-search').val(query);
            this.table.search(query).draw();
            $('#results-count').text(this.table.page.info().recordsDisplay);
        }
    }

    toggleView = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState((prevState) => ({ showFronts: !prevState.showFronts }));
    }

    handleSearchChange = (e) => {
        this.setState({ searchValue: e.target.value });
    }

    handleSearchSubmit = (e) => {
        e.preventDefault();
        this.table.search(this.state.searchValue).draw();
        $('#results-count').text(this.table.page.info().recordsDisplay);
    }

    getUserReferralToken = () => {
        return this.props.user.referral_token;
    }

    toggleFilterDrawer = () => {
        this.setState({ filterDrawerIsOpen: !this.state.filterDrawerIsOpen }, () => {
            if (this.state.filterDrawerIsOpen && $(window).width() < 900) {
                document.body.classList.add("overflow-hidden");
            } else {
                document.body.classList.remove("overflow-hidden");
            }
        });
        // Wait for Drawer Animation
        setTimeout(() => {
            this.table.draw();
        }, 300);
    }

    hideCardEditor = () => {
        this.setState({ cardBeingEdited: {} });
    }

    hideCardMover = () => {
        this.setState({ cardBeingCloned: {} });
    }

    hideConfirmationModal = () => {
        this.setState({ cardBeingDeleted: {} });
    }

    handleCardDelete = () => {
        const cardId = this.state.cardBeingDeleted.id;
        this.props.deleteCard(cardId).then(() => {
            const rowNum = this.table.rows().data().toArray().findIndex(row => row.id === cardId);
            let rowData = this.table.row(rowNum).data();
            rowData.question = 'DELETED';
            rowData.answer = 'DELETED';
            rowData.deck = 'DELETED';
            this.table.row(rowNum).data(rowData).invalidate();
        });
        this.setState({ cardBeingDeleted: {} });
    }

    showBrowseClone = () => {
        this.setState({ showBrowseClone: true });
    }

    hideBrowseClone = () => {
        this.setState({ showBrowseClone: false });
    }

    renderConfirmation() {
        return (
            <div className={`modal modal--small modal--delete-card ${!_.isEmpty(this.state.cardBeingDeleted) ? 'is-open' : ''}`} onClick={this.hideConfirmationModal}>
                <div className='modal_content'>
                    <div className="modal_content_main">
                        <div className="modal_content_main_top p-0">
                            <h2 className="modal_content_main_top_title m-b-0 p-r-4">Are you sure you want to permanently delete this card?</h2>
                            <button className="modal_content_main_top_close" aria-label="Close Modal" onClick={() => this.hideConfirmationModal()}>
                                <X />
                            </button>
                        </div>
                    </div>
                    <div className='modal_content_actions'>
                        <button className="button button--secondary" onClick={this.hideConfirmationModal}>Cancel</button>
                        <button className="button button--error" onClick={this.handleCardDelete}>Delete</button>
                    </div>
                </div>
            </div>
        )
    }

    handleDeckNameChange = (value) => {
        const searchVal = _.get(value, `value`, null);
        this.table.column(1).search(searchVal).draw();
    }

    handleTagChange = (value) => {
        const searchVal = _.get(value, `value`, '');
        this.table.column(2).search(searchVal).draw();
    }

    suspendToggleHandler = (e) => {
        const value = e.target.value;
        this.table.column(3).search(value).draw();
        this.setState({ showSuspendedCards: !this.state.showSuspendedCards });
    }

    render() {
        return (
            <div className={`layout layout--browse ${this.state.filterDrawerIsOpen ? 'drawer-is-open' : ''}`}>
                <div className="layout--browse__container layout_container">
                    <div className="layout--browse__container__filter">
                        <div className='layout--browse__container__filter_header'>
                            <h2>Filters</h2>
                            <button className='button button--secondary button--close-drawer' onClick={() => this.toggleFilterDrawer()}><ArrowLeft /></button>
                        </div>
                        <div className='input'>
                            <label>Deck</label>
                            <Select
                                options={this.state.deckFilterOptions}
                                onChange={(value) => this.handleDeckNameChange(value)}
                                styles={customStyles}
                                isSearchable
                                isClearable
                                menuPortalTarget={document.body}
                                menuPlacement="auto"
                                menuPosition="fixed"
                            />
                            <label className='m-t-2'>Tags</label>
                            <Select
                                options={this.state.tagFilterOptions}
                                onChange={(value) => this.handleTagChange(value)}
                                styles={customStyles}
                                isSearchable
                                isClearable
                                menuPortalTarget={document.body}
                                menuPlacement="auto"
                                menuPosition="fixed"
                            />
                            {/* <div className='toggle m-t-2'>
                                <div className="toggle_option">
                                    <input
                                        type="radio"
                                        name="show-suspended-toggle-drawer"
                                        value="active"
                                        checked={!this.state.showSuspendedCards}
                                        onChange={this.suspendToggleHandler}
                                    />
                                    <label>Active</label>
                                </div>
                                <div className="toggle_option">
                                    <input
                                        type="radio"
                                        name="show-suspended-toggle-drawer"
                                        value="suspended"
                                        checked={this.state.showSuspendedCards}
                                        onChange={this.suspendToggleHandler}
                                    />
                                    <label>Suspended</label>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="layout--browse__container__main">
                        <h1>Browse <span>Cards</span></h1>
                        <div className='search-wrapper'>
                            <button className='button button--secondary button--filter' onClick={() => this.toggleFilterDrawer()}><Filter />Filters</button>
                            <form onSubmit={this.handleSearchSubmit}>
                                <div className="browse-search">
                                    <Search />
                                    <input id="browse-table-search" className="browse-search__input" type="text" placeholder="Search for any card..." value={this.state.searchValue} onChange={this.handleSearchChange} />
                                </div>
                                <button className='button submit-search-button'>
                                    <Search />
                                    <span>Search</span>
                                    <input type="submit" />
                                </button>
                            </form>
                        </div>
                        <div className='table-header'>
                            <div className='table-header_left'>
                                <span id="results-count" className='count'>0</span><span className='label'>Cards</span>
                            </div>
                            <div className='table-header_right'>
                                {(this.props.user.admin || this.props.user.curator) &&
                                    <button
                                        onClick={this.showBrowseClone}
                                        className="button button--secondary button--small"
                                    >
                                        Clone Results
                                    </button>
                                }
                                <Filter />
                                <Select
                                    options={this.state.deckFilterOptions}
                                    onChange={(value) => this.handleDeckNameChange(value)}
                                    styles={customStyles}
                                    isSearchable
                                    isClearable
                                    placeholder={'Filter by deck...'}
                                    menuPortalTarget={document.body}
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                />
                                <Select
                                    options={this.state.tagFilterOptions}
                                    onChange={(value) => this.handleTagChange(value)}
                                    styles={customStyles}
                                    isSearchable
                                    isClearable
                                    placeholder={'Filter by tag...'}
                                    menuPortalTarget={document.body}
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                />
                                {/* <div className='toggle'>
                                    <div className="toggle_option">
                                        <input
                                            type="radio"
                                            name="show-suspended-toggle"
                                            value="active"
                                            checked={!this.state.showSuspendedCards}
                                            onChange={this.suspendToggleHandler}
                                        />
                                        <label>Active</label>
                                    </div>
                                    <div className="toggle_option">
                                        <input
                                            type="radio"
                                            name="show-suspended-toggle"
                                            value="suspended"
                                            checked={this.state.showSuspendedCards}
                                            onChange={this.suspendToggleHandler}
                                        />
                                        <label>Suspended</label>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div id="browse-table-wrapper" className="data-table-wrapper">
                            <div id="search-loading-icon" className="lds-ellipsis">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <table id="browse-table" className="responsive" style={{ width: '100%' }} />
                            {!_.isEmpty(this.state.cardBeingEdited) &&
                                <CardEditor
                                    user={this.props.user}
                                    table={this.table}
                                    card={this.state.cardBeingEdited}
                                    hideModal={this.hideCardEditor}
                                    updateCardContent={this.props.updateCardContent}
                                />
                            }
                            {!_.isEmpty(this.state.cardBeingCloned) &&
                                <CardMover
                                    card={this.state.cardBeingCloned}
                                    hide={this.hideCardMover}
                                    isBrowseClone={false}
                                />
                            }
                            {this.state.showBrowseClone &&
                                <CardMover
                                    hide={this.hideBrowseClone}
                                    isBrowseClone={true}
                                    cloneCount={this.state.resultsCount}
                                />
                            }
                            {!_.isEmpty(this.state.cardBeingDeleted) && !this.props.user?.curator && this.renderConfirmation()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
