import {
	RECEIVE_PAST_CARD,
    CLEAR_PAST_CARDS,
    UNDO_CARD_RESPONSE,
} from '../../actions/allActions';

const pastCardsReducer = (state = [], action) => { 
    Object.freeze(state);
	let nextState = _.cloneDeep(state);
    const PAST_CARDS_LIMIT = 5;

	switch (action.type) {
		case RECEIVE_PAST_CARD:
            nextState.unshift(action.payload);
            if (nextState.length > PAST_CARDS_LIMIT) {
                nextState.length = PAST_CARDS_LIMIT;
            }
			return uniqueCardsInfo(nextState);
		case CLEAR_PAST_CARDS:
			return [];
		case UNDO_CARD_RESPONSE:
			nextState.shift();
            return nextState;
		default:
			return state;
	}
}

const uniqueCardsInfo = (arr) => {
  let a = arr.concat();
  for(let i=0; i<a.length; ++i) {
      for(let j=i+1; j<a.length; ++j) {
          if(a[i]['card'].id === a[j]['card'].id)
              a.splice(j--, 1);
      }
  }
  return a;
}

// const uniqueCardsInfo = (arr) => {
//     return [...new Map(arr.map((cardInfo) => [cardInfo["card"]["id"], cardInfo])).values()];
// }

export default pastCardsReducer;