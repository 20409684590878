const formatKenhubLinks = (str) => {
    if (_.isEmpty(str)) return str;
    for (let i = 0; true; i++) {
        const regex = /<a href="((https:\/\/)?(www.)?kenhub.com.*?)".*?>/;
        const match = str.match(regex);
        if (!match) break;

        let ahref = match[0];
        let url = match[1];
        let newUrl = url.split('?')[0] + '?utm_source=synaptiq&utm_medium=referral&utm_campaign=image-license';

        ahref = ahref.replace(url, newUrl);
        ahref = ahref.replace(/class=".*?"/, '');
        ahref = ahref.replace(/rel=".*?"/, '');
        const ahrefModified = ahref.slice(0, 3) + 'class="link-kenhub"' + ahref.slice(2);
        const externalLinkIcon = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 8.66667V12.6667C12 13.0203 11.8595 13.3594 11.6095 13.6095C11.3594 13.8595 11.0203 14 10.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V5.33333C2 4.97971 2.14048 4.64057 2.39052 4.39052C2.64057 4.14048 2.97971 4 3.33333 4H7.33333" stroke="var(--kenhub-brand)" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10 2H14V6" stroke="var(--kenhub-brand)" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.6665 9.33333L13.9998 2" stroke="var(--kenhub-brand)" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`;
        const linkWithIcon = ahrefModified + externalLinkIcon;

        str = str.replace(/<a href="(https:\/\/)?(www.)?kenhub.com.*?".*?>/, linkWithIcon);
    }
    return str;
}

export default formatKenhubLinks;
