import {
	RECEIVE_USER_SUBSCRIPTIONS,
	CLEAR_USER
} from '../../actions/allActions';

const subscriptionsReducer = (state = {}, action) => { 
	Object.freeze(state);

	switch (action.type) {
		case RECEIVE_USER_SUBSCRIPTIONS:
			return action.payload.subscriptions;
		case CLEAR_USER:
			return {};
		default:
			return state;
	}
}

export default subscriptionsReducer;
