const smoothScrollToElement = (elementId, containerSelector) => {
    const element = document.getElementById(elementId);
    if (element) {
        const container = document.querySelector(containerSelector);
        if (container) {
            const elementOffsetTop = element.offsetTop;
            const containerOffsetTop = container.offsetTop;
            const scrollToOffset = elementOffsetTop - containerOffsetTop;

            const scrollOptions = {
                top: scrollToOffset,
                behavior: 'smooth'
            };

            container.scrollTo(scrollOptions);
        }
    }
};

export default smoothScrollToElement;
