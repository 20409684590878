export const cacheCardImages = cards => {
    if (cards[0] === null) return;
    for (const card of cards) {
        let urls = getImageUrls(card.question)
        urls = urls.concat(getImageUrls(card.answer))
        if (!_.isEmpty(card.extra)) urls = urls.concat(getImageUrls(card.extra))
        if (!_.isEmpty(card.question_overlay)) urls = urls.concat(getImageUrls(card.question_overlay))
        if (!_.isEmpty(card.answer_overlay)) urls = urls.concat(getImageUrls(card.answer_overlay))

        preloadImages(urls)
    }
}

const getImageUrls = (string) => {
    const imgRex = /<img.*?src="(.*?)"[^>]*?>/g;
    const images = [];
    let img;
    while ((img = imgRex.exec(string))) {
        images.push(img[1]);
    }
    return images;
}

const preloadImages = (array) => {
    if (!preloadImages.list) {
        preloadImages.list = [];
    }
    var list = preloadImages.list;
    for (var i = 0; i < array.length; i++) {
        var img = new Image();
        img.onload = function() {
            var index = list.indexOf(this);
            if (index !== -1) {
                // remove image from the array once it's loaded
                // for memory consumption reasons
                list.splice(index, 1);
            }
        }
        list.push(img);
        img.src = array[i];
        // console.log('##### img.src: ' + img.src);
    }
}
