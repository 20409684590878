import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { debounce } from 'lodash';
import { Flip } from 'react-toastify';
import Fun1 from '../../../assets/images/fun/fun-1.jpg';
import Fun2 from '../../../assets/images/fun/fun-2.jpg';
import Fun3 from '../../../assets/images/fun/fun-3.jpg';
import Fun4 from '../../../assets/images/fun/fun-4.jpg';
import Fun5 from '../../../assets/images/fun/fun-5.jpg';
import Fun6 from '../../../assets/images/fun/fun-6.jpg';
import Fun7 from '../../../assets/images/fun/fun-7.jpg';
import Fun8 from '../../../assets/images/fun/fun-8.jpg';
import Fun9 from '../../../assets/images/fun/fun-9.jpg';
import keyContainsModifier from '../../util/keyContainsModifier';

function NavigationKeybinds({ user }) {
    const [keybindsDisabled, setKeybindsDisabled] = useState(false);
    const history = useHistory();
    let location = useLocation();
    const FUN_DEFAULTS = [
        {
            imgSrc: Fun1,
            imgAlt: "Elephant",
            quote: '“The greater the difficulty, the more the glory in surmounting it.”',
            author: '— Epicurus'
        },
        {
            imgSrc: Fun2,
            imgAlt: "Puffin",
            quote: '“Do or do not. There is no try.”',
            author: '— Yoda'
        },
        {
            imgSrc: Fun3,
            imgAlt: "Giraffe",
            quote: '“If people are doubting how far you can go, go so far that you can’t hear them anymore.”',
            author: '— Michele Ruiz'
        },
        {
            imgSrc: Fun4,
            imgAlt: "Fennec Fox",
            quote: '“The best time to plant a tree was 20 years ago. The second best time is now.”',
            author: '— Chinese Proverb'
        },
        {
            imgSrc: Fun5,
            imgAlt: "Bear",
            quote: '“Opportunity is missed by most people because it is dressed in overalls and looks like work.”',
            author: '— Thomas Eddison'
        },
        {
            imgSrc: Fun6,
            imgAlt: "Panda",
            quote: '“Nothing is impossible, the word itself says “I’m possible!”',
            author: '— Audrey Hepburn'
        },
        {
            imgSrc: Fun7,
            imgAlt: "Sea Turtle",
            quote: '“I’ve missed more than 9,000 shots in my career. I’ve lost almost 300 games. 26 times I’ve been trusted to take the game winning shot and missed. I’ve failed over and over and over again in my life, and that is why I succeed.”',
            author: '— Michael Jordan'
        },
        {
            imgSrc: Fun8,
            imgAlt: "Hippos",
            quote: '“The secret of getting ahead is getting started.”',
            author: '— Babe Ruth'
        },
        {
            imgSrc: Fun9,
            imgAlt: "Lamb",
            quote: '“It’s hard to beat a person who never gives up.”',
            author: '— Babe Ruth'
        }
    ];


    const returnFun = () => {
        let index = 0;
        if (localStorage.getItem('syn-fun-index') !== null) {
            index = parseInt(localStorage.getItem('syn-fun-index'));
        }
        if (index === FUN_DEFAULTS.length) {
            index = 0;
        }
        const funToastStructure = (
            <div className='toast--fun_content'>
                <div>
                    <img src={FUN_DEFAULTS[index].imgSrc} alt={FUN_DEFAULTS[index].imgAlt} />
                </div>
                <div>
                    <div className='quote'>{FUN_DEFAULTS[index].quote}</div>
                    <div className='author'>{FUN_DEFAULTS[index].author}</div>
                </div>
            </div>
        );
        localStorage.setItem('syn-fun-index', ++index);
        toast(() => funToastStructure, {
            transition: Flip,
            toastClassName: 'toast--fun',
            position: "top-center",
            autoClose: 7500,
            delayShow: 1000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
            onOpen: () => document.getElementsByClassName('Toastify')[0].classList.add('toast--fun'),
            onClose: () => document.getElementsByClassName('Toastify')[0].classList.remove('toast--fun')
        });
    }

    useEffect(() => {
        window.addEventListener('keydown', debouncedChangeHandler);
    }, []);

    useEffect(() => {
        if (location.pathname.includes('/deck') || location.pathname.includes('/builder')) {
            window.removeEventListener('keydown', debouncedChangeHandler);
            setKeybindsDisabled(true);
        } else if (keybindsDisabled) {
            window.addEventListener('keydown', debouncedChangeHandler);
            setKeybindsDisabled(false);
        }
    }, [location, keybindsDisabled]);
    
    const keyBinds = (e) => {
        // If in an input field, return.
        if (this !== e.target && (/textarea|select|input/i.test( e.target.nodeName ) || e.target.type === "text" || e.target.isContentEditable)) { return; }
        // Keybinds
        if (!keyContainsModifier(e) && (e.key === "i" || e.which === 73 || e.code === "KeyI")) {
            history.push('/upload');
        } else if (!keyContainsModifier(e) && (e.key === "c" || e.which === 67 || e.code === "KeyC")) {
            e.preventDefault();
            history.push('/browse');
        } else if (!keyContainsModifier(e) && (e.key === "s" || e.which === 83 || e.code === "KeyS")) {
            history.push('/stats');
        } else if (!keyContainsModifier(e) && (e.key === "a" || e.which === 65 || e.code === "KeyA")) {
            history.push('/settings');
        } else if (!keyContainsModifier(e) && (e.key === "h" || e.which === 72 || e.code === "KeyH")) {
            history.push('/');
        } else if (!keyContainsModifier(e) && (e.key === "q" || e.which === 81 || e.code === "KeyQ")) {
            returnFun();
        }
    };

    const debouncedChangeHandler = useMemo(
        () => debounce(keyBinds, 50, { 'leading': true, 'trailing': false })
    , []);

    return null;
}

const mapStateToProps = (state) => ({
    user: state.user
});
const mapDispatchToProps = (dispatch) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(NavigationKeybinds);
