import React from 'react';
import Modal from './Modal';
import { Link } from 'react-router-dom';
import { Unlock, Check } from 'react-feather';

function ProNudgeModal({ hide }) {
    return (
        <Modal
            name={'modal--pro-nudge'}
            title={'Free Trial Running Out'}
            small={true}
            hide={hide}
            cancelButtonText={'Close'}
            content={
                <>
                    <p>You're free trial of Synaptiq Pro is almost finished.</p>
                    <p className='m-b-0'>Upgrade to retain access to Pro features:</p>
                    <ul>
                        <li><span><Check/></span>Unlimited Pro decks</li>
                        <li><span><Check /></span>Unlimited Anki import</li>
                        <li><span><Check/></span>Priority support from physicians</li>
                    </ul>
                </>
            }
            actions={
                <Link to="/checkout" className="button"><Unlock />Unlock Pro</Link>
            }
        />
    );
}

export default ProNudgeModal;
