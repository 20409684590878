import React, { Component } from 'react';
import QuillEditor from './QuillEditor';
import Modal from '../misc/Modal';
import { initiateModalExit } from '../../util/modalExit';
import formatKenhubLinks from '../../util/formatKenhubLinks';

export default class CardEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            questionHtml: '',
            answerHtml: '',
            photoCreditsHtml: '',
            axonLearnMoreHtml: '',
            axonQuizMeHtml: '',
            axonQuizMeAnswerHtml: '',
            ord: this.props.card.ord || 0,
            model: 'basic',
            backShowsFront: false,
            initializedDebounce: false, 
            debounced: null,
            htmlMode: false,
        }
    }

    componentDidMount() {
        this.initializedDebounce();
        const card = this.props.card;
        if (!_.isEmpty(card)) {
            this.handleCardMount(card)
        }
    }

    componentWillUnmount() {
        this.removeKeyBinds();
    }

    // Set Home Page Keybinds
    initializedDebounce = () => {
        if (!this.state.initializedDebounce) {
            const debounced = _.debounce(this.keyBinds, 50, { 'leading': true, 'trailing': false })
            window.addEventListener('keydown', debounced)
            this.setState({ initializedDebounce: true, debounced })
        }
    }
    removeKeyBinds = () => {
        if (this.state.initializedDebounce) {
            window.removeEventListener('keydown', this.state.debounced);
        }
    }
    keyBinds = (e) => {
        // Escape Keybinds
        if ((e.metaKey && (e.key === "Enter" || e.which === 13 || e.code === "Enter"))) {
            this.handleEditSubmit();
        }
    }
    
    handleCardMount(card) {
        if (card.model == 'cloze') {
            const questionHtml = this.formatClozeForEditing(card.question, card.answer);
            const extraHtml = this.handlePhotoCredits(card.extra);
            this.setState({
                questionHtml: questionHtml,
                answerHtml: extraHtml,
                axonLearnMoreHtml: card.axon_learn_more,
                axonQuizMeHtml: card.axon_quiz_me,
                axonQuizMeAnswerHtml: card.axon_quiz_me_answer,
                model: 'cloze'
            });
        } else if (card.model?.toLowerCase().includes('basic')) {
            this.setState({
                questionHtml: card.question,
                answerHtml: card.answer,
                axonLearnMoreHtml: card.axon_learn_more,
                axonQuizMeHtml: card.axon_quiz_me,
                axonQuizMeAnswerHtml: card.axon_quiz_me_answer,
                model: 'basic'
            });
        } else if (card.model?.toLowerCase().includes('multichoice')) {
            const questionHtml = this.formatMultichoiceQuestionForEditing(card.question, card.multi_choice_answer);
            const extraContent = this.formatMultichoiceExtraForEditing(card.extra);
            this.setState({
                questionHtml: questionHtml,
                answerHtml: extraContent,
                model: 'multichoice',
                ord: card.ord
            });
        }
    }

    removeFront(answer) {
        const split = answer.split("<hr className=\"answer solid\"></hr>");
        if (split.length > 1) {
            this.setState({ backShowsFront: true });
        }
        return split.at(-1);
    }

    isCloze() {
        return this.props.card.model?.toLowerCase().includes('cloze') || this.props.card.question.includes('card__cloze')
    }

    formatClozeForEditing(questionHtml, answerHtml) {
        let isMatch = true;
        for (let i = 0; isMatch; i++) {
            const regexAnswerGlobal = /<span class="card__cloze".*?>(.*?)<\/span>/g;
            const regexAnswer = /<span class="card__cloze".*?>(.*?)<\/span>/;

            const currentAnswerSpan = answerHtml.match(regexAnswerGlobal)?.[i];
            const answer = currentAnswerSpan?.match(regexAnswer)?.[1];

            const regexQuestion = /<span class="card__cloze".*?>\[(.*?)\]<\/span>/;
            const questionMatch = questionHtml.match(regexQuestion);
            const hint = questionMatch?.[1];

            // break if there are no more cloze questions to format
            if (!questionMatch) break;

            questionHtml = questionHtml.replace(
                /<span class="card__cloze".*?\[.*?\]<\/span>/, 
                `<cloze data-cloze-group="1" class="cloze-tile cloze-group-1">${answer}${hint == '...' ? '' : ' | ' + hint}</cloze>`
            )
        }
        return questionHtml;
    }

    formatMultichoiceQuestionForEditing(question, answerNum) {
        let formattedQuestion = question.replace("<span>A.", "<span>[[A.");
        const answerLetter = this.answerNumberToLetter(answerNum);
        formattedQuestion = formattedQuestion.replace("</span></label></div>", `]](${answerLetter})</span></label></div>`);
        formattedQuestion = formattedQuestion.replace(/<br>/g, '');
        return formattedQuestion;
    }

    formatMultichoiceExtraForEditing(extraContent) {
        let formattedContent = extraContent.replace('<p><strong>Explanation</strong></p>', '');
        formattedContent = formattedContent.replace(/<br>/g, '');
        return formattedContent;
    }

    answerNumberToLetter(num) {
        const mapping = ['A', 'B', 'C', 'D', 'E'];
        if (num >= 0 && num <= 4) {
            return mapping[num];
        } else {
            return '';
        }
    }

    handlePhotoCredits(extraHtml) {
        if (extraHtml.includes('<div class="photo-credits">')) {
            const photoCreditsHtml = this.extractPhotoCredits(extraHtml);
            this.setState({ photoCreditsHtml });
            extraHtml = extraHtml.replace(photoCreditsHtml, '');
            return extraHtml;
        } else {
            return extraHtml;
        }
    }

    extractPhotoCredits(htmlString) {
        const openingTag = '<div class="photo-credits">';
        const closingTag = '</div>\n</div>';

        const startIndex = htmlString.indexOf(openingTag);
        const endIndex = htmlString.indexOf(closingTag, startIndex);

        if (startIndex === -1 || endIndex === -1) return '';

        return htmlString.slice(startIndex, endIndex + closingTag.length);
    }

    setQuestionHtml = (html) => {
        if (this.state.htmlMode) {
            this.setState({ questionHtml: html.target.value });
        } else {
            this.setState({ questionHtml: html });
        }
    }

    setAnswerHtml = (html) => {
        if (this.state.htmlMode) {
            this.setState({ answerHtml: html.target.value });
        } else {
            this.setState({ answerHtml: html });
        }
    }

    setAxonLearnMoreHtml = (e) => {
        this.setState({ axonLearnMoreHtml: e.target.value });
    }

    setAxonQuizMeHtml = (e) => {
        this.setState({ axonQuizMeHtml: e.target.value });
    }

    setAxonQuizMeAnswerHtml = (e) => {
        this.setState({ axonQuizMeAnswerHtml: e.target.value });
    }

    handleEditSubmit() {
        if (_.isEmpty(this.props.card)) return;
        const { id, model, deck_id, src } = this.props.card;
        let answerHtml = this.state.answerHtml;
        if (model == 'cloze' && this.state.photoCreditsHtml) {
            answerHtml = answerHtml + this.state.photoCreditsHtml;
            this.setState({ photoCreditsHtml: '' });
        }

        const questionHtml = formatKenhubLinks(this.state.questionHtml);
        answerHtml = formatKenhubLinks(answerHtml);

        let card = {};
        if (model == 'multichoice') {
            const correctAnswer = this.extractMultichoiceCorrectAnswer(questionHtml);
            const mainContent = this.formatMultichoiceContent(questionHtml);
            const extraContent = this.formatMultichoiceExtraContent(answerHtml);
            card = {
                id,
                question: mainContent,
                answer: mainContent,
                deck_id,
                extra: extraContent,
                model,
                ord: this.state.ord,
                multi_choice_answer: correctAnswer,
            }
        } else {
            card = {
                id,
                question: questionHtml,
                answer: model == 'cloze' ? '' : answerHtml,
                deck_id,
                extra: model == 'cloze' ? answerHtml : '',
                model,
                src,
                ord: this.state.ord,
                axon_learn_more: this.state.axonLearnMoreHtml,
                axon_quiz_me: this.state.axonQuizMeHtml,
                axon_quiz_me_answer: this.state.axonQuizMeAnswerHtml
            }
        }
        this.props.updateCardContent(card).then(() => {
            this.props.table?.ajax.reload(null, false);
        });
        initiateModalExit('.modal--card-editor', this.props.hideModal);
    }

    extractMultichoiceCorrectAnswer = (inputString) => {
        const regex = /\]\]\((.)\)/;
        const match = inputString.match(regex);

        if (match && match[1]) {
            const letter = match[1];
            // Convert letter A-E to corresponding number 0-4
            return letter.charCodeAt(0) - 'A'.charCodeAt(0);
        } else {
            return null;
        }
    }

    formatMultichoiceContent = (questionHtml) => {
        // Remove non-breaking spaces
        let formattedContent = questionHtml.replace('&nbsp;', '');
        // Remove the answer substring
        formattedContent = formattedContent.replace(/\]\]\((.)\)/, ']]');
        // Remove possible <p> tags surrounding multichoice option section
        formattedContent = formattedContent.replace('<p>[[', '[[').replace(']]</p>', ']]');
        // Format image substring
        formattedContent = formattedContent.replace(/<p>\{(.*?)\}<\/p>/g, '<img src="https://d1lze488p2cr08.cloudfront.net/radiology/top-score/images/$1.jpg">');
        // Format multichoice section
        formattedContent = this.formatMultichoiceOptions(formattedContent);

        return formattedContent;
    }

    formatMultichoiceExtraContent = (extraContent) => {
        if (!extraContent) return extraContent;

        // Remove non-breaking spaces
        let formattedContent = extraContent.replace('&nbsp;', '');

        // Start with "<b>Explanation</b>"
        formattedContent = "<p><strong>Explanation</strong></p>" + formattedContent;

        // Replace "Other choices and discussion" with bold tag
        formattedContent = formattedContent.replace("Other choices and discussion", "<strong>Other choices and discussion</strong>");

        return formattedContent;
    }

    formatMultichoiceOptions = (inputString) => {
        // inputString.replace('<p>[[', '').replace(']]</p>', ']]');

        const regex = /\[\[((.|\n|\r)*?)\]\]/;
        const matches = inputString.match(regex);
        if (!matches || matches.length < 2) {
            return inputString;
        }

        const choices = matches[1].replace(/<\/?p>/g, ' ').split(/\s(?=[A-E]\.)/).map(choice => choice.trim());
        let htmlOutput = '<div id="multichoice-options">';

        choices.forEach((choice, index) => {
            htmlOutput += `<label id="choice-${index}" for="choice-${index}-input"><input id="choice-${index}-input" type="radio" name="multichoice" /><span>${choice}</span></label>`;
        });

        htmlOutput += '</div>';
        return inputString.replace(regex, htmlOutput);
    }

    toggleHtmlMode = () => {
        this.setState({htmlMode: !this.state.htmlMode})
    }

    render() {
        return (
            <Modal
                name={'modal--card-editor'}
                title={'Edit Card'}
                hide={this.props.hideModal}
                content={
                    <div className='builder'>
                        <div className='editors'>
                            <div className='question-container'>
                                <h3>Front</h3>
                                {!this.state.htmlMode && <QuillEditor
                                    version={'editor-question'}
                                    value={this.state.questionHtml}
                                    setValue={this.setQuestionHtml}
                                />}
                                {this.state.htmlMode && <textarea className='html-editor' value={this.state.questionHtml} onChange={(e) => this.setQuestionHtml(e)}/>}
                            </div>
                            <div className='answer-container'>
                                {this.isCloze() ? (<h3>Extra</h3>) : (<h3>Back</h3>)}
                                {!this.state.htmlMode && <QuillEditor
                                    version={'answer'}
                                    value={this.state.answerHtml}
                                    setValue={this.setAnswerHtml}
                                    userAdmin={this.props.user.admin || this.props.user.curator}
                                    clozeEditor={this.state.model === 'cloze'}
                                />}
                                {this.state.htmlMode && <textarea className='html-editor' value={this.state.answerHtml} onChange={(e) => this.setAnswerHtml(e)}/>}
                            </div>
                            {(this.props.user?.admin || this.props.user?.curator) && (
                                <> 
                                    <div>
                                        <div className='ord-setter m-b-1'>
                                            <label for="quantity"><h3>Order</h3></label>
                                            <input type="number" name="card-ord" value={this.state.ord} onChange={(e) => this.setState({ ord: e.target.value })} min="-99999999" max="99999999"></input>
                                        </div>
                                        {this.props.user?.admin && this.state.model === 'basic' && <button className='button button--secondary' onClick={this.toggleHtmlMode}>{this.state.htmlMode ? 'Default Mode' : 'HTML Mode'}</button>}
                                    </div>
                                    {this.state.model !== 'multichoice' &&
                                        <div>
                                            <h3>Axon Learn More</h3>
                                            <textarea className='html-editor' value={this.state.axonLearnMoreHtml} onChange={(e) => this.setAxonLearnMoreHtml(e)}/>
                                        </div>
                                    }
                                    {/* <div>
                                        <h3>Axon Quiz Me</h3>
                                        <textarea className='html-editor' value={this.state.axonQuizMeHtml} onChange={(e) => this.setAxonQuizMeHtml(e)}/>
                                    </div>
                                    <div>
                                        <h3>Axon Quiz Me Answer</h3>
                                        <textarea className='html-editor' value={this.state.axonQuizMeAnswerHtml} onChange={(e) => this.setAxonQuizMeAnswerHtml(e)}/>
                                    </div> */}
                                </>
                            )}
                        </div>
                    </div>
                }
                actions={
                    <button className="button" onClick={() => this.handleEditSubmit()}>Update</button>
                }
            />
        )
    }
}

