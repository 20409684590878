import { connect } from 'react-redux'
import CardBuilder from './CardBuilder'
import { 
    createCards,
    deleteCard,
    receiveSelectedDeck,
    clearCreatedCards,
    updateCardContent
} from '../../actions/allActions'

const mapStateToProps = (state) => {
    return {
        user: state.user,
        userProperties: state.userProperties,
        decks: state.decks,
        decksTree: state.decksTree,
        selectedDeckId: state.selectedDeck,
        createdCards: state.createdCards
    }
}

const mapDispatchToProps = dispatch => {
    return {
        createCards: (card) => dispatch(createCards(card)),
        clearSelectedDeck: () => dispatch(receiveSelectedDeck(null)),
        clearCreatedCards: () => dispatch(clearCreatedCards()),
        deleteCard: (cardId) => dispatch(deleteCard(cardId)),
        updateCardContent: (card) => dispatch(updateCardContent(card)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardBuilder);
