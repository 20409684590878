import React, { Component } from 'react';
import Modal from '../misc/Modal';

import Space from '../../../assets/images/icons/key-space.svg';
import Space_Dark from '../../../assets/images/icons/key-space-dark.svg';
import Key1 from '../../../assets/images/icons/key-1.svg';
import Key1_Dark from '../../../assets/images/icons/key-1-dark.svg';
import Key2 from '../../../assets/images/icons/key-2.svg';
import Key2_Dark from '../../../assets/images/icons/key-2-dark.svg';
import Key3 from '../../../assets/images/icons/key-3.svg';
import Key3_Dark from '../../../assets/images/icons/key-3-dark.svg';
import Key4 from '../../../assets/images/icons/key-4.svg';
import Key4_Dark from '../../../assets/images/icons/key-4-dark.svg';
import Key5 from '../../../assets/images/icons/key-5.svg';
import Key5_Dark from '../../../assets/images/icons/key-5-dark.svg';
import Z from '../../../assets/images/icons/key-z.svg';
import Z_Dark from '../../../assets/images/icons/z-dark.svg';
import E from '../../../assets/images/icons/key-e.svg';
import E_Dark from '../../../assets/images/icons/e-dark.svg';
import F from '../../../assets/images/icons/key-f.svg';
import F_Dark from '../../../assets/images/icons/key-f-dark.svg';
import S from '../../../assets/images/icons/key-s.svg';
import S_Dark from '../../../assets/images/icons/s-dark.svg';
import R from '../../../assets/images/icons/key-r.svg';
import R_Dark from '../../../assets/images/icons/r-dark.svg';
import DELETE_KEY from '../../../assets/images/icons/key-delete.svg';
import DELETE_KEY_DARK from '../../../assets/images/icons/dark/key-delete.svg';
import I from '../../../assets/images/icons/key-i.svg';
import I_Dark from '../../../assets/images/icons/key-i-dark.svg';

export default class ShortcutsModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const twoButtonBindings = (
            <>
                <div>Rate as Wrong</div>
                <div>
                    <img className="keybind" src={Key1} alt="1 Key" />
                    <img className="keybind dark" src={Key1_Dark} alt="1 Key" />
                </div>
                <div>Rate as Right</div>
                <div className='d-flex'>
                    <img className="keybind" src={Key2} alt="2 Key" /><img className="keybind dark" src={Key2_Dark} alt="2 Key" />&nbsp;/&nbsp;<img className="keybind" src={Space} alt="Spacebar" /><img className="keybind dark" src={Space_Dark} alt="Spacebar" />
                </div>
            </>
        );
        const threeButtonBindings = (
            <>
                <div>Rate as Wrong</div>
                <div>
                    <img className="keybind" src={Key1} alt="1 Key" />
                    <img className="keybind dark" src={Key1_Dark} alt="1 Key" />
                </div>
                <div>Rate as Right</div>
                <div className='d-flex'>
                    <img className="keybind" src={Key2} alt="2 Key" /><img className="keybind dark" src={Key2_Dark} alt="2 Key" />&nbsp;/&nbsp;<img className="keybind" src={Space} alt="Spacebar" /><img className="keybind dark" src={Space_Dark} alt="Spacebar" />
                </div>
                <div>Rate as Easy</div>
                <div>
                    <img className="keybind" src={Key3} alt="3 Key" />
                    <img className="keybind dark" src={Key3_Dark} alt="3 Key" />
                </div>
            </>
        );
        const fourButtonBindings = (
            <>
                <div>Rate as Again</div>
                <div>
                    <img className="keybind" src={Key1} alt="1 Key"/>
                    <img className="keybind dark" src={Key1_Dark} alt="1 Key"/>
                </div>
                <div>Rate as Wrong</div>
                <div>
                    <img className="keybind" src={Key2} alt="2 Key" />
                    <img className="keybind dark" src={Key2_Dark} alt="2 Key" />
                </div>
                <div>Rate as Right</div>
                <div className='d-flex'>
                    <img className="keybind" src={Key3} alt="3 Key" /><img className="keybind dark" src={Key3_Dark} alt="3 Key" />&nbsp;/&nbsp;<img className="keybind" src={Space} alt="Spacebar" /><img className="keybind dark" src={Space_Dark} alt="Spacebar" />
                </div>
                <div>Rate as Easy</div>
                <div>
                    <img className="keybind" src={Key4} alt="4 Key" />
                    <img className="keybind dark" src={Key4_Dark} alt="4 Key" />
                </div>
            </>
        );
        let ratingButtons;
        if (this.props.userStudySettings?.num_responses === 2) {
            ratingButtons = twoButtonBindings;
        } else if (this.props.userStudySettings?.num_responses === 4) {
            ratingButtons = fourButtonBindings;
        } else {
            ratingButtons = threeButtonBindings;
        }
        return (
            <Modal
                name={'modal--shortcuts'}
                title={'Study Shortcuts'}
                hide={this.props.hideModal}
                small={true}
                cancelButtonText={"Close"}
                content={
                    <>
                        <div className="keybinds-wrapper keybinds-wrapper--cards">
                            <div>Reveal</div>
                            <div>
                                <img className="keybind" src={Space} alt="Spacebar"/>
                                <img className="keybind dark" src={Space_Dark} alt="Spacebar"/>
                            </div>
                            <div>View Front</div>
                            <div>
                                <img className="keybind" src={F} alt="F"/>
                                <img className="keybind dark" src={F_Dark} alt="F"/>
                            </div>
                            {ratingButtons}
                            <div>Undo Answer</div>
                            <div>
                                <img className="keybind" src={Z} alt="Z"/>
                                <img className="keybind dark" src={Z_Dark} alt="Z"/>
                            </div>
                            {/* <div>Suspend Card</div>
                            <div>
                                <img className="keybind" src={DELETE_KEY} alt="Delete"/>
                                <img className="keybind dark" src={DELETE_KEY_DARK} alt="Delete"/>
                            </div> */}
                            <div>Edit Card (Owner Decks)</div>
                            <div>
                                <img className="keybind" src={E} alt="E Key"/>
                                <img className="keybind dark" src={E_Dark} alt="Ctrl E" />
                            </div>
                            <div>Report Card (Viewer Decks)</div>
                            <div>
                                <img className="keybind" src={R} alt="R Key"/>
                                <img className="keybind dark" src={R_Dark} alt="Ctrl R" />
                            </div>
                            <div>Star Card (Owner Decks)</div>
                            <div>
                                <img className="keybind" src={S} alt="S Key"/>
                                <img className="keybind dark" src={S_Dark} alt="Ctrl S" />
                            </div>
                            <div>Card Details</div>
                            <div>
                                <img className="keybind" src={I} alt="I Key"/>
                                <img className="keybind dark" src={I_Dark} alt="Ctrl I" />
                            </div>
                        </div>
                        <div className="keybinds-wrapper keybinds-wrapper--qbank">
                            <div>Choose A</div>
                            <div>
                                <img className="keybind" src={Key1} alt="1 Key"/>
                                <img className="keybind dark" src={Key1_Dark} alt="1 Key"/>
                            </div>
                            <div>Choose B</div>
                            <div>
                                <img className="keybind" src={Key2} alt="2 Key" />
                                <img className="keybind dark" src={Key2_Dark} alt="2 Key" />
                            </div>
                            <div>Choose C</div>
                            <div className='d-flex'>
                                <img className="keybind" src={Key3} alt="3 Key" />
                                <img className="keybind dark" src={Key3_Dark} alt="3 Key" />
                            </div>
                            <div>Choose D</div>
                            <div>
                                <img className="keybind" src={Key4} alt="4 Key" />
                                <img className="keybind dark" src={Key4_Dark} alt="4 Key" />
                            </div>
                            <div>Choose E</div>
                            <div>
                                <img className="keybind" src={Key5} alt="5 Key" />
                                <img className="keybind dark" src={Key5_Dark} alt="5 Key" />
                            </div>
                            <div>Next Question</div>
                            <div>
                                <img className="keybind" src={Space} alt="Spacebar" />
                                <img className="keybind dark" src={Space_Dark} alt="Spacebar" />
                            </div>
                            <div>View Front</div>
                            <div>
                                <img className="keybind" src={F} alt="F"/>
                                <img className="keybind dark" src={F_Dark} alt="F"/>
                            </div>
                            <div>Undo Answer</div>
                            <div>
                                <img className="keybind" src={Z} alt="Z"/>
                                <img className="keybind dark" src={Z_Dark} alt="Z"/>
                            </div>
                            {/* <div>Suspend Card</div>
                            <div>
                                <img className="keybind" src={DELETE_KEY} alt="Delete"/>
                                <img className="keybind dark" src={DELETE_KEY_DARK} alt="Delete"/>
                            </div> */}
                            <div>Report Card (Viewer Decks)</div>
                            <div>
                                <img className="keybind" src={R} alt="R Key"/>
                                <img className="keybind dark" src={R_Dark} alt="Ctrl R" />
                            </div>
                            <div>Star Card (Owner Decks)</div>
                            <div>
                                <img className="keybind" src={S} alt="S Key"/>
                                <img className="keybind dark" src={S_Dark} alt="Ctrl S" />
                            </div>
                        </div>
                    </>
                }
            />
        )
    }
}
