import React, { Component } from 'react';
import {
    Area,
    AreaChart,
    Pie,
    PieChart,
    Cell,
    ResponsiveContainer,
    Tooltip,
    XAxis, YAxis,
    CartesianGrid
} from 'recharts';
// import isMobile from '../../util/isMobile';
import CalendarHeatmap from 'react-calendar-heatmap';
import ReactTooltip from 'react-tooltip';
import NoCards from '../../../assets/images/graphics/no-cards.svg';
import ReviewBadge from './ReviewBadge';
import StatTotalReviews from '../../../assets/images/stat-total-reviews.svg';
import StatCurrentStreak from '../../../assets/images/stat-current-streak.svg';
import StatLongestStreak from '../../../assets/images/stat-longest-streak.svg';
import StatCardsToday from '../../../assets/images/stat-cards-today.svg';
import StatTotalReviewsDark from '../../../assets/images/stat-total-reviews-dark.svg';
import StatCurrentStreakDark from '../../../assets/images/stat-current-streak-dark.svg';
import StatLongestStreakDark from '../../../assets/images/stat-longest-streak-dark.svg';
import StatCardsTodayDark from '../../../assets/images/stat-cards-today-dark.svg';
import ProgressBar from '../misc/ProgressBar';

export default class Stats extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
        };
    }

    componentDidMount() {
        this.props.clearActiveStudyProfile();
        this.props.fetchUserStats();
        this.props.fetchHeatmap();
        this.props.fetchPastStudySessions();
        window.scrollTo(0, 0);
        window.addEventListener('resize', this.handleResize);
        ahoy.track("View Stats");
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    cardsToday = () => {
        const today = moment();
        const formattedDate = today.format('YYYY-MM-DD');
        const entry = this.props.heatmap.find(entry => entry.date === formattedDate);
        return entry?.count;
    }

    handleResize = () => {
        this.setState({ windowWidth: window.innerWidth });
    };

    renderCulumativeStats = () => {
        const today = moment();
        const formattedDate = today.format('YYYY-MM-DD');
        const idx = this.props.heatmap.findIndex(entry => entry.date === formattedDate);
        const start = (idx - 6) < 0 ? 0 : idx - 6;
        const lastSevenDates = this.props.heatmap.slice(start, idx + 1);

        const CustomTooltip = ({ active, payload, label }) => {
            if (active && payload && payload.length) {
                return (
                    <div className="custom-tooltip">
                        <p className="label">{`Cards Studied: ${payload[0].value}`}</p>
                    </div>
                );
            }
            return null;
        }
        const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const studyDataWithWeekdays = lastSevenDates.map(item => {
            const [year, month, day] = item.date.split('-');
            const dateObj = new Date(year, month - 1, day);
            const dayOfWeekIndex = dateObj.getDay();
            const dayOfWeek = weekdays[dayOfWeekIndex];
            return { date: dayOfWeek, "Cards Studied": item.count };
        });
        return (
            <div className="block block--cards-per-day">
                <h2>Daily Cards</h2>
                <div style={{ 'height': '300px', 'marginTop': '2rem' }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <AreaChart data={studyDataWithWeekdays} margin={{ top: 32, bottom: 16, right: 44 }}>
                            <defs>
                                <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="var(--state-learning)" stopOpacity={0.8} />
                                    <stop offset="95%" stopColor="var(--state-learning)" stopOpacity={0} />
                                </linearGradient>
                            </defs>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis stroke="var(--component-stat-chart-axis)" dataKey="date" />
                            <YAxis stroke="var(--component-stat-chart-axis)" />
                            <Tooltip wrapperStyle={{ outline: '0' }} content={<CustomTooltip />} />
                            <Area type="monotone" dot dataKey="Cards Studied" stroke="var(--state-learning)" fill="url(#gradient)" />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </div>
        )
    }

    renderCardBreakdownChart = (totalCards) => {
        if (this.props.userStatsLoading) {
            return (
                <>
                    <div style={{ 'height': '300px' }}>
                        <div className="loader-rect-1" style={{display: "flex", justifyContent: "center", alignItems: "center", width: '270px', height: '270px', margin: "0 auto"}}>
                            <div style={{width: '200px', height: '200px', backgroundColor: 'var(--surface-primary)', borderRadius: "100%"}}></div>
                        </div>
                    </div>
                    <div className='legend'>
                        <div className='legend_item'>
                            <div className='legend_item_left'>
                                <div className='square'></div>New
                            </div>
                            <div className='legend_item_right loader-rect-1'>0<span>0</span></div>
                        </div>
                        <div className='legend_item legend_item--learning'>
                            <div className='legend_item_left'>
                                <div className='square'></div>Learning
                            </div>
                            <div className='legend_item_right loader-rect-1'>0<span>0</span></div>
                        </div>
                        <div className='legend_item legend_item--mastered'>
                            <div className='legend_item_left'>
                                <div className='square'></div>Mastered
                            </div>
                            <div className='legend_item_right loader-rect-1'>0<span>0</span></div>
                        </div>
                    </div>
                </>
            )
        }
        
        const cardStateColors = ['var(--state-unseen)', 'var(--state-learning)', 'var(--state-mastered)'];
        let newCardCount = this.props.cardBreakdown.find(o => o.name === "New")?.cards || 0;
        let learningCardCount = this.props.cardBreakdown.find(o => o.name === "Learning")?.cards || 0;
        let masteredCardCount = this.props.cardBreakdown.find(o => o.name === "Mastered")?.cards || 0;
        let newCardPercentage = Math.round((newCardCount / (newCardCount + learningCardCount + masteredCardCount)) * 100);
        let learningCardPercentage = Math.round((learningCardCount / (newCardCount + learningCardCount + masteredCardCount)) * 100);
        let masteredCardPercentage = 100 - newCardPercentage - learningCardPercentage;

        if (newCardCount + learningCardCount + masteredCardCount > 0) {
            return (
                <>
                    <div style={{ 'height': '300px' }}>
                        <ResponsiveContainer width="90%" height="90%">
                            <PieChart margin={{ top: 16, bottom: 16 }}>
                                <Pie
                                    stroke="none"
                                    data={this.props.cardBreakdown}
                                    dataKey="cards"
                                    cx="50%"
                                    cy="50%"
                                    innerRadius="70%"
                                    outerRadius="100%"
                                >
                                    {this.props.cardBreakdown.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={cardStateColors[index % cardStateColors.length]} />
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                    <div className='legend'>
                        <div className='legend_item'>
                            <div className='legend_item_left'>
                                <div className='square'></div>New
                            </div>
                            <div className='legend_item_right'>{newCardCount}<span>{newCardCount ? newCardPercentage + '%' : '0%'}</span></div>
                        </div>
                        <div className='legend_item legend_item--learning'>
                            <div className='legend_item_left'>
                                <div className='square'></div>Learning
                            </div>
                            <div className='legend_item_right'>{learningCardCount}<span>{learningCardCount ? learningCardPercentage + '%' : '0%'}</span></div>
                        </div>
                        <div className='legend_item legend_item--mastered'>
                            <div className='legend_item_left'>
                                <div className='square'></div>Mastered
                            </div>
                            <div className='legend_item_right'>{masteredCardCount}<span>{masteredCardCount ? masteredCardPercentage + '%' : '0%'}</span></div>
                        </div>
                    </div>
                </>
            )
        } else {
            return (
                <div className='no-card-breakdown'>
                    <img className='no-card-breakdown_graphic' src={NoCards} alt="Pie Chart" />
                    <h3>There are no cards in your account!</h3>
                    <p>Add cards by importing your Anki decks or building your own!</p>
                </div>
            )
        }
    }

    formatDate = (dateString) => {
        if (!dateString) return 'N/A';

        const date = new Date(dateString);

        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        const day = date.getDate();
        const monthName = months[date.getMonth()];
        const year = date.getFullYear();

        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        const strTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + ampm;

        return monthName + ' ' + day + ', ' + year + ' ' + strTime;
    }

    renderPastStudySessions = () => {
        return (
            this.props.pastStudySessions.map((session, index) => {
                const score = Math.round((session.right + session.easy) / (session.again + session.wrong + session.right + session.easy) * 100);
                return (
                    <div className='session-row' key={index}>
                        <div className='nowrap desktop-noshow'>
                            <div className='nowrap'>{session.deck}</div>
                            <div className='time'>{this.formatDate(session.created_at)}</div>
                        </div>
                        <div className='nowrap mobile-noshow'>{this.formatDate(session.created_at)}</div>
                        <div className='nowrap mobile-noshow'>{session.deck}</div>
                        <div>{session.duration}</div>
                        <div className='mobile-noshow'>{session.right + session.easy}</div>
                        <div className='mobile-noshow'>{session.again + session.wrong}</div>
                        <div className='score'>
                            <div className='num'>{score}%</div>
                            <ProgressBar type={'generic'} percentMastery={score} classes={"score-bar"} />
                        </div>
                    </div>
                )
            })
        )
    }

    render() {
        const { windowWidth } = this.state;
        let heatmapValues = this.props.heatmap;
        if (windowWidth < 600) {
            heatmapValues = this.props.heatmap?.slice(this.props.heatmap?.length - 90);
        }
        const heatmapStart = heatmapValues[0]?.date;
        const heatmapEnd = heatmapValues[heatmapValues.length - 1]?.date;
        return (
            <div className='layout layout--stats'>
                <div className="layout--stats_container layout_container">
                    <div className='layout--stats_container_wrapper'>
                        <h1><span>My</span> Statistics</h1>
                        <div className='heatmap'>
                            <h2>Reviews By Day</h2>
                            {!_.isEmpty(heatmapValues) && <CalendarHeatmap
                                startDate={heatmapStart}
                                endDate={heatmapEnd}
                                values={heatmapValues}
                                classForValue={(value) => {
                                    if (!value) return 'color-empty';
                                    let classes = '';
                                    if (!value || value.count <= 0) {
                                        classes += 'color-empty';
                                    }
                                    else if (!value.future && value.count < 25)
                                        classes += 'past-scale-1';
                                    else if (!value.future && value.count < 50)
                                        classes += 'past-scale-2';
                                    else if (!value.future && value.count < 75)
                                        classes += 'past-scale-3';
                                    else if (!value.future)
                                        classes += 'past-scale-4';
                                    else if (value.future && value.count < 25)
                                        classes += 'future-scale-1';
                                    else if (value.future && value.count < 50)
                                        classes += 'future-scale-2';
                                    else if (value.future && value.count < 75)
                                        classes += 'future-scale-3';
                                    else {
                                        classes += 'future-scale-4';
                                    }
                                    return classes;
                                }}
                                tooltipDataAttrs={(value) => {
                                    const givenDate = new Date(value.date);
                                    givenDate.setDate(givenDate.getDate() + 1);
                                    const today = new Date();
                                    givenDate.setHours(0, 0, 0, 0);
                                    today.setHours(0, 0, 0, 0);
                                    if (value.count !== null) {
                                        if (!value.future) {
                                            if (givenDate.getTime() === today.getTime()) {
                                                return {
                                                    'data-tip': 'Today<br>Reviews: ' + value.count + '<br> ' + new Date(value.date).toLocaleDateString('en-us', { year: "numeric", month: "long", day: "numeric", timeZone: 'UTC' }),
                                                    'data-html': true
                                                }
                                            } else {
                                                return {
                                                    'data-tip': 'Reviews: ' + value.count + '<br> ' + new Date(value.date).toLocaleDateString('en-us', { year: "numeric", month: "long", day: "numeric", timeZone: 'UTC' }),
                                                    'data-html': true
                                                }
                                            }
                                        } else {
                                            return {
                                                'data-tip': 'Reviews Due: ' + value.count + '<br> ' + new Date(value.date).toLocaleDateString('en-us', { year: "numeric", month: "long", day: "numeric", timeZone: 'UTC' }),
                                                'data-html': true
                                            }
                                        }
                                    }
                                }}
                                transformDayElement={(element, value, index) => {
                                    const givenDate = new Date(value.date);
                                    givenDate.setDate(givenDate.getDate() + 1);
                                    const today = new Date();
                                    givenDate.setHours(0, 0, 0, 0);
                                    today.setHours(0, 0, 0, 0);
                                    if (givenDate.getTime() === today.getTime()) {
                                        return React.cloneElement(element, {
                                            rx: "50%",
                                            ry: "50%"
                                        });
                                    }
                                    return element;
                                }}
                            />}
                        </div>
                        <div className="tiles">
                            <div className="tile">
                                <div className="tile_icon">
                                    <div className='tile_icon_inner'>
                                        <img className="theme-specific light-only" src={StatTotalReviews} alt="Total Reviews Icon" />
                                        <img className="theme-specific dark-only" src={StatTotalReviewsDark} alt="Total Reviews Icon" />
                                    </div>
                                </div>
                                <div className="tile_stats">
                                    {this.props.userStatsLoading ?
                                        <div className="tile_stats_value loader-rect-1">0</div>
                                        : <div className="tile_stats_value">{this.props.totalStudyCount}</div>}
                                    <div className="tile_stats_label">Total Reviews</div>
                                </div>
                            </div>
                            <div className="tile">
                                <div className="tile_icon">
                                    <div className='tile_icon_inner'>
                                        <img className="theme-specific light-only" src={StatCurrentStreak} alt="Current Streak Icon" />
                                        <img className="theme-specific dark-only" src={StatCurrentStreakDark} alt="Current Streak Icon" />
                                    </div>
                                </div>
                                <div className="tile_stats">
                                    {this.props.userStatsLoading ? 
                                        <div className="tile_stats_value loader-rect-1">0</div>
                                        : <div className="tile_stats_value">{this.props.currentStreak}<span className="tile_stats_value_sub-label">Days</span></div>}
                                    <div className="tile_stats_label">Current Streak</div>
                                </div>
                            </div>
                            <div className="tile">
                                <div className="tile_icon">
                                    <div className='tile_icon_inner'>
                                        <img className="theme-specific light-only" src={StatLongestStreak} alt="Longest Streak Icon" />
                                        <img className="theme-specific dark-only" src={StatLongestStreakDark} alt="Longest Streak Icon" />
                                    </div>
                                </div>
                                <div className="tile_stats">
                                    {this.props.userStatsLoading ?
                                        <div className="tile_stats_value loader-rect-1">0</div>
                                        : <div className="tile_stats_value">{this.props.longestStreak}<span className="tile_stats_value_sub-label">Days</span></div>}
                                    <div className="tile_stats_label">Longest Streak</div>
                                </div>
                            </div>
                            <div className="tile">
                                <div className="tile_icon">
                                    <div className='tile_icon_inner'>
                                        <img className="theme-specific light-only" src={StatCardsToday} alt="Cards Today Icon" />
                                        <img className="theme-specific dark-only" src={StatCardsTodayDark} alt="Cards Today Icon" />
                                    </div>
                                </div>
                                <div className="tile_stats">
                                    {this.props.userStatsLoading ?
                                        <div className="tile_stats_value loader-rect-1">0</div>
                                        : <div className="tile_stats_value">{this.cardsToday()}</div>}
                                    <div className="tile_stats_label">Reviews Today</div>
                                </div>
                            </div>
                        </div>
                        {this.props.pastStudySessions.length > 0 && <div className='past-sessions'>
                            <h2>Latest Sessions</h2>
                            <div className='session-table'>
                                <div className='session-row session-row--header'>
                                    <div>Date</div>
                                    <div>Deck</div>
                                    <div>Session Time</div>
                                    <div>Right</div>
                                    <div>Wrong</div>
                                    <div>Score</div>
                                </div>
                                {this.renderPastStudySessions()}
                            </div>
                        </div>}
                        <ReviewBadge loading={this.props.userStatsLoading} reviews={this.props.totalStudyCount} type='container' />
                        <div className="block-container two-col">
                            <div className="block block--pie">
                                <h2>Card Mastery</h2>
                                {this.renderCardBreakdownChart()}
                            </div>
                            {/* {this.renderCulumativeStats()} */}
                        </div>
                    </div>
                </div>
                <ReactTooltip />
            </div>
        )
    }
}

