import {
    RECEIVE_SELECTED_DECK,
} from '../../actions/allActions';

const selectedDeckReducer = (state = null, action) => { 
	Object.freeze(state);

	switch (action.type) {
		case RECEIVE_SELECTED_DECK:
			return action.deckId;
		default:
			return state;
	}
}

export default selectedDeckReducer;