import { connect } from 'react-redux'
import { setTheme } from '../../actions/allActions'
import { withRouter } from 'react-router-dom';
import UserMenu from './UserMenu'

const mapStateToProps = state => {
    return {
        theme: state.theme 
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setTheme: (theme) => dispatch(setTheme(theme)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserMenu))
