import React from 'react';
import { connect } from 'react-redux';
import { LogOut } from 'react-feather';
import { userLogout } from '../../actions/allActions';

const SignOutButton = ({ userLogout }) => {
    const signOutRequest = () => {
        $.ajax({
            method: 'DELETE',
            url: 'users/sign_out'
        }).then(() => {
            userLogout();
            window.location.pathname = '/users/sign_in';
        });
    }

    let iconLogout = <LogOut/>;

    return (
        <button 
            className="button button--secondary button--sign-out" 
            onClick={signOutRequest}
        >
            {iconLogout}Logout
        </button>
    )
}

const mapSTP = () => {
  return {};
};

const mapDTP = (dispatch) => ({
    userLogout: () => dispatch(userLogout()),
});

export default connect(mapSTP, mapDTP)(SignOutButton);
