import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { BarChart2, Home, User, Layers } from 'react-feather';
import { getProductId } from '../../util/cohortUtil';

export default class AppBar extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        // Icons
        let iconSearch = <Layers />;
        let iconStats = <BarChart2 />;
        let iconHome = <Home />;
        let iconUser = <User />;

        let homeActive = this.props.location.pathname == "/" ? "active" : "";
        let statsActive = this.props.location.pathname.includes('/stats') ? "active" : "";
        let browseActive = this.props.location.pathname.includes('/browse') ? "active" : "";
        let settingsActive = this.props.location.pathname.includes('/settings') ? "active" : "";

        return (
            <nav className="app-bar">
                <div className="app-bar__inner">
                    <Link className={`app-bar__inner__button ${homeActive}`} to={`/`}>{iconHome}</Link>
                    <Link className={`app-bar__inner__button ${browseActive}`} to={`/browse`}>{iconSearch}</Link>
                    <Link className={`app-bar__inner__button ${statsActive}`} to={`/stats`}>{iconStats}</Link>
                    <Link className={`app-bar__inner__button profile-picture-item ${settingsActive}`} to={`/settings`}>{this.props.user.profile_picture ? <img src={this.props.user.profile_picture} alt="Profile Picture" /> : iconUser}</Link>
                </div>
            </nav>
        )
    }
}
