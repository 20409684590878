import {
    RECEIVE_ALL_DECKS,
    RECEIVE_DECKS,
    RECEIVE_DECK,
} from '../../actions/allActions';

const decksReducer = (state = {}, action) => { 
	Object.freeze(state);
	// let nextState = _.cloneDeep(state);

	switch (action.type) {
		case RECEIVE_ALL_DECKS:
			return action.payload;
		case RECEIVE_DECKS:
			const newDecks = action.payload;
			return { ...state, ...newDecks };
		case RECEIVE_DECK:
			const deckId = action.payload.deck.id;
            const deck = action.payload.deck;
			const newDeck = { [deckId]: deck };
			return { ...state, ...newDeck };
		default:
			return state;
	}
}

export default decksReducer;