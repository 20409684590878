import React, { Component } from 'react';
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import CardMenu from './CardMenu';
import CardReporter from './CardReporter';
import ShortcutsModal from './ShortcutsModal';
import CardDetailsModal from './CardDetailsModal';
import { PanZoom } from 'react-easy-panzoom';
import { Minus, MoreVertical, Plus, X } from 'react-feather';
import keyContainsModifier from '../../util/keyContainsModifier';
import AssetDisplay from '../assets/AssetDisplay';

class FlashCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cardWidth: 0,
            cardHeight: 0,
            isCardMenuOpen: false,
            cardMenuYPosition: 0,
            showCardReporter: false,
            showCardDetails: false,
            showShortcutsModal: false,
            selectedContent: null,
            lastAudioSrc: null,
            IOIsPortrait: false,
            initializedDebounce: false,
            debounced: null,
            assetId: null
        }
        this.contentRef = React.createRef();
        this.panZoomRef = React.createRef();
    }

    componentDidMount() {
        // Suppress Card Menu on Outside Click
        document.addEventListener('mouseup', function (e) {
            if (this.state.isCardMenuOpen) {
                let trigger = document.getElementById('card-menu-trigger');
                if (trigger && !trigger.contains(e.target)) {
                    this.hideCardMenu();
                }
            }
        }.bind(this));

        if (this.props.card.model === 'multichoice') {
            document.body.classList.add('is-qbank');
            if (this.props.activeStudySession) {
                let updatedStats = {...this.props.activeStudySession};
                updatedStats["type"] = "multichoice";
                this.props.receiveActiveStudySession(updatedStats);
            }
        }

        window.addEventListener('resize', function () {
            if (this.state.isCardMenuOpen) {
                this.hideCardMenu();
            }
        }.bind(this));
        this.initializedDebounce();
        this.renderAssets();
        document.querySelector('html').style.height = '100%';
    }

    componentDidUpdate(prevProps) {
        this.addFlashcardListeners();
        if (this.props.card !== prevProps.card) {
            this.handleCardChange();
            this.setState({assetId: null});
            this.renderAssets();
        }
    }
    
    componentWillUnmount() {
        this.removeKeyBinds();
        document.querySelector('html').style.height = 'auto';
        document.body.classList.remove('is-qbank');
    }
    
    handleCardChange = () => {
        if (this.props.card.model === 'multichoice') {
            document.body.classList.add('is-qbank');
            if (this.props.activeStudySession) {
                let updatedStats = {...this.props.activeStudySession};
                updatedStats["type"] = "multichoice";
                this.props.receiveActiveStudySession(updatedStats);
            }
        } else {
            document.body.classList.remove('is-qbank');
        }
    }


    initializedDebounce = () => {
        if (!this.state.initializedDebounce) {
            const debounced = _.debounce(this.keyBinds, 50, { 'leading': true, 'trailing': false })
            window.addEventListener('keydown', debounced)
            this.setState({ initializedDebounce: true, debounced })
        }
    }

    removeKeyBinds = () => {
        if (this.state.initializedDebounce) {
            window.removeEventListener('keydown', this.state.debounced);
        }
    }

    keyBinds = (e) => {
        if (this !== e.target && (/textarea|select|input/i.test(e.target.nodeName) || e.target.type === "text" || e.target.isContentEditable)) { return; }
        if (!this.props.card.editable && !(keyContainsModifier(e)) && (e.code == "KeyR" || e.keyCode == 82 || e.key == "r")) {
            e.preventDefault();
            this.showCardReporter();
        } else if (!(keyContainsModifier(e)) && this.props.card.model !== 'multichoice' && (e.code == "KeyI" || e.keyCode == 73 || e.key == "i")) {
            if (this.state.showCardDetails) {
                this.hideCardDetails();
            } else {
                this.showCardDetails();
            }
        }
    }

    handleImageClick = (e) => {
        if (e.target && e.target.tagName === 'IMG') {
            let contentToDisplay = e.target;
            let parent = e.target.parentElement;
            while (parent) {
                if (parent.classList.contains('card__image-occ__wrapper') || parent.classList.contains('io-content-wrapper')) {
                    contentToDisplay = parent;
                    break;
                }
                parent = parent.parentElement;
            }
            this.setState({ selectedContent: contentToDisplay.outerHTML });
            this.props.openImageViewer();
        }
    };

    handleContentLoad = () => {
        if (this.contentRef.current && this.contentRef.current.querySelector('img')) {
            const image = this.contentRef.current.querySelector('img');
            const imageWidth = image.offsetWidth;
            const imageHeight = image.offsetHeight;

            const viewportWidth = window.innerWidth;
            const viewportHeight = window.innerHeight;

            const scaleWidth = viewportWidth / imageWidth;
            const scaleHeight = viewportHeight / imageHeight;

            const scale = Math.min(scaleWidth, scaleHeight);

            image.style.transform = `scale(${scale})`;
        }
    };

    handleZoomIn = () => {
        if (this.panZoomRef.current) {
            this.panZoomRef.current.zoomIn();
        }
    };

    handleZoomOut = () => {
        if (this.panZoomRef.current) {
            this.panZoomRef.current.zoomOut();
        }
    };

    addFlashcardListeners = () => {
        // Auto-play audio element
        setTimeout(this.autoplayAudio, 200);

        // Prevent base image from loading before overlay (apkg image occlusion)
        var mask = document.querySelector('#io-overlay>img');
        if (!!mask) {
            if (mask.complete) {
                this.overlayLoaded();
            } else {
                mask.addEventListener('load', this.overlayLoaded);
            }
        }

        const photoCreditsElement = document.getElementById("photo-credits");
        const photoCreditsPanelElement = document.getElementById("reference-target");
        if (photoCreditsElement) {
            photoCreditsElement.addEventListener("click", () => {
                photoCreditsPanelElement.classList.toggle('is-open');
            });
        }
    }

    autoplayAudio = () => {
        const audioEl = document.getElementsByTagName('audio')[0];
        if (!!audioEl) {
            const src = audioEl?.children[0].src;
            if (src !== this.state.lastAudioSrc) {
                audioEl.load();
                audioEl.play();
                this.setState({ lastAudioSrc: src });
            }
        }
    }

    overlayLoaded = () => {
        var original = document.querySelector('#io-original');
        if (!!original) original.style.visibility = "visible";
    }

    hideCardMenu() {
        this.setState({ isCardMenuOpen: false });
    }

    renderCardContent() {
        if (_.isEmpty(this.props.card) || this.props.loading) return null;

        if (this.props.isFlipped) {
            if (this.props.card.model == "occlusion") {
                if (!_.isEmpty(this.props.card.header)) {
                    return (
                        <>
                            {parse(this.props.card.question)}
                            <br></br>
                            {parse(this.props.card.header)}
                            {_.isEmpty(this.props.card.css) ? null : parse(this.props.card.css)}
                        </>
                    );
                } else {
                    return (parse(this.props.card.question));
                }
            } else if (this.props.card.model == "basic") {
                return (
                    <>
                        {parse(this.props.card.question)}
                        <hr className="solid"></hr>
                        {parse(this.props.card.answer)}
                        {_.isEmpty(this.props.card.css) ? null : parse(this.props.card.css)}
                    </>
                )
            } else {
                return (
                    <>
                        {parse(this.props.card.answer)}
                        {_.isEmpty(this.props.card.css) ? null : parse(this.props.card.css)}
                    </>
                )
            }
        } else {
            if (!_.isEmpty(this.props.card.header)) {
                return (
                    <>
                        {parse(this.props.card.question)}
                        <br></br>
                        {parse(this.props.card.header)}
                        {_.isEmpty(this.props.card.css) ? null : parse(this.props.card.css)}
                    </>
                );
            } else {
                return (
                    <>
                        {parse(this.props.card.question)}
                        {_.isEmpty(this.props.card.css) ? null : parse(this.props.card.css)}
                    </>
                )
            }
        }
    }

    renderOcclusionOverlay() {
        if (_.isEmpty(this.props.card)) return null;
        if (this.props.card.model == "occlusion") {
            return this.props.isFlipped ?
                (parse(this.props.card.answer_overlay || '')) :
                (parse(this.props.card.question_overlay || ''))
        }
    }

    renderExtraContent() {
        if (this.props.isFlipped) {
            if (_.isEmpty(this.props.card) || _.isEmpty(this.props.card.extra)) return null;
            return (
                <div className="card__extra">
                    {parse(this.props.card.extra)}
                    {this.state.assetId && <AssetDisplay pageId={this.state.assetId}/>}
                </div>
            )
        }
    }

    toggleCardMenu() {
        this.setState(state => ({
            isCardMenuOpen: !state.isCardMenuOpen
        }),
            () => {
                this.setState({
                    cardMenuYPosition: this.calculateCardMenuYPosition()
                })
            });
    }

    calculateCardMenuYPosition() {
        let wWidth = window.innerWidth;
        if (wWidth > 900) {
            return (document.getElementById('card-menu-trigger').getBoundingClientRect().top - document.querySelector('.layout--study').getBoundingClientRect().top - document.getElementById('card-menu').getBoundingClientRect().height).toString() + 'px';
        }
    }

    showCardReporter = () => {
        this.setState({ showCardReporter: true });
        this.props.removeKeyBinds();
    }

    showCardDetails = () => {
        this.setState({ showCardDetails: true });
    }

    hideCardDetails = () => {
        this.setState({ showCardDetails: false });
    }

    hideCardReporter = () => {
        this.setState({ showCardReporter: false });
        this.props.reinitializedKeyBinds();
    }

    showShortcutsModal = () => {
        this.setState({ showShortcutsModal: true });
    }

    hideShortcutsModal = () => {
        this.setState({ showShortcutsModal: false });
    }

    renderAllCardContent = () => {
        // New IO (Optimal)
        if (this.props.card.model === "occlusion") {
            return (
                <div className="card__image-occ__wrapper">
                    {this.renderCardContent()}
                    {this.renderOcclusionOverlay()}
                    {this.renderExtraContent()}
                </div>
            );
        // Legacy IO Styling from Import Script
        } else if (this.props.card.model?.toLowerCase().includes('occlusion')) {
            return (
                <div className="io-content-wrapper">
                    {this.renderCardContent()}
                    {this.renderOcclusionOverlay()}
                    {this.renderExtraContent()}
                </div>
            );
        } else if (this.props.card.model === 'multichoice') {
            return (
                <div className="qbank-content">
                    <div className='left'>
                        {this.renderCardContent()}
                    </div>
                    <div className='right'>
                        {this.renderExtraContent()}
                    </div>
                </div>
            );
        // All Other Cards
        } else {
            return (
                <div>
                    {this.renderCardContent()}
                    {this.renderOcclusionOverlay()}
                    {this.renderExtraContent()}
                </div>
            );
        }
    }

    setFontSize = () => {
        if (this.props.userProperties?.studyTextSize === 'small') {
            return 'font-size--small';
        } else if (this.props.userProperties?.studyTextSize === 'large') {
            return 'font-size--large';
        } else {
            return '';
        }
    }

    renderAssets = () => {
        if (!this.props.card.extra) return;
        const id = this.findAssetId(this.props.card.extra);
        this.setState({assetId: id})
    }

    findAssetId = (htmlString) => {
        const regex = /data-asset-id="([^"]+)"/;
        const match = htmlString.match(regex);
        return match ? match[1] : null;
    }

    render() {
        const { showImageViewer, selectedContent } = this.state;
        let modeMenuIcon = <MoreVertical />;
        return (
            <React.Fragment>
                <div className={`card ${this.setFontSize()} ${this.props.card.model === 'multichoice' ? 'qbank' : ''}`}>
                    <div className="overflow-overlay overflow-overlay--top"></div>
                    <div className={`card__text ${this.props.card.model?.toLowerCase().includes('occlusion') ? " is-image-occlusion" : ""}`} onClick={this.handleImageClick}>
                        {this.renderAllCardContent()}
                    </div>
                    <div className="overflow-overlay"></div>
                    <button id="card-menu-trigger" className="button--card-menu" onClick={() => this.toggleCardMenu()}>{modeMenuIcon}</button>
                </div>
                <CardMenu
                    isPrevCard={this.props.isPrevCard}
                    card={this.props.card}
                    currentType={this.props.currentType}
                    isFlipped={this.props.isFlipped}
                    isActive={this.state.isCardMenuOpen}
                    menuY={this.state.cardMenuYPosition}
                    unflip={this.props.unflipCard}
                    undoResponse={this.props.undoResponse}
                    showCardReporter={this.showCardReporter}
                    showCardDetails={this.showCardDetails}
                    showShortcutsModal={this.showShortcutsModal}
                    tagCard={this.props.tagCard}
                    untagCard={this.props.untagCard}
                    editCard={this.props.editCard}
                    suspendCard={this.props.suspendCard}
                    cloneCard={this.props.cloneCard}
                    deck={this.props.deck}
                />
                {this.state.showCardReporter &&
                    <CardReporter
                        cardId={this.props.card.id}
                        hideModal={this.hideCardReporter}
                    />
                }
                {this.state.showShortcutsModal &&
                    <ShortcutsModal
                        hideModal={this.hideShortcutsModal}
                        userStudySettings={this.props.userStudySettings}
                    />
                }
                {this.state.showCardDetails &&
                    <CardDetailsModal
                        card={this.props.card}
                        hide={this.hideCardDetails}
                    />
                }
                {this.props.showImageViewer && (
                    <div className="lightbox">
                        <button className='button button--close' onClick={this.props.closeImageViewer}><X /></button>
                        <PanZoom
                            ref={this.panZoomRef}
                            style={{ width: '100%', height: '100%' }}
                            autoCenter
                            enableBoundingBox={true}
                            realPinch={true}
                        >
                            <div
                                style={{ width: '100%', height: '100%' }}
                                ref={this.contentRef}
                                dangerouslySetInnerHTML={{ __html: selectedContent }}
                                onLoad={this.handleContentLoad}
                            />
                        </PanZoom>
                        <div className="controls">
                            <button className='zoom-control' onClick={this.handleZoomIn} aria-label='Zoom In'><Plus/></button>
                            <button className='zoom-control' onClick={this.handleZoomOut} aria-label='Zoom Out'><Minus/></button>
                        </div>
                    </div>
                )}
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FlashCard);
