import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { redeemAccessCode } from '../../actions/allActions';
import { initiateModalExit } from '../../util/modalExit';
import Modal from '../misc/Modal';

function RedeemCodeModal({ hide, redeemAccessCode }) {
    const [accessCode, setAccessCode] = useState('');

    useEffect(() => {
        document.body.classList.add("overflow-hidden");
        window.addEventListener('keydown', debouncedChangeHandler);
        return () => {
            window.removeEventListener('keydown', debouncedChangeHandler);
        }
    }, [accessCode]);

    const handleInputChange = (event) => {
        setAccessCode(event.target.value);
    };

    const handleSaveChanges = () => {
        // Hardcoded Thieme deck ID for now
        const deckId = 18076;
        redeemAccessCode(accessCode).then(res => {
            if (res.status === 'success') {
                const toastConfig = { position: "bottom-left", autoClose: 3000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined }
                toast.success('Deck successfully unlocked!', toastConfig);
                modalExit();
            } else {
                const toastConfig = { position: "bottom-left", autoClose: false, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined }
                toast.error('Access code invalid.', toastConfig);
            }
        });
        setAccessCode('');
    }

    const keyBinds = (e) => {
        if (e.key === "Enter" || e.which === 13 || e.code === "Enter") {
            if (!!accessCode) {
                handleSaveChanges();
            }
        }
    }

    const modalExit = () => {
        document.body.classList.remove("overflow-hidden");
        initiateModalExit('.modal--thieme-unlocker', hide);
    }

    const debouncedChangeHandler = useMemo(
        () => debounce(keyBinds, 50, { 'leading': true, 'trailing': false })
        , [accessCode]);

    return (
        <Modal
            name={'modal--redeem-code'}
            title={"Unlock or Extend Access to a Thieme Deck"}
            hide={hide}
            content={
                <div>
                    <form>
                        <div className='input'>
                            <label className='label'>Enter Access Code</label>
                            <input type="text" value={accessCode} onChange={handleInputChange} />
                        </div>
                    </form>
                </div>
            }
            actions={
                <>
                    <button className='button button--success' onClick={handleSaveChanges} disabled={!accessCode}>Unlock</button>
                </>
            }
        >
        </Modal>
    )
}

const mapStateToProps = (state) => ({
});
const mapDispatchToProps = (dispatch) => ({
    redeemAccessCode: (accessCode) => dispatch(redeemAccessCode(accessCode))
});
export default connect(mapStateToProps, mapDispatchToProps)(RedeemCodeModal);
