import { 
	RECEIVE_USER_STUDY_SETTINGS,
} from '../../actions/allActions';

const userStudySettingsReducer = (state = {}, action) => { 
	Object.freeze(state);
	let nextState = _.cloneDeep(state);

	switch (action.type) {
		case RECEIVE_USER_STUDY_SETTINGS:
			return action.payload.userStudySettings;
		default:
			return state;
	}
}

export default userStudySettingsReducer;
