import {
    RECEIVE_SHARED_USERS,
} from '../../actions/allActions';

const sharedUsersReducer = (state = [], action) => { 
	Object.freeze(state);

	switch (action.type) {
		case RECEIVE_SHARED_USERS:
			const sharedUsers = action.payload.users.sort((a, b) => {
				return new Date(a.created_at) - new Date(b.created_at);
			});
			return sharedUsers;
		default:
			return state;
	}
}

export default sharedUsersReducer;