import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import _ from 'lodash';
import check from '../../../assets/images/icons/check.svg';
import Confetti from '../misc/Confetti';
import { isPaywalled } from '../../util/cohortUtil';

class GoodWork extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sessionStats: {}
        }
    }

    componentDidMount() {
        document.body.classList.add('good-work-screen');
        if (!_.isEmpty(this.props.activeStudySession)) {
            let stats = {...this.props.activeStudySession};
            let startTime = new Date(stats.sessionTime);
            if (!isNaN(startTime)) {
                let difference = Math.abs(new Date().getTime() - startTime.getTime()) / 1000;
                let hours = Math.floor(difference / 3600);
                let minutes = Math.floor((difference % 3600) / 60);
                let seconds = Math.floor(difference % 60);
                if (hours > 0) {
                    stats.sessionTime = (hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds);
                } else {
                    stats.sessionTime = (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds);
                }
            } else {
                stats.sessionTime = "XX:XX";
            }
            this.setState({sessionStats: stats});
        }
        this.initializedDebounce();
    }

    componentWillUnmount() {
        this.removeKeyBinds();
        document.body.classList.remove('good-work-screen');
    }

    renderLinks = () => {
        if (isPaywalled(this.props.user)) {
            return (
                <>
                    <Link to={'/checkout'} className='button button--large button--unlock'>{this.props.user.has_expired_subscription ? 'Resubscribe Now' : 'Unlock Full Deck'}</Link>
                    <div className='or'>OR</div>
                    <Link className="link-home" to={`/`}>Return Home</Link>
                </>
            )
        } else {
            return <Link className="button" to={`/`}>Return Home</Link>
        }
    }

    calculateTotalCards = () => {
        return this.state.sessionStats.again + this.state.sessionStats.wrong + this.state.sessionStats.right + this.state.sessionStats.easy;
    }

    initializedDebounce = () => {
        if (!this.state.initializedDebounce) {
            const debounced = _.debounce(this.keyBinds, 50, { 'leading': true, 'trailing': false })
            window.addEventListener('keydown', debounced)
            this.setState({ initializedDebounce: true, debounced })
        }
    }

    keyBinds = (e) => {
        if (document.activeElement.id === "report-input") return;
        if ((e.code == "Space" || e.keyCode == 32 || e.key == " " || e.code == "Enter" || e.keyCode == 13 || e.key == "Enter" || e.code == "KeyH" || e.keyCode == 72 || e.key == "h")) {
            this.props.history.push('/');
        }
    }

    removeKeyBinds = () => {
        if (this.state.initializedDebounce) {
            window.removeEventListener('keydown', this.state.debounced);
        }
    }

    render() {
        return (
            <div className="good-work">
                <Confetti/>
                <div className="good-work__content-container">
                    <div className="good-work__content-container__checkmark">
                        <img src={check} alt="Check Mark"/>
                    </div>
                    <h1>Good Work</h1>
                    <p>You've finished this study session!</p>
                    {!_.isEmpty(this.state.sessionStats) && <div className='session-details'>
                        <h2>Session Details</h2>
                        <div className='session-details_row'>
                            <div className='session-details_row_item'>
                                <div className='session-details_row_item_counter'>{this.calculateTotalCards()}</div>
                                <div className='session-details_row_item_label'>Card Reviews</div>
                            </div>
                            {this.state.sessionStats.type !== 'multichoice' && this.props.userStudySettings?.num_responses === 4 && <div className='session-details_row_item'>
                                <div className='session-details_row_item_counter'>{this.state.sessionStats.again}</div>
                                <div className='session-details_row_item_label'>Again</div>
                            </div>}
                            <div className='session-details_row_item'>
                                <div className='session-details_row_item_counter'>{this.state.sessionStats.type === 'multichoice' ? this.state.sessionStats.wrong + this.state.sessionStats.again : this.state.sessionStats.wrong}</div>
                                <div className='session-details_row_item_label'>Wrong</div>
                            </div>
                            <div className='session-details_row_item'>
                                <div className='session-details_row_item_counter'>{this.state.sessionStats.right}</div>
                                <div className='session-details_row_item_label'>Right</div>
                            </div>
                            {this.state.sessionStats.type !== 'multichoice' && (this.props.userStudySettings?.num_responses === 3 || this.props.userStudySettings?.num_responses === 4) && <div className='session-details_row_item'>
                                <div className='session-details_row_item_counter'>{this.state.sessionStats.easy}</div>
                                <div className='session-details_row_item_label'>Easy</div>
                            </div>}
                            <div className='session-details_row_item'>
                                <div className='session-details_row_item_counter'>{this.state.sessionStats.sessionTime}</div>
                                <div className='session-details_row_item_label'>Session Time</div>
                            </div>
                        </div>
                        <h2>Session Details</h2>
                    </div>}
                    <div className='good-work__content-container__button-container'>
                        {this.renderLinks()}
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(GoodWork);
