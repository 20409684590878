import React, { Component } from 'react';
import { initiateModalExit } from '../../util/modalExit';
import { X } from 'react-feather';
import FocusTrap from 'focus-trap-react';

export default class Modal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initializedDebounce: false, 
            debounced: null,
            activeTrap: false
        };

        this.mountTrap = this.mountTrap.bind(this);
        this.unmountTrap = this.unmountTrap.bind(this);

        // Mandatory Props
        // show - Shows the modal.
        // hide - Hides the modal.
        // name - Unique modal name, ex. modal--[NAME]

        // Optional Props
        // title - Title of the modal.
        // cancelButtonText - Override the default text of the default Cancel button. (Default: 'Cancel')
        // small - Specify if the modal should be small. (Default: false)
        // escape - If true, user can use escape to close modal. (Default: true)
        // hideCancel - Removes the defaulted Cancel/Close Button. (Default: false)
        // removeOverflowOnClose - Removes the body overflow when modal is closed. (Default: true)
        // alternateHideFunction - An alternative way to close the modal.
        // userMustAccept - A method that disables all ways to close modal other than clicking an accept button. (Default: false)
    }

    componentDidMount() {
        this.initializedDebounce();
    }

    componentWillUnmount() {
        this.removeKeyBinds();
    }

    mountTrap = () => {
        this.setState({ activeTrap: true });
    };
    
    unmountTrap = () => {
        this.setState({ activeTrap: false });
    };

    initializedDebounce = () => {
        if (!this.state.initializedDebounce) {
            const debounced = _.debounce(this.keyBinds, 50, { 'leading': true, 'trailing': false })
            window.addEventListener('keydown', debounced)
            this.setState({ initializedDebounce: true, debounced })
        }
    }
    removeKeyBinds = () => {
        if (this.state.initializedDebounce) {
            window.removeEventListener('keydown', this.state.debounced);
        }
    }
    keyBinds = (e) => {
        if (this.props.userMustAccept) return;
        if (e.key === "Escape" || e.which === 27 || e.code === "Escape") {
            this.closeModal();
        }
    }

    closeModal = () => {
        if (!!this.props.alternateHideFunction) {
            this.props.alternateHideFunction();
        } else {
            initiateModalExit('.' + this.props.name, this.props.hide);
        }
        if (!this.props.removeOverflowOnClose) {
            document.body.classList.remove("overflow-hidden");
        }
    }
    
    overlayClick = (e) => {
        if (this.props.userMustAccept) return;
        if (e.target === e.currentTarget) {
            this.closeModal();
        }
    }

    render() {
        return (
            <FocusTrap
                paused={this.props.isFocusTrapPaused}
                focusTrapOptions={{
                    onDeactivate: this.unmountTrap
                }}
            >
                <div className={`modal ${this.props.name} is-open ${this.props.small ? 'modal--small' : ''}`} onMouseDown={(e) => this.overlayClick(e)}>
                    <div className="modal_content">
                        <div className="modal_content_main">
                            <div className="modal_content_main_top">
                                {this.props.title && <h2 className='modal_content_main_top_title'>{this.props.title}</h2>}
                                {!this.props.userMustAccept && <button className="modal_content_main_top_close" aria-label="Close Modal" onClick={() => this.closeModal()}>
                                    <X />
                                </button>}
                            </div>
                            <div className="modal_content_main_body">
                                {this.props.content}
                            </div>
                        </div>
                        <div className="modal_content_actions">
                            {(!this.props.hideCancel && !this.props.userMustAccept) && <button className="button button--secondary button--cancel" onClick={this.closeModal}>{this.props.cancelButtonText ? this.props.cancelButtonText : "Cancel"}</button>}
                            {this.props.actions}
                        </div>
                    </div>
                </div>
            </FocusTrap>
        )
    }
}
