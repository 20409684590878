import {
	RECEIVE_CHECKOUT_SESSIONS,
} from '../../actions/allActions';

const checkoutSessionsReducer = (state = {}, action) => { 
	Object.freeze(state);

	switch (action.type) {
		case RECEIVE_CHECKOUT_SESSIONS:
			return action.payload.checkoutSessions;
		default:
			return state;
	}
}

export default checkoutSessionsReducer;
