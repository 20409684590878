import { connect } from 'react-redux'
import { setTheme } from '../../actions/allActions'
import { withRouter } from "react-router";
import Settings from './Settings'
import {
    fetchUser,
    updatePassword,
    updateUser,
    fetchBillingPortal,
    updateUserProperties,
    deleteUser
} from '../../actions/allActions'

const mapStateToProps = state => {
    return {
        theme: state.theme,
        user: state.user,
        userProperties: state.userProperties,
        subscriptions: state.subscriptions,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchUser: () => dispatch(fetchUser()),
        setTheme: (theme) => dispatch(setTheme(theme)),
        updateUser: (user_id, attributes) => dispatch(updateUser(user_id, attributes)),
        updatePassword: (current_password, new_password, new_password_confirmation) => dispatch(updatePassword(current_password, new_password, new_password_confirmation)),
        fetchBillingPortal: () => dispatch(fetchBillingPortal()),
        updateUserProperties: properties => dispatch(updateUserProperties(properties)),
        deleteUser: userId => dispatch(deleteUser(userId)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Settings))
