import React from 'react';
import { Star } from 'react-feather';

const ToastUnstar = () => (
    <div className='toast--unstar'>
        <Star/><span>Star Removed</span>
    </div>
);

export default ToastUnstar;
