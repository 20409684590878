import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import customStyles from '../../util/singleSelectStyles';
import { cloneDeck, receiveTaggingStrategy } from '../../actions/allActions';
import Modal from '../misc/Modal';

function DeckCloner({ hide, deckId, tags, cloneDeck, taggingStrategy, receiveTaggingStrategy }) {
    const [name, setName] = useState('');
    const [flatten, setFlatten] = useState(true);
    const [tagFilter, setTagFilter] = useState(false);
    const [isFocusTrapPaused, setIsFocusTrapPaused] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);

    const handleFlattenChange = (e) => {
        setFlatten(e.target.value === 'true');
    }

    const handleCheckedChange = (e) => {
        setTagFilter(!tagFilter);
    }

    const tagChangeHandler = (selection) => {
        selection = selection.map(item => item.value);
        setSelectedTags(selection);
    }

    const taggingStrategyChangeHandler = (e) => {
        const taggingStrategy = e.target.value;
        receiveTaggingStrategy(taggingStrategy);
    }

    const handleSubmit = () => {
        const cloneOptions = { name, flatten, tagFilter, tags: selectedTags, taggingStrategy };
        cloneDeck(deckId, cloneOptions);
        hide();
    }

    const suspendFocusTrap = () => {
        setIsFocusTrapPaused(true);
    }
    const enableFocusTrap = () => {
        setIsFocusTrapPaused(false);
    }

    const formattedTags = tags?.sort().map((tag) => { return { 'value': tag, 'label': tag } });

    return (
        <Modal
            name={'modal--clone-deck'}
            title='Clone Deck'
            hide={hide}
            small={true}
            isFocusTrapPaused={isFocusTrapPaused}
            content={
                <>
                    <div>
                        <label htmlFor="name" className='clone-deck-label'>New Name</label>
                        <input type="text" id="name" className='clone-deck-input' name="name" value={name} onChange={(e) => setName(e.target.value)} maxLength="40" />
                    </div>
                    <div>
                        <label htmlFor="tag-toggle" className='clone-deck-label'>Structure</label>
                        <div className='toggle-wrapper'>
                            <div className='toggle'>
                                <div className="toggle_option">
                                    <input
                                        type="radio"
                                        name="tag-toggle"
                                        value="false"
                                        checked={!flatten}
                                        onChange={handleFlattenChange}
                                    />
                                    <label>Retain Hierarchy</label>
                                </div>
                                <div className="toggle_option">
                                    <input
                                        type="radio"
                                        name="tag-toggle"
                                        value="true"
                                        checked={flatten}
                                        onChange={handleFlattenChange}
                                    />
                                    <label>Flatten</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <label htmlFor="checkbox" className='clone-deck-label'>Tag Filtering</label>
                    <div className="tag-checkbox">
                        <div className='tag-prompt'>Filter on tags?</div>
                        <input
                            type="checkbox"
                            name="checkbox"
                            checked={tagFilter}
                            onChange={handleCheckedChange}
                        />
                    </div>
                    <div className='row'>
                        <Select
                            placeholder={'Filter by Tag'}
                            isSearchable
                            isClearable
                            isMulti
                            styles={customStyles}
                            options={formattedTags}
                            onChange={tagChangeHandler}
                            onMenuOpen={suspendFocusTrap}
                            onMenuClose={enableFocusTrap}
                            menuPortalTarget={document.body}
                            menuPlacement="auto"
                            menuPosition="fixed"
                        />
                    </div>
                    <div className='row'>
                        <div className='toggle-wrapper'>
                            <div className='toggle'>
                                <div className="toggle_option">
                                    <input
                                        type="radio"
                                        name="strategy-toggle"
                                        value="and"
                                        checked={taggingStrategy === 'and'}
                                        onChange={taggingStrategyChangeHandler}
                                    />
                                    <label>And</label>
                                </div>
                                <div className="toggle_option">
                                    <input
                                        type="radio"
                                        name="strategy-toggle"
                                        value="or"
                                        checked={taggingStrategy === 'or'}
                                        onChange={taggingStrategyChangeHandler}
                                    />
                                    <label>Or</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            actions={
                <>
                    <button className='button m-l-auto' onClick={handleSubmit}>Clone</button>
                </>
            }
        >
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    taggingStrategy: state.tags?.strategy,
});
const mapDispatchToProps = (dispatch) => ({
    cloneDeck: (deckId, options) => dispatch(cloneDeck(deckId, options)),
    receiveTaggingStrategy: (strategy) => dispatch(receiveTaggingStrategy(strategy)),
});
export default connect(mapStateToProps, mapDispatchToProps)(DeckCloner);
