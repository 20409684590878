import { RECEIVE_LAST_CLONE_TARGET } from '../../actions/allActions';

const lastCloneTargetReducer = (state = '', action) => { 
	Object.freeze(state);
	switch (action.type) {
		case RECEIVE_LAST_CLONE_TARGET:
			return action.payload;
		default:
			return state;
	}
}

export default lastCloneTargetReducer;
