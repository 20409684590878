const openIntercomUtil = () => {
    window.Intercom('show');
    // Disabling for Now
    // let routeToContent = window.location.href;
    // if (routeToContent.includes('/deck')) {
    //     window.Intercom('showArticle', 7852313);
    // } else if (routeToContent.includes('/browse')) {
    //     window.Intercom('showArticle', 7852456);
    // } else if (routeToContent.includes('/stats')) {
    //     window.Intercom('showArticle', 7852459);
    // } else if (routeToContent.includes('/builder')) {
    //     window.Intercom('showArticle', 7852460);
    // } else if (routeToContent.includes('/upload')) {
    //     window.Intercom('showArticle', 7852461);
    // } else if (routeToContent.includes('/settings')) {
    //     window.Intercom('showArticle', 7852492);
    // } else {
    //     window.Intercom('showSpace', 'help');
    // }
}

export default openIntercomUtil;