import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Search } from 'react-feather';
import { useHistory, useLocation } from 'react-router-dom';

function GlobalSearch({ user }) {
    const [currentSearchFunctionIndex, setCurrentSearchFunctionIndex] = useState(0);
    const [panelIsOpen, setPanelIsOpen] = useState(false);
    const [currentSearchValue, setCurrentSearchValue] = useState('');
    const history = useHistory();

    useEffect(() => {
        const slashPress = (e) => {
            if (this !== e.target && (/textarea|select|input/i.test( e.target.nodeName ) || e.target.type === "text" || e.target.isContentEditable)) { return; }
            if (e.key === "/" || e.which === 191 || e.code === "Slash") {
                e.preventDefault();
                const searchField = document.getElementById('global-search');
                searchField?.focus();
            }
        };
        document.addEventListener('click', handleClickOutside);
        document.addEventListener('keydown', slashPress);
        return () => {
            document.removeEventListener('click', handleClickOutside);
            document.removeEventListener('keydown', slashPress);
        };
    }, []);

    const handleChange = (e) => {
        if (e.target.value) {
            setPanelIsOpen(true);
        } else {
            setPanelIsOpen(false);
            setCurrentSearchFunctionIndex(0);
        }
        setCurrentSearchValue(e.target.value);
    }

    const handleClickOutside = (e) => {
        const element = document.getElementById('gsearch');
        if (element && !element.contains(e.target)) {
            setPanelIsOpen(false)
        }
    };

    const handleKeyPress = (e) => {
        if (e.code == "Enter" || e.keyCode == 13 || e.key == "Enter") {
            e.target.blur();
            e.preventDefault();
            const searchType = returnSearchFunction();
            if (searchType === 'google') {
                submitGoogleSearch();
            } else {
                submitBrowseSearch();
            }
        } else if (e.code == "ArrowUp" || e.keyCode == 38 || e.key == "ArrowUp") {
            setActiveSearchFunction(-1);
        } else if (e.code == "ArrowDown" || e.keyCode == 38 || e.key == "ArrowDown") {
            setActiveSearchFunction(1);
        }
    }

    const submitBrowseSearch = () => {
        history.push('/browse?search=' + currentSearchValue);
        setPanelIsOpen(false);
        setCurrentSearchValue('');
    }
    
    const submitGoogleSearch = () => {
        const url = `https://www.google.com/search?q=${encodeURIComponent(currentSearchValue)}`;
        window.open(url, "_blank");
        setPanelIsOpen(false);
        setCurrentSearchValue('');
    }

    const setActiveSearchFunction = (increment) => {
        let index = currentSearchFunctionIndex + increment;
        const parentElement = document.querySelector('#search-functions');
        const listItems = parentElement.querySelectorAll('li');
        const listItemsCount = listItems.length;
        if (index < 0) {
            index = listItemsCount - 1;
        }
        if (index >= listItemsCount) {
            index = 0;
        }
        listItems.forEach((item, itemIndex) => {
            if (itemIndex === index) {
                item.classList.add('active');
            } else {
                item.classList.remove('active');
            }
        });
        setCurrentSearchFunctionIndex(index);
    }

    const handleFocus = () => {
        if (currentSearchValue) {
            setPanelIsOpen(true);
        }
    }

    const returnSearchFunction = () => {
        let type;
        const parentElement = document.querySelector('#search-functions');
        const listItems = parentElement.querySelectorAll('li');
        listItems.forEach((item, itemIndex) => {
            if (itemIndex === currentSearchFunctionIndex) {
                type = item.getAttribute('data-search');
            }
        });
        return type;
    }

    return (
        <form id="gsearch" className='gsearch'>
            <div className='gsearch-input-wrapper'>
                <Search />
                <input
                    id="global-search"
                    type="text"
                    placeholder='Search for something...'
                    onChange={(e) => handleChange(e)}
                    onKeyDown={(e) => handleKeyPress(e)}
                    onFocus={handleFocus}
                    value={currentSearchValue}
                />
                <div className='badge badge--keybind'>/</div>
            </div>
            <div className={`gsearch-panel ${panelIsOpen ? 'is-open' : ''}`}>
                <ul id="search-functions">
                    <li className='active' data-search='browse' onClick={submitBrowseSearch}><span>Search Cards</span><div className='badge--keybind'>ENTER</div></li>
                    <li data-search='google' onClick={submitGoogleSearch}><div>Search Google</div><div className='badge--keybind'>ENTER</div></li>
                </ul>
            </div>
        </form>
    );
}

const mapStateToProps = (state) => ({
    user: state.user
});
const mapDispatchToProps = (dispatch) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(GlobalSearch);
