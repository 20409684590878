export const getSearchValue = () => {
    const url = window.location.href;
    const param = "search";
    var startIndex = url.indexOf(param + "=");
    if (startIndex === -1) return null;
  
    var endIndex = url.indexOf("&", startIndex);
    if (endIndex === -1) endIndex = url.length;
  
    var value = url.substring(startIndex + param.length + 1, endIndex);
    value = decodeURIComponent(value.replace(/\+/g, ' '));
    return value;
}