import React, { Component } from 'react';
import AnkiDropzone from './AnkiDropzone';

export default class DeckUploader extends Component {
    render() {
        return (
            <div>
                <AnkiDropzone />
            </div>
        )
    }
}

