import React, { useState, useEffect } from 'react';
import TargetDeckSelector from './TargetDeckSelector';
import { connect } from 'react-redux';
import { Search } from 'react-feather';
import parse from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
    fetchSemanticSearchResults,
    curatorClone
} from '../../actions/allActions';

function CuratorHome({ curatorClone, lastCloneTarget, decks }) {
    const [searchValue, setSearchValue] = useState('');
    const [cards, setCards] = useState([]);
    const [selectedIdxs, setSelectedIdxs] = useState(new Set());
    const [showDeckSelector, setShowDeckSelector] = useState(false);
    const [submitEnabled, setSubmitEnabled] = useState(false);

    const toastConfig = { position: "bottom-left", autoClose: 3000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined }
    const sanitizeConfig = {
        // allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'h1', 'h2', 'cloze'],
        // allowedAttributes: {},
        css: false // Disallow inline CSS
    };

    useEffect(() => {
        if (decks[lastCloneTarget] && selectedIdxs.size > 0) {
            setSubmitEnabled(true);
        } else {
            setSubmitEnabled(false);
        }
    }, [lastCloneTarget, selectedIdxs]);

    const handleSearchSubmit = async (e) => {
        e.preventDefault();
        const res = await fetchSemanticSearchResults(searchValue);
        const cards = res.cards;
        setCards(cards);
        setSelectedIdxs(new Set());
    }

    const handleChangeTargetDeck = () => {
        setShowDeckSelector(true);
    }

    const handleCloneSubmit = () => {
        const cardIds = [];
        for (const idx of selectedIdxs) {
            cardIds.push(cards[idx].id);
        }
        if (!decks[lastCloneTarget]) {
            window.toast.error('You must select a target deck deck.', toastConfig);
            return;
        }
        if (cardIds.length === 0) {
            window.toast.error('You must select some cards to clone.', toastConfig);
            return;
        }
        curatorClone(lastCloneTarget, cardIds);
        setSelectedIdxs(new Set());
    }

    const handleCardClick = (e, idx) => {
        if (e.shiftKey) {
            for (let i = 0; i <= idx; i++) {
                addSelectedIdx(i);
            }
        } else {
            if (selectedIdxs.has(idx)) {
                removeSelectedIdx(idx);
            } else {
                addSelectedIdx(idx);
            }
        }
    }

    const addSelectedIdx = idx => {
        setSelectedIdxs(prev => new Set(prev).add(idx));
    }

    const removeSelectedIdx = idx => {
        setSelectedIdxs(prev => {
            const next = new Set(prev);
            next.delete(idx);
            return next;
        });
    }

    const hideDeckSelector = () => {
        setShowDeckSelector(false);
    }

    const CardRow = ({ index, style, data }) => {
        const card = data[index];
        return (
            <div style={style}>
                <div onClick={(e) => handleCardClick(e, index)} className={`card-result-row ${selectedIdxs.has(index) ? 'card-result-selected' : ''}`}>
                    <div className='card-result-item'>
                        {parse(sanitizeHtml(card.question, sanitizeConfig))}
                    </div>
                    <div className='card-result-item'>
                        {parse(sanitizeHtml(card.answer, sanitizeConfig))}
                    </div>
                </div>
            </div>
        )
    };

    return (
        <div className="layout layout--clone">
            <div className="layout--clone__container">
                <div className="layout--clone__container__main">
                    <h1>Curation <span>Home</span></h1>
                    <div className='search-wrapper'>
                        <form onSubmit={handleSearchSubmit}>
                            <div className="browse-search">
                                <Search />
                                <input id="browse-table-search" className="browse-search__input" type="text" placeholder="Search a topic..." value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                            </div>
                            <input type="submit" value="Search" className="button" />
                        </form>
                    </div>
                    <div className="actions-wrapper">
                        <div className="target-changer">
                            <div className="target-indicator">
                                Adding Cards To: <span>{decks[lastCloneTarget] ? decks[lastCloneTarget]?.name : '_________'}</span>
                            </div>
                            <button
                                className="button button--secondary button--small"
                                onClick={handleChangeTargetDeck}
                            >
                                Change Target Deck
                            </button>
                        </div>
                        <button
                            className={`button button--success button--medium ${submitEnabled ? '' : 'is-disabled'}`}
                            onClick={handleCloneSubmit}
                        >
                            Add Cards to Target Deck
                        </button>
                    </div>
                    <div className='results-header'>
                        <div className='item'>Front</div>
                        <div className='item'>Back</div>
                    </div>
                    <div className="cards-results-wrapper">
                        <AutoSizer>
                            {({ height, width }) => (
                                <List
                                    className="List"
                                    height={height}
                                    itemCount={cards.length}
                                    itemSize={230}
                                    width={width}
                                    itemData={cards}
                                >
                                    {CardRow}
                                </List>
                            )}
                        </AutoSizer>
                    </div>
                    {showDeckSelector && 
                        <TargetDeckSelector hide={hideDeckSelector} />
                    }
                </div>
            </div>
        </div>
    )
}

const mSTP = (state) => ({
    lastCloneTarget: state.lastCloneTarget,
    decks: state.decks,
});
const mDTP = (dispatch) => ({
    curatorClone: (targetDeckId, cardIds) => dispatch(curatorClone(targetDeckId, cardIds))
});
export default connect(mSTP, mDTP)(CuratorHome);
