import _ from 'lodash';
import React, { Component } from 'react';
import { isCohortUser, isPaywalled, isThiemeDeck } from '../../util/cohortUtil';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import isMobile from '../../util/isMobile';
import customStyles from '../../util/singleSelectStyles';
import { initiateModalExit } from '../../util/modalExit';
import ClipLoader from "react-spinners/ClipLoader";
import YieldToggle from '../misc/YieldToggle';
import FocusTrap from 'focus-trap-react';
import {
    Send,
    Plus,
    X,
    Trash2,
    Settings,
    Edit,
    GitMerge,
    RefreshCcw,
    MoreVertical,
    Info,
    Copy,
    Layers,
    Lock,
    Book,
    EyeOff,
    Eye
} from 'react-feather';
import Modal from '../misc/Modal';

export default class SessionSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuPosition: 'left',
            isDeckActionsMenuOpen: false,
            isConfirmationModalOpen: false,
            newToStudy: 0,
            dueToStudy: 0,
            sessionName: null,
            optionIdx: 0,
            confirming: null,
            initializedDebounce: false,
            debounced: null,
            deleteInputMatchesTitle: false,
            isFocusTrapPaused: false,
            quickStart: false,
            numberTagsSelected: 0,
            showReferences: false
            // taggingStrategy: 'and',
        }
        this.actionsMenuRef = React.createRef();
    }

    async componentDidMount() {
        if (this.props.isQuickStart) {
            this.setState({ quickStart: true });
        }

        this.props.fetchUserProperties().then(() => {
            if (this.props.userProperties?.lastStudySession && !this.props.confirmingMenuType) {
                document.getElementById('btn_option_' + this.props.userProperties.lastStudySession)?.click();
            }
        });

        if (this.props.confirmingMenuType) {
            this.toggleConfirming(this.props.confirmingMenuType);
        }

        document.body.classList.add("overflow-hidden");
        this.props.receiveTags(this.props.deck.tag_list);

        this.initializedDebounce();

        if (this.props.newCount == 0 && this.props.dueCount == 0) {
            this.setState({ quickStart: false });
            return;
        }

        this.props.clearCards();
        this.props.clearSelectedDeck();

        if (!_.isEmpty(this.props.userProperties?.persistentTags) 
            && !_.isEmpty(this.props.userProperties?.persistentTags[this.props.deckId])
            && !_.isEmpty(this.props.userProperties?.persistentTags[this.props.deckId].tags)) {
            const tags = this.props.userProperties?.persistentTags[this.props.deckId].tags;
            const taggingStrategy = this.props.userProperties?.persistentTags[this.props.deckId].taggingStrategy;
            await this.selectTag(tags, taggingStrategy);
            if (this.props.isQuickStart) {
                setTimeout(() => {
                    const start = document.getElementById('start-session');
                    if (!start?.classList.contains("disable-submit")) {
                        start?.click();
                    }
                    this.setState({ quickStart: false });
                }, 500);
            }
        } else {
            const options = {
                count: 5,
                type: 'new_and_due',
            }
            this.props.fetchCards(this.props.deckId, options).then(() => {
                if (this.props.isQuickStart) {
                    setTimeout(() => {
                        const start = document.getElementById('start-session');
                        if (!start?.classList.contains("disable-submit")) {
                            start?.click();
                        }
                        this.setState({ quickStart: false });
                    }, 500);
                }
            });
        }


        this.refreshStudyCounts();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.optionIdx !== prevState.optionIdx) {
            this.props.updateUserProperties({ lastStudySession: this.state.optionIdx });
        }
    }

    componentWillUnmount() {
        this.removeKeyBinds();
    }

    // Set Session Settings Keybinds
    initializedDebounce = () => {
        if (!this.state.initializedDebounce) {
            const debounced = _.debounce(this.keyBinds, 50, { 'leading': true, 'trailing': false })
            window.addEventListener('keydown', debounced)
            this.setState({ initializedDebounce: true, debounced })
        }
    }
    removeKeyBinds = () => {
        if (this.state.initializedDebounce) {
            window.removeEventListener('keydown', this.state.debounced);
        }
    }
    keyBinds = (e) => {
        if (e.key === "Escape" || e.which === 27 || e.code === "Escape") {
            if (this.state.confirming) {
                this.toggleConfirming(null);
            } else {
                this.props.hideModal();
            }
            // Enter Key should start session only if focus is on the session option that is active, otherwise it should select that option (or any other elements that the keyboard would normally select)
        } else if (!this.state.confirming && (e.key === "Enter" || e.which === 13 || e.code === "Enter")) {
            if (document.activeElement === document.getElementById('btn_option_0') || document.activeElement === document.getElementById('option_0')) {
                if (document.getElementById('option_0').checked && !(this.state.newToStudy == 0 && this.state.dueToStudy == 0)) {
                    e.preventDefault();
                    document.getElementById('start-session')?.click();
                } else {
                    document.getElementById('btn_option_0')?.click();
                    document.getElementById('btn_option_0')?.focus();
                }
            } else if (document.activeElement === document.getElementById('btn_option_1')) {
                if (document.getElementById('option_1').checked && !(this.state.newToStudy == 0 && this.state.dueToStudy == 0)) {
                    e.preventDefault();
                    document.getElementById('start-session')?.click();
                } else {
                    document.getElementById('btn_option_1')?.click();
                    document.getElementById('btn_option_1')?.focus();
                }
            } else if (document.activeElement === document.getElementById('btn_option_2')) {
                if (document.getElementById('option_2').checked && !(this.state.newToStudy == 0 && this.state.dueToStudy == 0)) {
                    e.preventDefault();
                    document.getElementById('start-session')?.click();
                } else {
                    document.getElementById('btn_option_2')?.click();
                    document.getElementById('btn_option_2')?.focus();
                }
            }
        } else if (!this.state.confirming && e.key === "1" || e.which === 49 || e.code === "Digit1") {
            document.getElementById('btn_option_0')?.click();
            document.getElementById('btn_option_0')?.focus();
        } else if (!this.state.confirming && e.key === "2" || e.which === 50 || e.code === "Digit2") {
            document.getElementById('btn_option_1')?.click();
            document.getElementById('btn_option_1')?.focus();
        } else if (!this.state.confirming && e.key === "3" || e.which === 51 || e.code === "Digit3") {
            document.getElementById('btn_option_2')?.click();
            document.getElementById('btn_option_2')?.focus();
        }
    }

    calculateMenuPosition = () => {
        let gearRect = document.querySelector('.button--deck-actions')?.getBoundingClientRect();
        let bodyRect = document.body.getBoundingClientRect();
        if (gearRect && gearRect.left > (bodyRect.width / 2)) {
            this.setState({ menuPosition: 'right' });
        } else {
            this.setState({ menuPosition: 'left' });
        }
    }

    refreshStudyCounts = () => {
        if (!this.props.confirmingMenuType) {
            document.getElementById(`btn_option_${this.state.optionIdx}`)?.click();
        }
    }

    closeModal = () => {
        document.removeEventListener('click', this.closeActionsMenu);
        this.props.clearTags();
        this.props.fetchDeckProgress();
        initiateModalExit('#session-modal', this.props.hideModal);
    }

    overlayClick = (e) => {
        if (this.state.confirming && e.target === e.currentTarget) {
            this.toggleConfirming(null);
        }
        else if (e.target === e.currentTarget) {
            this.closeModal();
        }
    }

    setStudySettings = () => {
        const config = {
            due: this.state.dueToStudy,
            new: this.state.newToStudy,
            deckId: this.props.deckId
        };
        this.props.receiveActiveStudyProfile(config);
        this.props.invalidateProgressCaches(this.props.deckId);
        ahoy.track("Begin Session", {
            name: this.state.sessionName,
            deck_id: this.props.deck.id,
            deck_name: this.props.deck.name
        });
    }

    toggleDeckActionsMenu = () => {
        this.calculateMenuPosition();
        this.setState({ isDeckActionsMenuOpen: !this.state.isDeckActionsMenuOpen }, () => {
            document.addEventListener("click", this.closeActionsMenu);
        });
    }

    closeActionsMenu = () => {
        if (this.actionsMenuRef.current && this.actionsMenuRef.current.contains(event.target)) return;
        this.setState({ isDeckActionsMenuOpen: false }, () => {
            document.removeEventListener('click', this.closeActionsMenu);
        });
    }

    toggleConfirming = (confirming) => {
        document.querySelector('#session-modal > .modal_content').style.animation = 'none';
        document.querySelector('#session-modal > .modal_content')?.offsetHeight;
        document.querySelector('#session-modal > .modal_content')?.classList.toggle("modalOpenAnimation");
        document.querySelector('#session-modal > .modal_content').style.animation = null;
        if (confirming === null) {
            this.setState({ confirming, deleteInputMatchesTitle: false });
            if (this.props.closeAfterConfirming) {
                this.props.hideModal();
            }
        } else {
            this.setState({ confirming });
        }
    }

    clickEditDeck = () => {
        this.props.handleEditClick();
    }

    clickMergeDeck = () => {
        this.props.handleMergeClick(false);
    }

    clickDeleteDeck = () => {
        this.props.deleteDeck(this.props.deck.id);
        this.props.hideModal();
    }

    clickRemoveDeck = () => {
        this.props.deleteDeckOwnership(this.props.deck.id);
        this.props.hideModal();
    }

    clickResetDeck = () => {
        this.props.resetDeck(this.props.deck.id);
        this.props.hideModal();
    }

    clickSuspendDeck = () => {
        this.props.suspendDeck(this.props.deck.id);
        this.props.hideModal();
    }

    clickUnSuspendDeck = () => {
        this.props.unSuspendDeck(this.props.deck.id);
        this.props.hideModal();
    }

    setSelectedDeck = () => {
        this.props.receiveSelectedDeck(this.props.deck.id);
    }

    editStudyProfile = (id) => {
        this.props.editStudyProfile(id);
    }

    cloneDeck = () => {
        this.props.cloneDeck(this.props.deck.id);
    }

    showSchoolAssigner = () => {
        this.props.showSchoolAssigner(this.props.deck.id);
    }

    saveCollapseDefaults = () => {
        this.props.saveCollapseDefaults(this.props.deck.id);
    }

    setAsPaid = () => {
        this.props.setAsPaid(this.props.deck.id);
    }

    handleConfirmDeckDelete = (e) => {
        const deleteInputMatchesTitle = this.props.deck.name === e.target.value;
        this.setState({ deleteInputMatchesTitle });
    }

    handleConfirmKeyUp = (e) => {
        if (this.state.deleteInputMatchesTitle && (e.code == "Enter" || e.keyCode == 13 || e.key == "Enter")) {
            this.clickDeleteDeck();
        }
    }

    renderConfirmation() {
        return (
            <div ref={this.confirmationModalRef} className="modal_content_confirmation">
                <div className="modal_content_main">
                    <div className="modal_content_main_top">
                        {this.state.confirming === 'reset' &&
                            <h2 className='modal_content_main_top_title'>Are you sure you want to reset <span className='confirmation-deck-name'>{this.props.deck.name}</span>?</h2>}
                        {this.state.confirming === 'suspend' &&
                            <h2 className='modal_content_main_top_title'>Are you sure you want to suspend all cards in <span className='confirmation-deck-name'>{this.props.deck.name}</span>?</h2>}
                        {this.state.confirming === 'unsuspend' &&
                            <h2 className='modal_content_main_top_title'>Are you sure you want to reactivate all cards in <span className='confirmation-deck-name'>{this.props.deck.name}</span>?</h2>}
                        {this.state.confirming === 'delete' &&
                            <h2 className='modal_content_main_top_title'>Are you sure you want to delete <span className='confirmation-deck-name'>{this.props.deck.name}</span>?</h2>}
                        {this.state.confirming === 'remove' &&
                            <h2 className='modal_content_main_top_title'>Are you sure you want to remove <span className='confirmation-deck-name'>{this.props.deck.name}</span> from your account?</h2>}
                        <button className="modal_content_main_top_close" aria-label="Close Modal" onClick={() => this.toggleConfirming(null)}>
                            <X />
                        </button>
                    </div>
                    <div className='modal_content_main_body'>
                        {this.state.confirming === 'reset' &&
                            <p className='m-y-0'>This action will permanently reset progress on all cards in this deck. All cards will be 'New' again.</p>}
                        {this.state.confirming === 'suspend' &&
                            <p className='m-y-0'>Suspended cards will no longer show up in your study sessions. This action is reversible.</p>}
                        {this.state.confirming === 'unsuspend' &&
                            <p className='m-y-0'>Any suspended cards in this deck will be reactivated and show in study sessions.</p>}
                        {this.state.confirming === 'delete' &&
                            <>
                                <p className='m-t-0'>Deleting <strong>{this.props.deck.name}</strong> will delete all its cards and sub-decks. If this is a shared deck, deleting will also remove all Editors and Viewers from the deck, and will delete all users' progress. To delete this deck, type the full name of the deck below.</p>
                                <div className='input m-b-0'>
                                    <input type="text" onChange={this.handleConfirmDeckDelete} onKeyUp={this.handleConfirmKeyUp} placeholder="Type deck name to delete..." />
                                </div>
                            </>
                        }
                        {this.state.confirming === 'remove' &&
                            <p className='m-y-0'>This action will remove this deck from your account. You will no longer be able to view it or its sub-decks and your study progress will be lost.</p>
                        }
                        {/* {this.state.confirming === 'delete' && !this.props.deck.shared &&
                            <p>Deleting <strong>{this.props.deck.name}</strong> will delete all its cards and sub-decks.</p>
                        } */}
                    </div>
                </div>
                <div className='modal_content_actions'>
                    <button className="button button--secondary" onClick={() => this.toggleConfirming(null)}>Cancel</button>
                    {this.state.confirming === 'reset' && <button className="button button--error" onClick={this.clickResetDeck}>Reset</button>}
                    {this.state.confirming === 'suspend' && <button className="button button--error" onClick={this.clickSuspendDeck}>Suspend</button>}
                    {this.state.confirming === 'unsuspend' && <button className="button button--error" onClick={this.clickUnSuspendDeck}>Reactivate</button>}
                    {this.state.confirming === 'delete' && <button className="button button--error" onClick={this.clickDeleteDeck} disabled={!this.state.deleteInputMatchesTitle}>Delete</button>}
                    {this.state.confirming === 'remove' && <button className="button button--error" onClick={this.clickRemoveDeck}>Remove</button>}
                    {/* {this.state.confirming === 'delete' && !this.props.deck.shared && <button className="button button--error" onClick={this.clickDeleteDeck}>Delete</button>} */}
                </div>
            </div>
        )
    }

    renderActionsMenu() {
        return (
            <div ref={this.actionsMenuRef} id="deck-action-menu" className={`menu menu--deck-actions ${this.state.isDeckActionsMenuOpen ? 'is-open' : ''}`}>
                <button className="button button--icon-only button--deck-actions" onClick={() => this.toggleDeckActionsMenu()}>
                    <MoreVertical />
                </button>
                <div className={`deck-action-dropdown menu__dropdown ${this.state.menuPosition}`}>
                    {this.props.deck.access === 'owner' &&
                        <div className="menu__dropdown__item">
                            {<button style={{ textDecoration: 'none' }} onClick={this.clickEditDeck}><Edit /> Edit Deck</button>}
                        </div>
                    }
                    {!isCohortUser(this.props.user) && this.props.deck.access === 'owner' &&
                        <div className="menu__dropdown__item">
                            {<button style={{ textDecoration: 'none' }} onClick={this.clickMergeDeck}><GitMerge /> Merge Deck</button>}
                        </div>
                    }
                    {/* Paid feature */}
                    {!isPaywalled(this.props.user) &&
                        <div className="menu__dropdown__item">
                            {<button style={{ textDecoration: 'none' }} onClick={() => this.toggleConfirming('reset')}><RefreshCcw /> Reset Deck</button>}
                        </div>
                    }
                    {/* <div className="menu__dropdown__item">
                        {<button style={{ textDecoration: 'none' }} onClick={() => this.toggleConfirming('suspend')}><EyeOff /> Suspend Cards</button>}
                    </div>
                    <div className="menu__dropdown__item">
                        {<button style={{ textDecoration: 'none' }} onClick={() => this.toggleConfirming('unsuspend')}><Eye />Reactivate Cards</button>}
                    </div> */}
                    {this.props.deck.access === 'owner' &&
                        <div className="menu__dropdown__item">
                            {<button style={{ textDecoration: 'none' }} onClick={() => this.toggleConfirming('delete')}><Trash2 /> Delete Deck</button>}
                        </div>
                    }
                    {this.props.deck.access !== 'owner' && !isThiemeDeck(this.props.deck) &&
                        <div className="menu__dropdown__item">
                            {<button style={{ textDecoration: 'none' }} onClick={() => this.toggleConfirming('remove')}><Trash2 /> Remove Deck</button>}
                        </div>
                    }
                    {(this.props.user.admin || this.props.user.curator) &&
                        <div className="menu__dropdown__item">
                            {<button style={{ textDecoration: 'none' }} onClick={this.cloneDeck}><Copy /> Clone Deck</button>}
                        </div>
                    }
                    {(this.props.user.admin || this.props.user.curator) &&
                        <div className="menu__dropdown__item">
                            {<button style={{ textDecoration: 'none' }} onClick={this.saveCollapseDefaults}><Layers /> Save Collapse Defaults</button>}
                        </div>
                    }
                    {(this.props.user.admin || this.props.user.curator) &&
                        <div className="menu__dropdown__item">
                            {this.props.deck.paid ?
                                (<button style={{ textDecoration: 'none' }} onClick={this.setAsPaid}><Lock /> Set as Not Pro</button>)
                                : (<button style={{ textDecoration: 'none' }} onClick={this.setAsPaid}><Lock /> Set as Pro</button>)
                            }
                        </div>
                    }
                    {(this.props.user.admin || this.props.user.curator) &&
                        <div className="menu__dropdown__item">
                            {<button style={{ textDecoration: 'none' }} onClick={this.showSchoolAssigner}><Book /> Assign to School</button>}
                        </div>
                    }
                    {this.props.deck.description &&
                        <div className="menu__dropdown__item">
                            {<button style={{ textDecoration: 'none' }} onClick={this.showReferencesModal}><Info /> References</button>}
                        </div>
                    }
                </div>
            </div>
        )
    }

    showReferencesModal = () => {
        this.setState({ showReferences: true });
    }

    hideReferencesModal = () => {
        this.setState({ showReferences: false });
    }

    selectTag = async (tags, taggingStrategy) => {
        // Depending on if this function is called by the selector onChange or
        // the taggingStrategy toggle onChange, the tags array will be a different structure.
        // This checks which type it is and and handles accordingly.
        if (!!tags && typeof tags[0] === 'object') {
            tags = tags.map(entry => entry.value)
        }
        const numberTagsSelected = tags ? tags.length : 0;
        this.setState({ numberTagsSelected });
        this.props.fetchDeckProgress(tags, taggingStrategy).then(this.refreshStudyCounts);
        this.props.receiveSelectedTags(tags);
        this.setPersistentTags(tags, this.props.deckId, taggingStrategy);
        this.props.clearCards();
        const options = {
            count: 5,
            type: 'new_and_due',
            tags: tags,
            taggingStrategy: taggingStrategy,
        }
        await this.props.fetchCards(this.props.deckId, options);
    }

    suspendFocusTrap = () => {
        this.setState({ isFocusTrapPaused: true });
    }
    enableFocusTrap = () => {
        this.setState({ isFocusTrapPaused: false });
    }

    setPersistentTags = (tags, deckId, taggingStrategy) => {
        let tempTags = _.clone(this.props.userProperties.persistentTags);
        if (!tempTags) {
            tempTags = {};
        }
        if (!tempTags[deckId]) {
            tempTags[deckId] = {};
        }
        tempTags[deckId].tags = tags;
        tempTags[deckId].taggingStrategy = taggingStrategy;
        this.props.updateUserProperties({ persistentTags: tempTags });
    }

    renderTagDropdown() {
        // Hides this feature from generic users until we want to release
        const YTTooltip = `
            <div class="yt-options">
                <p><strong>High Yield</strong> - Select this tag to only focus on the most critical info.</p>
                <p><strong>Standard</strong> - Select this tag to learn the bulk of the important material.</p>
                <p><strong>All</strong> - Select this tag to learn everything, including the most granular details.</p>
            </div>
        `;
        if (_.isEmpty(this.props.tags)) return;
        if (isThiemeDeck(this.props.deck)) {
            const yieldValues = [
                { label: 'High Yield', value: 'High Yield' },
                { label: 'Standard', value: 'Standard' },
                { label: 'All', value: 'all' }
            ]
            const taggingStrategy = this.props.taggingStrategy;
            let defaultSelected = { label: 'All', value: 'all' };
            if (!_.isEmpty(this.props.userProperties?.persistentTags)) {
                if (!_.isEmpty(this.props.userProperties?.persistentTags[this.props.deckId])) {
                    if (this.props.userProperties?.persistentTags[this.props.deckId].tags?.length > 0) {
                        if (this.props.userProperties?.persistentTags[this.props.deckId].tags[0] === 'High Yield') {
                            defaultSelected = { label: 'High Yield', value: 'High Yield' };
                        } else if (this.props.userProperties?.persistentTags[this.props.deckId].tags[0] === 'Standard') {
                            defaultSelected = { label: 'Standard', value: 'Standard' };
                        }
                    }
                }
            }
            return (
                <div className='yield-toggle-wrapper'>
                    <Info data-tip={YTTooltip} data-html={true} data-place="left" />
                    <YieldToggle
                        handleYieldToggleChange={(_tag) => this.selectTag(_tag, taggingStrategy)}
                        values={yieldValues}
                        selected={defaultSelected}
                    />
                </div>
            )
        } else {
            let tags;
            if (this.props.user.admin || this.props.user.curator) {
                tags = this.props.tags
                    .filter((tag) => !tag.includes('[hide]'))
                    .map((tag) => { return { 'value': tag, 'label': tag } });
            } else {
                tags = this.props.tags
                    .filter((tag) => (!tag.includes('[hide]') && !tag.includes('Starred') && !tag.includes('Image Card')))
                    .map((tag) => { return { 'value': tag, 'label': tag } });
            }
            let startTags = '';
            let taggingStrategy = this.props.taggingStrategy;
            if (!_.isEmpty(this.props.userProperties?.persistentTags)) {
                if (!_.isEmpty(this.props.userProperties?.persistentTags[this.props.deckId])) {
                    if (this.props.userProperties?.persistentTags[this.props.deckId].tags?.length > 0) {
                        startTags = this.props.userProperties?.persistentTags[this.props.deckId].tags
                            .filter((tag) => !tag.includes('[hide]'))
                            .sort()
                            .map((tag) => { return { 'value': tag, 'label': tag } });
                        taggingStrategy = this.props.userProperties?.persistentTags[this.props.deckId].taggingStrategy;
                    }
                }
            }
            return (
                <div className='tag-section'>
                    <Select
                        defaultValue={startTags}
                        placeholder={'Study by Tag'}
                        isSearchable
                        isClearable
                        isMulti
                        options={tags}
                        onChange={(_tag) => this.selectTag(_tag, taggingStrategy)}
                        onMenuOpen={this.suspendFocusTrap}
                        onMenuClose={this.enableFocusTrap}
                        styles={customStyles}
                        menuPortalTarget={document.body}
                        menuPlacement="auto"
                        menuPosition="fixed"
                    />
                    {this.state.numberTagsSelected > 1 && <div className='toggle-wrapper'>
                        <Info data-tip={"Choose how tags are combined."} data-html={true} data-place="left" />
                        <div className='toggle'>
                            <div className="toggle_option">
                                <input
                                    type="radio"
                                    name="tag-toggle"
                                    value="and"
                                    checked={this.props.taggingStrategy === 'and'}
                                    onChange={this.handleTaggingStrategyChange}
                                />
                                <label>And</label>
                            </div>
                            <div className="toggle_option">
                                <input
                                    type="radio"
                                    name="tag-toggle"
                                    value="or"
                                    checked={this.props.taggingStrategy === 'or'}
                                    onChange={this.handleTaggingStrategyChange}
                                />
                                <label>Or</label>
                            </div>
                        </div>
                    </div>}
                </div>
            )
        }
    }

    handleTaggingStrategyChange = (e) => {
        const taggingStrategy = e.target.value;
        this.props.receiveTaggingStrategy(taggingStrategy);
        this.selectTag(this.props.selectedTags, taggingStrategy);
    }

    calcNewToStudy = (newTarget) => {
        return Math.min(this.props.newCount, newTarget);
    }

    calcDueToStudy = (dueTarget) => {
        if (this.props.progress.hasOwnProperty('unlockedDue')) {
            return Math.min(this.props.unlockedDueCount, dueTarget);
        }
        return Math.min(this.props.dueCount, dueTarget);
    }

    renderContent() {
        const ALL_CARDS_NUM = 99999999;

        const studyOptions = this.props.studyProfiles.map((session, key) => {
            const sessionName = session.name;
            const optionIdx = key;
            let newToStudy, dueToStudy, numCardsJsx;
            if (session.mode === 'fixed_number') {
                dueToStudy = this.calcDueToStudy(session.total);
                // Must come after dueToStudy calc
                newToStudy = this.calcNewToStudy(session.total - dueToStudy);
                numCardsJsx = session.total >= ALL_CARDS_NUM
                    ? (<span className="option-subtitle">All New & All Due</span>)
                    : (<span className="option-subtitle">{session.total} Cards</span>)
            } else if (session.mode === 'new_and_due') {
                newToStudy = this.calcNewToStudy(session.new);
                dueToStudy = this.calcDueToStudy(session.due);

                const numNewCards = session.new >= ALL_CARDS_NUM ? 'All' : session.new;
                const numDueCards = session.due >= ALL_CARDS_NUM ? 'All' : session.due;
                numCardsJsx = (<span className="option-subtitle">{numNewCards} New & {numDueCards} Due</span>);
            }
            return (
                <div key={key} className='form-control'>
                    {key === 0 && <input id={`option_${key}`} type="radio" name="session_choice" defaultChecked />}
                    {key !== 0 && <input id={`option_${key}`} type="radio" name="session_choice" />}
                    <label tabIndex="0" data-tip={!isMobile() ? sessionName : ''} data-delay-show={300} id={`btn_option_${key}`} htmlFor={`option_${key}`} onClick={() => {
                        this.setState({ dueToStudy, newToStudy, sessionName, optionIdx }, () => ReactTooltip.rebuild())
                    }}>
                        {/* Paid feature */}
                        {!isPaywalled(this.props.user) &&
                            <button className="button button--icon-only button--session-settings" onClick={() => this.editStudyProfile(session.id)}><Settings /></button>
                        }
                        <span className="option-title">{session.name}</span>
                        {numCardsJsx}
                    </label>
                </div>
            )
        })
        return (
            <form id="session-settings" className={studyOptions.length === 3 ? 'three-cols' : ''}>
                {studyOptions}
            </form>
        );
    }

    renderSessionModal() {
        const disableSubmit = (this.state.newToStudy == 0 && this.state.dueToStudy == 0);
        const disabledTooltip = 'You must study at least one card.';
        const countLoader = (
            <ClipLoader color={'var(--primary-color)'} loading={true} speedMultiplier={.75} cssOverride={{
                "width": "1rem",
                "height": "1rem"
            }} />
        );
        return (
            <>
                {this.state.quickStart &&
                    <div className='loader-overlay'>
                        <ClipLoader color={'var(--primary-color)'} loading={true} speedMultiplier={.75} cssOverride={{
                            "width": "3rem",
                            "height": "3rem"
                        }} />
                    </div>
                }
                <div className="modal_content_main">
                    <div className="modal_content_main_top">
                        <h2 className='modal_content_main_top_title'>
                            <span>{this.props.deck.name}</span>
                            {this.renderActionsMenu()}
                        </h2>
                        <button className="modal_content_main_top_close" aria-label="Close Modal" onClick={() => this.closeModal()}>
                            <X />
                        </button>
                    </div>
                    <div className="modal_content_main_body">
                        <div className={`deck-controls ${isThiemeDeck(this.props.deck) ? 'is-gb' : ''}`}>
                            {(!this.props.deck.parent_id || this.props.deck.access !== 'viewer') && <div className='deck-controls_left'>
                                {this.props.deck.access !== 'viewer' && !Capacitor.isNativePlatform() &&
                                    <Link to={`/builder`} className="button button--secondary button--mobile-collapse button--small" style={{ textDecoration: 'none' }} onClick={this.setSelectedDeck}><Plus /> <span>Add Cards</span></Link>
                                }
                                {!this.props.deck.parent_id && <button className="button button--secondary button--mobile-collapse button--small" onClick={() => this.props.showDeckSharer(this.props.deckId)}><Send /> <span>Share Deck</span></button>}
                            </div>}
                            {this.renderTagDropdown()}
                        </div>
                        <p className="session-settings-label">Choose your study session length:</p>
                        {this.renderContent()}
                        <div className='footer-content'>
                            <p>You will study <span className="highlight">{!this.props.loading ? this.state.newToStudy : countLoader}</span> new cards and <span className="highlight">{!this.props.loading ? this.state.dueToStudy : countLoader}</span> due cards.</p>
                        </div>
                    </div>
                </div>
                <div className='modal_content_actions'>
                    {this.props.loading ?
                        <div className="session-submit disable-submit">
                            <ClipLoader color={'var(--button-primary-color)'} loading={true} speedMultiplier={.75} cssOverride={{
                                "width": "1rem",
                                "height": "1rem"
                            }} />
                        </div>
                        : <div data-tip={disableSubmit ? disabledTooltip : null}>
                            <Link
                                id="start-session"
                                to={`/deck/${this.props.deckId}`}
                                style={{ textDecoration: 'none' }}
                                onClick={() => this.setStudySettings()}
                                className={`session-submit ${disableSubmit ? 'disable-submit' : ''} ${this.props.firstTimeUser ? 'pulse--green' : ''}`}
                            >
                                Begin Studying
                            </Link>
                        </div>
                    }
                </div>
                <ReactTooltip />
            </>
        )
    }

    render() {
        return (
            <FocusTrap
                paused={this.state.isFocusTrapPaused}
            >
                <div id="session-modal" className="modal modal--session-settings is-open" onMouseDown={(e) => this.overlayClick(e)}>
                    <div className={`modal_content ${this.state.quickStart ? 'qs' : ''} ${!!this.state.confirming ? 'confirming-is-open' : ''}`}>
                        {!!this.state.confirming ? this.renderConfirmation() : this.renderSessionModal()}
                    </div>
                    {this.state.showReferences &&
                        <Modal
                            name={'modal--references'}
                            small={true}
                            title={'References'}
                            cancelButtonText={'Close'}
                            hide={this.hideReferencesModal}
                            content={
                                <>
                                    {this.props.deck.description}
                                </>
                            }
                        />
                    }
                </div>
            </FocusTrap>
        )
    }
}
