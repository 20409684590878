import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import Confetti from '../misc/Confetti';
import { fetchPresignedUrl, importDeck } from '../../actions/allActions';
import { Link } from 'react-router-dom';
import { Upload, Folder } from 'react-feather';
import parse from 'html-react-parser';

function AnkiDropzone({ importDeck, user }) {
  // max file size is 100 MB
  const maxSize = 100_000_000;
  // max file size is 500 MB
  // const maxSize = 524288000;

  const [uploadProgress, setUploadProgress] = useState(0);


  const toastConfig = {
      position: "bottom-left",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
  }

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length !== 1) return;
    const apkg = acceptedFiles[0];
    uploadDeckToS3(apkg)
  }, [])

  const onDropRejected = useCallback(rejectedFiles => {
    if (rejectedFiles.length < 1) return;
    if (rejectedFiles[0].file.size > maxSize) {
      const sizeMessage = parse(
        `<div className="error--upload-size">We currently do not support uploads over 100MB. You can import larger decks by breaking them into smaller (<100MB) chunks and importing the chunks one at a time.</div>`
      );
      window.toast.error(sizeMessage, toastConfig);
    } else if (rejectedFiles[0].errors[0].code === 'file-invalid-type') {
      window.toast.error("File must be of type APKG.", toastConfig);
    }
  }, [])

  const { 
    isDragActive, 
    getRootProps, 
    getInputProps, 
    isDragReject, 
    acceptedFiles, 
    fileRejections,
    open } = useDropzone({
    onDropRejected, 
    onDrop,
    accept: {'application/zip': ['.apkg']},
    minSize: 0,
    maxSize,
  });

  const uploadDeckToS3 = async (file) => {
    const bucket = 'synaptiq-prod';
    const dirName = `apkgs/`;
    const data = await fetchPresignedUrl(bucket, dirName, file.name);

    if (data.url) {
      $.ajax({
        xhr: function() {
          var xhr = new window.XMLHttpRequest();
          xhr.upload.addEventListener("progress", function(evt) {
            if (evt.lengthComputable) {
              var percentComplete = evt.loaded / evt.total;
              percentComplete = parseInt(percentComplete * 99);
              setUploadProgress(percentComplete);
            }
          }, false);
          return xhr;
        },
        url : data.url,
        type : "PUT",
        data : file,
        dataType : "text",
        cache : false,
        contentType : file.type,
        processData : false,
        error: (res) => {
            console.log('error: ' + JSON.stringify(res))
        },
        success: (res) => {
          importDeck(file.name).then(res => {
            if (!!res.job_id) {
              setUploadProgress(100);
              document.getElementById('return-home-from-upload').focus();
            }
          });
        }
      })  
    }
  }

  return (
    <div className='layout layout--upload'>
        <div className="layout--upload__container layout_container">
            <div className='header-content'>
              <h1>Import <span>a Deck</span></h1>
              {(user.subscription_status === 'trialing' || user.subscription_status === 'active') ? <p>Files must be less than 100MB. You can import larger decks by breaking them into smaller (&lt;100MB) chunks and importing the chunks one at a time.</p> : <p>Choose an .apkg file to jumpstart your learning. (100<span className='size'>MB</span> Limit)</p>}
            </div>
            <div className='upload-dropzone'>
              <div className='click-area' {...getRootProps()}>
                <input {...getInputProps()} />
                <div className={`upload-dropzone-content ${ uploadProgress > 0 ? 'active-upload' : ''}`}>
                    <div className='top-icon'><Upload/></div>
                    <h2>Drag &amp; Drop a Deck</h2>
                    <p>Supported decks use the .apkg file extension.</p>
                    <div className='strike-or'>Or</div>
                    <div className='choose-file'><Folder/><span>Choose a File</span></div>
                </div>
                {uploadProgress > 0 && uploadProgress < 100 &&
                  <div className='progress-bar'>
                    <div className='progress-bar-bar' style={{ width: `${uploadProgress}%` }}></div>
                    <div className='progress-bar-percentage'>{ uploadProgress }%</div>
                  </div>
                }
              </div>
              {uploadProgress == 100 &&
                <>
                  <Confetti/>
                  <div className='upload-dropzone-success'>
                    <div className='upload-dropzone-success-content'>
                      <h2>Success!</h2>
                      <p>Your deck has been successfully imported. We're putting on the finishing touches now. You will be notified when your deck is ready to study! You can leave this page, or upload another deck.</p>
                      <div className='button-container'>
                        <Link id="return-home-from-upload" className='button button--primary' to={`/`}>Return Home</Link>
                        <button className='button button--link' onClick={() => { 
                            setUploadProgress(0);
                            open();
                        }}>Import Another Deck</button>
                      </div>
                    </div>
                  </div>
                </>
              }
            </div>
        </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
    user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  importDeck: (deckName) => dispatch(importDeck(deckName))
});

export default connect(mapStateToProps, mapDispatchToProps)(AnkiDropzone);
